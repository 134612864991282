import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { Badge, Col, Row, Media, Progress, Button, Form, Label, Input, FormGroup } from 'reactstrap';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';
import NavLink from 'reactstrap/lib/NavLink';
import { size, map, filter } from 'lodash';
import ReactGA from 'react-ga';

import * as constants from '../../../../../../constants/constants';
import { errorMessages } from '../../../../../../constants/messages';

import ScrollAreaWrapper from '../../../Components/ScrollAreaWrapper';
import { Firebase } from '../../../../../../lib/firebase';

// import PlayerType from './PlayerType';

// import gPlayedImg from '../../../../../../images/icons/games_played.svg';
// import leaderboardImg from '../../../../../../images/icons/leaderboard.svg';
// import gWonImg from '../../../../../../images/icons/wins.svg';
// import giftsImg from '../../../../../../images/icons/gift.svg';
// import infoImg from '../../../../../../images/icons/info_chats.svg';
// import closeImg from '../../../../../../images/redesign/components/modal/close.png';
// import lightCloseImg from '../../../../../../images/redesign/light-mode/components/modal/close.png';
import coin from '../../../../../../images/redesign/common/coin.svg';
import rating from '../../../../../../images/redesign/common/rating.svg';
import defaultImage from '../../../../../../images/redesign/common/default_image.svg';
// import lightDefaultImage from '../../../../../../images/redesign/light-mode/common/default_image.svg';
import { countDigits } from '../../../../../../utils/numUtils';
import addFriendIcon from '../../../../../../images/redesign/mobile/icons/add-friend-icon.svg';
import removeFriendIcon from '../../../../../../images/redesign/mobile/icons/blocked-players-active-dark-icon.svg';
import giftImg from '../../../../../../images/redesign/player/gifts.svg';
import blockImage from '../../../../../../images/redesign/common/block.png';
import unblockImage from '../../../../../../images/redesign/mobile/icons/unblock-icon.svg';
import exchangeCoinImg from '../../../../../../images/redesign/mobile/icons/exchange-coin-icon.svg';
import BlockUserModal from '../../Modals/BlockUser';
import coinImg from '../../../../../../images/redesign/common/coin.svg';


import {
  getFriends,
  addFriend,
  unBlockUser,
  blockUser,
  removeFriend,
  sendMoney,
} from '../../../../../../actions/member';
import IconPopover from '../../../Components/Components/IconPopover';
import CustomModal from '../../../Components/Components/Modal';
import ErrorNotification from '../../../Components/Components/ErrorNotification';

class PlayerModalRightMobile extends React.Component {
  static propTypes = {
    playerInfo: PropTypes.shape(),
    gifts: PropTypes.shape(),
    closePlayerModal: PropTypes.func.isRequired,
    posClassName: PropTypes.string,
    modalOpen: PropTypes.bool,
    photo: PropTypes.string,
    t: PropTypes.func.isRequired,
    screenMode: PropTypes.string,
    member: PropTypes.shape(),
    position: PropTypes.string,
    mId: PropTypes.string,
    uid: PropTypes.string,
    name: PropTypes.string,
    addAFriend: PropTypes.func.isRequired,
    removeAFriend: PropTypes.func.isRequired,
    toggleGiftsModal: PropTypes.func,
    notificationSettings: PropTypes.shape(),
    isIgnored: PropTypes.bool,
    tournamentRoom: PropTypes.bool,
    fetchFriends: PropTypes.func.isRequired,
    friends: PropTypes.shape(),
    socProvider: PropTypes.string,
    sendMoneyToFriend: PropTypes.func.isRequired,
  };

  static defaultProps = {
    playerInfo: null,
    gifts: null,
    posClassName: null,
    modalOpen: false,
    photo: '',
    screenMode: constants.SCREEN_MODE.normal,
    member: null,
    position: null,
    mId: '',
    uid: '',
    name: '',
    notificationSettings: {},
    isIgnored: false,
    tournamentRoom: false,
    friends: {},
    socProvider: ''
  };

  constructor(props) {
    super(props);
    this.state = {
      activeTab: constants.PLYERINFO_NEW_TABS.statistics,
      blockUserModal: false,
      addRemoveFriendPopoverOpen: null,
      sendMoneyModalOpen: false,
      sendMoneyAlertOpen: false,
      sendMoneyAlertType: '',
      sendMoneyMessage: '',
      sendMoneyAmount: 0,
      sendMoneyRestMoney: 0,
      sendMoneyPopoverOpen: false,
      sendMoneyFriendId: '',
    };
  }

  componentDidMount() {
    const {
      fetchFriends, socProvider,
    } = this.props;
    const { hostname } = window.location;

    let devMode = false;

    if (hostname && (hostname.includes('dev') || hostname.includes('localhost'))) {
      devMode = true;
    }
    this.setState({ devMode });

    if (socProvider === 'facebook') {
      Firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          window.FB.getLoginStatus((response) => {
            if (response.status === 'connected') {
              const { accessToken } = response.authResponse;

              window.FB.api(
                `/${user.providerData[0].uid}/friends`,
                'GET',
                { access_token: accessToken },
                (resp) => {
                  //  this.setState({ friends: resp.data });

                  console.log(resp);
                  if (resp && resp.data) {
                    fetchFriends(resp).then((res) => {
                      console.log(res);
                    });
                  }
                },
              );
            }
          });
        } else {
          //  console.log('no user');
        }
      });
    } else if (socProvider === 'draugiem') {

      fetchFriends(devMode);
    } else {
      fetchFriends();
    }
  }

  handleChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    if (event.target.name === 'sendMoneyAmount') {
      if (value.toString().length > 3) return;
      this.setState({
        [event.target.name]: value
          .replace(/[^0-9]/g, '')  // Remove non-numeric characters
          .replace(/^0+/, '')  // Remove leading zeros
      });
    } else {
      this.setState({
        [event.target.name]: value,
      });
    }
  }

  setAddRemoveFriendPopoverOpen = (value) => {
    this.setState({addRemoveFriendPopoverOpen: value});
  }

  setSendMoneyPopoverOpen = (value) => {
    this.setState({sendMoneyPopoverOpen: value});
  }

  playerInfoTab = (tab) => {

    const { activeTab } = this.state;
    console.log(tab, "infoModal")
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  generateImageUrl = (key, borders, index) => {
    let img;
    let imgkey;
    imgkey = key;
    if (key === 'highestEarned') {
      imgkey = 'balance';
    }
    if (size(borders) > 1) {
      if (borders[index + 1]) {
        // eslint-disable-next-line
        img = require(`../../../../../../images/Ach/${imgkey}_${borders[index]
          }_${borders[index + 1]}.png`);
      } else {
        // eslint-disable-next-line
        img = require(`../../../../../../images/Ach/${imgkey}_${borders[index]}.png`);
      }
    } else {
      if (constants.BIG_SIZE_ACH.includes(imgkey)) {
        img = require(`../../../../../../images/Ach/${imgkey}_1.svg`);
      } else {
        img = require(`../../../../../../images/Ach/${imgkey}_1.png`);
      }
    }
   
    // console.log("generatge image key", key, borders, index, img);
    return img;
  }

  closePlayerModalFunc = () => {
    const { closePlayerModal } = this.props;

    closePlayerModal();
  }

  changeDateFormat = (timestamp) => {
    const date = new Date(timestamp);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // add 1 to month as it starts from 0
    const year = date.getFullYear();
    const formattedDate = `${day}.${month}.${year}`;
    return formattedDate;
  }

  handleSendAddFriend = (selectId) => {
    const { addAFriend } = this.props;
    addAFriend(selectId);
  }

  handleRemoveFriend = (selectId) => {
    const { removeAFriend } = this.props;
    removeAFriend(selectId);
  }

  getCirclePaddingRight = (numberInCircle) => {
    const digits = countDigits(numberInCircle);
    if (digits === 1) {
      return 10;
    } else if (digits === 2) {
      return 5;
    } else if (digits === 3) {
      return 1;
    }
    return 0;
  }

  playButtonSound = () => {
    const { soundOn } = this.props;

    if (soundOn) {
      const playPromise = this.buttonClickedAudio.play();
      if (playPromise !== undefined) {
        playPromise
          .then(_ => {
            // Automatic playback started!
            // Show playing UI.
            console.log("audio played auto");
          })
          .catch(error => {
            // Auto-play was prevented
            // Show paused UI.
            console.log("playback prevented");
          });
      }
    }
  }

  toggleBlockUser = () => {
    const { notificationSettings, uid, name } = this.props;
    this.playButtonSound();

    if (notificationSettings && notificationSettings.ignorePlayer === false) {
      this.blockUserInstant(uid, name);
    } else {
      this.setState(prevState => ({
        blockUserModal: !prevState.blockUserModal,
      //  blockUserSelectedUid: id,
        blockUserSelectedName: name,
      }));
    }
  };

  toggleSendMoney = (id) => {
    this.setState(prevState => ({
      sendMoneyModalOpen: !prevState.sendMoneyModalOpen,
      sendMoneyAlertOpen: false,
      sendMoneyMessage: '',
      sendMoneyAlertType: '',
      sendMoneyAmount: 0,
    }));

  }
  openModalSendMoney = (id) => {
    this.setState(prevState => ({
      sendMoneyModalOpen: !prevState.sendMoneyModalOpen,
      sendMoneyFriendId: id,
    }));
  }

  sendMoney = () => {
    const { sendMoneyToFriend } = this.props;

    const { sendMoneyFriendId, sendMoneyAmount } = this.state;

    if (!sendMoneyFriendId) {
      return this.setState({
        sendMoneyAlertOpen: true,
        sendMoneyAlertType: constants.FROUNT_SIDE_STATUS.danger,
        sendMoneyMessage: 'noSelectedFriend'
      })
    }
    if (sendMoneyAmount === 0) {
      return this.setState({
        sendMoneyAlertOpen: true,
        sendMoneyAlertType: constants.FROUNT_SIDE_STATUS.danger,
        sendMoneyMessage: 'noAmount'
      })
    }
    if (sendMoneyAmount > 100) {
      return this.setState({
        sendMoneyAlertOpen: true,
        sendMoneyAlertType: constants.FROUNT_SIDE_STATUS.danger,
        sendMoneyMessage: 'sentPerDayMoney',
      });
    }
    if (sendMoneyFriendId && sendMoneyAmount) {
      sendMoneyToFriend(sendMoneyFriendId, sendMoneyAmount).then(res => {
        if (res && res.status === constants.FROUNT_SIDE_STATUS.success) {
          this.setState({
            sendMoneyAlertOpen: true,
            sendMoneyAlertType: constants.FROUNT_SIDE_STATUS.success,
            sendMoneyMessage: 'sentMoney'
          })
        } else {
          if (res.message === errorMessages.sentPerDayMoneyLimit) {
            this.setState({
              sendMoneyAlertOpen: true,
              sendMoneyAlertType: constants.FROUNT_SIDE_STATUS.danger,
              sendMoneyMessage: errorMessages.sentPerDayMoneyLimit,
              sendMoneyRestMoney: res.restMoney || 0,
            });
          } else {
            this.setState({
              sendMoneyAlertOpen: true,
              sendMoneyAlertType: constants.FROUNT_SIDE_STATUS.danger,
              sendMoneyMessage: 'failedSentMoney',
            });
          }
        }
      });
      // this.setState(prevState => ({
      //   modal: !prevState.modal,
      // }));
    }
  }

  blockUserInstant = () => {
    const { block, uid, name } = this.props;

    if (uid && name) {
      console.log('ReactGA - Ignore Player');
      
      ReactGA.event({
        category: 'Game',
        action: 'Ignore Player',
      });

      block(uid, name);

      this.setState({
        blockUserModal: false,
      //  blockUserSelectedUid: null,
      //  blockUserSelectedName: null,
      });
    }
  };

  blockUser = () => {
    const { block, uid, name } = this.props;
    //  const { blockUserSelectedUid, blockUserSelectedName } = this.state;

    console.log('blockUser', { uid, name });

    if (uid && name) {
      ReactGA.event({
        category: 'Game',
        action: 'Ignore Player',
      });

      block(uid, name);

      this.playButtonSound();

      this.setState({
        blockUserModal: false,
      //  blockUserSelectedUid: null,
      //  blockUserSelectedName: null,
      });
    }
  };

  unBlockUser = () => {
    const { unblock, uid, name } = this.props;

    unblock(uid, name);
  }

  render() {
    const { friends, mId, uid, position, playerInfo, gifts, closePlayerModal, posClassName, modalOpen, photo, t, screenMode, member, largePlayer, currentType, sittingOut, gameState, toggleGiftsModal, isIgnored, tournamentRoom } = this.props;
    const { activeTab, blockUserSelectedName, blockUserModal, addRemoveFriendPopoverOpen, sendMoneyModalOpen, sendMoneyAlertOpen, sendMoneyAlertType, sendMoneyMessage, sendMoneyAmount, sendMoneyRestMoney, sendMoneyPopoverOpen, } = this.state;

    const isPlayerFirstPerson = mId && mId === uid;
    let totalAch = 0;
    if (playerInfo && playerInfo.userAchievements) {
      map(playerInfo.userAchievements, (item, key) => {
        console.log(item, key, constants.ACHIEVEMENT_DATA[key], "debug");
        if (size(constants.UNIQUE_ACHIEVEMNT_DATA[key]) > 0 && item) {
          totalAch += 1;
        } else if (size(constants.ACHIEVEMENT_DATA[key]) > 0) {
          map(constants.ACHIEVEMENT_DATA[key].borders, (border, idx) => {
            console.log(border, item, "achievementCheck")
            if (border <= item) {
              totalAch += 1;
            }
          });
        }
        // if (constants.achievementsData[key]) {
        //   if (size(constants.achievementsData[key].borders) > 1) {
        //     map(constants.achievementsData[key].borders, (border, idx) => {
        //       if (border < item) {
        //         totalAch += 1;
        //       }
        //     });
        //     // const filterBorder = constants.achievementsData[key].borders.filter(idx => console.log(constants.achievementsData[key].borders[idx], item,));
        //     // totalAch += size(filterBorder);
        //   } else {
        //     totalAch += 1;
        //   }
        // }
      });
    }
    // console.log(totalAch, "total Ach");
    // console.log(playerInfo, "check playerInfo");
    console.log(largePlayer,playerInfo, sittingOut, "largePlayerlargePlayer")

    const isFriendsWithPlayer = (size(friends) > 0 && size(filter(friends, (item, key) => { return item.uid === uid; })) > 0);
    return (
      <div className={`player-info-mobile-modal player-info-mobile-modal-${posClassName}`}>
        <BlockUserModal
          t={t}
          blockUserModal={blockUserModal}
          blockUserSelectedName={blockUserSelectedName}
          toggleBlockUser={this.toggleBlockUser}
          blockUserFunction={this.blockUser}
        />
        <div className="player-info-new-header">
          <div className="player-info-new-header-title-wrapper">
            <div className="player-info-new-player">
              <div className="player-info-new-player-background">
                <div className="player-info-new-player-image-wrapper">
                  <div className="player-info-new-player-image-background" />
                  <div style={{ backgroundImage: photo ? `url(${photo})` : `url(${defaultImage})` }} className="player-info-new-player-image" />
                  <div className="player-info-new-player-image-overlay" />
                </div>
                <div className="player-info-new-player-name-wrapper">
                  <div className="player-info-new-player-name">
                    {playerInfo?.name || ''}
                  </div>
                </div>
                {
                  mId && mId !== uid && (
                    <div className="player-info-new-player-name-wrapper-friend">
                      {
                        size(friends) > 0 && size(filter(friends, (item, key) => { return item.uid === uid; })) > 0 ? (
                          <Badge color="success">{t('friend')}</Badge>
                        ) : (null)
                      }
                    </div>
                  )
                }
                {/* {(largePlayer) ? (
                  <PlayerType
                    t={t}
                    currentType={currentType}
                    sittingOut={sittingOut && playerInfo.position === sittingOut}
                    gameState={gameState}
                    largePlayer={!!(largePlayer && playerInfo.position === largePlayer)}
                  />
                ) : (
                  <Fragment>
                      {(sittingOut && playerInfo.position === sittingOut) ? (
                      <div className="player-type-wrapper">
                        <div className="player-type-mazais">{t('dealer')}</div>
                      </div>
                    ) : (
                      <div className="player-type-none" />
                    )}
                  </Fragment>
                )} */}
                <div className="player-info-new-player-wrapper">
                  <Media src={coin} className="player-info-new-player-balance-icon mr-2" />
                  <div className="player-info-new-player-balance">
                    {playerInfo?.bal || 0}
                  </div>
                  <Media src={rating} className="player-info-new-player-rating-icon" />
                  <div className="player-info-new-player-rating">
                    {playerInfo?.rating || 0}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="player-info-new-player-reg-date">
            <span>{`${t('common:myInfo.registered')}:`}</span>
            <span>{`${playerInfo && playerInfo.dateCreated ? this.changeDateFormat(playerInfo.dateCreated) : t('common:myInfo.oldRegisteredDate')}`}</span>
          </div>
        </div>
        <div className="player-info-new-body">
          <div className="player-info-new-body-content">
            <Col sm="12">
              <div className="player-info-new-row player-info-new-tabs">
                <div className="player-info-new-tab-link">
                  <NavLink
                    className={classNames('player-info-new-tab-link-text cursor-pointer', {
                      [`player-info-new-tab-link-text-active-${screenMode}`]: activeTab === constants.PLYERINFO_NEW_TABS.statistics
                    })}
                    onClick={() => this.playerInfoTab(constants.PLYERINFO_NEW_TABS.statistics)}
                  >
                    {t('statistics')}
                  </NavLink>
                </div>
                <div className="player-info-new-tab-link">
                  <NavLink
                    className={classNames('player-info-new-tab-link-text player-info-new-tab-link-text-trophy cursor-pointer', {
                      [`player-info-new-tab-link-text-active-${screenMode}`]: activeTab === constants.PLYERINFO_NEW_TABS.trophies
                    })}
                    onClick={() => this.playerInfoTab(constants.PLYERINFO_NEW_TABS.trophies)}
                  >
                    {t('trophies')}<label className="player-info-new-tab-link-text-trophy-bage">{totalAch}</label>
                  </NavLink>
                </div>
                <div className="player-info-new-tab-link">
                  <NavLink
                    className={classNames('player-info-new-tab-link-text player-info-new-tab-link-text-gifts cursor-pointer', {
                      [`player-info-new-tab-link-text-active-${screenMode}`]: activeTab === constants.PLYERINFO_NEW_TABS.gifts
                    })}
                    onClick={() => this.playerInfoTab(constants.PLYERINFO_NEW_TABS.gifts)}
                  >
                    {t('gifts')}<label className="player-info-new-tab-link-text-gifts-bage">{size(playerInfo?.giftsHistory || {})}</label>
                  </NavLink>
                </div>
              </div>
              {
                activeTab === constants.PLYERINFO_NEW_TABS.statistics ? (
                  <div
                    className="layout-mobile-body-main-scrollarea player-info-statistics-scrollarea"
                    //  className="ach-table-scrollarea"
                    show
                    rightOffset={0}
                    topOffset={0}
                    bottomOffset={0}
                  >
                    <div className="player-info-new-row player-info-new-statistics">
                      <Col xs="10">
                        <div className={`player-info-new-statistics-points-${screenMode}`}>
                          {t('ratingPoints')}: <label className="player-info-new-statistics-value">{playerInfo?.rating || 0}</label>
                        </div>
                        <div className="player-info-new-statistics-progress">
                          <Progress
                            color="link"
                            value={playerInfo?.rating ? playerInfo.rating / constants.PLYERINFO_NEW_PERCENTRATE.percent : 0}
                            className="my-info-ratings-progressbar"
                          />
                        </div>
                        <div className="player-info-new-statistics-percent">
                          {t('betterThan')}: <label className="mr-1">{playerInfo?.rating ? playerInfo.rating / constants.PLYERINFO_NEW_PERCENTRATE.percent : 0}%</label> {t('common:menu.players')}
                        </div>
                      </Col>
                      <Col xs="10">
                        <div className={`player-info-new-statistics-points-${screenMode}`}>
                          {t('gPlayed')}: <label className="player-info-new-statistics-value">{playerInfo?.gPlayed || 0}</label>
                        </div>
                        <div className="player-info-new-statistics-progress">
                          <Progress
                            color="link"
                            value={playerInfo?.betterGPlayed || 0}
                            className="my-info-ratings-progressbar"
                          />
                        </div>
                        <div className="player-info-new-statistics-percent">
                          {t('betterThan')}: <label className="mr-1">{playerInfo?.betterGPlayed || 0}%</label> {t('common:menu.players')}
                        </div>
                      </Col>
                      <Col xs="10">
                        <div className={`player-info-new-statistics-points-${screenMode}`}>
                          {t('largeZole')}: <label className="player-info-new-statistics-value">{playerInfo?.bgWon || 0}</label>
                        </div>
                        <div className="player-info-new-statistics-progress">
                          <Progress
                            color="link"
                            value={playerInfo?.betterBGWon || 0}
                            className="my-info-ratings-progressbar"
                          />
                        </div>
                        <div className="player-info-new-statistics-percent">
                          {t('betterThan')}: <label className="mr-1">{playerInfo?.betterBGWon || 0}% </label>{t('common:menu.players')}
                        </div>
                      </Col>
                    </div>
                    <div className="player-info-new-row player-info-new-statistics">
                      <Col xs="10">
                        <div className={`player-info-new-statistics-points-${screenMode}`}>
                          {t('smallZole')}: <label className="player-info-new-statistics-value">{playerInfo?.sgWon || 0}</label>
                        </div>
                        <div className="player-info-new-statistics-progress">
                          <Progress
                            color="link"
                            value={playerInfo?.betterSGWon || 0}
                            className="my-info-ratings-progressbar"
                          />
                        </div>
                        <div className="player-info-new-statistics-percent">
                          {t('betterThan')}: <label className="mr-1">{playerInfo?.betterSGWon || 0}%</label> {t('common:menu.players')}
                        </div>
                      </Col>
                      <Col xs="10">
                        <div className={`player-info-new-statistics-points-${screenMode}`}>
                          {t('winningTables')}: <label className="player-info-new-statistics-value">{playerInfo?.tblsWon || 0}</label>
                        </div>
                        <div className="player-info-new-statistics-progress">
                          <Progress
                            color="link"
                            value={playerInfo?.betterTblsWon || 0}
                            className="my-info-ratings-progressbar"
                          />
                        </div>
                        <div className="player-info-new-statistics-percent">
                          {t('betterThan')}: <label className="mr-1">{playerInfo?.betterTblsWon || 0}%</label> {t('common:menu.players')}
                        </div>
                      </Col>
                      <Col xs="10">
                        <div className={`player-info-new-statistics-points-${screenMode}`}>
                          {t('archievements')}: <label className="player-info-new-statistics-value">{playerInfo?.achvCnt || 0}</label>
                        </div>
                        <div className="player-info-new-statistics-progress">
                          <Progress
                            color="link"
                            value={playerInfo?.betterAchvCnt || 0}
                            className="my-info-ratings-progressbar"
                          />
                        </div>
                        <div className="player-info-new-statistics-percent">
                          {t('betterThan')}: <label className="mr-1">{playerInfo?.betterAchvCnt || 0}% </label>{t('common:menu.players')}
                        </div>
                      </Col>
                    </div>
                    <div className="player-info-new-row player-info-new-statistics">
                      <Col xs="10">
                        <div className={`player-info-new-statistics-points-${screenMode}`}>
                          {t('maxNumberOfPartiesInRoom')}: <label className="player-info-new-statistics-value">{playerInfo?.maxRndInGame || 0}</label>
                        </div>
                        <div className="player-info-new-statistics-progress">
                          <Progress
                            color="link"
                            value={playerInfo?.betterMaxRndInGame || 0}
                            className="my-info-ratings-progressbar"
                          />
                        </div>
                        <div className="player-info-new-statistics-percent">
                          {t('betterThan')}: <label className="mr-1">{playerInfo?.betterMaxRndInGame || 0}%</label> {t('common:menu.players')}
                        </div>
                      </Col>
                      <Col xs="10">
                        <div className={`player-info-new-statistics-points-${screenMode}`}>
                          {t('averageNumberOfPartiesPerRoom')}: <label className="player-info-new-statistics-value">{playerInfo?.averageNumberOfPartiesPerRoom || 0}</label>
                        </div>
                        <div className="player-info-new-statistics-progress">
                          <Progress
                            color="link"
                            value={playerInfo?.averageNumberOfPartiesPerRoom ? playerInfo.averageNumberOfPartiesPerRoom / constants.PLYERINFO_NEW_PERCENTRATE.percent : 0}
                            className="my-info-ratings-progressbar"
                          />
                        </div>
                        <div className="player-info-new-statistics-percent">
                          {t('betterThan')}: <label className="mr-1">{playerInfo?.averageNumberOfPartiesPerRoom ? playerInfo.averageNumberOfPartiesPerRoom / constants.PLYERINFO_NEW_PERCENTRATE.percent : 0}%</label> {t('common:menu.players')}
                        </div>
                      </Col>
                      <Col xs="10">
                        <div className={`player-info-new-statistics-points-${screenMode}`}>
                          {t('howManyRoomsPlayedWithThisPlayer')}: <label className="player-info-new-statistics-value">{playerInfo?.howManyRoomsPlayedWithThisPlayer || 0}</label>
                        </div>
                        <div className="player-info-new-statistics-progress">
                          <Progress
                            color="link"
                            value={playerInfo?.howManyRoomsPlayedWithThisPlayer ? playerInfo.howManyRoomsPlayedWithThisPlayer / constants.PLYERINFO_NEW_PERCENTRATE.percent : 0}
                            className="my-info-ratings-progressbar"
                          />
                        </div>
                        <div className="player-info-new-statistics-percent">
                          {t('betterThan')}: <label className="mr-1">{playerInfo?.howManyRoomsPlayedWithThisPlayer ? playerInfo.howManyRoomsPlayedWithThisPlayer / constants.PLYERINFO_NEW_PERCENTRATE.percent : 0}% </label>{t('common:menu.players')}
                        </div>
                      </Col>
                    </div>
                  </div>
                ) : activeTab === constants.PLYERINFO_NEW_TABS.trophies ? (
                  <div className="giftsHistory-tab-new-row">
                    <ScrollAreaWrapper
                      className="giftsHistory-tab-new-row-scrollarea"
                      show
                      rightOffset={0}
                      topOffset={0}
                      bottomOffset={0}
                    >
                      <Row>
                        {(playerInfo && playerInfo.userAchievements) && map(playerInfo.userAchievements, (item, key) => (
                          constants.achievementsData[key] && (
                          // findAchievements(key, item, constants.achievementsData[key].borders)
                            (size(constants.achievementsData[key].borders) > 1) ? (
                              map(constants.achievementsData[key].borders, (border, index) => (
                                (border <= item) && (
                                // (constants.achievementsData[key].borders[index + 1] && (item > constants.achievementsData[key].borders[index] && item < constants.achievementsData[key].borders[index + 1])) ? (
                                // eslint-disable-next-line
                                <Col xs="3" className='d-flex align-items-center'>
                                  {/* {playerInfo.giftsHistory[key] && ( */}
                                  <img
                                    // id={`giftsHistory-${key}`}
                                    className="giftsHistory-new"
                                    src={this.generateImageUrl(key, constants.achievementsData[key].borders, index)}
                                    alt="X"
                                  />
                                  {/* )} */}
                                </Col>
                                )
                              ))
                            ) : (
                              <Col xs="3" className='d-flex align-items-center'>
                                {/* {playerInfo.giftsHistory[key] && ( */}
                                <img
                                  // id={`giftsHistory-${key}`}
                                  className="giftsHistory-new"
                                  src={this.generateImageUrl(key, constants.achievementsData[key].borders)}
                                  alt="X"
                                />
                                {/* )} */}
                              </Col>
                            )
                          )
                        ))}
                      </Row>
                    </ScrollAreaWrapper>
                  </div>
                ) : (
                  <div className="giftsHistory-tab-new-row">
                    <ScrollAreaWrapper
                      className="giftsHistory-tab-new-row-scrollarea"
                      show
                      rightOffset={0}
                      topOffset={0}
                      bottomOffset={0}
                    >
                      <Row className="m-0">
                        {(gifts && playerInfo && playerInfo.giftsHistory) && Object.keys(playerInfo.giftsHistory).map(key => (
                          <Col xs="2" key={`${key}`}>
                            {playerInfo.giftsHistory[key] && (
                              <div
                                id={`giftsHistory-${key}`}
                                className="giftsHistory-new-item"
                                style={{ backgroundImage: `url(${gifts[playerInfo.giftsHistory[key].giftId] ? gifts[playerInfo.giftsHistory[key].giftId].image : ''})` }}
                              >
                                <>
                                  <UncontrolledTooltip
                                    className="giftsHistory-tooltip player-receivedGift-tooltip-new"
                                    placement="bottom"
                                    target={`giftsHistory-${key}`}
                                    container={'div > div'}
                                  >
                                    <div className="giftsHistory-tooltip-from">
                                      {playerInfo.giftsHistory[key].fromName || ''}
                                    </div>
                                    <div className="giftsHistory-tooltip-comment">
                                      {playerInfo.giftsHistory[key].comment || ''}
                                    </div>
                                  </UncontrolledTooltip>
                                </>

                              </div>
                            )}
                          </Col>
                        ))}
                      </Row>
                    </ScrollAreaWrapper>
                  </div>
                )
              }
              <Row className="player-info-footer">
                {
                  activeTab === constants.PLYERINFO_NEW_TABS.statistics ? (
                    <>
                      {isFriendsWithPlayer ? (
                        <>
                          <div className='top-table-row-player-friend top-table-row-text d-flex align-items-center'
                            style={{
                              background: 'linear-gradient(180deg, #333333 0%, #121212 100%)',
                              width: '40px',
                              height: '40px',
                              minWidth: '40px',
                              minHeight: '40px',
                              borderRadius: '50%',
                              margin: '11px',
                              marginBottom: '15px',
                              marginTop: '7px',

                              display: 'inline-flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                            id="player-modal-remove-friend-mobile"
                            onMouseEnter={() => this.setAddRemoveFriendPopoverOpen(true)}
                            onMouseLeave={() => this.setAddRemoveFriendPopoverOpen(false)}
                          >
                            <Media
                              className='top-table-row-player-friend top-table-row-text'
                              src={removeFriendIcon}
                              onClick={() => this.handleRemoveFriend(uid)}
                              style={{
                                width: '28px',
                                height: '28px',
                                minWidth: '28px',
                                minHeight: '28px',
                                objectFit: 'contain',
                              }}
                            />
                          </div>
                          <IconPopover text={t('common:top.removeFriend')} popoverOpen={addRemoveFriendPopoverOpen} targetId="player-modal-remove-friend-mobile" placement="right" />
                        </>
                      ) : (
                        <>
                          <Media
                            src={(size(friends) > 0 && size(filter(friends, (item, key) => { return item.uid === uid; })) > 0) ? removeFriendIcon : addFriendIcon}
                            alt=""
                            disabled={isPlayerFirstPerson}
                            className={isPlayerFirstPerson && 'giftsHistory-disabled-button'}
                            onClick={() => this.handleSendAddFriend(uid)}
                            onMouseEnter={() => this.setAddRemoveFriendPopoverOpen(true)}
                            onMouseLeave={() => this.setAddRemoveFriendPopoverOpen(false)}
                            id="player-modal-add-friend-mobile"
                            style={{pointerEvents: 'all', cursor: isPlayerFirstPerson ? 'auto' : 'pointer'}}
                          />
                          <IconPopover text={t('common:top.addAsFriend')} popoverOpen={!isPlayerFirstPerson && addRemoveFriendPopoverOpen} targetId="player-modal-add-friend-mobile" placement="right" />
                        </>
                      )}

                      { !tournamentRoom && 
                        (isIgnored ? (
                          <Media
                            src={unblockImage}
                            className={`player-info-footer-unblock ${(tournamentRoom || isPlayerFirstPerson) && 'disabled'}`}
                            alt=""
                            onClick={!tournamentRoom ? this.unBlockUser : false}
                          />
                        ) : (
                          <Media
                            src={blockImage}
                            className={`player-info-footer-block ${(tournamentRoom || isPlayerFirstPerson) && 'giftsHistory-disabled-button'}`}
                            alt=""
                            onClick={!tournamentRoom ? this.toggleBlockUser : false}
                          />
                        ))
                      }
                      <Media src={giftImg} className={`player-info-footer-gift ${isPlayerFirstPerson && 'giftsHistory-disabled-button'}`} alt="" onClick={() => toggleGiftsModal(uid)} style={{cursor: 'pointer'}} />
                    </>
                  ) : (
                    <>
                      <Media
                        src={exchangeCoinImg}
                        alt=""
                        disabled={isPlayerFirstPerson || !isFriendsWithPlayer}
                        onClick={() => this.openModalSendMoney(uid)}
                        className={`${(isPlayerFirstPerson || !isFriendsWithPlayer) && 'giftsHistory-disabled-button'}`}
                        id="send-money-ingame-mobile"
                        onMouseEnter={() => this.setSendMoneyPopoverOpen(true)}
                        onMouseLeave={() => this.setSendMoneyPopoverOpen(false)}
                        style={{cursor: 'pointer'}}
                      />

                      { !tournamentRoom && 
                        (isIgnored ? (
                          <Media
                            src={unblockImage}
                            className={`player-info-footer-unblock ${(tournamentRoom || isPlayerFirstPerson) && 'disabled'}`}
                            alt=""
                            onClick={!tournamentRoom ? this.unBlockUser : false}
                          />
                        ) : (
                          <Media
                            src={blockImage}
                            className={`player-info-footer-block ${(tournamentRoom || isPlayerFirstPerson) && 'giftsHistory-disabled-button'}`}
                            alt=""
                            onClick={!tournamentRoom ? this.toggleBlockUser : false}
                          />
                        ))
                      }
                      <Media src={giftImg} alt="" disabled={isPlayerFirstPerson} onClick={() => toggleGiftsModal(uid)} className={`player-info-footer-gift ${isPlayerFirstPerson && 'giftsHistory-disabled-button'}`} style={{cursor: 'pointer'}} />

                      <IconPopover text={t('common:popovers.sendMoney')} popoverOpen={sendMoneyPopoverOpen} targetId="send-money-ingame-mobile" placement="right" />
                      <CustomModal
                        isOpen={sendMoneyModalOpen}
                        toggle={this.toggleSendMoney}
                        size="md"
                        title={t('common:sendMoney.sendMoneyFriend')}
                        footer={(
                          <>
                            <Button color="link" className="btn notification-footer-button" onClick={this.sendMoney}>{t('common:common.send')}</Button>
                            <Button color="link" className="btn notification-footer-button" onClick={this.toggleSendMoney}>{t('common:common.cancel')}</Button>
                          </>
                        )}
                        body={(
                          <>
                            <Fragment>
                              <ErrorNotification
                                isVisible={sendMoneyAlertOpen}
                                text={
                                  <>
                                    {sendMoneyMessage === errorMessages.sentPerDayMoney ? (
                                      <div className='d-flex flex-row'>
                                        <span>
                                          {t('common:sendMoney.sentPerDayMoney')}
                                        </span>
                                        <Media
                                          src={coinImg}
                                          width={18}
                                          className='ml-1 mr-1'
                                        />
                                        <span>
                                          {t('common:sendMoney.perFriend')}
                                        </span>
                                      </div>
                                    ) : sendMoneyMessage === errorMessages.sentPerDayMoneyLimit ? (
                                      <div className='d-flex flex-column'>
                                        <div className='d-flex flex-row'>
                                          <span>
                                            {t('common:sendMoney.sentPerDayMoney')}
                                          </span>
                                          <Media
                                            src={coinImg}
                                            width={18}
                                            className='ml-1 mr-1'
                                          />
                                          <span>
                                            {t('common:sendMoney.perDayFriend')}
                                          </span>
                                        </div>
                                        <div>
                                          {t(`common:sendMoney.sentPerDayMoneyLimit`, { restMoney: sendMoneyRestMoney })}
                                        </div>
                                      </div>
                                    ) : (
                                      t(`common:sendMoney.${sendMoneyMessage}`)
                                    )}
                                  </>
                                }
                                isSuccess={sendMoneyAlertType === constants.FROUNT_SIDE_STATUS.success}
                              />
                            </Fragment>
                            <Form className="friends-modal-body-form">
                              <FormGroup>
                                <Label for="amount">{t('common:sendMoney.enterText')}</Label>
                                <Input type="text" max={100} min={0} name="sendMoneyAmount" id="sendMoneyAmount" value={sendMoneyAmount} placeholder="0" onChange={this.handleChange} />
                              </FormGroup>
                            </Form>
                          </>
                        )}
                      />
                    </>
                  )
                }
              </Row>
            </Col>
          </div>
        </div>
      </div>
    );
  }
}




const mapStateToProps = state => ({
  member: state.member || {},
  mId: state.member.uid,
  currentType: state.game.currentType,
  largePlayer: state.game.largePlayer,
  gameState: state.game.globalParams.gameState,
  sittingOut: state.game.sittingOut,
  notificationSettings: (state.userSettings[state.member.uid] && state.userSettings[state.member.uid].notificationSettings) ? (state.userSettings[state.member.uid].notificationSettings) : (state.userSettings.default ? state.userSettings.default.notificationSettings : null),
  friends: state.member.friends || {},
  socProvider: state.member.socProvider || null,
});

const mapDispatchToProps = {
  addAFriend: addFriend,
  removeAFriend: removeFriend,
  block: blockUser,
  unblock: unBlockUser,
  fetchFriends: getFriends,
  sendMoneyToFriend: sendMoney,

};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation(['playerInfo', 'common'])(PlayerModalRightMobile)));

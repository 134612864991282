import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';

import Button from 'reactstrap/lib/Button';
import Media from 'reactstrap/lib/Media';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import closeImg from '../../../images/icons/close.png';

import { setCheckedVersion } from '../../../actions/member';

import {
  FONT_MODE, NEW_VERSION,
} from '../../../constants/constants';

import config from '../../../constants/config';

const isInAppFrame = config.isInAppFrame();

class NewVersion extends React.Component {
  static propTypes = {
    newVersion: PropTypes.number,
    t: PropTypes.func.isRequired,
    setCheckedVersionFunc: PropTypes.func.isRequired,
    fontMode: PropTypes.string,
    openChangeLogVersionModal: PropTypes.bool,
    closeTriggerChangeLogModal: PropTypes.func.isRequired,
    hidden: PropTypes.bool,
  }

  static defaultProps = {
    newVersion: null,
    fontMode: FONT_MODE.normal,
    openChangeLogVersionModal: false,
    hidden: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      version: NEW_VERSION || '3.8.1',
    };
  }

  closeModal = () => {
    const { closeTriggerChangeLogModal, setCheckedVersionFunc } = this.props;
    const { version } = this.state;
    closeTriggerChangeLogModal();
    setCheckedVersionFunc(version);
  }

  render() {
    const { newVersion, t, i18n, fontMode, setCheckedVersionFunc, openChangeLogVersionModal, hidden } = this.props;
    const { version } = this.state;

    console.log('newVersion modal', openChangeLogVersionModal, newVersion, version);

    if (hidden) {
      return null;
    }

    if ((!newVersion || newVersion >= version) && !openChangeLogVersionModal) {
      if (!newVersion) {
        console.log('setCheckedVersionFunc');
        setCheckedVersionFunc(version);
      }
      return null;
    }

    return (
      <Modal container={'div > div'} isOpen size="lg" className={`${isInAppFrame ? 'modal-is-app-frame' : ''} notification new-version-modal old-modal root-font-${fontMode}`}>
        <ModalHeader
          toggle={this.closeModal}
          className="notification-header"
          close={
            <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.closeModal} />
            }
        />
        <ModalBody className="notification-body">

          {(!i18n.language || i18n.language === 'lv') ? (
            <>
              <h2 className="new-version-header">Sveiki, zolmaņi!</h2>
              <p className="new-version-body">Pēdējā laikā esam strādājuši pie kārtējā spēles atjauninājuma, kurā ir iekļauti šādi jaunumi un labojumi:</p>
              <ul className="new-version-body">
                <li> Spēles istabā ir papildināts pieejamo emocijkonu saraksts</li>
                <li> Vairāki turnīru vizuāli un tehniski uzlabojumi un papildinājumi</li>
                <li> Izlabota kļūda ar iepriekšējo sasniegumu saglabāšanos pēc dzēsta konta atjaunošanas</li>
                <li> Pievienota iespēja saņemt dāvanu Lieldienās un 4. maijā</li>
                <li> Spēle tagad ir pieejama arī Inbox.lv platformā</li>
                <li> Ieviests ranks 'Rupeklis', kas tiek piešķirts par apvainojumiem istabas čatā</li>
                <li> Lai spēlētu Pro istabā, tagad vajag vismaz 300 vinnētas partijas un nevis sasniegtu 10. līmeni</li>
                <li> Administrācija var izvietot reklāmas banerus dažās spēles lapās</li>
                <li> Administrācija var rīkot aptaujas spēlētāju viedokļa iegūšanai ar spēli saistītos jautājumos</li>
              </ul>
              <p className="new-version-body">Tāpat arī spēles WEB versijā (<a className="new-version-link" href="https://spelezoli.lv/" target="_blank" rel="noopener noreferrer">https://spelezoli.lv/</a>) ir iespējams iepazīties ar izstrādes stadijā esošo jauno spēles dizainu - droši varat izteikt atsauksmes un ierosinājumus.</p>
              <p className="new-version-body">Jūsu Zoles administrācija</p>
            </>
          ) : (null)}


          {(i18n.language === 'en') ? (
            <>
              <h2 className="new-version-header">Hello players!</h2>

              <p className="new-version-body">We've recently been working on a regular update to the game that includes the following news and fixes:</p>
              <ul className="new-version-body">
                <li> Added new emoticons in the game room;</li>
                <li> Several visual and functional improvements and additions to tournaments;</li>
                <li> Fixed a bug when previous achievements were not dropped after restoring a deleted account;</li>
                <li> Added the ability to receive a gift on Easter and May 4th;</li>
                <li> The game is now also available on the Inbox.lv platform;</li>
                <li> The rank “Curser” has been introduced, which is awarded for insults in the room chat;</li>
                <li> To play in the Pro room you now need a minimum of 300 wins, rather than level 10.;</li>
                <li> Administration may place advertising banners on some pages of the game.;</li>
                <li> Administration may conduct polls to obtain player opinions on issues related to the game.</li>
              </ul>
              <p className="new-version-body">Also in the WEB version of the game (<a className="new-version-link" href="https://spelezoli.lv/" target="_blank" rel="noopener noreferrer">https://spelezoli.lv/</a>)  you can get acquainted with the new design of the game. We ask you to leave your feedback and suggestions.</p>
              <p className="new-version-body">Your Zole Administration</p>

            </>
          ) : (null)}



          {(i18n.language === 'ru') ? (
            <>
              <h2 className="new-version-header">Здравствуйте, уважаемый игрок!</h2>

              <p className="new-version-body">Недавно мы закончили работу над очередным обновлением игры, которое включает в себя следующие улучшения и исправления:</p>
              <ul className="new-version-body">
                <li> Добавлены новые смайлы в игровую комнатуе</li>
                <li> Некоторые визуальные и функциональные улучшения и дополнения турниров</li>
                <li> Исправлена ошибка, из-за которой предыдущие достижения не сбрасывались после восстановления удаленной учетной записи</li>
                <li> Добавлена возможность получить подарок на Пасху и 4 мая.</li>
                <li> Игра теперь доступна также на платформе Inbox.lv</li>
                <li> Введен статус «Хам», который присуждается за грубость в чате игровой комнаты</li>
                <li> Чтобы играть в Про комнате теперь необходимо минимум 300 побед, а не достижение 10 уровня</li>
                <li> Администрация теперь может разместить рекламные баннеры на некоторых страницах игры</li>
                <li> Администрация может проводить опросы для определения мнений игроков по вопросам, относящимися к игре</li>
              </ul>
              <p className="new-version-body">Также в WEB-версии игры (<a className="new-version-link" href="https://spelezoli.lv/" target="_blank" rel="noopener noreferrer">https://spelezoli.lv/</a> можно ознакомиться с новым дизайном игры. Просим оставить свои отзывы и предложения.</p>
              <p className="new-version-body">Ваша администрация</p>
            </>
          ) : (null)}

        </ModalBody>
        <ModalFooter className="notification-footer">
          <Button color="link" className="notification-footer-button" onClick={this.closeModal}>{t('common.ok')}</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  newVersion: state.member.newVersion || null,
});

const mapDispatchToProps = {
  setCheckedVersionFunc: setCheckedVersion,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(NewVersion));

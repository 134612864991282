import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const Render3PlayerCards = React.memo(({
  currentTable, cardsAdded, myPos, isFullscreen, isWebVersion,
}) => (
  <div className="played-cards">
    {myPos === 'player1' && (
    <Fragment>
      {currentTable && Object.keys(currentTable).map((key, index) => (
        <Fragment key={currentTable[key] ? currentTable[key].card : key}>
          {(currentTable[key] && currentTable[key].player === 'player1') ? (
            <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
          ) : (null)}
          {(currentTable[key] && currentTable[key].player === 'player2') ? (
            <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
          ) : (null)}
          {(currentTable[key] && currentTable[key].player === 'player3') ? (
            <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
          ) : (null)}
        </Fragment>
      ))}
    </Fragment>
    )}
    {myPos === 'player2' && (
    <Fragment>
      {currentTable && Object.keys(currentTable).map((key, index) => (
        <Fragment key={currentTable[key] ? currentTable[key].card : key}>
          {(currentTable[key] && currentTable[key].player === 'player1') ? (
            <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
          ) : (null)}
          {(currentTable[key] && currentTable[key].player === 'player2') ? (
            <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
          ) : (null)}
          {(currentTable[key] && currentTable[key].player === 'player3') ? (
            <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
          ) : (null)}
        </Fragment>
      ))}
    </Fragment>
    )}
    {myPos === 'player3' && (
    <Fragment>
      {currentTable && Object.keys(currentTable).map((key, index) => (
        <Fragment key={currentTable[key] ? currentTable[key].card : key}>
          {(currentTable[key] && currentTable[key].player === 'player1') ? (
            <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
          ) : (null)}
          {(currentTable[key] && currentTable[key].player === 'player2') ? (
            <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
          ) : (null)}
          {(currentTable[key] && currentTable[key].player === 'player3') ? (
            <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
          ) : (null)}
        </Fragment>
      ))}
    </Fragment>
    )}
  </div>
));

Render3PlayerCards.propTypes = {
  currentTable: PropTypes.arrayOf(PropTypes.shape()),
  cardsAdded: PropTypes.shape(),
  myPos: PropTypes.string,
  isFullscreen: PropTypes.bool,
  isWebVersion: PropTypes.bool,
};

Render3PlayerCards.defaultProps = {
  currentTable: [],
  cardsAdded: {},
  myPos: '',
  isFullscreen: false,
  isWebVersion: false,
};

export default Render3PlayerCards;

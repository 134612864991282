import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';

import { map, size, filter } from 'lodash';

import ScrollArea from 'react-scrollbar';
import * as EmailValidator from 'email-validator';

import { Col, Row, Button, Label, NavLink, Media, Form, FormGroup, Input } from 'reactstrap';

import { TextInput } from '../Components/Components/TextInput';
import * as constants from '../../../../constants/constants';
import { errorMessages } from '../../../../constants/messages';

import emailImg from '../../../../images/redesign/about-us/email.svg';
import phoneImg from '../../../../images/redesign/about-us/phone.svg';
import addressImg from '../../../../images/redesign/about-us/address.svg';
import checkAction from '../../../../images/icons/checkAction.svg';


import {
  sendSupportMessage,
} from '../../../../actions/member';

// import { error } from 'logrocket';

const Contacts = ({ }) => {

  const { i18n, t } = useTranslation('common');

  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [limitCount, setLimitCount] = useState(0);
  const [status, setStatus] = useState('');
  const [titleError, setTitleError] = useState('');
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [messageError, setMessageError] = useState('');
  const [isSent, setIsSent] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const dispatch = useDispatch();

  const handleOnlineStatusChange = () => {
    setIsOnline(navigator.onLine);
  };

  useEffect(() => {
    window.addEventListener('online', handleOnlineStatusChange);
    window.addEventListener('offline', handleOnlineStatusChange);

    return () => {
      window.removeEventListener('online', handleOnlineStatusChange);
      window.removeEventListener('offline', handleOnlineStatusChange);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (title && name && email && EmailValidator.validate(email) && message) {
      setLoading(false);
    }
  }, [name, title, email, message]);

  const handleInputChange = useCallback((value, type) => {
    if (type === constants.PROFILE_FIELDS.email) setEmail(value);
    if (type === constants.PROFILE_FIELDS.name) setName(value);
    if (type === constants.PROFILE_FIELDS.title) setTitle(value);
    if (type === constants.PROFILE_FIELDS.message) setMessage(value);
  }, []);

  const initialValue = () => {
    // setEmail('');
    // setName('');
    // setTitle('');
    setMessage('');
    setStatus('');
    // setError('');
    setIsSent(true);
    // setLoading(true);
    setLimitCount(0);
    setMessageError();
    setEmailError();
    setNameError();
    setTitleError();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (title && name && email && EmailValidator.validate(email) && message) {
      console.log("okay");
      dispatch(sendSupportMessage({ name, email, title, message, design: 'new' }));
      initialValue();
    } else {
      setStatus('danger');
      if (!message) {
        setMessageError('missingMessage');
      } else {
        setMessageError('');
      }
      if (!email) {
        setEmailError('missingEmail');
      } else if (!EmailValidator.validate(email)) {
        setEmailError('wrongEmail');
      } else {
        setEmailError('');
      }
      if (!name) {
        setNameError('missingFirstName');
      } else {
        setNameError('');
      }
      if (!title) {
        setTitleError('missingTitle')
      } else {
        setTitleError();
      }
    }


  };

  const handleChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    setMessage(value);
    setLimitCount(value.length);
  };


  return (
    <>
      <Row className="contacts-page d-flex align-items-center">
        <Col sm="12" className="no-padding">
          <Label className="contacts-page-title">{t('menu.contacts')}</Label>
        </Col>
        <Col sm="12" className="no-padding">
          <Row className="contacts-page-first-section d-flex flex-row">
            {
              (!isSent) ?
                <Col lg={8} sm={12} className="pl-0 contacts-page-first-section-item mb-5 d-flex flex-column">
                  <Label className="mb-4 sub-title">{t('home.contactUs')}</Label>
                  <Form onSubmit={handleSubmit} className="contacts-page-first-section-item-common-form">
                    <div className="d-flex flex-row inline-input-group">
                      <FormGroup className="three-part-section mr-1">
                        <TextInput
                          maxLength="100"
                          width='100%'
                          contentEditable
                          inputType={constants.PROFILE_FIELDS.title}
                          secondaryPlaceholder={t('home.title')}
                          initialValue={title}
                          onChange={(value, type) => handleInputChange(value, type)}
                          inlineMessageVisible
                          inputStatus={titleError === errorMessages.missingTitle && status}
                          inputMessage={titleError === errorMessages.missingTitle && t(`member.${titleError}`)}
                          autoFocus
                        />
                      </FormGroup>
                      <FormGroup className="three-part-section mr-1">
                        <TextInput
                          maxLength="100"
                          type={constants.PROFILE_FIELDS.name}
                          width='100%'
                          contentEditable
                          inputType={constants.PROFILE_FIELDS.name}
                          secondaryPlaceholder={t('home.name')}
                          initialValue={name}
                          onChange={(value, type) => handleInputChange(value, type)}
                          inlineMessageVisible
                          inputStatus={nameError === errorMessages.missingFirstName && status}
                          inputMessage={nameError === errorMessages.missingFirstName && t(`member.${nameError}`)}
                        />
                      </FormGroup>
                      <FormGroup className="three-part-section mr-1">
                        <TextInput
                          maxLength="100"
                          type={constants.PROFILE_FIELDS.email}
                          width='100%'
                          contentEditable
                          inputType={constants.PROFILE_FIELDS.email}
                          secondaryPlaceholder={t('home.email')}
                          initialValue={email}
                          onChange={(value, type) => handleInputChange(value, type)}
                          inlineMessageVisible
                          inputStatus={(emailError === errorMessages.missingEmail || emailError === errorMessages.wrongEmail) && status}
                          inputMessage={(emailError === errorMessages.missingEmail || emailError === errorMessages.wrongEmail) && t(`member.${emailError}`)}
                        />
                      </FormGroup>
                    </div>
                    <div className="d-flex flex-row mt-4">
                      <FormGroup className="comments-section">
                        <Input
                          type="textarea"
                          name="message"
                          id="message"
                          maxLength={constants.CONTACTS_LENGTH.toString()}
                          value={message}
                          rows="7"
                          onChange={handleChange}
                          placeholder={t('home.yourComment')}
                        />
                        {
                          status === constants.FROUNT_SIDE_STATUS.danger && messageError === errorMessages.missingMessage && (
                            <div className={`inputInlineMessage-${status}`}>
                              <p>{t(`member.${messageError}`)}</p>
                            </div>
                          )
                        }
                        <Label className={constants.FEEDBACK_LENGTH - limitCount > 10 ? 'limit-characters' : 'limited-characters-error'}>{`${limitCount}/${constants.CONTACTS_LENGTH}`}</Label>
                      </FormGroup>
                    </div>

                    <Row>
                      <Col className="text-left send-message-button no-padding">
                        <Button className="layout-highlighted-button top-settings-new-section-login-button login-section-button d-flex align-items-center" disabled={loading || !isOnline}>
                          {t('home.sendMessage')}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
                : <Col className="contact-message-sent-success">
                  <Media src={checkAction} className="contact-message-sent-success-image" />
                  <div className="contact-message-sent-success-text">{t('member.messageSent')}</div>
                </Col>
            }
            <Col lg={4} sm={12} className="contacts-page-first-section-item pb-5 mb-5 d-flex flex-column">
              <Label className="sub-title">{t('home.contactInformation')}</Label>
              <div className="d-flex align-items-start contacts-information">
                <div className="d-flex align-items-start contacts-information-icon">
                  <Media src={emailImg} alt="x" className="email-icon" />
                </div>
                <div className="d-flex align-items-start contacts-information-content">
                  <a href={`mailto:${constants.CONTACT_INFORMATION.email}`}>{constants.CONTACT_INFORMATION.email}</a>
                </div>
              </div>
              <div className="d-flex align-items-start contacts-information">
                <div className="d-flex align-items-start contacts-information-icon">
                  <Media src={phoneImg} alt="x" className="phone-icon" />
                </div>
                <div className="d-flex align-items-start contacts-information-content">
                  {constants.CONTACT_INFORMATION.phone}
                </div>
              </div>
              <div className="d-flex align-items-start contacts-information">
                <div className="d-flex align-items-start contacts-information-icon">
                  <Media src={addressImg} alt="x" className="address-icon" />
                </div>
                <div className="d-flex align-items-start contacts-information-content">
                  <Row className="d-flex flex-column">
                    {
                      map(constants.CONTACT_INFORMATION.address, item => (
                        <Col className="no-padding">
                          {item}
                        </Col>
                      ))
                    }
                  </Row>
                </div>
              </div>
            </Col>

            <Col lg={4} sm={12} className="contacts-information-third-section d-none">
              <div className="d-flex align-items-start contacts-information">
                <div className="d-flex align-items-start contacts-information-icon">
                  <Media src={addressImg} alt="x" className="address-icon" />
                </div>
                <div className="d-flex align-items-start contacts-information-content">
                  <Row className="d-flex flex-column">
                    {
                      map(constants.CONTACT_INFORMATION.address, item => (
                        <Col className="no-padding">
                          {item}
                        </Col>
                      ))
                    }
                  </Row>
                </div>
              </div>
            </Col>


          </Row>
        </Col>
      </Row>
    </>
  );
};

Contacts.propTypes = {
  selectBlog: PropTypes.shape({}),
  selectBlogId: PropTypes.string,
  allBlogs: PropTypes.arrayOf(PropTypes.shape({})),
  goToBlogList: PropTypes.func.isRequired,
  handleReadArticle: PropTypes.func.isRequired,
};

Contacts.defaultProps = {
  selectBlogId: '',
  allBlogs: [],
  selectBlog: {},
};

export default Contacts;

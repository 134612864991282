import React, { Fragment, useEffect, useState } from 'react';
import Select from 'react-select';
import {
  Row, Col, Label, Button, Pagination, PaginationItem, PaginationLink,
} from 'reactstrap/lib';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { round, size, map } from 'lodash';
import { fetchFeedbackHistory } from '../../../../actions/admin';
import DateFormat from '../../UI/DateFormat';
import { FORMAT_DATE_TYPE } from '../../../../constants/constants';
import FeedbackUserMessage from './FeedbackUserMessage';


const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px dotted pink',
    color: state.isSelected ? 'red' : 'gray',
    padding: 10,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return { ...provided, opacity, transition };
  },
};

const FeedbackHistory = ({ selectedId, feedback, showNotification }) => {
  const dispatch = useDispatch();
  const [designs] = useState(feedback ? feedback?.designs : []);
  const [platforms] = useState(feedback ? feedback?.platforms : []);
  const [design, setDesign] = useState(feedback && feedback.designs ? feedback.designs[0] : {});
  const [platform, setPlatform] = useState(feedback && feedback.platforms ? feedback.platforms[0] : {});
  const [selectKey, setSelectKey] = useState('');
  const [showActive, setShowActive] = useState(false);
  const [pagesCount, setPagesCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedUid, setSelectedUid] = useState('');
  const [selectedName, setSelectedName] = useState('');
  const [openChatModal, setOpenChatModal] = useState(false);
  const pageSize = 50;

  const feedbackHistory = useSelector(state => state.admin.feedbackHistory || []);
  const totalRating = useSelector(state => state.admin.totalRating || 0);

  useEffect(() => {
    dispatch(fetchFeedbackHistory(selectedId, design, platform));
  }, [selectedId, design, platform]);

  useEffect(() => {
    setPagesCount(Math.ceil(size(feedbackHistory) / pageSize));
  }, [feedbackHistory]);


  const changeDesignOption = (selectedDesign) => {
    setDesign(selectedDesign);
  };
  const changePlatformOption = (selectedPlatform) => {
    setPlatform(selectedPlatform);
  };

  const handleComment = (key) => {
    if (key === selectKey) {
      setSelectKey(key);
      setShowActive(!showActive);
    } else {
      setSelectKey(key);
      setShowActive(true);
    }
  };

  const handleClick = (e, index) => {
    e.preventDefault();
    setCurrentPage(index);
  };

  const toggleChatModal = (uid, name) => {
    if (openChatModal) {
      setSelectedUid(uid);
      setSelectedName(name);
    } else {
      setSelectedUid('');
      setSelectedName('');
    }
    setOpenChatModal(!openChatModal);
  };

  return (
    <Fragment>
      <Row>
        <Col sm="6">
          <Label className="mr-2">Average Rating Stars: </Label>
          <Label className="text-lg">
            {size(feedbackHistory) === 0 ? 'No feedback yet' : round(totalRating / size(feedbackHistory), 4)}
          </Label>
        </Col>
        <Col sm="3">
          <Select closeMenuOnSelect options={designs} styles={customStyles} value={design} onChange={changeDesignOption} />
        </Col>
        <Col sm="3">
          <Select closeMenuOnSelect options={platforms} styles={customStyles} value={platform} onChange={changePlatformOption} />
        </Col>
      </Row>
      <Row>
        <table style={{ width: '100%', fontSize: 14 }}>
          <colgroup>
            <col span="1" className="" />
          </colgroup>
          <thead>
            <tr>
              <th className="feedback-table-col-normal">
                Date Created
              </th>
              <th className="feedback-table-col-normal">
                Name
              </th>
              <th className="feedback-table-col-normal">
                Player Id
              </th>
              <th className="feedback-table-col-small">
                Stars
              </th>
              <th className="feedback-table-col-comment">
                Comment
              </th>
              <th className="feedback-table-col-comment">
                Message
              </th>
            </tr>
          </thead>
          <tbody>
            {feedbackHistory && size(feedbackHistory) > 0 ? map(feedbackHistory, (item, key) => (
              <Fragment key={key}>
                <tr key={key} className={selectKey === key && showActive ? 'allUsers-table-row feedback-table-col open-comment' : 'allUsers-table-row feedback-table-col'} onClick={() => handleComment(key)}>
                  <td className="allUsers-table-col">
                    <DateFormat formatType={FORMAT_DATE_TYPE.fullDateMinute} date={item.lastFeedback} />
                  </td>
                  <td className="allUsers-table-col">
                    {item.name}
                  </td>
                  <td className="allUsers-table-col">
                    {item.key}
                  </td>
                  <td className="allUsers-table-col">
                    {item.rating}
                  </td>
                  <td className="allUsers-table-col">
                    {selectKey === key && showActive ? item.comment : size(item.comment) > 100 ? `${item.comment.slice(0, 100)}...` : item.comment}
                  </td>
                  <td className="allUsers-table-col">
                    <Button color="primary" onClick={() => toggleChatModal(item.key, item.name)}>
                      Message
                    </Button>
                  </td>
                </tr>
              </Fragment>
            )) : (null)}
            {pagesCount && size(feedbackHistory) > pageSize ? (
              <div className="pagination-wrapper">
                <Pagination aria-label="Page navigation example">
                  <PaginationItem disabled={currentPage <= 0}>
                    <PaginationLink
                      style={{ color: '#000' }}
                      onClick={e => handleClick(e, currentPage - 1)}
                      previous
                      href="#"
                    />
                  </PaginationItem>

                  <PaginationItem disabled={currentPage === 0}>
                    <PaginationLink style={{ color: '#000' }} onClick={e => handleClick(e, 0)} href="#">
                      1
                    </PaginationLink>
                  </PaginationItem>

                  {[...Array(pagesCount)].map((page, i) => {
                    if (i > currentPage - 3 && i < currentPage + 3) {
                      return (
                        <PaginationItem active={i === currentPage} key={page}>
                          <PaginationLink style={{ color: '#000' }} onClick={e => handleClick(e, i)} href="#">
                            {i + 1}
                          </PaginationLink>
                        </PaginationItem>
                      );
                    }

                    return null;
                  })}

                  {currentPage < (pagesCount - 3) && (
                    <PaginationItem disabled={currentPage >= pagesCount - 1}>
                      <PaginationLink style={{ color: '#000' }} onClick={e => handleClick(e, pagesCount - 1)} href="#">
                        {pagesCount}
                      </PaginationLink>
                    </PaginationItem>
                  )}

                  <PaginationItem disabled={currentPage >= pagesCount - 1}>
                    <PaginationLink
                      style={{ color: '#000' }}
                      onClick={e => handleClick(e, currentPage + 1)}
                      next
                      href="#"
                    />
                  </PaginationItem>
                </Pagination>
              </div>
            ) : (null)}
          </tbody>
        </table>
        {openChatModal && (
          <FeedbackUserMessage isOpen={openChatModal} uid={selectedId} name={selectedName} showNotification={showNotification} toggleChatModal={toggleChatModal} />
        )}
      </Row>
    </Fragment>
  );
};

FeedbackHistory.propTypes = {
  selectedId: PropTypes.string,
  feedback: PropTypes.shape({}),
  showNotification: PropTypes.func.isRequired,
};

FeedbackHistory.defaultProps = {
  selectedId: '',
  feedback: {},
};

export default FeedbackHistory;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

import defaultImage from '../../../../../images/redesign/common/default_image.svg';
import lightDefaultImage from '../../../../../images/redesign/light-mode/common/default_image.webp';
import normalDefaultImage from '../../../../../images/redesign/normal-mode/common/default_image.webp';
import * as constants from '../../../../../constants/constants';

import CustomDate from '../../Components/Components/CustomDate';

import {
  getBannedUsers,
} from '../../../../../actions/users';
import { Media } from 'reactstrap/lib';
import PlayerAvatar from '../../Components/Components/PlayerAvatar';

class BannedUsers extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    fetchBannedUsers: PropTypes.func.isRequired,
    bannedUsers: PropTypes.shape({
      name: PropTypes.shape({}),
      endDate: PropTypes.shape({}),
      reason: PropTypes.shape({}),
    }),
  };

  static defaultProps = {
    bannedUsers: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      sortedKeysBannedUsers: this.getSortedKeys(props.bannedUsers),
    };
  }

  componentDidMount() {
    const { fetchBannedUsers } = this.props;

    fetchBannedUsers();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.bannedUsers !== this.props.bannedUsers) {
      this.setState({
        sortedKeysBannedUsers: this.getSortedKeys(this.props.bannedUsers),
      });
    }
  }

  getSortedKeys(bannedUsers) {
    return Object.entries(bannedUsers)
      .sort(([, a], [, b]) => a.endDate - b.endDate)
      .map(([key]) => key);
  }

  render() {
    const { bannedUsers, t, screenMode } = this.props;
    const { sortedKeysBannedUsers } = this.state;

    return (
      <Fragment>
        <Row>
          <Col sm="12" className="wrapper-column">
            <Row className="player-history-table-header">
              <Col xs="4" className="player-history-table-header-col">
                {t('common.name')}
              </Col>
              <Col xs="3" className="player-history-table-header-col">
                {t('bannedUsers.end')}
              </Col>
              <Col xs="5" className="player-history-table-header-col-last player-history-table-header-col text-left">
                {t('bannedUsers.reason')}
              </Col>
            </Row>
            <div
              className="player-history-table-scrollarea player-history-table-body banned-user-history-table-body"
              show
              rightOffset={0}
              topOffset={23}
              bottomOffset={-3}
            >
              {(bannedUsers && sortedKeysBannedUsers.length > 0) ? sortedKeysBannedUsers.map((key, index) => (
                <>
                  {bannedUsers[key] ? (
                    <Row
                      key={key}
                      className={`player-history-table-row ${index % 2 === 0 ? 'odd' : 'even'
                        }`}
                    >
                      <Col xs="4" className="player-history-table-col" style={{whiteSpace: 'nowrap', display: 'inline-flex'}}>
                        <div style={{display: 'inline-flex', width: 49}}>
                          <PlayerAvatar photo={bannedUsers[key].photo} screenMode={screenMode}/>
                        {/* <Media src={bannedUsers[key].photo || (screenMode === constants.SCREEN_MODE.light ? lightDefaultImage : (screenMode === constants.SCREEN_MODE.normal ? normalDefaultImage : defaultImage))} className={`player-avatar ${bannedUsers[key].photo ? '' : 'player-avatar-default'}`} alt="avatar" /> */}
                        </div>
                        <div style={{display: 'inline-flex', width: 'calc(100% - 49px)', height: 36, whiteSpace: 'normal', textOverflow: 'ellipsis', overflow: 'hidden', alignItems: 'center', verticalAlign: 'top'}}>
                          <div style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>{bannedUsers[key].name}</div>
                        </div>
                      </Col>
                      <Col xs="3" className="player-history-table-col">
                        <CustomDate format="DD-MM-YYYY, hh:mm" date={bannedUsers[key].endDate} />
                      </Col>
                      <Col xs="5" className="player-history-table-col player-history-table-col-last text-left">
                        {bannedUsers[key].reason}
                      </Col>
                    </Row>
                  ) : (null)}
                </>
              )) : <div className="d-flex align-items-center h-100">{t('ignoredUsers.ignoredUsersEmptyEntries')}</div>}
            </div>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  bannedUsers: (state.users && state.users.bannedUsers) ? state.users.bannedUsers : {},
});

const mapDispatchToProps = {
  fetchBannedUsers: getBannedUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(BannedUsers));

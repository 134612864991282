import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { connect } from 'react-redux';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';

import { removeAutomated } from '../../../../../actions/game';

const AutomatedOverlay = React.memo(({
  roomId, resetAutomated, automated, t, isMobile,
}) => {
  if (!automated) {
    return null;
  }

  return (
    <Fragment>
      <div className={classNames('new-design-automation', {
        'new-design-automation-mobile': isMobile,
      })}
      >
        <div className="automation new-design-automation-section">
          <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
            <div style={{ fontSize: 15 }}>
              {t('automatedMessage')}
            </div>
            <Row>
              <Col>
                <Button className="layout-highlighted-button" onClick={() => resetAutomated(roomId)}>
                  {t('return')}
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Fragment>
  );
});

AutomatedOverlay.propTypes = {
  roomId: PropTypes.string,
  automated: PropTypes.bool,
  resetAutomated: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
};

AutomatedOverlay.defaultProps = {
  roomId: null,
  automated: false,
  isMobile: false,
};

// const mapStateToProps = (state, ownProps) => ({
//  fastGame: state.game.globalParams.fastGame,
// });

const mapDispatchToProps = {
  resetAutomated: removeAutomated,
};

export default connect(null, mapDispatchToProps)(AutomatedOverlay);

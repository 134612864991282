import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Media from 'reactstrap/lib/Media';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';

// import closeImg from '../../../../../images/redesign/components/modal/close.png';
import mobileCloseImg from '../../../../../images/redesign/components/modal/mobileClose.png';
// import closeModalImg from '../../../../../images/icons/close_mobile.png';
import config from '../../../../../constants/config';
// import starsImg from '../../../../images/redesign/components/modal/modal_stars.png';

const CustomModal = React.memo(({
  headerShow, isOpen, toggle, body, footer, title, size, onButtonClose, warning, inlineClassName, titleTranslateFlag, footerClose, width, addClassName, returnFocusAfterClose, shiftCloseButton, customTitleClassName, verticalCentering, largeText, modalVerticalCentering, innerAutoFocus, minWidth, zIndex, forceBackdropEnabled,
}) => {
  const { t } = useTranslation('common');

  const isInAppFrame = config.isInAppFrame();

  const closeButtonStyle = {};
  if (shiftCloseButton) {
    closeButtonStyle.right = '-46px';
  }

  return (
    <>
      <Modal zIndex={zIndex} container={'div > div > div'} autoFocus={innerAutoFocus} isOpen={isOpen} toggle={toggle} returnFocusAfterClose={returnFocusAfterClose ? returnFocusAfterClose : true} className={`default-modal ${modalVerticalCentering && 'modal-vertically-centered'} ${addClassName} ${inlineClassName}`} backdrop={inlineClassName ? (forceBackdropEnabled ? true : false) : true} size={size || 'md'} style={{ maxWidth: `${(width) || null}px`, minWidth: minWidth }}>
        {
          headerShow && (
            <ModalHeader close={<div style={closeButtonStyle} className={classNames({ 'modal-close-img': !footerClose }, { 'web-close': !footerClose, 'mobile-detector-close': footerClose })} onClick={toggle || onButtonClose} />}>
              <div className={`${warning ? 'default-modal-title-warning' : 'default-modal-title'} ${customTitleClassName}`}>
                {titleTranslateFlag ? t(`home.${title}`) : title}
              </div>
            </ModalHeader>
          )
        }
        <ModalBody className={`default-modal-body ${largeText ? 'modal-large-text' : ''} ${verticalCentering ? 'd-flex justify-content-center align-items-center' : ''} ${footer ? '' : 'default-modal-body-noFooter'}`}>
          {body}
        </ModalBody>
        {footer && (
          <ModalFooter>
            {footer}
          </ModalFooter>
        )}
        {
          !footerClose && (
            <Media className="mobile-close" src={mobileCloseImg} alt="X" onClick={toggle || onButtonClose} />
          )
        }
      </Modal>
    </>
  );
});

CustomModal.propTypes = {
  body: PropTypes.shape(),
  footer: PropTypes.shape(),
  toggle: PropTypes.func.isRequired,
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  size: PropTypes.string,
  onButtonClose: PropTypes.func.isRequired,
  warning: PropTypes.bool,
  inlineClassName: PropTypes.string,
  addClassName: PropTypes.string,
  titleTranslateFlag: PropTypes.bool,
  footerClose: PropTypes.bool,
  width: PropTypes.number,
  shiftCloseButton: PropTypes.bool,
  customTitleClassName: PropTypes.string,
  verticalCentering: PropTypes.bool,
  largeText: PropTypes.bool,
  headerShow: PropTypes.bool,
  modalVerticalCentering: PropTypes.bool,
  innerAutoFocus: PropTypes.bool,
  minWidth: PropTypes.string,
  zIndex: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  forceBackdropEnabled: PropTypes.bool,
};

CustomModal.defaultProps = {
  body: null,
  footer: null,
  title: null,
  isOpen: false,
  size: 'md',
  warning: false,
  footerClose: false,
  inlineClassName: null,
  addClassName: null,
  width: null,
  customTitleClassName: '',
  verticalCentering: false,
  largeText: false,
  headerShow: true,
  modalVerticalCentering: false,
  innerAutoFocus: true,
  minWidth: null,
  forceBackdropEnabled: false,
};

export default CustomModal;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { filter, size, map } from 'lodash';

import RoomsTableRowRender from './RoomsTableRowRender';
import { getActiveRoomsCount, isRoomSpeedInFilter } from '../../../../../utils/roomUtils';


const RoomsTableRender = React.memo(({
  tooltipId, friends, roomsProps, joinedRoom, uid, leaveRoom, activeTournament, finishedTournamentLastRound, popoverOpen, joinRoomClicked, onHover, onHoverLeave, joinRoomClickedFunc, joinPrivateRoomConfirm, screenMode, filters, t
}) => {
  let filteredRooms = { ...roomsProps };
  const friendsIds = size(friends) > 0 ? map(friends, (item, key) => { return size(item) > 0 ? item.uid : null; }) : [];

  console.log(filteredRooms, friends, friendsIds, "filteredRooms");
  if (filters && filters?.gameType && size(filters?.gameType) > 0) {
    filteredRooms = filter(filteredRooms, (item, key) => { return item.globalParams?.gameType.includes(filters?.gameType); })
  }

  if (filters?.fourPRoom && filters?.fourPRoom[0] === true) {
    filteredRooms = filter(filteredRooms, (item, key) => { return item.globalParams?.fourPRoom === true; })
  } else if (filters?.fourPRoom && filters?.fourPRoom[0] === false) {
    filteredRooms = filter(filteredRooms, (item, key) => { return !item.globalParams?.fourPRoom })
  }

  if (filters?.minGames && size(filters?.minGames) > 0) {
    filteredRooms = filter(filteredRooms, (item, key) => { return filters?.minGames.includes(item.globalParams?.minGames); })
  }
  if (filters?.bet && size(filters?.bet) > 0) {
    filteredRooms = filter(filteredRooms, (item, key) => { return filters?.bet.includes(item.globalParams?.bet); })
  }
  if (filters?.speed && size(filters?.speed) > 0) {
    filteredRooms = filter(filteredRooms, (item, key) => { return isRoomSpeedInFilter(item, filters?.speed) })
  }
  if (filters?.maza) {
    filteredRooms = filter(filteredRooms, (item, key) => { return item.globalParams?.smallGame; })
  }
  if (filters?.privateRoom) {
    filteredRooms = filter(filteredRooms, (item, key) => { return item.globalParams?.privateRoom; })
  }
  if (filters?.pro) {
    filteredRooms = filter(filteredRooms, (item, key) => { return item.globalParams?.proGame; })
  }

  if (joinedRoom && joinedRoom.key && joinedRoom.globalParams && joinedRoom.playersList) {
    filteredRooms = filter(filteredRooms, (item, key) => { return item.key !== joinedRoom.key; })
  }

  if (filters?.friendsRoom && size(friendsIds) > 0) {
    filteredRooms = filter(filteredRooms, (item, key) => {
      if (item?.playersList) {
        return (item.playersList.player1 && friendsIds.includes(item.playersList?.player1?.uid)) || (item.playersList.player2 && friendsIds.includes(item.playersList.player2.uid)) || (item.playersList.player3 && friendsIds.includes(item.playersList.player3.uid));
      }
    });
  }

  if (getActiveRoomsCount(filteredRooms) > 0) {
    return (
      <Fragment>
        {Object.keys(filteredRooms).map(item => {
          return (
            <RoomsTableRowRender
              key={item}
              room={filteredRooms[item]}
              removal={filteredRooms[item].removal}
              joinedRoom={joinedRoom}
              uid={uid}
              leaveRoom={leaveRoom}
              activeTournament={activeTournament}
              finishedTournamentLastRound={finishedTournamentLastRound}
              popoverOpen={popoverOpen}
              joinRoomClicked={joinRoomClicked}
              onHover={onHover}
              onHoverLeave={onHoverLeave}
              joinRoomClickedFunc={joinRoomClickedFunc}
              joinPrivateRoomConfirm={joinPrivateRoomConfirm}
              screenMode={screenMode}
              tooltipId={tooltipId}
            />
          );
        })}
      </Fragment>
    );
  }
  return (<div className="d-flex align-items-center h-100 rooms-table-empty-entries">{t('roomsTable.roomsTableEmptyEntries')}</div>);
});

RoomsTableRender.propTypes = {
  joinedRoom: PropTypes.shape(),
  uid: PropTypes.string,
  leaveRoom: PropTypes.func.isRequired,
  activeTournament: PropTypes.string,
  finishedTournamentLastRound: PropTypes.bool,
  popoverOpen: PropTypes.shape(),
  joinRoomClicked: PropTypes.bool,
  onHover: PropTypes.func.isRequired,
  onHoverLeave: PropTypes.func.isRequired,
  joinRoomClickedFunc: PropTypes.func.isRequired,
  joinPrivateRoomConfirm: PropTypes.func.isRequired,
  filters: PropTypes.shape(),
  tooltipId: PropTypes.string,
};

RoomsTableRender.defaultProps = {
  joinedRoom: null,
  uid: null,
  activeTournament: null,
  finishedTournamentLastRound: null,
  popoverOpen: {},
  joinRoomClicked: false,
  filters: {
    speed: [],
    bet: [],
    minGames: [],
    fourPRoom: [],
    gameType: [],
    maza: false,
    privateRoom: false,
    pro: false,
  },
  tooltipId: null,
};


export default RoomsTableRender;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
// import Moment from 'react-moment';

import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import classNames from 'classnames';

import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
import Media from 'reactstrap/lib/Media';

import ScrollAreaWrapper from '../../Components/ScrollAreaWrapper';

import MenuRow from '../../Components/Components/MenuRow';

import Leaderboard from './TournamentLeaderboard';
import CustomDate from '../../Components/Components/CustomDate';

import CustomModal from '../../Components/Components/Modal';
import TournamentTutorial from './Components/TournamentTutorial';
import TournamentInfo from './Components/TournamentInfo';

import tutorialImage from '../../../../../images/icons/help2.svg';
import lightInfoImg from '../../../../../images/redesign/light-mode/player/info.svg';

import {
  getTournamentPlayers,
  getTournamentsHistory,
} from '../../../../../actions/tournaments';
import { SCREEN_MODE } from '../../../../../constants/constants';
import { getTournamentType } from '../../../../../utils/tournamentUtils';

class TournamentsHistory extends React.PureComponent {
  static propTypes = {
    tournaments: PropTypes.arrayOf(PropTypes.shape()),
    tournamentPlayers: PropTypes.shape(),
    uid: PropTypes.string,
    t: PropTypes.func.isRequired,
    changeTab: PropTypes.func.isRequired,
    fetchTournamentPlayers: PropTypes.func.isRequired,
    fetchTournamentsHistory: PropTypes.func.isRequired,
    screenMode: PropTypes.string,
  }

  static defaultProps = {
    tournaments: [],
    tournamentPlayers: {},
    uid: null,
    screenMode: SCREEN_MODE.normal,
  }

  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      tournamentId: '',
      scrollPos: 0,
      showTournamentTutorial: false,
      tournamentInfoModalOpen: false,
      selectTournament: {},
    };
  }

  componentDidMount() {
    const { fetchTournamentsHistory } = this.props;

    const { hostname } = window.location;

    let devMode = false;

    if (hostname && (hostname.includes('dev') || hostname.includes('localhost'))) {
      devMode = true;
    }

    fetchTournamentsHistory(devMode);
  }

  openModal = (id) => {
    this.setState({
      tournamentId: id,
      openModal: true,
    });
  }

  toggle = () => {
    this.setState(prevState => ({
      openModal: !prevState.openModal,
    }));
  }

  fetchTournamentPlayers = (tournamentId) => {
    const { fetchTournamentPlayers } = this.props;

    if (tournamentId) {
      fetchTournamentPlayers(tournamentId).then(() => {
        this.setState({ openModal: true });
      });
    }
  }

  updateScrollPos = (val) => {
    if (val.topPosition || val.topPosition === 0) {
      this.setState({ scrollPos: val.topPosition || 0 });
    }
  //  this.setState({ scrollPos: val.topPosition || 0 });
  }

  scroll = (newVal) => {
    this.tableScrollbar.scrollYTo(newVal);
  }

  // toggleShowTournamentTutorial = () => {
  //   const { showTournamentTutorial } = this.state;

  //   if (!showTournamentTutorial) {
  //     ReactGA.event({
  //       category: 'Menu',
  //       action: 'Tournaments Help',
  //     });
  //   }

  //   this.setState(prevState => ({ showTournamentTutorial: !prevState.showTournamentTutorial }));
  // }

  renderTournamentState = t => (
    <div className="tournament-state tournament-state-closed">
      {t('tournaments.closed')}
    </div>
  )

  openTournamentInfoModal = (selectId) => {
    const { tournaments } = this.props;

    this.setState({
      tournamentInfoModalOpen: true,
      selectTournament: {...tournaments.filter(tournament  => tournament.id === selectId)[0]}
    })
  }

  closeTournamentInfoModal = () => {
    this.setState({
      tournamentInfoModalOpen: false,
    })
  }

  render() {
    const {
      t,
      tournaments,
      uid,
      changeTab,
      tournamentPlayers,
      screenMode,
    } = this.props;

    const {
      openModal,
      tournamentId,
      scrollPos,
      showTournamentTutorial,
      selectTournament,
      tournamentInfoModalOpen,
    } = this.state;

    return (
      <>
        <Helmet>
          <title>
            Zole - {t('route.tournaments')}
          </title>
        </Helmet>
        <div className="layout-body tournaments-history-layout-body">
          {/* <div className="layout-body-top">
            <Row className="layout-subheader">
              <Col xs="10">
                <div className="layout-subheader-link">
                  <Button color="link" className="layout-subheader-link-text active-tournaments-button" onClick={() => changeTab('6')}>
                    {t('tournaments.activeTournaments')}
                  </Button>
                </div>
                <div className="layout-subheader-link">
                  <Button color="link" className="layout-subheader-link-text future-tournaments-button" onClick={() => changeTab('16')}>
                    {t('tournaments.futureTournaments')}
                  </Button>
                </div>
                <div className="layout-subheader-link">
                  <Button color="link" className="layout-subheader-link-text past-tournaments-button layout-subheader-link-text-active" onClick={() => changeTab('7')}>
                    {t('tournaments.tournamentHistory')}
                  </Button>
                </div>
              </Col>
              <Col xs="2">
                <Media className="tournament-header-tutorial" src={screenMode === SCREEN_MODE.light ? lightInfoImg : tutorialImage} onClick={() => this.toggleShowTournamentTutorial()} />
              </Col>
            </Row>
          </div> */}
          <div className="layout-body-main">
            <div
              className="tournament-scrollarea"
              contentClassName="tournament-scrollarea-body"
              show
              rightOffset={0}
              topOffset={0}
              bottomOffset={40}
            >
              {tournaments && tournaments.length > 0 && tournaments.filter(tournament => tournament?.currentState !== 'play' && tournament?.currentState !== 'pause').map(tournament => {

                const { startDate, registerTime } = tournament;
                let registerDate;
                if (startDate && registerTime) {
                  registerDate = startDate - (registerTime * 1000 * 60);
                }
                return (
                  <MenuRow key={tournament.id}>
                    <Row key={tournament.id} className="tournament-table-row">
                      <div className='active-tournaments'>
                        <div className='active-tournaments-info'>
                          <div className='active-tournaments-info-section'>
                            <div className='active-tournaments-info-section-title'>
                              <div className='active-tournaments-info-section-title-left'>
                                <Media src={screenMode === SCREEN_MODE.light ? lightInfoImg : tutorialImage} onClick={() => this.openTournamentInfoModal(tournament.id)} className='active-tournaments-info-section-title-left-img' alt="x"/>
                                <span className='active-tournaments-info-section-title-left-title'>{tournament.name}</span>
                                <span className='active-tournaments-info-section-title-left-private'>
                                  { getTournamentType(tournament, t) }
                                </span>
                              </div>
                              <div className='active-tournaments-info-section-title-right history-tournaments-info-section-title-right'>
                                <div>{`${t('tournaments.closed')} `}</div>
                              </div>
                            </div>
                            <div className='active-tournaments-info-section-time'>
                              <span className='active-tournaments-info-section-time-rounds'>{`${t('tournaments.rounds')}:`}</span>
                              <span className='active-tournaments-info-section-time-roundsValue'>{tournament.rounds}</span>
                              <span className='active-tournaments-info-section-time-label'>{`${t('tournaments.beginning')}:`}</span>
                              <span className='active-tournaments-info-section-time-content'><CustomDate format="DD.MM.YYYY hh:mm" date={startDate} /></span>
                            </div>
                          </div>
                          <div className='active-tournaments-info-button'>
                            <Button color="link" className="layout-default-button layout-float-right" onClick={() => this.fetchTournamentPlayers(tournament.id)}>
                              {t('tournaments.result')}
                            </Button>
                          </div>
                        </div>
                        <div className={
                          classNames('active-tournaments-sponsor', {
                            'active-tournaments-sponsor-two': tournament?.sponsorImg && tournament?.prizeImg
                          })
                        }>
                          {
                            tournament?.sponsorImg && (
                              <div className={
                                classNames('active-tournaments-sponsor-section', {
                                  'active-tournaments-sponsor-section-two': tournament?.sponsorImg && tournament?.prizeImg
                                })
                              }>
                                <span>{t('tournaments.sponsorTournaments')}</span>
                                <Media src={tournament?.sponsorImg} alt="x" />
                              </div>
                            )
                          }
                          {
                            tournament?.prizeImg && (
                              <div className={
                                classNames('active-tournaments-sponsor-section', {
                                  'active-tournaments-sponsor-section-two': tournament?.sponsorImg && tournament?.prizeImg
                                })
                              }>
                                <span>{t('tournaments.mainPrize')}</span>
                                <Media src={tournament?.prizeImg} alt="x" />
                              </div>
                            )
                          }
                        </div>
                      </div>
                    </Row>
                  </MenuRow>
                );
              })}
            </div>
          </div>
        </div>

        <TournamentTutorial showTournamentTutorial={showTournamentTutorial} toggleShowTournamentTutorial={this.toggleShowTournamentTutorial} />
        <CustomModal
          isOpen={tournamentInfoModalOpen}
          toggle={this.closeTournamentInfoModal}
          size="lg"
          title={selectTournament?.name || t('tournaments.tournaments')}
          footer={
            <Button color="link" className="btn notification-footer-button" onClick={this.closeTournamentInfoModal}>
              {t('common.close')}
            </Button>
          }
          body={<TournamentInfo t={t} selectTournament={selectTournament} />}
        />

        {uid && tournamentPlayers && openModal ? (
          <>
            <CustomModal
              isOpen={openModal}
              toggle={this.toggle}
              size="lg"
              addClassName="tournament-leaderboard-modal"
              title={t('tournaments.complete')/*t('tournaments.top')*/}
              modalVerticalCentering
              footer={
                <Button color="link" className="btn notification-footer-button" onClick={this.toggle}>
                  {t('common.close')}
                </Button>
              }
              /* <Button color="link" className="modal-footer-button" onClick={this.toggle}>{t('tournaments.close')}</Button> */
              body={<Leaderboard tournament={selectTournament} leaderboard={tournamentPlayers} tournamentId={tournamentId} uid={uid} screenMode={screenMode} />}
            />
          </>
        ) : (null)}
      </>
    );
  }
}

const mapStateToProps = state => ({
  ignoredUsers: state.member.ignoredUsers || {},
  uid: state.member.uid || null,
  tournaments: (state.tournaments && state.tournaments.tournamentsHistory) ? state.tournaments.tournamentsHistory : {},
  tournamentPlayers: (state.tournaments && state.tournaments.tournamentPlayers) ? state.tournaments.tournamentPlayers : {},
});

const mapDispatchToProps = {
  fetchTournamentPlayers: getTournamentPlayers,
  fetchTournamentsHistory: getTournamentsHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(TournamentsHistory));

import React, { Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from 'reactstrap/lib/Spinner';

import { map, get, size, isEqual } from 'lodash';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';
import MenuRow from '../../Components/Components/MenuRow';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import ScrollAreaWrapper from '../../../UI/ScrollAreaWrapper';

import * as constants from '../../../../../constants/constants';
import {
  getWeeklyStatistics,
} from '../../../../../actions/member';
import config from '../../../../../constants/config';

const WeeklyStatusHome = ({closeWeeklyData}) => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  const weeklyStatistics = useSelector(state => state.member.weeklyStatistics || {});
  const [weeklyData, setWeeklyData] = useState(null);

  useEffect(() => {
    dispatch(getWeeklyStatistics());
  }, []);

  useEffect(() => {
    if(weeklyStatistics && size(weeklyStatistics) > 0 && !isEqual(weeklyStatistics, weeklyData)) {
      setWeeklyData(weeklyStatistics);
    }
  }, [weeklyStatistics])

  const getTrend = (week1, week2) => {
    const trend = week1 - week2;

    return trend;
  };

  const isInAppFrame = config.isInAppFrame();

  return (
    <>
      <div>
        <div>

          <Row className={`${isInAppFrame ? 'menu-table-row-wrapper-compact-height' : 'menu-table-row-wrapper'}`} style={{marginLeft: '2px', marginRight: '3px', paddingRight: '25px'}}>
            <Col xs="4" className='weekly-statistics-name weekly-statistics-title text-center'>
              {
                size(weeklyData) === 0 && (
                  <Spinner color="warning" style={{ width: '2rem', height: '2rem' }} />
                )
              }
            </Col>
            <Col xs="2" className='weekly-statistics-title text-center'>
              {t('myInfo.lastWeek')}
            </Col>
            <Col xs="2" className='weekly-statistics-title text-center'>
              {t('myInfo.twoWeeksAgo')}
            </Col>
            <Col xs="2" className='weekly-statistics-title text-center'>
              {t('myInfo.trend')}
            </Col>
            <Col xs="2" className='weekly-statistics-title text-center'>
              {t('myInfo.average')}
            </Col>
          </Row>
          <div
            className="layout-body-main-scrollarea weekly-status-scrollbar"
            contentClassName="layout-body-main-scrollarea-body"
            show
            rightOffset={0}
            topOffset={30}
            bottomOffset={0}
          >
            {weeklyData && size(weeklyData) > 0 && (
              <Fragment>
                {constants.WEEKLY_STATISTICS_DATA && map(constants.WEEKLY_STATISTICS_DATA, (item, key) => (
                  <MenuRow key={key} compactHeight={isInAppFrame}>
                    <Row key={key}>
                      <Col xs="4" className='weekly-statistics-name'>
                        {t(`myInfo.${key}`)}
                      </Col>
                      <Col xs="2" className='weekly-statistics-content text-center'>
                        {get(weeklyData, `oneWeekAgo.${key}`, 0)}
                      </Col>
                      <Col xs="2" className='weekly-statistics-content text-center'>
                        {get(weeklyData, `twoWeeksAgo.${key}`, 0)}
                      </Col>
                      <Col xs="2" className='weekly-statistics-trend'>
                        <div className={
                          classNames({
                            'menu-table-row-weekly-large-trend': get(weeklyData, `oneWeekAgo.${key}`, 0) - get(weeklyData, `twoWeeksAgo.${key}`, 0) > 0,
                            'menu-table-row-weekly-low-trend': get(weeklyData, `oneWeekAgo.${key}`, 0) - get(weeklyData, `twoWeeksAgo.${key}`, 0) < 0,
                            'menu-table-row-weekly-neutral-trend': get(weeklyData, `oneWeekAgo.${key}`, 0) - get(weeklyData, `twoWeeksAgo.${key}`, 0) === 0,
                          })
                        }>
                          { getTrend(get(weeklyData, `oneWeekAgo.${key}`, 0), get(weeklyData, `twoWeeksAgo.${key}`, 0))}
                        </div>
                      </Col>
                      <Col xs="2" className='weekly-statistics-content text-center'>
                        {(get(weeklyData, `oneWeekAgo.${key}`, 0) + get(weeklyData, `twoWeeksAgo.${key}`, 0)) / 2}
                      </Col>
                    </Row>
                  </MenuRow>
                ))}
              </Fragment>
            )}
          </div>
        </div>
      </div>
      <ModalFooter>
        <Button color="secondary" onClick={closeWeeklyData}>
          {t('common:common.ok')}
        </Button>
      </ModalFooter>
    </>
  );
};

export default WeeklyStatusHome;

import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';
import ScrollArea from 'react-scrollbar';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Form from 'reactstrap/lib/Form';
import Input from 'reactstrap/lib/Input';
import Media from 'reactstrap/lib/Media';
import ScrollAreaButtons from './UI/ScrollAreaButtons';

import Message from './SupportMessage';

import closeImg from '../../images/icons/close.png';

import {
  sendSupportMessage,
  setSupportAsRead,
  supportMessageFileUpload,
} from '../../actions/member';

import {
  checkAutoForCompensation,
} from '../../actions/admin';

import {
  FONT_MODE,
} from '../../constants/constants';

import config from '../../constants/config';

const isInAppFrame = config.isInAppFrame();

class ContactSupport extends React.Component {
  static propTypes = {
  //  supportChatStatus: PropTypes.shape({
  //    read: PropTypes.bool,
  //  }),
    supportChat: PropTypes.shape(),
    uid: PropTypes.string,
    name: PropTypes.string,
    //  chatMessages: PropTypes.shape(),
    closeErrorSubmit: PropTypes.bool.isRequired,
    modalOpen: PropTypes.bool,
    resetClose: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    sendSupportMsg: PropTypes.func.isRequired,
    supportMessageFileUpload: PropTypes.func.isRequired,
    //  setSupportRead: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    fontMode: PropTypes.string,
    checkAutoForComp: PropTypes.func.isRequired,
  };

  static defaultProps = {
  //  chatMessages: {},
    uid: null,
    name: '',
    supportChat: null,
    //  supportChatStatus: null,
    modalOpen: false,
    fontMode: FONT_MODE.normal,
  };

  constructor(props) {
    super(props);
    this.state = {
    //  modalOpen: false,
      inputMessage: '',
      fileUploadUrl: '',
      fileName: '',
      ableToSend: true,
    };
    this.hiddenFileInput = React.createRef(null);
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate(prevProps) {
    const {
      closeErrorSubmit, resetClose, supportChat, modalOpen,
    } = this.props;
    const prevSupportChat = prevProps.supportChat;
    const prevModalOpen = prevProps.modalOpen;
    if (closeErrorSubmit) {
      resetClose();
      this.closeModal();
    }

    if (
      supportChat
      && (!prevSupportChat
        || (prevSupportChat
          && Object.keys(prevSupportChat).length !== Object.keys(supportChat).length))
    ) {
      this.scrollToBottom();
    }

    if (modalOpen && modalOpen !== prevModalOpen) {
      this.scrollToBottom();
    }
  }

  scrollToBottom = () => {
    setTimeout(() => {
      if (this.messagesScrollbar) {
        this.messagesScrollbar.scrollBottom();
      }
    }, 200);
  };

  closeModal = () => {
  //  this.setState({ modalOpen: false });
  };

  /* toggle = () => {
    const { modalOpen } = this.state;
    const { setSupportRead, supportChatStatus } = this.props;

    if (!modalOpen && supportChatStatus && !supportChatStatus.read) {
      setSupportRead();
    }
  }; */

  handleChange = (event) => {
    if (event.key !== 'Enter' || (event.key === 'Enter' && event.shiftKey)) {
      const value = event.target.type === 'checkbox' ? event.target.checked : (event.target.value).slice(0, 300);

      this.setState({
        [event.target.name]: value,
      });
    }
  };

  handleEnter = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      this.submitMessage();
    }
  };

  submitMessage = () => {
    const { sendSupportMsg, name, supportChat, uid, checkAutoForComp } = this.props;
    const { inputMessage, fileUploadUrl, fileName } = this.state;
    console.log({inputMessage}, {fileUploadUrl});
    if (supportChat && Object.keys(supportChat).length && Object.keys(supportChat).length >= 1000) {
      this.setState({ inputMessage: '' });
      return null;
    }

    let screen = 'dWeb'
    if (window.innerWidth <= 1280 && window.innerWidth > 832) {
      screen = 'tablet'
    } else if (window.innerWidth <= 832) {
      screen = 'mWeb'
    }

    this.setState({ inputMessage: '', fileUploadUrl: '', fileName: '' });
    if (inputMessage != '' || fileUploadUrl != '') {
      return sendSupportMsg({
        message: inputMessage, name, fileUploadUrl, fileName, design: 'old', screen
      }).then((res) => {
        console.log('sendSupportMsg res', res);
        if (res.type === 'SUPPORT_MESSAGE_SUCCESS') {
          checkAutoForComp(uid, name);
        }
      });
    }
  };

  _handleFileClick = () => {
    console.log("trigger", this.hiddenFileInput);
    this.hiddenFileInput.current.click();
  };

  chooseFile = (e) => {
    e.preventDefault();

    const { showNotification, t } = this.props;
    const target = e.target.files[0];
console.log(target?.type, "fileType")
    var typeList = ".jpg,.jpeg,.png,.pdf,.msword ,.doc,.rtf,.txt,ain";
    if (!typeList.includes((target?.type).substring((target?.type).length - 3)) || target.type == "") { return showNotification(t('support.error'), t('support.unsupportedFile'), 'danger'); }
    if (target.size / 1024 > 1024) {
      showNotification(t('support.warning'), t('support.cantUpload'), 'warning');

      this.setState({
        fileUploadUrl: '',
        fileName: '',
      });
    } else {
      this.setState({ ableToSend: false });

      const { supportMessageFileUpload } = this.props;
      supportMessageFileUpload(target).then((data) => {
        this.setState({
          fileUploadUrl: data.url,
          fileName: target.name,
          ableToSend: true,
        });
        showNotification(t('support.success'), t('support.attachedSuccess'), 'success');
      });
    }
  }

  updateScrollPos = (val) => {
    this.setState({ scrollPos: val.topPosition || 0 });
  }

  scroll = (newVal) => {
    this.messagesScrollbar.scrollYTo(newVal);
  }

  render() {
    const {
      inputMessage, scrollPos, ableToSend, fileName,
    } = this.state;

    const {
      t, modalOpen, toggle, supportChat, uid, fontMode,
    } = this.props;

    // toggle={toggle}
console.log("messagePos", scrollPos);
    return (
      <Row className="contact-support">
        <Modal container={'div > div'} isOpen={modalOpen} toggle={toggle} className={`contact-support-modal old-modal root-font-${fontMode} ${isInAppFrame ? 'modal-is-app-frame' : ''}`}>
          <ModalHeader
          //  toggle={toggle}
            toggle={toggle}
            className="contact-support-modal-header"
            close={(
              <Media
                src={closeImg}
                className="notification-header-close"
                alt="X"
              //  onClick={toggle}
                onClick={toggle}
              />
            )}
          >
            {t('support.contact')}

            <ul className="notification-header-description">
              <li>{t('support.description1')}</li>
              <li>{t('support.description2')}</li>
              <li>{t('support.description3')}</li>
            </ul>
          </ModalHeader>
          <ModalBody className="contact-support-modal-body">
            <>
              <Row>
                <Col className="contact-support-chat-body-wrapper" style={{ height: 320 }} md="12">
                  <ScrollAreaButtons
                    scrollPos={scrollPos}
                    show={this.messagesScrollbar && this.messagesScrollbar.state && this.messagesScrollbar.state.realHeight > this.messagesScrollbar.state.containerHeight ? true : null}
                    scroll={this.scroll}
                    speed={30}
                    rightOffset={1}
                    topOffset={-1}
                    bottomOffset={-2}
                  />
                  <ScrollArea
                    speed={0.65}
                    className="contact-support-chat-body-scroll-area"
                    contentClassName="contact-support-chat-body-scroll-area-content"
                  //  onScroll={this.handleScroll}
                  //  smoothScrolling
                    verticalContainerStyle={{
                      background: 'transparent',
                      opacity: 1,
                      width: 11,
                    }}
                    verticalScrollbarStyle={{
                      background: '#fff',
                      borderRadius: 1,
                      width: 10,
                    //  minScrollSize: 25,
                    }}
                    minScrollSize={30}
                    horizontal={false}
                    onScroll={this.updateScrollPos}
                    ref={(el) => { this.messagesScrollbar = el; }}
                  >
                    {supportChat
                      && Object.keys(supportChat).map(key => (
                        <Row key={key}>
                          <Col md="12">
                            <Message
                              uid={uid}
                              messageId={supportChat[key].messageId || null}
                              message={supportChat[key].message || null}
                              translationKey={supportChat[key].translationKey || null}
                              translationKeyData={supportChat[key].translationKeyData || {}}
                              filteredMessage={supportChat[key].filteredMessage || null}
                              fileUploadUrl={supportChat[key].fileUpload || null}
                              fileName={supportChat[key].fileName || null}
                              isFiltered={supportChat[key].isFiltered || false}
                              userUid={supportChat[key].userUid || null}
                              date={supportChat[key].date || null}
                              fontMode={fontMode}
                              t={t}
                            />
                          </Col>
                        </Row>
                      ))}
                  </ScrollArea>
                </Col>
              </Row>
              <Row className="contact-support-chat-footer">
                <Form style={{ width: '100%' }} onSubmit={this.submitMessage}>
                  <Col md="12">
                    <Input
                      className="contact-support-chat-footer-input"
                      type="textarea"
                      name="inputMessage"
                      id="inputMessage"
                      autoComplete="off"
                      placeholder={`${t('common.writeMessage')}...`}
                      value={inputMessage}
                      onChange={this.handleChange}
                      onKeyPress={this.handleEnter}
                    />
                  </Col>
                </Form>
              </Row>
              <Row>
                <Button
                  className="btn-warning admin-file-upload-button"
                  onClick={this._handleFileClick}
                >
                  {fileName === '' ? t('support.chooseFile') : fileName}
                </Button>
                <input
                  id="filesDialog"
                  className="chat-footer-input admin-file-upload-input"
                  accept=".jpg,.png,.pdf,.doc,.rtf,.txt"
                  type="file"
                  ref={this.hiddenFileInput}
                  onChange={this.chooseFile}
                />
                {/*  <Input
                  ref={input => (this.inputRef = input)}
                  id="filesDialog"
                  className="chat-footer-input"
                  accept=".jpg,.png,.pdf,.doc,.rtf,.txt"
                  style={{ marginLeft: 20, display: 'none' }}
                  type="file"
                  onChange={(e) => { console.log('test2'); this.chooseFile(e.target.files[0]); }}
                /> */}
              </Row>
            </>
          </ModalBody>
          <ModalFooter className="contact-support-modal-footer">
            <Button
              type="button"
              className="contact-support-modal-footer-button"
              color="link"
              disabled={!ableToSend}
              onClick={this.submitMessage}
            >
              {t('common.send')}
            </Button>
            <Button
              type="button"
              className="contact-support-modal-footer-button"
              color="link"
              onClick={toggle}
            >
              {t('common.cancel')}
            </Button>
          </ModalFooter>
        </Modal>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  uid: state.member.uid || '',
  name: state.member.name || '',
  supportChat: state.member.supportChat || null,
//  supportChatStatus: state.member.supportChatStatus || null,
});

const mapDispatchToProps = {
  sendSupportMsg: sendSupportMessage,
  setSupportRead: setSupportAsRead,
  supportMessageFileUpload,
  checkAutoForComp: checkAutoForCompensation,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(ContactSupport));

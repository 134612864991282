import React from 'react';
import PropTypes from 'prop-types';

import Col from 'reactstrap/lib/Col';

import ContactSupport from '../../ContactSupport/ContactSupport';

const BottomBar = (({
  t, supportChatStatus, toggleBottomBarTab, toggleSupport, supportModalOpen, screenMode, handleClickStart
}) => {
  const handleStartJoyride = (e) => {
    handleClickStart(e);
  };

  return (
    <div className={`bottom-bar bottom-bar-${screenMode}`}>
      <Col sm="12" className="bottom-bar-links">
        <a
          href="#"
          className={`bottom-bar-links-help ${supportChatStatus && supportChatStatus.read === false ? 'incoming' : ''}`}
          onClick={() => toggleSupport()}
        >
          <span className={`${supportChatStatus && supportChatStatus.read === false ? 'incoming-text' : ''}`}>
            {t('menu.help')}
          </span>
          <ContactSupport
            modalOpen={supportModalOpen}
            toggle={toggleSupport}
            screenMode={screenMode}
          />
        </a>
        <a href="#" onClick={(e) => handleStartJoyride(e)}>
          <span>
            {t('menu.tutorial')}
          </span>
        </a>
        <a href="#" onClick={() => toggleBottomBarTab('moneyHistory')}>
          <span>
            {t('menu.moneyHistory')}
          </span>
        </a>
        <a href="#" onClick={() => toggleBottomBarTab('pointsHistory')}>
          <span>
            {t('menu.pointsHistory')}
          </span>
        </a>
        <a href="#" onClick={() => toggleBottomBarTab('bannedUsers')}>
          <span>
            {t('menu.bannedUsers')}
          </span>
        </a>
        <a href="#" onClick={() => toggleBottomBarTab('helpPage')}>
          <span>
            {t('menu.helpPage')}
          </span>
        </a>
        <a href="#" onClick={() => toggleBottomBarTab('aboutPage')}>
          <span>
            {t('menu.aboutPage')}
          </span>
        </a>
        <a href="#" onClick={() => toggleBottomBarTab('termsOfUse')}>
          <span>
            {t('home.termsOfUse')}
          </span>
        </a>
        <a href="/landing" target='_blank'>
          <span>
            {t('menu.home')}
          </span>
        </a>
        <a href="/blog" target='_blank'>
          <span>
            {t('menu.blog')}
          </span>
        </a>
        <a href="/contacts" target='_blank'>
          <span>
            {t('menu.contacts')}
          </span>
        </a>
        <a href="/version-history" target='_blank'>
          <span>
            {t('menu.changelog')}
          </span>
        </a>
      </Col>
    </div>
  );
});


BottomBar.propTypes = {
  t: PropTypes.func.isRequired,
  toggleBottomBarTab: PropTypes.func.isRequired,
  toggleSupport: PropTypes.func.isRequired,
  supportChatStatus: PropTypes.shape(),
  supportModalOpen: PropTypes.bool,
};

// BottomBar.defaultProps = {
//   supportChatStatus: {},
// };

export default BottomBar;

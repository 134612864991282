import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input, Media } from 'reactstrap';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import { useTranslation } from 'react-i18next';
import CustomBirthday from './CustomBirthday';
import * as constants from '../../../../../constants/constants';

import Dot from '../../../../../images/redesign/player/dot.svg';
import config from '../../../../../constants/config';

const formatDate = (dateString) => {
  if (!dateString) return '';
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  const dateObject = new Date(dateString);
  return dateObject.toLocaleDateString('en-GB', options);
};

export const TextInput = (props) => {
  const { 
    autoFocus,
    mbirthday,
    dRef,
    mRef,
    yRef,
    handleKeyDay,
    handleKeyMonth,
    handleKeyYear,
    customDay,
    customMonth,
    customYear,
    width,
    placeholder,
    initialValue,
    onChange,
    inputType,
    contentEditable,
    marginTop,
    secondaryPlaceholder,
    type,
    inputStatus,
    inputMessage,
    inlineMessageVisible,
    loading,
    birthDisable,
    birthday,
    maxLength,
    curLength,
    dropUp,
    disabled,
  } = props;
  const [currentValue, setCurrentValue] = React.useState(inputType === constants.PROFILE_FIELDS.birthdayMobile ? formatDate(initialValue) : initialValue);

  const [currentDay, setCurrentDay] = React.useState(inputType === constants.PROFILE_FIELDS.birthdayMobile ? formatDate(initialValue).slice(0,2) : null);
  const [currentMonth, setCurrentMonth] = React.useState(inputType === constants.PROFILE_FIELDS.birthdayMobile ? formatDate(initialValue).slice(3,5) : null);
  const [currentYear, setCurrentYear] = React.useState(inputType === constants.PROFILE_FIELDS.birthdayMobile ? formatDate(initialValue).slice(6,10) : null);

  const { i18n, t } = useTranslation('common');

  const inputRef = useRef(null);
  useEffect(() => {
    if (inputRef.current && autoFocus) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  useEffect(() => {
    if (inputType === constants.PROFILE_FIELDS.birthdayMobile) {
      const formattedBday = formatDate(mbirthday);
      setCurrentValue(formattedBday);
      setCurrentDay(formattedBday.slice(0,2));
      setCurrentMonth(formattedBday.slice(3,5));
      setCurrentYear(formattedBday.slice(6,10));
    }
  }, [mbirthday]);

  useEffect(() => {
    if (inputType === constants.PROFILE_FIELDS.birthdayMobile) {
      onChange({day: currentDay, month: currentMonth, year: currentYear}, inputType);
    }
  }, [currentDay,currentMonth,currentYear]);

  const handleChange = React.useCallback((e, birthdayField) => {
    if (inputType === constants.PROFILE_FIELDS.birthday) {
      setCurrentValue(e);
      onChange(e, inputType);
    } else if (inputType === constants.PROFILE_FIELDS.birthdayMobile) {
      let inputText = e.target.value.replace(/\D/g, '');

      if (birthdayField === 'day') {
        if (inputText.length === 1) {
          if (inputText[0] !== '0') {
            inputText = '0' + inputText;
          } else {
            inputText = '';
          }
        }

        if (inputText.length > 2) {
          if (inputText[0] === '0') {
            inputText = inputText.slice(1,3);
          } else {
            inputText = inputText.slice(0,2);
          }
        }
        setCurrentDay(inputText);
      }
      else if (birthdayField === 'month') {
        if (inputText.length === 1) {
          if (inputText[0] !== '0') {
            inputText = '0' + inputText;
          } else {
            inputText = '';
          }
        }
        if (inputText.length > 2) {
          if (inputText[0] === '0') {
            inputText = inputText.slice(1,3);
          } else {
            inputText = inputText.slice(0,2);
          }
        }

        setCurrentMonth(inputText);

      }
      else if (birthdayField === 'year') {
        if (inputText.length > 4) {
          inputText = inputText.slice(0,4);
        }
        setCurrentYear(inputText);
      }
    } else {
      setCurrentValue(e.target.value);
      onChange(e.target.value, inputType);
    }
  }, []);

  return (
    <div
      style={{
        marginTop: marginTop || 0,
        display: 'flex',
        paddingRight: 12,
        paddingLeft: 12,
        height: 56,
        borderRadius: 25,
        borderWidth: 1,
        borderColor: 'gray',
        borderStyle: 'solid',
        backgroundColor: 'white',
        flexDirection: 'column',
        color: 'black',
        width: width,
        paddingTop: 15,
        paddingBottom: 15,
        position: 'relative',
      }}
    >
      <div
        style={{
          paddingLeft: 12,
          fontFamily: 'Open Sans',
          color: '#868686',
          fontStyle: 'normal',
          fontWeight: 400,
          position: 'absolute',
          top: 0,
          marginTop: -3,
        }}
        className="new-input-placeholder"
      >
        {' '}
        {placeholder}{' '}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          textAlign: 'center',
          alignItems: 'center',
          position: 'relative',
          height: '100%',
        }}
      >
        {
          inputType === constants.PROFILE_FIELDS.birthday ? (
            <>
              <DateTimePicker
                inputProps={{
                  component: props => <input {...props} readOnly />
                }}
                focusSelect={false}
                className={`birthday-new-input ${config.isInAppFrame() && 'birthday-new-input-in-app-frame'}`}
                format={constants.FORMAT_DATE_TYPE.slashFullDate}
                culture={i18n.language}
                time={false}
                onChange={(e) => contentEditable && handleChange(e)}
                value={initialValue}
                defaultValue={initialValue}
                disabled={birthDisable}
                dropUp={dropUp || false}
              />
              <CustomBirthday 
                handleKeyDay={handleKeyDay}
                handleKeyMonth={handleKeyMonth}
                handleKeyYear={handleKeyYear}
                customDay={customDay}
                customMonth={customMonth}
                customYear={customYear}
                dRef={dRef}
                mRef={mRef}
                yRef={yRef}
                mbirthday={mbirthday}
                birthday={birthday}
                language={i18n.language}
                dPlaceholder={i18n.language === 'ru' ? 'ДД' : 'DD'}
                mPlaceholder={'MM'}
                yPlaceholder={i18n.language === 'en' ? 'YYYY' : (i18n.language === 'lv' ? 'GGGG' : 'ГГГГ')}
              />
            </>
          ) : inputType === constants.PROFILE_FIELDS.birthdayMobile ? (
            <>
              <>
                <Input
                  innerRef={inputRef}
                  value={currentDay}
                  onChange={(e) => contentEditable && handleChange(e, 'day')}
                  placeholder={i18n.language === 'ru' ? 'ДД' : 'DD'}
                  style={{
                    width: '20%',
                    height: '100%',
                    borderWidth: 0,
                    fontStyle: 'normal',
                    fontWeight: 400,
                    color: 'black',
                    padding: 0,
                    textAlign: 'center',
                    fontSize: 16,
                  }}
                  className="new-input-second-placeholder"
                  disabled={birthDisable}
                />
                <div style={{width: '10%'}}>/</div>
                <Input
                  innerRef={inputRef}
                  value={currentMonth}
                  onChange={(e) => contentEditable && handleChange(e, 'month')}
                  placeholder={'MM'}
                  style={{
                    width: '20%',
                    height: '100%',
                    borderWidth: 0,
                    fontStyle: 'normal',
                    fontWeight: 400,
                    color: 'black',
                    padding: 0,
                    textAlign: 'center',
                    fontSize: 16,
                  }}
                  className="new-input-second-placeholder"
                  disabled={birthDisable}
                />
                <div style={{width: '10%'}}>/</div>

                <Input
                  innerRef={inputRef}
                  value={currentYear}
                  onChange={(e) => contentEditable && handleChange(e, 'year')}
                  placeholder={i18n.language === 'en' ? 'YYYY' : (i18n.language === 'lv' ? 'GGGG' : 'ГГГГ')}
                  style={{
                    width: '40%',
                    height: '100%',
                    borderWidth: 0,
                    fontStyle: 'normal',
                    fontWeight: 400,
                    color: 'black',
                    padding: 0,
                    textAlign: 'center',
                    fontSize: 16,
                  }}
                  className="new-input-second-placeholder"
                  disabled={birthDisable}
                />
              </>
            </>
          ) : (
            <>
              <Media src={Dot} />
              <Input
                innerRef={inputRef}
                type={type}
                value={currentValue}
                onChange={(e) => contentEditable && handleChange(e)}
                placeholder={secondaryPlaceholder}
                style={{
                  height: '100%',
                  width: '100%',
                  fontSize: 16,
                  borderWidth: 0,
                  fontStyle: 'normal',
                  fontWeight: 400,
                  color: 'black',
                  padding: 0,
                  paddingLeft: 7,
                }}
                className="new-input-second-placeholder"
                disabled={loading || inputStatus === constants.FROUNT_SIDE_STATUS.success}
                maxLength={maxLength}
              />
            </>
          )
        }
        {
          (curLength || curLength === 0) && (
            <div
              className="new-input-current-length"
            >
              {`${curLength}/${maxLength}`}
            </div>
          )
        }
        {
          inlineMessageVisible && (
            <div className={`inputInlineMessage-${inputStatus} inputInlineMessage-${inputStatus}-${i18n.language}`}>
              <p style={{margin: 0}}>{inputMessage}</p>
            </div>
          )
        }
      </div>
    </div>
  );
};

TextInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  width: PropTypes.number,
  inputStatus: PropTypes.string,
  inputMessage: PropTypes.string,
  inlineMessageVisible: PropTypes.bool,
  loading: PropTypes.bool,
  maxLength: PropTypes.number,
  autoFocus: PropTypes.bool,
};

TextInput.defaultProps = {
  value: '',
  placeholder: '',
  width: 50,
  inputStatus: 'danger',
  inputMessage: 'wrong input code',
  inlineMessageVisible: false,
  loading: false,
  maxLength: null,
  autoFocus: false,
};

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import classNames from 'classnames'
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
import Media from 'reactstrap/lib/Media';
import Popover from 'reactstrap/lib/Popover';
import PopoverBody from 'reactstrap/lib/PopoverBody';
import Spinner from 'reactstrap/lib/Spinner';
import Input from 'reactstrap/lib/Input';

import ScrollAreaWrapper from '../../../Components/ScrollAreaWrapper';

import MenuRow from '../../../Components/Components/MenuRow';

import Players from '../TournamentPlayers';
import CustomDate from '../../../Components/Components/CustomDate';

import CustomModal from '../../../Components/Components/Modal';
import TournamentTutorial from '../Components/TournamentTutorial';
import PopoverEndTimer from '../Components/PopoverEndTimer';
import TournamentTimer from '../Components/TournamentTimer';
import TournamentInfoMobile from '../Mobile/Components/TournamentInfo';
import coinImg from '../../../../../../images/redesign/common/coin.svg';

import tutorialImage from '../../../../../../images/icons/help2.svg';
import lightInfoImg from '../../../../../../images/redesign/light-mode/player/info.svg';
import infoImg from '../../../../../../images/icons/info_chats.svg';
import LandScapeLayoutMobileHeader from '../../Layout/Mobile/LandScapeLayoutMobileHeader';


import {
  getTournamentPlayers,
  getTournamentActiveRooms,
  joinTournament,
  leaveTournament,
  returnToTournament,
  pauseTournament,
  getTournaments,
} from '../../../../../../actions/tournaments';

import {
  FONT_MODE, MENU_NAVIGATION, SCREEN_MODE
} from '../../../../../../constants/constants';
import { getActiveTournaments, getMyActiveTournaments, getOtherActiveTournaments, getTournamentById, getTournamentPlayersAlias, getTournamentPlayersTitle, getTournamentType } from '../../../../../../utils/tournamentUtils';
import { setOpenTournamentsTutorial } from '../../../../../../actions/state';

// class TournamentTimer extends React.Component {
//   static propTypes = {
//     time: PropTypes.number,
//     offset: PropTypes.number,
//     t: PropTypes.func.isRequired,
//   }

//   static defaultProps = {
//     time: 0,
//     offset: 0,
//   }

//   constructor(props) {
//     super(props);
//     this.state = {
//       minutes: 0,
//       seconds: 0,
//     };
//   }

//   componentDidMount() {
//     const { time } = this.props;
//     this.intervalID = setInterval(() => {
//       const { offset } = this.props;

//       if (time) {
//         const gameLength = time - (Date.now() + offset);
//         const minutes = Math.floor((gameLength % (1000 * 60 * 60)) / (1000 * 60));
//         const seconds = Math.floor((gameLength % (1000 * 60)) / 1000);

//         if (minutes >= 0 && seconds >= 0) {
//           this.setState({
//             minutes: minutes < 10 ? `0${minutes}` : minutes,
//             seconds: seconds < 10 ? `0${seconds}` : seconds,
//           });
//         }
//       }
//     }, 1000);
//   }

//   componentWillUnmount() {
//     clearInterval(this.intervalID);
//   }

//   render() {
//     const { time, t } = this.props;
//     const { minutes, seconds } = this.state;

//     if (!time) return null;

//     return (
//       ` (${t('tournaments.newRoundIn')} ${minutes}:${seconds})`
//     );
//   }
// }

class Tournaments extends React.Component {
  static propTypes = {
    tournaments: PropTypes.arrayOf(PropTypes.shape()),
    tournamentPlayers: PropTypes.shape(),
    myTournamentsData: PropTypes.shape(),
    registeredTournament: PropTypes.shape(),
    offset: PropTypes.number,
    uid: PropTypes.string,
    //  member: PropTypes.shape(),
    //  i18n: PropTypes.shape(),
    t: PropTypes.func.isRequired,
    joinTournamentFunc: PropTypes.func.isRequired,
    leaveTournamentFunc: PropTypes.func.isRequired,
    returnToTournamentFunc: PropTypes.func.isRequired,
    pauseTournamentFunc: PropTypes.func.isRequired,
    changeTab: PropTypes.func.isRequired,
    fetchTournamentPlayers: PropTypes.func.isRequired,
    fetchTournamentActiveRooms: PropTypes.func.isRequired,
    fetchTournaments: PropTypes.func.isRequired,
    fontMode: PropTypes.string,
    screenMode: PropTypes.string,
    OpenMobileDropdownModal: PropTypes.func.isRequired,
    finishedTournamentLastRound: PropTypes.bool,
  }

  static defaultProps = {
    tournaments: [],
    tournamentPlayers: null,
    myTournamentsData: null,
    registeredTournament: null,
    uid: null,
    offset: 0,
    fontMode: FONT_MODE.normal,
    screenMode: SCREEN_MODE.normal,
    finishedTournamentLastRound: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      openJoinModal: false,
      tournamentId: '',
      scrollPos: 0,
      tournamentToJoin: null,
      tournamentToJoinFee: null,
      registerPopoverOpen: {},
      privateTournamentConfirm: false,
      privateTournamentConfirmError: '',
      tournamentConfirmError: '',
      privateTournamentId: '',
      password: [],
      lowBalanceError: false,
      showTournamentTutorial: false,
      tournamentInfoModalOpen: false,
      selectTournament: {},
      popoverEndTime: 0,
      returnConfirmationOpen: false,
      pauseConfirmationOpen: false,
      confirmationTournamentId: null,
    };

    this.digit2 = React.createRef();
    this.digit3 = React.createRef();
    this.digit4 = React.createRef();
  }

  componentWillMount() {
    const { fetchTournaments } = this.props;

    const { hostname } = window.location;

    let devMode = false;

    if (hostname && (hostname.includes('dev') || hostname.includes('localhost'))) {
      devMode = true;
    }

    fetchTournaments(devMode);
  }

  componentWillMount() {

  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.openTournamentsTutorial) {
      this.toggleShowTournamentTutorial();
      this.props.setOpenTournamentsTutorialFunc(false);
    }
  }

  /* joinTournamentNotification = (tournamentId, fee) => {
    this.setState({
      openJoinModal: true,
      tournamentToJoin: tournamentId,
      tournamentToJoinFee: fee,
    });
  } */

  handleChangeDigit = (e) => {
    const { password } = this.state;

    if (e.target) {
      const { value, name } = e.target;

      const newVal = value.charAt(value.length - 1);

      if (!isNaN(newVal) || !newVal) {
        password[name] = newVal;

        if (newVal) {
          if (name === 0 || name === '0') {
            if (this.digit2) {
              this.digit2.current.focus();
            }
          }
          if (name === 1 || name === '1') {
            if (this.digit3) {
              this.digit3.current.focus();
            }
          }
          if (name === 2 || name === '2') {
            if (this.digit4) {
              this.digit4.current.focus();
            }
          }
        }

        this.setState({
          password,
        });
      }
    }
  }

  joinTournamentNotification = (tournamentId, fee) => {
    const { tournaments, registeredTournament, joinTournamentFunc } = this.props;

    const tournament = tournaments.find(element => element.id === tournamentId);

    if (tournament && !(tournament.registeredPlayers >= tournament.maxPlayers) && !registeredTournament) {
      joinTournamentFunc('init');

      this.setState({
        openJoinModal: true,
        tournamentToJoin: tournamentId,
        tournamentToJoinFee: fee,
      });
    }
  }

  toggleJoin = () => {
    this.setState(prevState => ({
      openJoinModal: !prevState.openJoinModal,
    }));
  }


  closePrivateTournamentEnter = () => {
    this.setState({
      password: [], privateTournamentConfirm: false, privateTournamentId: '', privateTournamentConfirmError: '',
    });
  }

  joinPrivateTournamentConfirm = (tournamentId, fee) => {
    const { tournaments, registeredTournament, joinTournamentFunc } = this.props;
    const tournament = tournaments.find(element => element.id === tournamentId);

    if (tournament && !(tournament.registeredPlayers >= tournament.maxPlayers) && !registeredTournament) {
      joinTournamentFunc('init');

      this.setState({
        //  openJoinModal: true,
        tournamentToJoin: tournamentId,
        tournamentToJoinFee: fee,
        privateTournamentConfirm: true,
        privateTournamentId: tournamentId,
        privateTournamentConfirmError: '',
      });
    }
  }


  joinPrivateTournamentClicked = () => {
    const { joinTournamentFunc } = this.props;
    const { privateTournamentId, password } = this.state;
    if (!privateTournamentId) {
      return null;
    }

    if (password && password.length === 4) {
      //  this.setState({ joinRoomClicked: true });

      const passString = password.join('');

      joinTournamentFunc(privateTournamentId, passString).then((res) => {
        if (res && res.status === 'success') {
          this.setState({
            password: [], privateTournamentConfirm: false, privateTournamentId: '', privateTournamentConfirmError: '',
          });

          setTimeout(() => {
            this.setState({
              openJoinModal: false,
              tournamentToJoin: '',
              tournamentToJoinFee: '',
            });
          }, 250);
        } else if (res.error === 'wrong password') {
          this.setState({ privateTournamentConfirmError: 'tournamentWrongPassword' }); // Nepareizs kods
        } else if (res.error === 'tournament already registered') {
          this.setState({
            password: [], privateTournamentConfirm: false, privateTournamentId: '', privateTournamentConfirmError: '',
          });

          setTimeout(() => {
            this.setState({
              openJoinModal: false,
              tournamentToJoin: '',
              tournamentToJoinFee: '',
            });
          }, 250);
        } else if (res.error === 'tournament max players') {
          this.setState({ privateTournamentConfirmError: 'tournamentFull' }); // Turnīrs pilns
        } else if (res.error === 'insuf bal tournament') {
          const { tournaments } = this.props;
          this.setState({ privateTournamentConfirmError: 'noBalanceTournament', lowBalanceError: true, selectTournament: { ...tournaments.filter(tournament => tournament.id === tournament.id)[0] } });
        } else {
          this.setState({ privateTournamentConfirmError: 'tournamentError' }); // Radās kļūda pievienojoties, mēģini vēlreiz
        }
      });
    } else {
      console.log('no password');
    }

    return null;
  }

  /*  showPrivateTournamentPassword = () => {
    const { privateTournamentPassword } = this.props;
 
    if (privateTournamentPassword) {
      this.setState({
        showPrivateTournamentPassword: true,
      });
    }
  }
 
  closePrivateTournamentPassword = () => {
    this.setState({
      showPrivateTournamentPassword: false,
    });
  }  */

  joinTournament = (tournamentId) => {
    const { joinTournamentFunc } = this.props;

    if (tournamentId) {
      joinTournamentFunc(tournamentId).then((res) => {
        if (res && res.status === 'success') {
          //  this.setState({
          //    password: [], privateTournamentConfirm: false, privateTournamentId: '', privateTournamentConfirmError: '',
          //  });

          setTimeout(() => {
            this.setState({
              openJoinModal: false,
              tournamentToJoin: '',
              tournamentToJoinFee: '',
            });
          }, 250);
        } else if (res.error === 'tournament already registered') {
          //  this.setState({
          //    password: [], privateTournamentConfirm: false, privateTournamentId: '', privateTournamentConfirmError: '',
          //  });

          setTimeout(() => {
            this.setState({
              openJoinModal: false,
              tournamentToJoin: '',
              tournamentToJoinFee: '',
            });
          }, 250);
        } else if (res.error === 'tournament max players') {
          this.setState({ tournamentConfirmError: 'tournamentFull' }); // Turnīrs pilns
        } else if (res.error === 'insuf bal tournament') {
          this.setState({ tournamentConfirmError: 'noBalanceTournament', lowBalanceError: true });
        } else {
          this.setState({ tournamentConfirmError: 'tournamentError' }); // Radās kļūda pievienojoties, mēģini vēlreiz
        }
      });
    }

    setTimeout(() => {
      this.setState({
        openJoinModal: false,
        tournamentToJoin: '',
        tournamentToJoinFee: '',
      });
    }, 250);
  }

  closeLowBalanceError = () => {
    this.setState({ lowBalanceError: false });
  }

  buyMoney = () => {
    const { changeTab } = this.props;

    changeTab(MENU_NAVIGATION.buyPageMoneyPacks);
  }

  openModal = (id) => {
    //  const { tournamentPlayers } = this.props;
    //  const leaderboard = tournamentPlayers[id];

    this.setState({
      tournamentId: id,
      openModal: true,
      //    leaderboard,
    });
  }

  toggle = () => {
    this.setState(prevState => ({
      openModal: !prevState.openModal,
    }));
  }


  /*  toggleJoin = () => {
   this.setState(prevState => ({
     openJoinModal: !prevState.openJoinModal,
   }));
 }  */

  toggleLeave = () => {
    this.setState(prevState => ({
      openLeaveModal: !prevState.openLeaveModal,
    }));
  }

  leaveTournamentNotification = (tournamentId) => {
    this.setState({
      openLeaveModal: true,
      tournamentToLeave: tournamentId,
    });
  }

  leaveTournament = (tournamentId) => {
    const { leaveTournamentFunc } = this.props;

    if (tournamentId) {
      leaveTournamentFunc(tournamentId);
    }

    setTimeout(() => {
      this.setState({
        openLeaveModal: false,
        tournamentToLeave: '',
      });
    }, 250);
  }

  fetchTournamentPlayers = (tournamentId) => {
    const { fetchTournamentPlayers } = this.props;

    if (tournamentId) {
      fetchTournamentPlayers(tournamentId).then(() => {
        this.setState({
          openModal: true,
          tournamentId: tournamentId,
        });
      });
    }
  }

  fetchTournamentActiveRooms = (tournamentId) => {
    const { fetchTournamentActiveRooms, tournaments } = this.props;
    const { popoverOpen } = this.state;

    if (popoverOpen && popoverOpen === tournamentId) {
      this.setState({ popoverOpen: null });
    } else if (tournamentId) {
      fetchTournamentActiveRooms(tournamentId).then((res) => {
        const tournament = tournaments.find(element => element.id === tournamentId);

        if (res && res.data) {
          const roomsCount = Object.keys(res.data).length;
          let partiesCount = 0;

          Object.keys(res.data).map((key) => {
            if (res.data[key] && res.data[key].party) {
              partiesCount += parseInt(res.data[key].party, 10);
            }
            return null;
          });

          const { roundLength, gameStartTime } = tournament;
          const averageParties = partiesCount / roomsCount;
          const averagePartiesLeft = Math.round(parseInt(roundLength, 10) - averageParties);

          let gameEndTime = gameStartTime + (20 * 60 * 1000);

          if (roundLength > 24) {
            gameEndTime = gameStartTime + (60 * 60 * 1000);
          } else if (roundLength > 12) {
            gameEndTime = gameStartTime + (40 * 60 * 1000);
          }

          this.setState({ popoverOpen: tournamentId, popoverRoomsCount: roomsCount, popoverEndTime: gameEndTime, popoverPartiesCount: roomsCount > 0 ? averagePartiesLeft : 0 });
        }
      });
    }
  }

  togglePopover = () => {
    this.setState({ popoverOpen: null });
  }

  updateScrollPos = (val) => {
    if (val.topPosition || val.topPosition === 0) {
      this.setState({ scrollPos: val.topPosition || 0 });
    }
    //  this.setState({ scrollPos: val.topPosition || 0 });
  }

  scroll = (newVal) => {
    this.tableScrollbar.scrollYTo(newVal);
  }

  onRegisterHover = (id) => {
    const { registerPopoverOpen } = this.state;
    if (registerPopoverOpen && !registerPopoverOpen[id]) {
      this.setState(prevState => ({
        registerPopoverOpen: {
          ...prevState.registerPopoverOpen,
          [id]: true,
        },
      }));
    }
  }

  onRegisterHoverLeave = (id) => {
    this.setState(prevState => ({
      registerPopoverOpen: {
        ...prevState.registerPopoverOpen,
        [id]: false,
      },
    }));
    //  this.setState({
    //    registerPopoverOpen: false,
    //  })
  }

  toggleShowTournamentTutorial = () => {
    const { showTournamentTutorial } = this.state;

    if (!showTournamentTutorial) {
      ReactGA.event({
        category: 'Menu',
        action: 'Tournaments Help',
      });
    }
    this.setState(prevState => ({ showTournamentTutorial: !prevState.showTournamentTutorial }));
  }

  renderTournamentState = (t, state) => (
    <div className={`tournament-state tournament-state-${state}`}>
      {t(`tournaments.${state}`)}
    </div>
  )

  openTournamentInfoModal = (selectId) => {
    const { tournaments } = this.props;

    this.setState({
      tournamentInfoModalOpen: true,
      selectTournament: { ...tournaments.filter(tournament => tournament.id === selectId)[0] }
    })
  }

  closeTournamentInfoModal = () => {
    this.setState({
      tournamentInfoModalOpen: false,
    })
  }

  toggleReturnConfirmationOpen = (tournamentId) => {
    const { returnConfirmationOpen } = this.state;
    this.setState({
      confirmationTournamentId: returnConfirmationOpen ? null : tournamentId,
      returnConfirmationOpen: !returnConfirmationOpen,
    })
  }

  togglePauseConfirmationOpen = (tournamentId) => {
    const { pauseConfirmationOpen } = this.state;
    this.setState({
      confirmationTournamentId: pauseConfirmationOpen ? null : tournamentId,
      pauseConfirmationOpen: !pauseConfirmationOpen,
    })
  }

  render() {
    const {
      t,
      tournaments,
      myTournamentsData,
      //  member,
      registeredTournament,
      uid,
      offset,
      changeTab,
      tournamentPlayers,
      returnToTournamentFunc,
      pauseTournamentFunc,
      fontMode,
      fetchTournamentActiveRooms,
      screenMode,
      toggle,
      activeTab,
      hasActiveRegistration,
      achievementsNotification,
      handleClickStart,
      OpenMobileDropdownModal,
      showUserSettingsModal,
      //  privateTournamentPassword,
      finishedTournamentLastRound,
    } = this.props;

    const {
      openModal,
      tournamentId,
      tournamentToJoin,
      tournamentToJoinFee,
      openJoinModal,
      tournamentToLeave,
      openLeaveModal,
      scrollPos,
      registerPopoverOpen,
      privateTournamentConfirm,
      privateTournamentConfirmError,
      tournamentConfirmError,
      password,
      popoverOpen,
      popoverRoomsCount,
      popoverPartiesCount,
      //  showPrivateTournamentPassword,
      lowBalanceError,
      showTournamentTutorial,
      popoverEndTime,
      selectTournament,
      tournamentInfoModalOpen,
      returnConfirmationOpen,
      pauseConfirmationOpen,
      confirmationTournamentId,
    } = this.state;

    console.log('tournaments aaa', tournaments);

    const activeTournaments = getActiveTournaments(tournaments);
    const myActiveTournaments = getMyActiveTournaments(activeTournaments, myTournamentsData);
    const otherActiveTournaments = getOtherActiveTournaments(activeTournaments, myTournamentsData);

    console.log('tournaments aaa activeTournaments', activeTournaments);

    return (
      <>
        <Helmet>
          <title>
            Zole - {t('route.tournaments')}
          </title>
        </Helmet>
        <div className="layout-mobile-body layout-mobile-body-tournaments-tab layout-mobile-body-tournaments-page">

          <div className="layout-mobile-body-top" />

          <div className="layout-mobile-body-main">
            <div class="layout-mobile-body-main-title">{t('tournaments.activeTournaments')}</div>
            <div
              className="tournament-scrollarea"
              contentClassName="tournament-scrollarea-body"
              show
              rightOffset={0}
              topOffset={0}
              bottomOffset={40}
            >
              <Fragment>
                {
                  (myActiveTournaments && myActiveTournaments.length > 0) ? (
                    <Fragment>
                      <span className="tournaments-participate-content">{t('tournaments.activeMyTournament')}</span>
                      {(myActiveTournaments.map((tournament) => {
                        const { startDate, registerTime } = tournament;
                        let registerDate;
                        if (startDate && registerTime) {
                          registerDate = startDate - (registerTime * 1000 * 60);
                        }

                        return (
                          <MenuRow key={tournament.id}>
                            <Row key={tournament.id} className="tournament-table-row tournament-table-row-main">
                              <div className='active-tournaments'>
                                <div className='active-tournaments-info'>
                                  <div className='active-tournaments-info-section'>
                                    <div className='active-tournaments-info-section-title'>
                                      <div className='active-tournaments-info-section-title-wrapper'>
                                        <Media src={screenMode === SCREEN_MODE.light ? lightInfoImg : tutorialImage} onClick={() => this.openTournamentInfoModal(tournament.id)} className='active-tournaments-info-section-title-left-img' alt="x" />
                                        <div className='active-tournaments-info-section-inner-wrapper'>
                                          <div className='first-line'>
                                            <span className='active-tournaments-info-section-title-left-title'>{tournament.name}</span>
                                            <div className={`active-tournaments-info-section-title-left-title-status ${tournament.currentState}-status`}>
                                              {/**participant view */}
                                              {tournament.currentState === 'play' ? (
                                                tournament.rounds !== tournament.currentRound ? (
                                                  <>
                                                    {t('tournaments.activeNewRoundAfter', { round: tournament.currentRound })}
                                                    <PopoverEndTimer fetchTournamentActiveRooms={fetchTournamentActiveRooms} tournamentId={tournament.id} tournaments={tournaments} time={popoverEndTime || 0} offset={offset} />
                                                  </>
                                                ) : (
                                                  <>
                                                    {t('tournaments.activeTournamentEndsAfter')}
                                                    <PopoverEndTimer fetchTournamentActiveRooms={fetchTournamentActiveRooms} tournamentId={tournament.id} tournaments={tournaments} time={popoverEndTime || 0} offset={offset} />
                                                  </>
                                                )
                                              ) : (
                                                tournament.rounds !== tournament.currentRound ? (
                                                  <>
                                                    {t('tournaments.pausedNewRoundAfter', { round: tournament.currentRound })}
                                                    <TournamentTimer t={t} time={tournament.pauseUntil || 0} offset={offset} />
                                                  </>
                                                ) : (
                                                  <>
                                                    {t('tournaments.pausedNewRoundAfter', { round: tournament.currentRound })}
                                                    <TournamentTimer t={t} time={tournament.pauseUntil || 0} offset={offset} />
                                                  </>
                                                )
                                              )}
                                            </div>
                                          </div>
                                          <div className='second-line'>
                                            <span className='active-tournaments-info-section-title-left-private'>
                                              {getTournamentType(tournament, t)}
                                            </span>
                                            <div className='active-tournaments-info-section-time-rounds-wrapper'>
                                              <span className='active-tournaments-info-section-time-rounds'>{`${t('tournaments.rounds')}:`}</span>
                                              <span className='active-tournaments-info-section-time-roundsValue'>{tournament.rounds}</span>
                                            </div>
                                            <div className='active-tournaments-info-section-time-wrapper'>
                                              <span className='active-tournaments-info-section-time-label'>{`${t('tournaments.beginning')}:`}</span>
                                              <span className='active-tournaments-info-section-time-content'><CustomDate format="DD.MM.YYYY hh:mm" date={startDate} /></span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='active-tournaments-info-button'>
                                    <Button color="link" className="layout-default-button" onClick={() => this.fetchTournamentPlayers(tournament.id)}>
                                      {t('tournaments.players')}
                                    </Button>
                                    {registeredTournament === tournament.id && myTournamentsData && myTournamentsData.tournamentId === tournament.id && tournament.currentState && (tournament.currentState === 'play' || tournament.currentState === 'pause') && (
                                      <Fragment>
                                        {myTournamentsData.paused ? (
                                          <Button color="link" disabled={/*tournament.rounds === tournament.currentRound*/false} id="tournaments-return-button" className="layout-highlighted-button" onClick={(e) => { this.toggleReturnConfirmationOpen(tournament.id); }}>
                                            {t('tournaments.return')}
                                          </Button>
                                        ) : (
                                          <Button color="link" disabled={/*tournament.rounds === tournament.currentRound*/finishedTournamentLastRound} id="tournaments-pause-button" className="layout-highlighted-button" onClick={(e) => { this.togglePauseConfirmationOpen(tournament.id); }}>
                                            {t('tournaments.pause')}
                                          </Button>
                                        )}
                                        <Button color="link" disabled={finishedTournamentLastRound} className="layout-highlighted-button" onClick={() => this.leaveTournamentNotification(tournament.id)}>
                                          {t('tournaments.leave')}
                                        </Button>
                                      </Fragment>
                                    )}
                                  </div>
                                </div>
                                <div className={
                                  classNames('active-tournaments-sponsor', {
                                    'active-tournaments-sponsor-two': tournament?.sponsorImg && tournament?.prizeImg
                                  })
                                }>
                                  {
                                    tournament?.sponsorImg && (
                                      <div className={
                                        classNames('active-tournaments-sponsor-section', {
                                          'active-tournaments-sponsor-section-two': tournament?.sponsorImg && tournament?.prizeImg
                                        })
                                      }>
                                        <span>{t('tournaments.sponsorTournaments')}</span>
                                        <Media src={tournament?.sponsorImg} alt="x" />
                                      </div>
                                    )
                                  }
                                  {
                                    tournament?.prizeImg && (
                                      <div className={
                                        classNames('active-tournaments-sponsor-section', {
                                          'active-tournaments-sponsor-section-two': tournament?.sponsorImg && tournament?.prizeImg
                                        })
                                      }>
                                        <span>{t('tournaments.mainPrize')}</span>
                                        <Media src={tournament?.prizeImg} alt="x" />
                                      </div>
                                    )
                                  }
                                </div>
                              </div>
                            </Row>
                          </MenuRow>
                        );
                      }))}
                    </Fragment>
                  ) : null
                }
              </Fragment>
              <Fragment>
                {(otherActiveTournaments && otherActiveTournaments.length > 0) ? (
                  <Fragment>
                    {/* <span className="tournaments-participate-content">{t('tournaments.activeOtherTournaments')}</span> */}
                    {(otherActiveTournaments.map((tournament) => {
                      const { startDate, registerTime } = tournament;
                      let registerDate;
                      if (startDate && registerTime) {
                        registerDate = startDate - (registerTime * 1000 * 60);
                      }

                      return (
                        <MenuRow key={tournament.id}>
                          <Row key={tournament.id} className="tournament-table-row tournament-table-row-main">
                            <div className='active-tournaments'>
                              <div className='active-tournaments-info'>
                                <div className='active-tournaments-info-section'>
                                  <div className='active-tournaments-info-section-title'>
                                    <div className='active-tournaments-info-section-title-wrapper'>
                                      <Media src={screenMode === SCREEN_MODE.light ? lightInfoImg : tutorialImage} onClick={() => this.openTournamentInfoModal(tournament.id)} className='active-tournaments-info-section-title-left-img' alt="x" />
                                      <div className='active-tournaments-info-section-inner-wrapper'>
                                        <div className='first-line'>
                                          <span className='active-tournaments-info-section-title-left-title'>{tournament.name}</span>
                                          <div className={`active-tournaments-info-section-title-left-title-status ${tournament.currentState}-status`}>
                                            {/**non participant view */}
                                            {tournament.currentState === 'play' ? (
                                              tournament.rounds !== tournament.currentRound ? (
                                                <>
                                                  {t('tournaments.activeNewRoundAfter', { round: tournament.currentRound })}
                                                  <PopoverEndTimer fetchTournamentActiveRooms={fetchTournamentActiveRooms} tournamentId={tournament.id} tournaments={tournaments} time={popoverEndTime || 0} offset={offset} />
                                                </>
                                              ) : (
                                                <>
                                                  {t('tournaments.activeTournamentEndsAfter')}
                                                  <PopoverEndTimer fetchTournamentActiveRooms={fetchTournamentActiveRooms} tournamentId={tournament.id} tournaments={tournaments} time={popoverEndTime || 0} offset={offset} />
                                                </>
                                              )
                                            ) : (
                                              tournament.rounds !== tournament.currentRound ? (
                                                <>
                                                  {t('tournaments.pausedNewRoundAfter', { round: tournament.currentRound })}
                                                  <TournamentTimer t={t} time={tournament.pauseUntil || 0} offset={offset} />
                                                </>
                                              ) : (
                                                <>
                                                  {t('tournaments.pausedNewRoundAfter', { round: tournament.currentRound })}
                                                  <TournamentTimer t={t} time={tournament.pauseUntil || 0} offset={offset} />
                                                </>
                                              )
                                            )}
                                          </div>
                                        </div>
                                        <div className='second-line'>
                                          <span className='active-tournaments-info-section-title-left-private'>
                                            {getTournamentType(tournament, t)}
                                          </span>
                                          <div className='active-tournaments-info-section-time-rounds-wrapper'>
                                            <span className='active-tournaments-info-section-time-rounds'>{`${t('tournaments.rounds')}:`}</span>
                                            <span className='active-tournaments-info-section-time-roundsValue'>{tournament.rounds}</span>
                                          </div>
                                          <div className='active-tournaments-info-section-time-wrapper'>
                                            <span className='active-tournaments-info-section-time-label'>{`${t('tournaments.beginning')}:`}</span>
                                            <span className='active-tournaments-info-section-time-content'><CustomDate format="DD.MM.YYYY hh:mm" date={startDate} /></span>
                                          </div>
                                        </div>
                                      </div>

                                    </div>

                                  </div>
                                </div>
                                <div className='active-tournaments-info-button'>
                                  <Button color="link" className="layout-default-button" onClick={() => this.fetchTournamentPlayers(tournament.id)}>
                                    {t('tournaments.players')}
                                  </Button>
                                  {registeredTournament === tournament.id && myTournamentsData && myTournamentsData.tournamentId === tournament.id && tournament.currentState && (tournament.currentState === 'play' || tournament.currentState === 'pause') && (
                                    <Fragment>
                                      {myTournamentsData.paused ? (
                                        <Button color="link" disabled={/*tournament.rounds === tournament.currentRound*/false} id="tournaments-return-button" className="layout-highlighted-button" onClick={(e) => { this.toggleReturnConfirmationOpen(tournament.id); }}>
                                          {t('tournaments.return')}
                                        </Button>
                                      ) : (
                                        <Button color="link" disabled={/*tournament.rounds === tournament.currentRound*/false} id="tournaments-pause-button" className="layout-highlighted-button" onClick={(e) => { this.togglePauseConfirmationOpen(tournament.id) }}>
                                          {t('tournaments.pause')}
                                        </Button>
                                      )}
                                    </Fragment>
                                  )}
                                </div>
                              </div>
                              <div className={
                                classNames('active-tournaments-sponsor', {
                                  'active-tournaments-sponsor-two': tournament?.sponsorImg && tournament?.prizeImg
                                })
                              }>
                                {
                                  tournament?.sponsorImg && (
                                    <div className={
                                      classNames('active-tournaments-sponsor-section', {
                                        'active-tournaments-sponsor-section-two': tournament?.sponsorImg && tournament?.prizeImg
                                      })
                                    }>
                                      <span>{t('tournaments.sponsorTournaments')}</span>
                                      <Media src={tournament?.sponsorImg} alt="x" />
                                    </div>
                                  )
                                }
                                {
                                  tournament?.prizeImg && (
                                    <div className={
                                      classNames('active-tournaments-sponsor-section', {
                                        'active-tournaments-sponsor-section-two': tournament?.sponsorImg && tournament?.prizeImg
                                      })
                                    }>
                                      <span>{t('tournaments.mainPrize')}</span>
                                      <Media src={tournament?.prizeImg} alt="x" />
                                    </div>
                                  )
                                }
                              </div>
                            </div>
                          </Row>
                        </MenuRow>
                      );
                    }))}
                  </Fragment>
                ) : null}
              </Fragment>
              <Fragment>
                {
                  (activeTournaments && activeTournaments.length > 0) ? <></> : <div className="future-tournaments-empty-entries">{t('tournaments.activeTournamentsEmptyEntries')}</div>
                }
              </Fragment>
            </div>
          </div>
        </div>

        <TournamentTutorial showTournamentTutorial={showTournamentTutorial} toggleShowTournamentTutorial={this.toggleShowTournamentTutorial} />

        {uid && tournamentPlayers && openModal && getTournamentPlayersAlias(getTournamentById(tournaments, tournamentId)) !== 'complete' ? (
          <>
            <CustomModal
              isOpen={openModal}
              toggle={this.toggle}
              addClassName="tournament-top-modal"
              size="lg"
              title={getTournamentPlayersTitle(tournamentId, tournaments, t)/*t('tournaments.top')*/}
              footer={
                <Button color="link" className="btn notification-footer-button" onClick={this.toggle}>
                  {t('common.close')}
                </Button>
              }
              body={<Players tournament={getTournamentById(tournaments, tournamentId)} players={tournamentPlayers} tournamentId={tournamentId} uid={uid} screenMode={screenMode} />}
            />
          </>
        ) : (null)}

        <CustomModal
          isOpen={tournamentInfoModalOpen}
          toggle={this.closeTournamentInfoModal}
          size="lg"
          addClassName={"tournaments-info-modal"}
          title={selectTournament?.name || t('tournaments.tournaments')}
          footer={
            <Button color="link" className="btn notification-footer-button" onClick={this.closeTournamentInfoModal}>
              {t('common.close')}
            </Button>
          }
          body={<TournamentInfoMobile t={t} selectTournament={selectTournament} />}
        />
        <CustomModal
          isOpen={privateTournamentConfirm}
          toggle={this.closePrivateTournamentEnter}
          size="lg"
          largeText
          title={t('tournaments.joinTournament')}
          footer={(
            <>
              <Button color="link" className="btn notification-footer-button" onClick={() => this.joinPrivateTournamentClicked()}>
                {t('tournaments.register')}
              </Button>
              <Button color="link" className="btn notification-footer-button" onClick={this.closePrivateTournamentEnter}>
                {t('common.close')}
              </Button>
            </>
          )}
          body={(
            <>
              <div className="text-center">
                {t('tournaments.joinTournamentMessage', { entryFee: tournamentToJoinFee })}
                <Media className="tournaments-join-message-image" src={coinImg} />
              </div>
              <Row>
                <Col>
                  {privateTournamentConfirmError && (
                    <div className="room-password-error">
                      {t(`notifications:${privateTournamentConfirmError}`)}
                    </div>
                  )}
                </Col>
              </Row>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 15 }}>
                <Input
                  className="room-password-digit"
                  type="text"
                  name={0}
                  id="digit1"
                  value={password[0] || ''}
                  onChange={this.handleChangeDigit}
                  innerRef={(el) => { this.digit1 = el; }}
                />
                <Input
                  className="room-password-digit"
                  type="text"
                  name={1}
                  id="digit2"
                  value={password[1] || ''}
                  onChange={this.handleChangeDigit}
                  innerRef={this.digit2}
                />
                <Input
                  className="room-password-digit"
                  type="text"
                  name={2}
                  id="digit3"
                  value={password[2] || ''}
                  onChange={this.handleChangeDigit}
                  innerRef={this.digit3}
                />
                <Input
                  className="room-password-digit"
                  type="text"
                  name={3}
                  id="digit4"
                  value={password[3] || ''}
                  onChange={this.handleChangeDigit}
                  innerRef={this.digit4}
                />
              </div>
            </>
          )}
        />

        <CustomModal
          isOpen={openJoinModal}
          toggle={this.toggleJoin}
          size="lg"
          verticalCentering
          largeText
          title={t('tournaments.joinTournament')}
          footer={(
            <>
              <Button color="link" className="modal-footer-button" onClick={() => this.joinTournament(tournamentToJoin)}>
                {t('tournaments.register')}
              </Button>
              <Button color="link" className="modal-footer-button" onClick={this.toggleJoin}>{t('tournaments.close')}</Button>
            </>
          )}
          body={(
            <>
              {tournamentConfirmError && (
                <Row>
                  <Col>
                    <div className="room-password-error text-center">
                      {t(`notifications:${tournamentConfirmError}`)}
                    </div>
                  </Col>
                </Row>
              )}

              <div className="tournaments-join-message text-center">
                {t('tournaments.joinTournamentMessage', { entryFee: tournamentToJoinFee })}
                <Media className="tournaments-join-message-image" src={coinImg} />
              </div>
            </>
          )}
        />

        <CustomModal
          isOpen={openLeaveModal}
          toggle={this.toggleLeave}
          size="lg"
          largeText
          verticalCentering
          title={t('tournaments.leaveTournament')}
          footer={(
            <>
              <Button color="link" className="modal-footer-button" onClick={() => this.leaveTournament(tournamentToLeave)}>
                {t('tournaments.leave')}
              </Button>
              <Button color="link" className="modal-footer-button" onClick={this.toggleLeave}>{t('tournaments.close')}</Button>
            </>
          )}
          body={t('tournaments.leaveTournamentBody')}
        />

        <CustomModal
          isOpen={lowBalanceError}
          toggle={this.closeLowBalanceError}
          size="lg"
          verticalCentering
          largeText
          title={t('common:home.notifications')}
          footer={
            <Fragment>
              <Button className="btn notification-footer-button" color="link" onClick={this.buyMoney}>{t('notifications:buyMoney')}</Button>
              <Button className="btn notification-footer-button" color="link" onClick={this.closeLowBalanceError}>{t('notifications:close')}</Button>
            </Fragment>
          }
          body={
            <div className='text-center'>
              {t('notifications:noMoneyTournamentDescription',
                {
                  feeMaxLoss: selectTournament && selectTournament.bet && selectTournament.entryFee + 16 * parseInt(selectTournament.bet.split(':')[1]),
                  bet: selectTournament?.bet, fee: selectTournament?.entryFee
                })}
            </div>
          }
        />

        <CustomModal
          isOpen={pauseConfirmationOpen}
          toggle={this.togglePauseConfirmationOpen}
          size="md"
          verticalCentering
          largeText
          title={t('home.confirm')}
          footer={(
            <>
              <Button className="question-modal-approve-btn" onClick={(e) => { pauseTournamentFunc(confirmationTournamentId); e.target.blur(); this.togglePauseConfirmationOpen(); }}>
                {t('common.yes')}
              </Button>
              <Button className="question-modal-decline-btn" onClick={this.togglePauseConfirmationOpen}>
                {t('common.no')}
              </Button>
            </>
          )}
          body={(
            <p className="text-center">
              {t('tournaments.pauseConfirmation')}
            </p>
          )}
        />

        <CustomModal
          isOpen={returnConfirmationOpen}
          toggle={this.toggleReturnConfirmationOpen}
          size="md"
          verticalCentering
          largeText
          title={t('home.confirm')}
          footer={(
            <>
              <Button className="question-modal-approve-btn" onClick={(e) => { returnToTournamentFunc(confirmationTournamentId); e.target.blur(); this.toggleReturnConfirmationOpen(); }}>
                {t('common.yes')}
              </Button>
              <Button className="question-modal-decline-btn" onClick={this.toggleReturnConfirmationOpen}>
                {t('common.no')}
              </Button>
            </>
          )}
          body={(
            <p className="text-center">
              {t('tournaments.returnConfirmation')}
            </p>
          )}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  finishedTournamentLastRound: state.member.finishedTournamentLastRound,
  registeredTournament: state.member.registeredTournament,
  uid: state.member.uid || '',
  offset: state.member.offset || 0,
  tournaments: (state.tournaments && state.tournaments.tournaments) ? state.tournaments.tournaments : {},
  myTournamentsData: (state.tournaments && state.tournaments.myTournamentsData) ? state.tournaments.myTournamentsData : {},
  tournamentPlayers: (state.tournaments && state.tournaments.tournamentPlayers) ? state.tournaments.tournamentPlayers : {},
  openTournamentsTutorial: state.state.openTournamentsTutorial || false,
});

const mapDispatchToProps = {
  pauseTournamentFunc: pauseTournament,
  returnToTournamentFunc: returnToTournament,
  leaveTournamentFunc: leaveTournament,
  joinTournamentFunc: joinTournament,
  fetchTournamentPlayers: getTournamentPlayers,
  fetchTournamentActiveRooms: getTournamentActiveRooms,
  setOpenTournamentsTutorialFunc: setOpenTournamentsTutorial,
  fetchTournaments: getTournaments,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['common', 'notifications'])(Tournaments));

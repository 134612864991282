import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import Button from 'reactstrap/lib/Button';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Pagination from 'reactstrap/lib/Pagination';
import PaginationItem from 'reactstrap/lib/PaginationItem';
import PaginationLink from 'reactstrap/lib/PaginationLink';
import Media from 'reactstrap/lib/Media';
import Form from 'reactstrap/lib/Form';
import FormGroup from 'reactstrap/lib/FormGroup';

import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import { connect } from 'react-redux';

import Moment from 'react-moment';
import moment from 'moment';
import { map, filter } from 'lodash';
import * as constants from '../../../constants/constants';
import { errorMessages } from '../../../constants/messages';

import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css';

import DateFormat from '../UI/DateFormat';

import pencilImg from '../../../images/icons/pencil.svg';
import checkImg from '../../../images/icons/check.png';
import cancelImg from '../../../images/icons/close_old.png';
import closeImg from '../../../images/icons/close.png';

import {
  getAllHints,
  addNewHint,
  supportMessageFileUpload,
  deleteHint,
  editSelectHint,
} from '../../../actions/admin';
import FileUploadImage from './components/FileUploadImage';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';

moment.locale('lv');
momentLocalizer();

class Hints extends React.Component {
  static propTypes = {
    loading: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
    allHints: PropTypes.shape([]),
    fetchAllHints: PropTypes.func.isRequired,
    addANewHint: PropTypes.func.isRequired,
    editASelectHint: PropTypes.func.isRequired,
    deleteAHint: PropTypes.func.isRequired,
  }

  static defaultProps = {
    allHints: [],
  }

  constructor(props) {
    super(props);
    this.state = {
      showOptions: '1',
      currentPage: 0,
      pageSize: 50,
      enTitle: '',
      enDescription: '',
      enAbleToSave: false,
      lvAbleToSave: false,
      ruAbleToSave: false,
      newHintModalOpen: false,
      deleteModal: false,
      selectHintId: '',
      openModal: false,
      oldData: {},
      lvTitle: '',
      ruTitle: '',
      lvDescription: '',
      ruDescription: '',
      devMode: false,
      hintType: constants.OLD_DESIGN,

      enPreview: null,
      enImageUrl: '',
      enShowCrop: false,
      enUploadFile: null,

      lvPreview: null,
      lvImageUrl: '',
      lvShowCrop: false,
      lvUploadFile: null,

      ruPreview: null,
      ruImageUrl: '',
      ruShowCrop: false,
      ruUploadFile: null,
    };
    this.handleClick = this.handleClick.bind(this);

    this.enHiddenFileInput = React.createRef(null);
    this.enHandleEditPhoto = this.enHandleEditPhoto.bind(this);
    this.enOnCrop = this.enOnCrop.bind(this);

    this.lvHiddenFileInput = React.createRef(null);
    this.lvHandleEditPhoto = this.lvHandleEditPhoto.bind(this);
    this.lvOnCrop = this.lvOnCrop.bind(this);

    this.ruHiddenFileInput = React.createRef(null);
    this.ruHandleEditPhoto = this.ruHandleEditPhoto.bind(this);
    this.ruOnCrop = this.ruOnCrop.bind(this);
  }

  componentDidMount() {
    const { fetchAllHints } = this.props;
    const { filterType } = this.state;

    fetchAllHints(filterType);
  }

  handleClick = (e, index) => {
    e.preventDefault();
    this.setState({
      currentPage: index,
    });
  }

  // edit existing admin functions
  toggleDeleteModal = (key) => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
      selectHintId: key,
    }));
  }

  handleChangeShowOptions = (e) => {
    const { showOptions } = this.state;
    if (e.target.value !== showOptions) {
      this.setState({
        showOptions: e.target.value,
      });
    }
  }

  handleChangeShowType = (e) => {
    const { hintType } = this.state;
    if (e.target.value !== hintType) {
      this.setState({
        hintType: e.target.value,
      });
    }
  }

  deleteHint = () => {
    const { deleteAHint, showNotification, t } = this.props;
    const { selectHintId } = this.state;

    if (selectHintId !== '') {
      deleteAHint(selectHintId).then((res) => {
        if (res && res.status === 'success') {
          showNotification('Success', 'Success', 'success');
        }

        if (res.status === 'error') {
          return showNotification('Error', res.message, 'danger');
        }
        this.setState({
          deleteModal: false,
          selectHintId: '',
        });
      });
    }
  }

  toggle = () => {
    this.setState(prevState => ({
      openModal: !prevState.openModal,
    }));
  }

  openModal = (id) => {
    const { allHints } = this.props;
    const selectHint = filter(allHints, (item) => { return  item.key === id })[0];
    console.log(selectHint, "selectHint====")
    this.setState({
      selectHintId: selectHint.key,
      enTitle: selectHint.titleEN || '',
      lvTitle: selectHint.titleLV || '',
      ruTitle: selectHint.titleRU || '',
      showOptions: selectHint.showOptions,
      enDescription: selectHint.descriptionEN || '',
      lvDescription: selectHint.descriptionLV || '',
      ruDescription: selectHint.descriptionRU || '',
      devMode: selectHint.devMode || false,
      hintType: selectHint.hintType || constants.OLD_DESIGN,
      openModal: true,
      deleteModal: false,
      newHintModalOpen: false,
      oldData: selectHint,

      enImageUrl: selectHint.enImageUrl || '',
      enPreview: null,
      lvImageUrl: selectHint.lvImageUrl || '',
      lvPreview: null,
      ruImageUrl: selectHint.ruImageUrl || '',
      ruPreview: null,
    });
  }

  // Add new admin functions
  openAddHintModal = () => {
    this.setState({
      enTitle: '',
      ruTitle: '',
      lvTitle: '',
      enDescription: '',
      lvDescription: '',
      ruDescription: '',
      imageUrl: null,
      preview: null,
      showCrop: false,
      devMode: false,
      hintType: constants.OLD_DESIGN,
      enAbleToSave: false,
      lvAbleToSave: false,
      ruAbleToSave: false,
      openModal: false,
      newHintModalOpen: true,
      oldData: {},
      showOptions: '1',
      lvImageUrl: '',
      lvUploadFile: null,
      lvPreview: null,
      enImageUrl: '',
      enPreview: null,
      enUploadFile: null,
      ruImageUrl: '',
      ruPreview: null,
      ruUploadFile: null,
    });
  }

  closeNewHintModal = () => {
    this.setState({ newHintModalOpen: false });
  }

  initStateValue = () => {
    this.setState({
      enTitle: '',
      ruTitle: '',
      lvTitle: '',
      enDescription: '',
      lvDescription: '',
      ruDescription: '',
      imageUrl: null,
      preview: null,
      showCrop: false,
      devMode: false,
      enAbleToSave: false,
      lvAbleToSave: false,
      ruAbleToSave: false,
      hintType: constants.OLD_DESIGN,
      newHintModalOpen: false,
      oldData: {},
      showOptions: '1',
      lvUploadFile: null,
      lvPreview: null,
      enImageUrl: '',
      enPreview: null,
      enUploadFile: null,
      ruImageUrl: '',
      ruPreview: null,
      ruUploadFile: null,
    });
  }

  addNewHint = () => {
    const { addANewHint, showNotification, t } = this.props;
    const {
      showOptions,
      enTitle,
      lvTitle,
      ruTitle,
      enDescription,
      lvDescription,
      ruDescription,
      showCrop,
      enAbleToSave,
      lvAbleToSave,
      ruAbleToSave,
      devMode,
      hintType,
      enImageUrl,
      lvImageUrl,
      ruImageUrl
    } = this.state;

    if (!showCrop && !enAbleToSave && !lvAbleToSave && !ruAbleToSave) {
      addANewHint({
        showOptions, enTitle, lvTitle, ruTitle, enDescription, lvDescription, ruDescription, enImageUrl, lvImageUrl, ruImageUrl, devMode, hintType,
      }).then((res) => {
        if (res && res.status === 'success') {
          showNotification('Success', "Hint successfully added.", 'success');
        }

        if (res.status === 'error') {
          if (res.message === errorMessages.missingData) {
            showNotification('Error', "Missed the data.", 'danger');
          } else if (res.message === errorMessages.wrongDate) {
            showNotification('Error', "The End date was inputed wrong.", 'danger');
          } else if (res.message === errorMessages.missingLVTitle) {
            showNotification('Error', "Missed the title by Latvian.", 'danger');
          } else if (res.message === errorMessages.missingENTitle) {
            showNotification('Error', "Missed the title by English.", 'danger');
          } else if (res.message === errorMessages.missingRUTitle) {
            showNotification('Error', "Missed the title by Russian.", 'danger');
          } else if (res.message === errorMessages.missingLVDescription) {
            showNotification('Error', "Missed the description by Latvian.", 'danger');
          } else if (res.message === errorMessages.missingENDescription) {
            showNotification('Error', "Missed the description by English.", 'danger');
          } else if (res.message === errorMessages.missingRUDescription) {
            showNotification('Error', "Missed the description by Russian.", 'danger');
          } else if (res.message === errorMessages.missingPhoto) {
            showNotification('Error', "Please upload the image.", 'danger');
          } else {
            showNotification('Error', res.message, 'danger');
          }
          return;
        }

        this.initStateValue();
      }).catch(error => showNotification('Error!', error.message, 'danger'));
    } else {
      showNotification('Warning', "If you don't apply or close uploaded image, you can't add new hint.", 'warning');
    }
  }

  editSelectHint = () => {
    const { editASelectHint, showNotification, t } = this.props;
    const {
      selectHintId,
      showOptions,
      enTitle,
      lvTitle,
      ruTitle,
      enDescription,
      lvDescription,
      ruDescription,
      enImageUrl,
      lvImageUrl,
      ruImageUrl,
      showCrop,
      enAbleToSave,
      lvAbleToSave,
      ruAbleToSave,
      oldData,
      devMode,
      hintType,
    } = this.state;

    if (!showCrop && !enAbleToSave && !lvAbleToSave && !ruAbleToSave) {
      editASelectHint({
        selectHintId, showOptions, enTitle, lvTitle, ruTitle, enDescription, lvDescription, ruDescription, enImageUrl, lvImageUrl, ruImageUrl, oldData, devMode, hintType,
      }).then((res) => {
        if (res && res.status === 'success') {
          showNotification('Success', 'Success', 'success');
        }

        if (res.status === 'error') {
          if (res.message === errorMessages.missingData) {
            showNotification('Error', "Missed the data.", 'danger');
          } else if (res.message === errorMessages.wrongDate) {
            showNotification('Error', "The End date was inputed wrong.", 'danger');
          } else if (res.message === errorMessages.missingLVTitle) {
            showNotification('Error', "Missed the title by Latvian.", 'danger');
          } else if (res.message === errorMessages.missingENTitle) {
            showNotification('Error', "Missed the title by English.", 'danger');
          } else if (res.message === errorMessages.missingRUTitle) {
            showNotification('Error', "Missed the title by Russian.", 'danger');
          } else if (res.message === errorMessages.missingLVDescription) {
            showNotification('Error', "Missed the description by Latvian.", 'danger');
          } else if (res.message === errorMessages.missingENDescription) {
            showNotification('Error', "Missed the description by English.", 'danger');
          } else if (res.message === errorMessages.missingRUDescription) {
            showNotification('Error', "Missed the description by Russian.", 'danger');
          } else if (res.message === errorMessages.missingPhoto) {
            showNotification('Error', "Please upload the image.", 'danger');
          } else {
            showNotification('Error', res.message, 'danger');
          }
          return;
        }

        this.initStateValue();
        this.setState({
          openModal: false,
        });
      }).catch(error => showNotification('Error!', error.message, 'danger'));
    } else {
      showNotification('Warning', "If you don't apply or close uploaded image, you can't add new hint.", 'warning');
    }
  }

  handleChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    this.setState({
      [event.target.name]: value,
    });
  }

  enHandleEditPhoto = (value) => {
    this.setState({
      enShowCrop: !value
    })
  }

  enOnCrop = (enPreview) => {
    this.setState({ enPreview });
  }

  lvHandleEditPhoto = (value) => {
    this.setState({
      lvShowCrop: !value
    })
  }

  lvOnCrop = (lvPreview) => {
    this.setState({ lvPreview });
  }

  ruHandleEditPhoto = (value) => {
    this.setState({
      ruShowCrop: !value
    })
  }

  ruOnCrop = (ruPreview) => {
    this.setState({ ruPreview });
  }

  uploadHintFile = (imageType) => {
    const { supportAMessageFileUpload } = this.props;
    const { enUploadFile, lvUploadFile, ruUploadFile } = this.state;
 
    if (imageType === constants.sendLanguage.lv) {
      this.setState({ lvAbleToSave: true });

      if (lvUploadFile !== null) {
        supportAMessageFileUpload(lvUploadFile).then((data) => {
          console.log('value@@@@@@@@@@@@@@@@', data);
          this.setState({
            lvImageUrl: data.url,
            lvAbleToSave: false,
          });
        }).catch((e) => {
          this.setState({
            lvPreview: null,
            lvAbleToSave: false,
          });
        });
      }
    } else if (imageType === constants.sendLanguage.en) {
      this.setState({ enAbleToSave: true });

      if (enUploadFile !== null) {
        supportAMessageFileUpload(enUploadFile).then((data) => {
          console.log('value@@@@@@@@@@@@@@@@', data);
          this.setState({
            enImageUrl: data.url,
            enAbleToSave: false,
          });
        }).catch((e) => {
          this.setState({
            enPreview: null,
            enAbleToSave: false,
          });
        });
      }
    } else if (imageType === constants.sendLanguage.ru) {
      this.setState({ ruAbleToSave: true });

      if (ruUploadFile !== null) {
        supportAMessageFileUpload(ruUploadFile).then((data) => {
          console.log('value@@@@@@@@@@@@@@@@', data);
          this.setState({
            ruImageUrl: data.url,
            ruAbleToSave: false,
          });
        }).catch((e) => {
          this.setState({
            ruPreview: null,
            ruAbleToSave: false,
          });
        });
      }
    }
  }

  lvHandleImageClick = () => {
    this.lvHiddenFileInput.current.click();
  };

  enHandleImageClick = () => {
    this.enHiddenFileInput.current.click();
  };

  ruHandleImageClick = () => {
    this.ruHiddenFileInput.current.click();
  };

  _handleImageChange = (e, imageType) => {
    e.preventDefault();

    try {
      const { showNotification, t } = this.props;
      const typeList = 'image/jpg,image/png,image/jpeg, image/pjp, image/pjpeg, image/jfif';
      const reader = new FileReader();
      const file = e.target.files[0];

      if (!typeList.includes(file.type) || file.type === '') {
        showNotification('Error!', "Unsupported file!", 'danger');
        return e.target.value === '';
      }

      if (file.size / 1024 > 3072) {
        showNotification('Warning', "You can't upload more than 3MB", 'warning');
        return e.target.value === '';
      }

      // reader.onloadend = () => {
      //   this.setState({
      //     preview: reader.result,
      //     uploadFile: file,
      //   });
      // };
      if (imageType === constants.sendLanguage.lv) {
        reader.onloadend = () => {
          this.setState({
            lvPreview: reader.result,
            lvUploadFile: file,
          });
        };
      } else if (imageType === constants.sendLanguage.en) {
        reader.onloadend = () => {
          this.setState({
            enPreview: reader.result,
            enUploadFile: file,
          });
        };
      } else if (imageType === constants.sendLanguage.ru) {
        reader.onloadend = () => {
          this.setState({
            ruPreview: reader.result,
            ruUploadFile: file,
          });
        };
      }
      reader.readAsDataURL(file);
    } catch (err) {
      const { showNotification, t } = this.props;
      showNotification('Error!', err.message, 'danger');

      return e.target.value === '';
    }
  }

  handlePreviewPhoto = (previewImg, imageType) => {
    console.log("previewImage");
    if (imageType === constants.sendLanguage.lv) {
      this.setState({
        lvPreview: previewImg,
      });
    } else if (imageType === constants.sendLanguage.en) {
      this.setState({
        enPreview: previewImg,
      });
    } else if (imageType === constants.sendLanguage.ru) {
      this.setState({
        ruPreview: previewImg,
      });
    }
  }

  handleReSetImage = (imageType) => {
    if (imageType === constants.sendLanguage.lv) {
      this.setState({
        lvPreview: '',
        lvImageUrl: '',
      });
    } else if (imageType === constants.sendLanguage.en) {
      this.setState({
        enPreview: '',
        enImageUrl: '',
      });
    } else if (imageType === constants.sendLanguage.ru) {
      this.setState({
        rurPreview: '',
        ruImageUrl: '',
      });
    }
  }

  handleReSetPreviewImage = (imageType) => {
    if (imageType === constants.sendLanguage.lv) {
      this.setState({
        lvPreview: null,
      });
    } else if (imageType === constants.sendLanguage.en) {
      this.setState({
        enPreview: null,
      });
    } else if (imageType === constants.sendLanguage.ru) {
      this.setState({
        ruPreview: null,
      });
    }
  }

  toggleDevMode = () => {
    this.setState(prevState => ({
      devMode: !prevState.devMode,
    }));
  }

  table() {
    const {
      t,
      allHints,
      i18n,
    } = this.props;

    const {
      currentPage,
      pageSize,
    } = this.state;
    const pagesCount = Math.ceil(Object.keys(allHints).length / pageSize);

    return (
      <Fragment>
        {allHints && allHints
          .slice(
            currentPage * pageSize,
            (currentPage + 1) * pageSize,
          )
          .map((item, index) => (
            <Fragment key={item.key}>
              <tr key={item.key} className={`allUsers-table-row ${index % 2 === 0 ? ('odd') : ('even')}`}>
                
                <td className="allUsers-table-col">
                  {filter(constants.WEEK_DAYS, (day) => { return day.id === item.showOptions })[0] ? filter(constants.WEEK_DAYS, (day) => { return day.id === item.showOptions })[0].value : null}
                </td>

                <td className="allUsers-table-col">
                  {i18n.language === 'en' ? item.titleEN : (i18n.language === 'lv' ? item.titleLV : (i18n.language === 'ru' ? item.titleRU : item.titleLV))}
                </td>
                <td className="allUsers-table-col">
                  {i18n.language === 'en' ? item.descriptionEN : (i18n.language === 'lv' ? item.descriptionLV : (i18n.language === 'ru' ? item.descriptionRU : item.descriptionLV))}
                </td>
                <td className="allUsers-table-col">
                  {item.devMode ? 'Yes' : 'No'}
                </td>
                <td className="allUsers-table-col">
                  <Button color="primary" onClick={() => this.openModal(item.key)}>
                    Edit
                  </Button>
                </td>
                <td className="allUsers-table-col">
                  <Button color="primary" onClick={() => this.toggleDeleteModal(item.key)}>
                    Delete
                  </Button>
                </td>
              </tr>
            </Fragment>
          ))}
        {pagesCount && Object.keys(allHints).length > pageSize && (
          <div className="pagination-wrapper">
            <Pagination aria-label="Page navigation example">
              <PaginationItem disabled={currentPage <= 0}>
                <PaginationLink
                  style={{ color: '#000' }}
                  onClick={e => this.handleClick(e, currentPage - 1)}
                  previous
                  href="#"
                />
              </PaginationItem>

              <PaginationItem disabled={currentPage === 0}>
                <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, 0)} href="#">
                  1
                </PaginationLink>
              </PaginationItem>

              {[...Array(pagesCount)].map((page, i) => {
                if (i > currentPage - 3 && i < currentPage + 3) {
                  return (
                    <PaginationItem active={i === currentPage} key={page}>
                      <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, i)} href="#">
                        {i + 1}
                      </PaginationLink>
                    </PaginationItem>
                  );
                }

                return null;
              })}

              {currentPage < (pagesCount - 3) && (
                <PaginationItem disabled={currentPage >= pagesCount - 1}>
                  <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, pagesCount - 1)} href="#">
                    {pagesCount}
                  </PaginationLink>
                </PaginationItem>
              )}

              <PaginationItem disabled={currentPage >= pagesCount - 1}>
                <PaginationLink
                  style={{ color: '#000' }}
                  onClick={e => this.handleClick(e, currentPage + 1)}
                  next
                  href="#"
                />
              </PaginationItem>
            </Pagination>
          </div>
        )}
      </Fragment>
    );
  }

  render() {
    const {
      t,
      loading,
    } = this.props;

    const {
      newHintModalOpen,
      enTitle,
      enDescription,
      enPreview,
      enImageUrl,
      lvPreview,
      lvImageUrl,
      ruPreview,
      ruImageUrl,
      lvShowCrop,
      enShowCrop,
      ruShowCrop,
      deleteModal,
      openModal,
      lvTitle,
      ruTitle,
      lvDescription,
      ruDescription,
      devMode,
      showOptions,
      enAbleToSave,
      lvAbleToSave,
      ruAbleToSave,
      hintType,
    } = this.state;

    console.log(enImageUrl, enPreview, lvImageUrl, lvPreview, ruImageUrl, ruPreview, "eventImage===")
    return (
      <Fragment>
        <UncontrolledPopover popperClassName={`popover`} placement="right" trigger="focus" target="admin-help-button-hints">
          <PopoverBody className="popover-body">
            An admin can add hints that later are displayed for the player players according to the selected options. Each hint has to have a title and body text in each of the listed languages, and also an image for each language. Each hint can be displayed on the old or the new design.
            <br /><br />
            Note: currently the hints are not available on the mobile app. 
          </PopoverBody>
        </UncontrolledPopover>
        <div style={{ marginTop: 100, color: '#fff' }}>
          <Row>
            <Col sm="4">
              <h2>
                Hints
                <Button className="admin-help-button" id="admin-help-button-hints">
                  ?
                </Button>
              </h2>
            </Col>
            <Col sm="4">
              <Button color="primary" onClick={this.openAddHintModal}>
                Add Hint
              </Button>
            </Col>
          </Row>
          <table style={{ width: '100%', fontSize: 12 }}>
            <colgroup>
              <col span="1" className="" />
            </colgroup>
            <thead>
              <tr>
                <th style={{ textAlign: 'center' }}>
                  Show Options
                </th>
                <th style={{ textAlign: 'center' }}>
                  Title
                </th>
                <th style={{ textAlign: 'center' }}>
                  Description
                </th>
                <th style={{ textAlign: 'center' }}>
                  Only Dev
                </th>
                <th style={{ textAlign: 'center' }}>
                  Edit
                </th>
                <th style={{ textAlign: 'center' }}>
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              {this.table()}
            </tbody>
          </table>

        </div>
        {/* Delete Hint modal */}
        <Modal container={'div > div'} isOpen={deleteModal} toggle={() => this.toggleDeleteModal('')} className="notification">
          <ModalHeader
            toggle={() => this.toggleDeleteModal('')}
            className="notification-header"
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={() => this.toggleDeleteModal('')} />
            }
          />
          <ModalBody className="notification-body">
            Do you really want to delete the data?
          </ModalBody>
          <ModalFooter className="notification-footer">
            <Button className="btn notification-footer-button" onClick={this.deleteHint}>
              Yes
            </Button>
            <Button type="button" className="btn notification-footer-button" onClick={() => this.toggleDeleteModal('')}>
              No
            </Button>
          </ModalFooter>
        </Modal>

        {/* Edit Hint modal */}
        <Modal container={'div > div'} size="lg" isOpen={openModal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
            Edit
          </ModalHeader>
          <ModalBody>
            <Form className="form-style">
              <FormGroup>
                <Label for="startDate">
                  Show Options
                </Label>
                <Row>
                  <Col md="12">
                    <Input type="select" name="showOptions" className="open-page-select" value={showOptions} disabled={loading} onChange={this.handleChangeShowOptions}>
                    {
                      map(constants.WEEK_DAYS, (item, key) => (
                        <option value={item.id} className="background-222">{item.value}</option>
                      ))
                    }
                  </Input>
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Label for="name">
                  Title By Latvian
                </Label>
                <Input
                  type="text"
                  name="lvTitle"
                  id="lvTitle"
                  value={lvTitle}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="name">
                  Title By English
                </Label>
                <Input
                  type="text"
                  name="enTitle"
                  id="enTitle"
                  value={enTitle}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="name">
                  Title By Russian
                </Label>
                <Input
                  type="text"
                  name="ruTitle"
                  id="ruTitle"
                  value={ruTitle}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="lvDescription">
                  Description By Latvian
                </Label>
                <Input
                  type="textarea"
                  name="lvDescription"
                  id="lvDescription"
                  value={lvDescription}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="enDescription">
                  Description By English
                </Label>
                <Input
                  type="textarea"
                  name="enDescription"
                  id="enDescription"
                  value={enDescription}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="ruDescription">
                  Description By Russian
                </Label>
                <Input
                  type="textarea"
                  name="ruDescription"
                  id="ruDescription"
                  value={ruDescription}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup className='hint-image'>
                <FileUploadImage 
                    preview={lvPreview}
                    imageUrl={lvImageUrl}
                    showCrop={lvShowCrop}
                    hiddenFileInput={this.lvHiddenFileInput}
                    handleImageClick={this.lvHandleImageClick}
                    handleImageChange={this._handleImageChange}
                    handleEditPhoto={this.lvHandleEditPhoto}
                    uploadEventFile={this.uploadHintFile}
                    imageType={constants.sendLanguage.lv}
                    loading={loading}
                    handlePreviewPhoto={this.handlePreviewPhoto}
                    handleReSetImage={this.handleReSetImage}
                    handleReSetPreviewImage={this.handleReSetPreviewImage}
                  />
                  <FileUploadImage 
                    preview={enPreview}
                    imageUrl={enImageUrl}
                    showCrop={enShowCrop}
                    hiddenFileInput={this.enHiddenFileInput}
                    handleImageClick={this.enHandleImageClick}
                    handleImageChange={this._handleImageChange}
                    handleEditPhoto={this.enHandleEditPhoto}
                    uploadEventFile={this.uploadHintFile}
                    imageType={constants.sendLanguage.en}
                    loading={loading}
                    handlePreviewPhoto={this.handlePreviewPhoto}
                    handleReSetImage={this.handleReSetImage}
                    handleReSetPreviewImage={this.handleReSetPreviewImage}
                  />
                  <FileUploadImage 
                    preview={ruPreview}
                    imageUrl={ruImageUrl}
                    showCrop={ruShowCrop}
                    hiddenFileInput={this.ruHiddenFileInput}
                    handleImageClick={this.ruHandleImageClick}
                    handleImageChange={this._handleImageChange}
                    handleEditPhoto={this.ruHandleEditPhoto}
                    uploadEventFile={this.uploadHintFile}
                    imageType={constants.sendLanguage.ru}
                    loading={loading}
                    handlePreviewPhoto={this.handlePreviewPhoto}
                    handleReSetImage={this.handleReSetImage}
                    handleReSetPreviewImage={this.handleReSetPreviewImage}
                  />
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col sm="12">
                    <Label className="event-type-input" style={{ marginLeft: 40 }}>
                      <Input type="checkbox" onClick={this.toggleDevMode} disabled={loading} checked={devMode} readOnly />
                      <span className="checkmark" />
                      <div className="event-type-text">
                        Only Dev
                      </div>
                    </Label>
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Label for="startDate">
                  Show Type
                </Label>
                <Row>
                  <Col md="12">
                    <Input type="select" name="showType" className="open-page-select" value={hintType} disabled={loading} onChange={this.handleChangeShowType}>
                      <option value={constants.OLD_DESIGN} className="background-222">{constants.OLD_DESIGN}</option>
                      <option value={constants.NEW_DESIGN} className="background-222">{constants.NEW_DESIGN}</option>
                  </Input>
                  </Col>
                </Row>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button className="modal-footer-button" disabled={loading || enAbleToSave || lvAbleToSave || ruAbleToSave} onClick={this.editSelectHint}>Save</Button>
            <Button className="modal-footer-button" disabled={loading || enAbleToSave || lvAbleToSave || ruAbleToSave} onClick={this.toggle}>Close</Button>
          </ModalFooter>
        </Modal>

        {/* Add Hint modal */}
        <Modal container={'div > div'} returnFocusAfterClose={false} size="lg" isOpen={newHintModalOpen} toggle={this.closeNewHintModal}>
          <ModalHeader toggle={this.closeNewHintModal}>
            Add Hint
          </ModalHeader>
          <ModalBody>
            <Form className="form-style">
              <FormGroup>
                <Label for="startDate">
                  Show Options
                </Label>
                <Row>
                  <Col md="12">
                    <Input type="select" name="showOptions" className="open-page-select" value={showOptions} disabled={loading} onChange={this.handleChangeShowOptions}>
                    {
                      map(constants.WEEK_DAYS, (item, key) => (
                        <option value={item.id} className="background-222">{item.value}</option>
                      ))
                    }
                  </Input>
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Label for="name">
                  Title By Latvian
                </Label>
                <Input
                  type="text"
                  name="lvTitle"
                  id="lvTitle"
                  value={lvTitle}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="name">
                  Title By English
                </Label>
                <Input
                  type="text"
                  name="enTitle"
                  id="enTitle"
                  value={enTitle}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="name">
                  Title By Russian
                </Label>
                <Input
                  type="text"
                  name="ruTitle"
                  id="ruTitle"
                  value={ruTitle}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="lvDescription">
                  Description By Latvian
                </Label>
                <Input
                  type="textarea"
                  name="lvDescription"
                  id="lvDescription"
                  value={lvDescription}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="enDescription">
                  Description By English
                </Label>
                <Input
                  type="textarea"
                  name="enDescription"
                  id="enDescription"
                  value={enDescription}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="ruDescription">
                  Description By Russian
                </Label>
                <Input
                  type="textarea"
                  name="ruDescription"
                  id="ruDescription"
                  value={ruDescription}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup className='hint-image'>
                <FileUploadImage 
                  preview={lvPreview}
                  imageUrl={lvImageUrl}
                  showCrop={lvShowCrop}
                  hiddenFileInput={this.lvHiddenFileInput}
                  handleImageClick={this.lvHandleImageClick}
                  handleImageChange={this._handleImageChange}
                  handleEditPhoto={this.lvHandleEditPhoto}
                  uploadEventFile={this.uploadHintFile}
                  imageType={constants.sendLanguage.lv}
                  loading={loading}
                  handlePreviewPhoto={this.handlePreviewPhoto}
                  handleReSetImage={this.handleReSetImage}
                  handleReSetPreviewImage={this.handleReSetPreviewImage}
                />
                <FileUploadImage 
                  preview={enPreview}
                  imageUrl={enImageUrl}
                  showCrop={enShowCrop}
                  hiddenFileInput={this.enHiddenFileInput}
                  handleImageClick={this.enHandleImageClick}
                  handleImageChange={this._handleImageChange}
                  handleEditPhoto={this.enHandleEditPhoto}
                  uploadEventFile={this.uploadHintFile}
                  imageType={constants.sendLanguage.en}
                  loading={loading}
                  handlePreviewPhoto={this.handlePreviewPhoto}
                  handleReSetImage={this.handleReSetImage}
                  handleReSetPreviewImage={this.handleReSetPreviewImage}
                />
                <FileUploadImage 
                  preview={ruPreview}
                  imageUrl={ruImageUrl}
                  showCrop={ruShowCrop}
                  hiddenFileInput={this.ruHiddenFileInput}
                  handleImageClick={this.ruHandleImageClick}
                  handleImageChange={this._handleImageChange}
                  handleEditPhoto={this.ruHandleEditPhoto}
                  uploadEventFile={this.uploadHintFile}
                  imageType={constants.sendLanguage.ru}
                  loading={loading}
                  handlePreviewPhoto={this.handlePreviewPhoto}
                  handleReSetImage={this.handleReSetImage}
                  handleReSetPreviewImage={this.handleReSetPreviewImage}
                />
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col sm="12">
                    <Label className="event-type-input" style={{ marginLeft: 40 }}>
                      <Input type="checkbox" onClick={this.toggleDevMode} disabled={loading} checked={devMode} readOnly />
                      <span className="checkmark" />
                      <div className="event-type-text">
                        Only Dev
                      </div>
                    </Label>
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Label for="startDate">
                  Show Type
                </Label>
                <Row>
                  <Col md="12">
                    <Input type="select" name="showType" className="open-page-select" value={hintType} disabled={loading} onChange={this.handleChangeShowType}>
                      <option value={constants.OLD_DESIGN} className="background-222">{constants.OLD_DESIGN}</option>
                      <option value={constants.NEW_DESIGN} className="background-222">{constants.NEW_DESIGN}</option>
                  </Input>
                  </Col>
                </Row>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button className="modal-footer-button" disabled={loading  || enAbleToSave || lvAbleToSave || ruAbleToSave} onClick={this.addNewHint}>Save</Button>
            <Button className="modal-footer-button" disabled={loading  || enAbleToSave || lvAbleToSave || ruAbleToSave} onClick={this.closeNewHintModal}>Close</Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  allHints: state.admin.allHints || [],
});

const mapDispatchToProps = {
  fetchAllHints: getAllHints,
  addANewHint: addNewHint,
  supportAMessageFileUpload: supportMessageFileUpload,
  deleteAHint: deleteHint,
  editASelectHint: editSelectHint,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('admin')(Hints));

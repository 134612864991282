import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';


import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';
import Label from 'reactstrap/lib/Label';
import Pagination from 'reactstrap/lib/Pagination';
import PaginationItem from 'reactstrap/lib/PaginationItem';
import PaginationLink from 'reactstrap/lib/PaginationLink';

import { withTranslation } from 'react-i18next';

import Moment from 'react-moment';
import moment from 'moment';

import { size, filter } from 'lodash';

import * as constants from '../../../constants/constants';
import DateFormat from '../UI/DateFormat';
import { useDispatch, useSelector } from 'react-redux';
import { getConfirmedPlayersCount } from '../../../actions/admin';

const NonVerifiedUsers = React.memo(({nonVerifiedUsers, currentPage, pagesCount, pageSize, handelClick, t, selectLanguageFlag, openVerifyModal, openDeleteModal }) => {
  
  const dispatch = useDispatch();

  const handleFilterClick = (e, index) => {
    e.preventDefault();

    handelClick(e, index);
  };

  const [totalSent, setTotalSent] = React.useState(0);

  useEffect(() => {
    dispatch(getConfirmedPlayersCount());
  }, [nonVerifiedUsers])
  
  useEffect(() => {
    const sentUsers = nonVerifiedUsers ? filter(nonVerifiedUsers, item => item.verifiedEmailSent) : {};
    setTotalSent(size(sentUsers));
  }, [nonVerifiedUsers]);

  const confirmedPlayerCount = useSelector(state => state.admin.confirmedPlayerCount || 0);

  console.log("nonVerifiedUsers test", nonVerifiedUsers);

  return (
    <>
      <Row className='mt-4'>
        
        <Col md="6">
          <h4>
            Total Player--
            <Label>{size(nonVerifiedUsers)}</Label>
          </h4>
        </Col>
        <Col md="6">
          <h4>
            Sent--
            <Label>{totalSent}</Label>
          </h4>
        </Col>
        <Col md="6">
          <h4>
            Confirmed Player--
            <Label>{confirmedPlayerCount}</Label>
          </h4>
        </Col>
      </Row>
      <table className="textAlign players-table">
        <thead>
          <tr>
            <th>
              <div>Name</div>
            </th>
            <th>
              {/* {t('lastLogin')} */}
              Last Login
            </th>
            <th>
              {/* {t('birthday')} */}
              Login Type
            </th>
            <th>
              {/* {t('dateCreated')} */}
              Date Created
            </th>
            <th>
              {/* {t('email')} */}
              Email
            </th>
            <th>
              {/* {t('playerId')} */}
              Player Id
            </th>
            <th>
              {/* {t('sent')} */}
              Sent
            </th>
            <th className="allUsers-table-col">
              {/* {t('verify')} */}
              Verify
            </th>
            <th className="allUsers-table-col">
              {/* {t('delete')} */}
              Delete
            </th>
          </tr>
        </thead>
        <tbody>
          {nonVerifiedUsers && nonVerifiedUsers
            .slice(
              currentPage * pageSize,
              (currentPage + 1) * pageSize,
            )
            .map((nonUsers, index) => (
              <tr key={nonUsers.key} className="allUsers-table-row">
                <td className="allUsers-table-col">
                  {nonUsers.name}
                </td>
                <td>
                  <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateMinute} date={nonUsers.lastLogin} />
                </td>
                <td>
                  {nonUsers.socProvider}
                </td>
                <td className="allUsers-table-col">
                  {
                    nonUsers.dateCreated && nonUsers.dateCreated !== undefined ? (
                      <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateMinute} date={nonUsers.dateCreated} />
                    ) : ('Long time ago')
                  }
                </td>
                <td className="allUsers-table-col">
                  {nonUsers.email}
                </td>
                <td className="allUsers-table-col">
                  {nonUsers.uid}
                </td>
                <td className="allUsers-table-col">
                  {nonUsers.verifiedEmailSent ? (
                    <Moment format="DD-MM-YYYY, HH:mm" locale="lv">
                      {nonUsers.verifiedEmailSent }
                    </Moment>
                  ) : '-'}
                </td>
                <td className="allUsers-table-col">
                  <Button color="danger" onClick={() => openVerifyModal(nonUsers.key)}>
                    Resend link
                  </Button>
                </td>
                <td className="allUsers-table-col">
                  <Button color="danger" onClick={() => openDeleteModal(nonUsers.key)}>
                    {/* {t('delete')} */}
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {pagesCount && Object.keys(nonVerifiedUsers).length > pageSize && (
        <div className="pagination-wrapper">
          <Pagination aria-label="Page navigation example">
            <PaginationItem disabled={currentPage <= 0}>
              <PaginationLink
                style={{ color: '#000' }}
                onClick={e => handleFilterClick(e, currentPage - 1)}
                previous
                href="#"
              />
            </PaginationItem>

            <PaginationItem disabled={currentPage === 0}>
              <PaginationLink style={{ color: '#000' }} onClick={e => handleFilterClick(e, 0)} href="#">
                1
              </PaginationLink>
            </PaginationItem>

            {[...Array(pagesCount)].map((page, i) => {
              if (i > currentPage - 3 && i < currentPage + 3) {
                return (
                  <PaginationItem active={i === currentPage} key={page}>
                    <PaginationLink style={{ color: '#000' }} onClick={e => handleFilterClick(e, i)} href="#">
                      {i + 1}
                    </PaginationLink>
                  </PaginationItem>
                );
              }

              return null;
            })}

            {currentPage < (pagesCount - 3) && (
              <PaginationItem disabled={currentPage >= pagesCount - 1}>
                <PaginationLink style={{ color: '#000' }} onClick={e => handleFilterClick(e, pagesCount - 1)} href="#">
                  {pagesCount}
                </PaginationLink>
              </PaginationItem>
            )}

            <PaginationItem disabled={currentPage >= pagesCount - 1}>
              <PaginationLink
                style={{ color: '#000' }}
                onClick={e => handleFilterClick(e, currentPage + 1)}
                next
                href="#"
              />
            </PaginationItem>
          </Pagination>
        </div>
      )}
    </>
  );
});

NonVerifiedUsers.propTypes = {
  nonVerifiedUsers: PropTypes.arrayOf(PropTypes.shape({})),
  pagesCount: PropTypes.number,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  handelClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  selectLanguageFlag: PropTypes.func.isRequired,
  openVerifyModal: PropTypes.func.isRequired,
  openDeleteModal: PropTypes.func.isRequired,
  fetchFilteredUsers: PropTypes.func.isRequired,
};

NonVerifiedUsers.defaultProps = {
  nonVerifiedUsers: [],
  pagesCount: 0,
  currentPage: 0,
  pageSize: 0,
};

export default withTranslation('chat')(NonVerifiedUsers);

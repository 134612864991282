import React from 'react';
import PropTypes from 'prop-types';

// import { connect } from 'react-redux';
import classNames from 'classnames';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';

// import EndResultFines from './EndResultFines';
// import EndResultPules from './EndResultPules';

import { getBetValue } from '../../../../../../common/services/data-service';
import infoImg from '../../../../../../images/redesign/player/info.svg';

const ScoreTableSum = ({
  myPos, totalPnts, isEndResultTable, gameType, bet, t, globalParams, players, showInfoModal, player1Pules, player2Pules, player3Pules, player1ShortName, player2ShortName, player3ShortName, player4ShortName,
}) => {

  const {
    minGames, gameState, fourPRoom, rPlayed, closeReason,
  } = globalParams;

  let betRatio;

  if (bet) {
    betRatio = parseInt(bet.replace('1:', ''), 10);
  }

  let betValue;
  let leavePenalty;
  let leaveReward;
  let penaltyPlayer;

  if (players && closeReason && closeReason.reason && (closeReason.reason === 'missedTurn' || closeReason.reason === 'leftRoom')) {
    betValue = getBetValue(bet);

    if (players.player1 && players.player1.uid === closeReason.playerUid) {
      penaltyPlayer = 'player1';
    } else if (players.player2 && players.player2.uid === closeReason.playerUid) {
      penaltyPlayer = 'player2';
    } else if (players.player3 && players.player3.uid === closeReason.playerUid) {
      penaltyPlayer = 'player3';
    }

    if (closeReason.isAutomated) {
      leavePenalty = 0;
      leaveReward = 0;
    } else {
      leavePenalty = fourPRoom ? Math.round(betValue * 24) : Math.round(betValue * 16);
      leaveReward = Math.round(0.25 * leavePenalty);
    }

    console.log('closeReason', { closeReason, minGames, rPlayed });

    if (closeReason.reason === 'leftRoom') {
      if (minGames && (rPlayed || rPlayed === 0) && minGames > (rPlayed) && betValue) {
        leavePenalty += Math.round((minGames - rPlayed) * 10 * betValue);

        leaveReward += Math.round(0.25 * (minGames - rPlayed) * 10 * betValue);
      }
    } else if (minGames && (rPlayed || rPlayed === 0) && minGames > rPlayed && betValue) {
      leavePenalty += Math.round((minGames - rPlayed) * 10 * betValue);

      leaveReward += Math.round(0.25 * (minGames - rPlayed) * 10 * betValue);
    }
  }

  return (
    <Row className="end-result-score-table-sum">
      <Col sm="12">
        <Row className="end-result-table-header-landscape">
          {/* <Col className="end-result-table-header-col">
            #
          </Col>
          <Col className="end-result-table-header-col">
            {
              myPos === 'player1' && player2ShortName
            }
            {
              myPos === 'player2' && player3ShortName
            }
            {
              myPos === 'player3' && player1ShortName
            }
          </Col>
          <Col className="end-result-table-header-col">
            {
              myPos === 'player1' && player1ShortName
            }
            {
              myPos === 'player2' && player2ShortName
            }
            {
              myPos === 'player3' && player3ShortName
            }
          </Col>
          <Col className="end-result-table-header-col">
            {
              myPos === 'player1' && player3ShortName
            }
            {
              myPos === 'player2' && player1ShortName
            }
            {
              myPos === 'player3' && player2ShortName
            }
          </Col>
          <Col className="end-result-table-header-col">
            {t('game:pules')}
          </Col>
          <Col className="end-result-table-header-col" /> */}
        </Row>
        <Row className="end-result-table-header mt-3">
          <Col className="end-result-table-header-col">
            {t('game:points')}
          </Col>
          <Col className="end-result-table-header-col">
            <div className={classNames({
              'player-history-table-col-positive': (myPos === 'player1' && totalPnts && totalPnts.player2 > 0) || (myPos === 'player2' && totalPnts && totalPnts.player2 > 0) || (myPos === 'player3' && totalPnts && totalPnts.player1 > 0),
              'player-history-table-col-negative': (myPos === 'player1' && (totalPnts && totalPnts.player2 < 0)) || (myPos === 'player2' && (totalPnts && totalPnts.player3 < 0)) || (myPos === 'player3' && (totalPnts && totalPnts.player1 < 0)),
              'player-history-table-col-neutral': (myPos === 'player1' && ((totalPnts && totalPnts.player2 === 0) || !totalPnts?.player2)) || (myPos === 'player2' && ((totalPnts && totalPnts.player3 === 0) || !totalPnts?.player3)) || (myPos === 'player3' && ((totalPnts && totalPnts.player1 === 0) || !totalPnts?.player1)),
            })}
            >
              <>
                {myPos === 'player1' && (
                  <>{(totalPnts && totalPnts.player2 ? totalPnts.player2 : 0)}</>
                )}
                {myPos === 'player2' && (
                  <>{(totalPnts && totalPnts.player3 ? totalPnts.player3 : 0)}</>
                )}
                {myPos === 'player3' && (
                  <>{(totalPnts && totalPnts.player1 ? totalPnts.player1 : 0)}</>
                )}
              </>
            </div>
          </Col>
          <Col className="end-result-table-header-col">
            <div className={classNames({
              'player-history-table-col-positive': (myPos === 'player1' && totalPnts && totalPnts.player1 > 0) || (myPos === 'player2' && totalPnts && totalPnts.player2 > 0) || (myPos === 'player3' && totalPnts && totalPnts.player3 > 0),
              'player-history-table-col-negative': (myPos === 'player1' && (totalPnts && totalPnts.player1 < 0)) || (myPos === 'player2' && (totalPnts && totalPnts.player2 < 0)) || (myPos === 'player3' && (totalPnts && totalPnts.player3 < 0)),
              'player-history-table-col-neutral': (myPos === 'player1' && ((totalPnts && totalPnts.player1 === 0) || !totalPnts?.player1)) || (myPos === 'player2' && ((totalPnts && totalPnts.player2 === 0) || !totalPnts.player2)) || (myPos === 'player3' && ((totalPnts && totalPnts.player3 === 0) || !totalPnts?.player3)),
            })}
            >
              <>
                {myPos === 'player1' && (
                  <>{(totalPnts && totalPnts.player1 ? totalPnts.player1 : 0)}</>
                )}
                {myPos === 'player2' && (
                  <>{(totalPnts && totalPnts.player2 ? totalPnts.player2 : 0)}</>
                )}
                {myPos === 'player3' && (
                  <>{(totalPnts && totalPnts.player3 ? totalPnts.player3 : 0)}</>
                )}
              </>
            </div>
          </Col>
          <Col className="end-result-table-header-col">
            <div className={classNames({
              'player-history-table-col-positive': (myPos === 'player1' && totalPnts && totalPnts.player3 > 0) || (myPos === 'player2' && totalPnts && totalPnts.player1 > 0) || (myPos === 'player3' && totalPnts && totalPnts.player2 > 0),
              'player-history-table-col-negative': (myPos === 'player1' && (totalPnts && totalPnts.player3 < 0)) || (myPos === 'player2' && (totalPnts && totalPnts.player1 < 0)) || (myPos === 'player3' && (totalPnts && totalPnts.player2 < 0)),
              'player-history-table-col-neutral': (myPos === 'player1' && ((totalPnts && totalPnts.player3 === 0) || !totalPnts?.player3)) || (myPos === 'player2' && ((totalPnts && totalPnts.player1 === 0) || !totalPnts?.player1)) || (myPos === 'player3' && ((totalPnts && totalPnts.player2 === 0) || !totalPnts?.player2)),
            })}
            >
              <>
                {myPos === 'player1' && (
                  <>{(totalPnts && totalPnts.player3 ? totalPnts.player3 : 0)}</>
                )}
                {myPos === 'player2' && (
                  <>{(totalPnts && totalPnts.player1 ? totalPnts.player1 : 0)}</>
                )}
                {myPos === 'player3' && (
                  <>{(totalPnts && totalPnts.player2 ? totalPnts.player2 : 0)}</>
                )}
              </>
            </div>
          </Col>
          <Col className="end-result-table-header-col" />
          <Col className="end-result-table-header-col" />
        </Row>
        <Row className="end-result-table-header mt-3">
          <Col className="end-result-table-header-col">
            {t('game:zoles')}
          </Col>
          <Col className="end-result-table-header-col">
            <div className={classNames({
              'player-history-table-col-positive': (myPos === 'player1' && totalPnts && betRatio * totalPnts.player2 > 0) || (myPos === 'player2' && totalPnts && betRatio * totalPnts.player3 > 0) || (myPos === 'player3' && totalPnts && betRatio * totalPnts.player1 > 0),
              'player-history-table-col-negative': (myPos === 'player1' && totalPnts && betRatio * totalPnts.player2 < 0) || (myPos === 'player2' && totalPnts && betRatio * totalPnts.player3 < 0) || (myPos === 'player3' && totalPnts && betRatio * totalPnts.player1 < 0),
              'player-history-table-col-neutral': (myPos === 'player1' && totalPnts && betRatio * totalPnts.player2 === 0) || (myPos === 'player2' && totalPnts && betRatio * totalPnts.player3 === 0) || (myPos === 'player3' && totalPnts && betRatio * totalPnts.player1 === 0),
            })}
            >
              <>
                {myPos === 'player1' && (
                  <>
                    {totalPnts && totalPnts.player2 && betRatio
                      ? betRatio * totalPnts.player2
                      : 0}
                  </>
                )}
                {myPos === 'player2' && (
                  <>
                    {totalPnts && totalPnts.player3 && betRatio
                      ? betRatio * totalPnts.player3
                      : 0}
                  </>
                )}
                {myPos === 'player3' && (
                  <>
                    {totalPnts && totalPnts.player1 && betRatio
                      ? betRatio * totalPnts.player1
                      : 0}
                  </>
                )}
              </>
            </div>
          </Col>
          <Col className="end-result-table-header-col">
            <div className={classNames({
              'player-history-table-col-positive': (myPos === 'player1' && totalPnts && betRatio * totalPnts.player1 > 0) || (myPos === 'player2' && totalPnts && betRatio * totalPnts.player2 > 0) || (myPos === 'player3' && totalPnts && betRatio * totalPnts.player3 > 0),
              'player-history-table-col-negative': (myPos === 'player1' && totalPnts && betRatio * totalPnts.player1 < 0) || (myPos === 'player2' && totalPnts && betRatio * totalPnts.player2 < 0) || (myPos === 'player3' && totalPnts && betRatio * totalPnts.player3 < 0),
              'player-history-table-col-neutral': (myPos === 'player1' && totalPnts && betRatio * totalPnts.player1 === 0) || (myPos === 'player2' && totalPnts && betRatio * totalPnts.player2 === 0) || (myPos === 'player3' && totalPnts && betRatio * totalPnts.player3 === 0),

            })}
            >
              <>
                {myPos === 'player1' && (
                  <>
                    {totalPnts && totalPnts.player1 && betRatio
                      ? betRatio * totalPnts.player1
                      : 0}
                  </>
                )}
                {myPos === 'player2' && (
                  <>
                    {totalPnts && totalPnts.player2 && betRatio
                      ? betRatio * totalPnts.player2
                      : 0}
                  </>
                )}
                {myPos === 'player3' && (
                  <>
                    {totalPnts && totalPnts.player3 && betRatio
                      ? betRatio * totalPnts.player3
                      : 0}
                  </>
                )}
              </>
            </div>
          </Col>
          <Col className="end-result-table-header-col">
            <div className={classNames({
              'player-history-table-col-positive': (myPos === 'player1' && totalPnts && betRatio * totalPnts.player3 > 0) || (myPos === 'player2' && totalPnts && betRatio * totalPnts.player1 > 0) || (myPos === 'player3' && totalPnts && betRatio * totalPnts.player2 > 0),
              'player-history-table-col-negative': (myPos === 'player1' && totalPnts && betRatio * totalPnts.player3 < 0) || (myPos === 'player2' && totalPnts && betRatio * totalPnts.player1 < 0) || (myPos === 'player3' && totalPnts && betRatio * totalPnts.player2 < 0),
              'player-history-table-col-neutral': (myPos === 'player1' && totalPnts && betRatio * totalPnts.player3 === 0) || (myPos === 'player2' && totalPnts && betRatio * totalPnts.player1 === 0) || (myPos === 'player3' && totalPnts && betRatio * totalPnts.player2 === 0),
            })}
            >
              <>
                {myPos === 'player1' && (
                  <>
                    {totalPnts && totalPnts.player3 && betRatio
                      ? betRatio * totalPnts.player3
                      : 0}
                  </>
                )}
                {myPos === 'player2' && (
                  <>
                    {totalPnts && totalPnts.player1 && betRatio
                      ? betRatio * totalPnts.player1
                      : 0}
                  </>
                )}
                {myPos === 'player3' && (
                  <>
                    {totalPnts && totalPnts.player2 && betRatio
                      ? betRatio * totalPnts.player2
                      : 0}
                  </>
                )}
              </>
            </div>
          </Col>
          <Col className="end-result-table-header-col" />
          <Col className="end-result-table-header-col" />
        </Row>
        {isEndResultTable && (
          <>
            {gameType && gameType.includes('P') && (
              <Row className="end-result-table-header mt-3">
                <Col className="end-result-table-header-col">
                  {t('game:activePules')}
                </Col>
                <Col className="end-result-table-header-col">
                  <div className={classNames({
                    'player-history-table-col-positive': (myPos === 'player1' && player2Pules > 0) || (myPos === 'player2' && player3Pules > 0) || (myPos === 'player3' && player1Pules > 0),
                    'player-history-table-col-negative': (myPos === 'player1' && player2Pules < 0) || (myPos === 'player2' && player3Pules < 0) || (myPos === 'player3' && player1Pules < 0),
                    'player-history-table-col-neutral': (myPos === 'player1' && player2Pules === 0) || (myPos === 'player2' && player3Pules === 0) || (myPos === 'player3' && player1Pules === 0),
                  })}
                  >
                    <>
                      {myPos === 'player1' && (
                        <>
                          {player2Pules}
                        </>
                      )}
                      {myPos === 'player2' && (
                        <>
                          {player3Pules}
                        </>
                      )}
                      {myPos === 'player3' && (
                        <>
                          {player1Pules}
                        </>
                      )}
                    </>
                  </div>
                </Col>
                <Col className="end-result-table-header-col">
                  <div className={classNames({
                    'player-history-table-col-positive': (myPos === 'player1' && player1Pules > 0) || (myPos === 'player2' && player2Pules > 0) || (myPos === 'player3' && player3Pules > 0),
                    'player-history-table-col-negative': (myPos === 'player1' && player1Pules < 0) || (myPos === 'player2' && player2Pules < 0) || (myPos === 'player3' && player3Pules < 0),
                    'player-history-table-col-neutral': (myPos === 'player1' && player1Pules === 0) || (myPos === 'player2' && player2Pules === 0) || (myPos === 'player3' && player3Pules === 0),

                  })}
                  >
                    <>
                      {myPos === 'player1' && (
                        <>
                          {player1Pules}
                        </>
                      )}
                      {myPos === 'player2' && (
                        <>
                          {player2Pules}
                        </>
                      )}
                      {myPos === 'player3' && (
                        <>
                          {player3Pules}
                        </>
                      )}
                    </>
                  </div>
                </Col>
                <Col className="end-result-table-header-col">
                  <div className={classNames({
                    'player-history-table-col-positive': (myPos === 'player1' && player3Pules > 0) || (myPos === 'player2' && player1Pules > 0) || (myPos === 'player3' && player2Pules > 0),
                    'player-history-table-col-negative': (myPos === 'player1' && player3Pules < 0) || (myPos === 'player2' && player1Pules < 0) || (myPos === 'player3' && player2Pules < 0),
                    'player-history-table-col-neutral': (myPos === 'player1' && player3Pules === 0) || (myPos === 'player2' && player1Pules === 0) || (myPos === 'player3' && player2Pules === 0),

                  })}
                  >
                    <>
                      {myPos === 'player1' && (
                        <>
                          {player3Pules}
                        </>
                      )}
                      {myPos === 'player2' && (
                        <>
                          {player1Pules}
                        </>
                      )}
                      {myPos === 'player3' && (
                        <>
                          {player2Pules}
                        </>
                      )}
                    </>
                  </div>
                </Col>
                <Col className="end-result-table-header-col" />
                <Col className="end-result-table-header-col" />
              </Row>
            )}
            {closeReason && closeReason.reason && (closeReason.reason === 'missedTurn' || closeReason.reason === 'leftRoom') && (
              <Row className="end-result-table-header mt-3">
                <Col className="end-result-table-header-col">
                  {closeReason.reason === 'missedTurn' && (
                    t('game:missedTurnPenalty')
                  )}
                  {closeReason.reason === 'leftRoom' && (
                    t('game:leftRoomPenalty')
                  )}
                </Col>
                <Col className="end-result-table-header-col">
                  <div className={classNames({
                    'player-history-table-col-positive': (myPos === 'player1' && penaltyPlayer !== 'player2') || (myPos === 'player2' && penaltyPlayer !== 'player3') || (myPos === 'player3' && penaltyPlayer !== 'player1'),
                    'player-history-table-col-negative': (myPos === 'player1' && penaltyPlayer === 'player2') || (myPos === 'player2' && penaltyPlayer === 'player3') || (myPos === 'player3' && penaltyPlayer === 'player1'),
                  })}
                  >
                    <>
                      {myPos === 'player1' && (
                        <>
                            {penaltyPlayer === 'player2' ? (-leavePenalty) : (leaveReward)}
                        </>
                      )}
                      {myPos === 'player2' && (
                        <>
                            {penaltyPlayer === 'player3' ? (-leavePenalty) : (leaveReward)}
                        </>
                      )}
                      {myPos === 'player3' && (
                        <>
                            {penaltyPlayer === 'player1' ? (-leavePenalty) : (leaveReward)}
                        </>
                      )}
                    </>
                  </div>
                </Col>
                <Col className="end-result-table-header-col">
                  <div className={classNames({
                    'player-history-table-col-positive': (myPos === 'player1' && penaltyPlayer !== 'player1') || (myPos === 'player2' && penaltyPlayer !== 'player2') || (myPos === 'player3' && penaltyPlayer !== 'player3'),
                    'player-history-table-col-negative': (myPos === 'player1' && penaltyPlayer === 'player1') || (myPos === 'player2' && penaltyPlayer === 'player2') || (myPos === 'player3' && penaltyPlayer === 'player3'),
                  })}
                  >
                    <>
                      {myPos === 'player1' && (
                        <>
                            {penaltyPlayer === 'player1' ? (-leavePenalty) : (leaveReward)}
                        </>
                      )}
                      {myPos === 'player2' && (
                        <>
                            {penaltyPlayer === 'player2' ? (-leavePenalty) : (leaveReward)}
                        </>
                      )}
                      {myPos === 'player3' && (
                        <>
                            {penaltyPlayer === 'player3' ? (-leavePenalty) : (leaveReward)}
                        </>
                      )}
                      
                    </>
                  </div>
                </Col>
                <Col className="end-result-table-header-col">
                  <div className={classNames({
                    'player-history-table-col-positive': (myPos === 'player1' && penaltyPlayer !== 'player3') || (myPos === 'player2' && penaltyPlayer !== 'player1') || (myPos === 'player3' && penaltyPlayer !== 'player2'),
                    'player-history-table-col-negative': (myPos === 'player1' && penaltyPlayer === 'player3') || (myPos === 'player2' && penaltyPlayer === 'player1') || (myPos === 'player3' && penaltyPlayer === 'player2'),
                  })}
                  >
                    <>
                      {myPos === 'player1' && (
                        <>
                            {penaltyPlayer === 'player3' ? (-leavePenalty) : (leaveReward)}
                        </>
                      )}
                      {myPos === 'player2' && (
                        <>
                            {penaltyPlayer === 'player1' ? (-leavePenalty) : (leaveReward)}
                        </>
                      )}
                      {myPos === 'player3' && (
                        <>
                            {penaltyPlayer === 'player2' ? (-leavePenalty) : (leaveReward)}
                        </>
                      )}
                    </>
                  </div>
                </Col>
                <Col className="end-result-table-header-col" />
                <Col className="end-result-table-header-col end-result-table-col-last">
                  <Media className="end-result-table-header-col-last-info-button" onClick={showInfoModal} src={infoImg} alt="x" />
                </Col>
              </Row>
            )}
          </>
        )}
      </Col>
    </Row>
  );
};

ScoreTableSum.propTypes = {
  totalPnts: PropTypes.shape(),
  myPos: PropTypes.string,
  isEndResultTable: PropTypes.bool,
  t: PropTypes.func.isRequired,
  showInfoModal: PropTypes.func.isRequired,
  player1Pules: PropTypes.number,
  player2Pules: PropTypes.number,
  player3Pules: PropTypes.number,
  globalParams: PropTypes.shape({}),
  players: PropTypes.shape({}),
};

ScoreTableSum.defaultProps = {
  totalPnts: {},
  myPos: '',
  isEndResultTable: false,
};

export default ScoreTableSum;

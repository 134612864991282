import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { map, filter, size } from 'lodash';
import { connect } from 'react-redux';

import Button from 'reactstrap/lib/Button';
import Media from 'reactstrap/lib/Media';
import Label from 'reactstrap/lib/Label';

import moment from 'moment';

import momentLocalizer from 'react-widgets-moment';

import * as constants from '../../../../../../constants/constants';

import treeSvg from '../../../../../../images/icons/tree-svg.svg';
import reminderImg from '../../../../../../images/redesign/common/game.png';

import {
  getDiscounts,
  getEventsBanner,
} from '../../../../../../actions/shop';
import DateFormat from '../../../../UI/DateFormat';

moment.locale('lv');
momentLocalizer();

class EventModals extends PureComponent {
    static propTypes = {
      t: PropTypes.func.isRequired,
      discounts: PropTypes.array,
      eventsData: PropTypes.array,
      switchTabs: PropTypes.func.isRequired,
      fetchDiscounts: PropTypes.func.isRequired,
      fetchEventsBanner: PropTypes.func.isRequired,
      isEventOption: PropTypes.bool,
      toggleEvent: PropTypes.func.isRequired,
      designPlatform: PropTypes.shape({}),
      toggleNewDesign: PropTypes.func.isRequired,
    }

    static defaultProps = {
      eventsData: [],
      discounts: [],
      isEventOption: false,
      designPlatform: {}
    }

    constructor(props) {
      super(props);
      this.state = {
        selectEvent: {},
        selectDiscount: {},
        toDate: (new Date()).getDate(),
        reminderModal: true,
      };
    }

    componentDidMount() {
      const {
        fetchDiscounts,
        fetchEventsBanner,
        eventsData,
        discounts,
      } = this.props;

      const { hostname } = window.location;

      let devMode = false;

      if (hostname && (hostname.includes('dev') || hostname.includes('localhost'))) {
        devMode = true;
      }

      fetchEventsBanner(devMode);
      fetchDiscounts(devMode);
      this.setState({
        devMode,
      })

      try {
        localStorage.removeItem('checkedEvents');
        localStorage.removeItem('checkedDiscounts');
      } catch (err) {
        console.log(err);
      }

      if (eventsData) {
        let usersEvent = [];
        try {
          if (localStorage.getItem('checkedEvents')) {
            usersEvent = JSON.parse(localStorage.getItem('checkedEvents'));
          }
        } catch (err) {
          console.log(err);
        }

        let count = 0;
        map(eventsData, (item, key) => {
          if (!usersEvent.includes(item.key) && count < 1) {
            count++;
            this.setState({
              selectEvent: item,
            });
          }
        });
      }
      if (discounts) {
        let usersDiscounts = [];
        try {
          if (localStorage.getItem('checkedDiscounts')) {
            usersDiscounts = JSON.parse(localStorage.getItem('checkedDiscounts'));
          }
        } catch (err) {
          console.log(err);
        }

        let count = 0;
        map(discounts, (item, key) => {
          if (!usersDiscounts.includes(item.key) && count < 1) {
            count++;
            this.setState({
              selectDiscount: item,
            });
          }
        });
      }
    }

//select event and key have to set correct !!!!! issue

    componentDidUpdate(prevProps) {
      const { eventsData, discounts } = this.props;
      console.log('componentDidUpdate');
      const { eventsData: prevEventsData, discounts: prevDiscounts } = prevProps;
      if (eventsData !== prevEventsData && eventsData && eventsData.length) {
        console.log('changed eventsData');
        let usersEvent = [];

        try {
          if (localStorage.getItem('checkedEvents')) {
            usersEvent = JSON.parse(localStorage.getItem('checkedEvents'));
          }
        } catch (err) {
          console.log(err);
        }

        let eventCount = 0;
        map(eventsData, (item) => {
          if (!usersEvent.includes(item.key) && eventCount < 1) {
            eventCount += 1;
            this.setState({
              selectEvent: item,
            });
          }
        });
      }
      if (discounts !== prevDiscounts && discounts && discounts.length) {
        console.log('changed discounts', discounts, prevDiscounts);
        let usersDiscounts = [];
        try {
          if (localStorage.getItem('checkedDiscounts')) {
            usersDiscounts = JSON.parse(localStorage.getItem('checkedDiscounts'));
          }
        } catch (err) {
          console.log(err);
        }

        let discountCount = 0;
        map(discounts, (item) => {
          if (!usersDiscounts.includes(item.key) && discountCount < 1) {
            discountCount += 1;
            this.setState({
              selectDiscount: item,
            });
          }
        });
      }
    }

    // shouldComponentUpdate(nextProps, nextState) {
    //   if (nextState.selectEvent.key !== this.state.selectEvent.key) {
    //     return true;
    //   }
    //   if (nextState.selectDiscount.key !== this.state.selectDiscount.key) {
    //     return true;
    //   }
    //   return false;
    // }

    closeModal = () => {
      const { eventsData, toggleEvent } = this.props;
      const { selectEvent } = this.state;

      let usersEvent = [];

      try {
        if (localStorage.getItem('checkedEvents')) {
          usersEvent = JSON.parse(localStorage.getItem('checkedEvents'));
          usersEvent.push(selectEvent.key);
          localStorage.setItem('checkedEvents', JSON.stringify(usersEvent));
        } else {
          usersEvent.push(selectEvent.key);
          localStorage.setItem('checkedEvents', JSON.stringify(usersEvent));
        }
      } catch (err) {
        console.log(err);
      }

      this.setState({
        selectEvent: {},
      });
      let count = 0;
      if (eventsData) {
        map(eventsData, (item) => {
          count += 1;
          if (!usersEvent.includes(item.key) && count < 1) {
            this.setState({
              selectEvent: item,
            });
          }
        });
      }

      // toggleEvent();
    }

    closeDiscountModal = () => {
      const { discounts } = this.props;
      const { selectDiscount } = this.state;
      let usersDiscounts = [];

      try {
        if (localStorage.getItem('checkedDiscounts')) {
          usersDiscounts = JSON.parse(localStorage.getItem('checkedDiscounts'));
          usersDiscounts.push(selectDiscount.key);
          localStorage.setItem('checkedDiscounts', JSON.stringify(usersDiscounts));
        } else {
          usersDiscounts.push(selectDiscount.key);
          localStorage.setItem('checkedDiscounts', JSON.stringify(usersDiscounts));
        }
      } catch (err) {
        console.log(err);
      }

      this.setState({
        lastDiscountImg: selectDiscount.imageUrl,
        selectDiscount: {},
      });

      let count = 0;
      if (discounts) {
        Object.keys(discounts).map(item => {
          count += 1;
          if (!usersDiscounts.includes(item.key) && count < 1) {
            this.setState({
              selectDiscount: item,
            });
          }
        });
      }
    }

    openPage = (type, page, externalLinkFlag) => {
      const { eventsData, switchTabs } = this.props;
      const { selectDiscount } = this.state;

      if (externalLinkFlag) {
        window.open(page, '_blank');
      } else {
        const switchTab = constants.MENU_NAVIGATION_TYPE.filter(item => item.label === page)[0].id;
        if (size(selectDiscount.selectedDiscountPackage) > 0 &&
              size(filter(selectDiscount.selectedDiscountPackage, item => { return constants.MONEYPACKS_TYPES.includes(item)})) === 0 &&
                size(filter(selectDiscount.selectedDiscountPackage, item => { return constants.PREMIUMPACKS_TYPES.includes(item)})) > 0
        ) {
          switchTabs('5.2');
        } else {
          switchTabs(switchTab);
        }
      }

      if (type === 'event') {
        this.closeModal();
      } else {
        this.closeDiscountModal();
      }

      // if (page === 'tournaments') {
      //   switchTabs(switchTab);
      // } else if (page === 'top') {
      //   switchTabs('2');
      // } else if (page === 'rooms') {
      //   switchTabs('1');
      // } else if (page === 'shop') {
      //   switchTabs('5');
      // } else if (page === 'shop_discounts') {
      //   switchTabs('5.5');
      // }
      // if (localStorage.getItem('checkedEvents')) {
      //   usersEvent = JSON.parse(localStorage.getItem('checkedEvents'));
      //   usersEvent.push(selectEvent);
      //   localStorage.setItem("checkedEvents", JSON.stringify(usersEvent));
      // } else {
      //   usersEvent.push(selectEvent);
      //   localStorage.setItem("checkedEvents", JSON.stringify(usersEvent));
      // }
      // let tab = '1';
      // this.setState({
      //   selectEvent: '',
      // });
      // let count = 0;
      // Object.keys(eventsData).map(key => {
      //   count++;
      //   if (!usersEvent.includes(key) && count < 1) {
      //     this.setState({
      //       selectEvent: key,
      //     });
      //   }
      // });
    }

    toggleNewDesignFunc = () =>  {
      const {toggleNewDesign } = this.props;

      toggleNewDesign();
      this.setState({
        reminderModal: false,
      })
    };

    render() {
      const {
        t,
        i18n,
        eventsData,
        discounts,
        isEventOption,
        designPlatform,
      } = this.props;
      const {
        selectEvent,
        selectDiscount,
        reminderModal,
        toDate,
        devMode,
        lastDiscountImg,
      } = this.state;
      
      let isFrequency = false;
      if (designPlatform && designPlatform.frequency > 0 && designPlatform.setFrequency) {
        const frequencyDate = moment.duration(moment(new Date()).diff(moment(new Date(designPlatform.setFrequency)))).days();
        isFrequency = frequencyDate % designPlatform.frequency === 0 ? true : false;
      }

      return (
        <>
          {
            (eventsData && eventsData.length > 0 && selectEvent && !isEventOption) ? (
              <div className={`event-section event-section-mobile ${selectEvent.key && 'event-section-mobile-show'}`}>
                <div className="event-header">
                  <div className="event-title">
                    {i18n.language === 'en' ? selectEvent.titleEN : (i18n.language === 'lv' ? selectEvent.titleLV : (i18n.language === 'ru' ? selectEvent.titleRU : selectEvent.titleLV))}
                  </div>
                  <div className="event-close events-header-close" onClick={() => this.closeModal()}>x</div>
                  {/* <div className="event-sub-title">
                    <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateMinute} date={new Date(selectEvent.startDate)} />
                    <Label className="event-sub-spaces">~</Label>
                    <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateMinute} date={new Date(selectEvent.endDate)} />
                  </div> */}
                  <div className="event-description">
                    {i18n.language === 'en' ? selectEvent.descriptionEN : (i18n.language === 'lv' ? selectEvent.descriptionLV : (i18n.language === 'ru' ? selectEvent.descriptionRU : selectEvent.descriptionLV))}
                  </div>
                </div>
                <div className="event-body">
                  <img src={selectEvent.imageUrl} alt="X" className="event-body-img" />
                </div>
                <div className="event-footer">
                  <Button className="event-footer-button" color="secondary" onClick={() => this.openPage('event', selectEvent.pageToOpen, selectEvent.externalLink)}>
                    <Media src={treeSvg} className="event-tree-svg-before" />
                    <label className="openPage">{t('common.open')}</label>
                    <Media src={treeSvg} className="event-tree-svg-end" />
                  </Button>
                </div>
              </div>
            ) : (null)
          }
          {
            (discounts && discounts.length > 0 && selectDiscount/*.showInBanner*/) ? (
              <div className={`discount-event-section discount-event-section-mobile ${selectDiscount.showInBanner && 'discount-event-section-mobile-show'}`}>
                <div className="event-header">
                  <div className="event-title">
                    {i18n.language === 'en' ? selectDiscount.titleEN : (i18n.language === 'lv' ? selectDiscount.titleLV : (i18n.language === 'ru' ? selectDiscount.titleRU : selectDiscount.titleLV))}
                  </div>
                  <div className="event-close events-header-close" onClick={() => this.closeDiscountModal()}>x</div>
                  {/* <div className="event-sub-title">
                    <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateMinute} date={new Date(selectDiscount.startDate)} />
                    <Label className="event-sub-spaces">~</Label>
                    <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateMinute} date={new Date(selectDiscount.endDate)} />
                  </div> */}
                </div>
                <div className="event-body">
                  <div className="event-description">
                    {i18n.language === 'en' ? selectDiscount.descriptionEN : (i18n.language === 'lv' ? selectDiscount.descriptionLV : (i18n.language === 'ru' ? selectDiscount.descriptionRU : selectDiscount.descriptionLV))}
                  </div>
                  <img src={selectDiscount.imageUrl || lastDiscountImg} alt="X" className="event-body-img" />
                </div>
                <div className="event-footer">
                  <Button className="event-footer-button" color="secondary" onClick={() => this.openPage('discount', 'shop_discounts')}>
                    <Media src={treeSvg} className="event-tree-svg-before" />
                    <label className="openPage">{t('common.open')}</label>
                    <Media src={treeSvg} className="event-tree-svg-end" />
                  </Button>
                </div>
              </div>
            ) : (null)
          }
          {/* {
            designPlatform && designPlatform.showReminder && (designPlatform.devMode && devMode) && isFrequency && reminderModal ? (
              <div className="discount-event-section discount-event-section-mobile d-none">
                <div className="event-header">
                  <div className="event-title">
                    {t('common.reminderTitle')}
                  </div>
                  <div className="event-close events-header-close" onClick={() => this.closeDiscountModal()}>x</div>
                </div>
                <div className="event-body">
                  <div className="event-description">
                    {t('common.reminderDescription')}
                  </div>
                  <img src={reminderImg} alt="X" className="event-body-img" />
                </div>
                <div className="event-footer">
                  <Button className="event-footer-button" color="secondary" onClick={() => this.toggleNewDesignFunc(false)}>
                    <Media src={treeSvg} className="event-tree-svg-before" />
                    <label className="openPage">{t('common.reminderButton')}</label>
                    <Media src={treeSvg} className="event-tree-svg-end" />
                  </Button>
                </div>
              </div>
            ) : (null)
          } */}
        </>
      );
    }
}

const mapStateToProps = state => ({
  eventsData: state.shop.eventsData,
  discounts: state.shop.discounts,
});

const mapDispatchToProps = {
  fetchEventsBanner: getEventsBanner,
  fetchDiscounts: getDiscounts,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(EventModals));

import React, { useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import PropTypes from 'prop-types';
import {
  Button,
  Media, Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap/lib';
import { connect } from 'react-redux';

import HelpSubsection from './HelpSubsection';
import TextEditor from '../../TextEditor/TextEditor';
import { supportFileUpload, supportMessageFileUpload } from '../../../../actions/admin';
import closeImg from '../../../../images/icons/close.png';

const ItemType = 'SECTION';

function HelpSection({
  index,
  section,
  isEdit,
  selectedId,
  onTitleChange,
  onContentChange,
  onSubsectionTitleChange,
  onSubsectionContentChange,
  onAddSubsection,
  onDeleteSection,
  onDeleteSubsection,
  moveSection,
  moveSubsection,
  handleUpdateSection,
  handleEditCancel,
  toggleEdit,
  editorState,
  handleExpand,
  supportAMessageFileUpload,
}) {
  const ref = useRef(null);
  const [deleteModal, setDeleteModal] = useState(false);

  const [{ handlerId }, drop] = useDrop({
    accept: ItemType,
    collect: monitor => ({
      handlerId: monitor.getHandlerId(),
    }),
    hover: (item, monitor) => {
      if (!ref.current || isEdit) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveSection(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: () => ({ id: section.id, index }),
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  const handleTitleChange = (e) => {
    onTitleChange(section.id, e.target.value);
  };

  const handleContentChange = (state) => {
    onContentChange(section.id, state);
  };

  const handleAddSubsection = () => {
    onAddSubsection(section.id);
  };

  const handleDeleteSection = () => {
    setDeleteModal(true);
  };

  const chooseFile = async (file) => {
    if (file !== null) {
      try {
        const data = await supportAMessageFileUpload(file);
        return { data: { link: data.url, src: data.url } };
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };

  const toggleDeleteModal = () => {
    setDeleteModal(false);
  };

  const handleDeleteSurvey = () => {
    onDeleteSection(section.id);
  };

  return (
    <div ref={ref} style={{ opacity: isDragging ? 0.5 : 1 }} data-handler-id={handlerId} className="d-flex w-full items-center flex-column gap-10">
      <div className="d-flex w-full flex-row gap-20">
        <div className='d-flex flex-row w-80'>
          <div className="w-40 d-flex flex-row">
            <div className='text-left'>
              {isEdit && selectedId === section.id ? (
                <input
                  type="text"
                  className="w-full"
                  value={section.orderId}
                  disabled
                />
              ) : (
                section.orderId
              )}
            </div>
            {isEdit && selectedId === section.id ? (
              <input
                type="text"
                className="w-full max-h-30"
                value={section.title}
                onChange={handleTitleChange}
              />
            ) : (
              section.title
            )}
          </div>
          <div className={`w-60 ${selectedId === section.id ? 'h-auto' : 'max-h-100'}`}>
            {isEdit && selectedId === section.id ? (
              <TextEditor editorState={editorState} chooseFile={chooseFile} editorStateSet notChangedContent handleEditState={handleContentChange} />
            ) : (
              <TextEditor viewEditorState={section.content} readOnly />
            )}
          </div>
        </div>
        <div className='d-flex flex-row w-20 gap-10'>
          <div className='w-20'>
            {isEdit && selectedId === section.id ? (
              <div className='d-flex flex-column gap-10'>
                <Button size="sm" color="warning" className='mr-1' onClick={() => handleUpdateSection(section.id)}>Save</Button>
                <Button size="sm" color="warning" onClick={() => handleEditCancel(section.id)}>Cancel</Button>
              </div>
            ) : (
              <Button size="sm" color="warning" onClick={() => toggleEdit(section.id, section.content)} disabled={isEdit && selectedId !== section.id}>Edit</Button>
            )}
          </div>
          <div className="w-50 d-flex flex-column gap-10">
            <Button size="sm" color="success" onClick={handleAddSubsection} disabled={isEdit}>Add Subsection</Button>
            <Button size="sm" color="danger" onClick={handleDeleteSection} disabled={isEdit}>Delete Section</Button>
          </div>
          <div className="w-30">
            <Button size="sm" color="success" className="mr-2" onClick={() => handleExpand(section.id)} disabled={isEdit}>{selectedId === section.id && !isEdit ? 'Collapse' : 'Expand'}</Button>
          </div>
        </div>
      </div>
      <Modal container={'div > div'} isOpen={deleteModal} toggle={() => toggleDeleteModal()} className="notification">
        <ModalHeader
          toggle={() => toggleDeleteModal()}
          className="notification-header"
          close={
            <Media src={closeImg} className="notification-header-close" alt="X" onClick={() => toggleDeleteModal()} />
          }
        />
        <ModalBody className="notification-body">
          Do you really want to delete this section?
        </ModalBody>
        <ModalFooter className="notification-footer">
          <Button className="btn notification-footer-button" onClick={() => handleDeleteSurvey()}>
            Delete
          </Button>
          <Button type="button" className="btn notification-footer-button" onClick={() => toggleDeleteModal()}>
            No
          </Button>
        </ModalFooter>
      </Modal>
      {section.subsections.map((subsection, subIndex) => (
        <HelpSubsection
          key={subsection.id}
          sectionId={section.id}
          sectionIndex={index}
          subsectionIndex={subIndex}
          subsection={subsection}
          isEdit={isEdit}
          selectedId={selectedId}
          onSubsectionTitleChange={onSubsectionTitleChange}
          onSubsectionContentChange={onSubsectionContentChange}
          onDeleteSubsection={onDeleteSubsection}
          moveSubsection={moveSubsection}
          handleUpdateSection={handleUpdateSection}
          handleEditCancel={handleEditCancel}
          toggleEdit={toggleEdit}
          editorState={editorState}
          handleExpand={handleExpand}
        />
      ))}
    </div>
  );
}

HelpSection.propTypes = {
  index: PropTypes.number,
  section: PropTypes.shape({}),
  isEdit: PropTypes.bool,
  selectedId: PropTypes.string,
  onTitleChange: PropTypes.func.isRequired,
  onContentChange: PropTypes.func.isRequired,
  onSubsectionTitleChange: PropTypes.func.isRequired,
  onSubsectionContentChange: PropTypes.func.isRequired,
  onAddSubsection: PropTypes.func.isRequired,
  onDeleteSection: PropTypes.func.isRequired,
  onDeleteSubsection: PropTypes.func.isRequired,
  moveSection: PropTypes.func.isRequired,
  moveSubsection: PropTypes.func.isRequired,
  handleUpdateSection: PropTypes.func.isRequired,
  handleEditCancel: PropTypes.func.isRequired,
  toggleEdit: PropTypes.func.isRequired,
  editorState: PropTypes.shape({}),
  handleExpand: PropTypes.func.isRequired,
  supportAMessageFileUpload: PropTypes.func.isRequired,
};

HelpSection.defaultProps = {
  index: null,
  section: {},
  isEdit: false,
  selectedId: null,
  editorState: null,
};

const mapDispatchToProps = {
  supportAMessageFileUpload: supportMessageFileUpload,
};

export default connect(
  null,
  mapDispatchToProps,
)(HelpSection);

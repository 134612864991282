import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import classNames from 'classnames';
import TopPageMyPos from './TopPageMyPos';
import Spinner from 'reactstrap/lib/Spinner';

import { useTranslation } from 'react-i18next';
import { orderBy, reverse, get, size, filter, map, } from 'lodash';
import MenuRow from '../../Components/Components/MenuRow';

// import ScrollAreaWrapper from '../../UI/ScrollAreaWrapper';
import ScrollAreaWrapper from '../../Components/ScrollAreaWrapper';
import TopPlayeAvatar from './Components/TopPlayerAvatar';
import * as constants from '../../../../../constants/constants';

import defaultImage from '../../../../../images/redesign/common/default_image.svg';

import blockImage from '../../../../../images/redesign/common/block.png';
import unblockImage from '../../../../../images/redesign/common/unblock.png';
import coinImage from '../../../../../images/redesign/common/coin.svg';
import ratingImage from '../../../../../images/redesign/common/rating.svg';

import lightBlockImage from '../../../../../images/redesign/light-mode/common/block.svg';
import lightUnblockImage from '../../../../../images/redesign/light-mode/common/unblock.svg';
import draugiemLogoIcon from '../../../../../images/icons/draugiem-lv.svg';
import facebookLogoIcon from '../../../../../images/icons/social-facebook.svg';
import addFriendIcon from '../../../../../images/redesign/mobile/icons/add-friend-icon.svg';
import removeFriendIcon from '../../../../../images/redesign/mobile/icons/blocked-players-active-dark-icon.svg';
import { trimString } from '../../../../../utils/stringUtils';
import IconPopover from '../../Components/Components/IconPopover';

const TopPageList = React.memo(({
  filterLevels, isFriendLoading, handleSendAddFriend, handleRemoveFriend, friends, millionairesOpen, leaderboard, isFiltered, activeType, ignoredUsers, bannedUsers, block, unblock, renderRating, order, orderby2, showFields, handleOrderBy, screenMode, leaderboardLoading, openFilter, myLeaderboard, searchValue
}) => {

  const { t } = useTranslation('common');
  const [sortLeaderboard, setSortLeaderboard] = useState([]);
  
  const [addRemoveFriendPopoverOpen, setAddRemoveFriendPopoverOpen] = useState(null);
  const [blockUnblockPopoverOpen, setBlockUnblockPopoverOpen] = useState(null);

  const [placePopoverOpen, setPlacePopoverOpen] = useState(false);
  const [playerPopoverOpen, setPlayerPopoverOpen] = useState(false);
  const [levelPopoverOpen, setLevelPopoverOpen] = useState(false);
  const [ratingsPopoverOpen, setRatingsPopoverOpen] = useState(false);
  const [balancePopoverOpen, setBalancePopoverOpen] = useState(false);
  const [pointsPopoverOpen, setPointsPopoverOpen] = useState(false);
  const [partiesPopoverOpen, setPartiesPopoverOpen] = useState(false);

  const [gWonPopoverOpen, setGWonPopoverOpen] = useState(false);
  const [bgWonPopoverOpen, setBgWonPopoverOpen] = useState(false);
  const [sgWonPopoverOpen, setSgWonPopoverOpen] = useState(false);
  const [tblsWonPopoverOpen, setTblsWonPopoverOpen] = useState(false);
  const [achvCntPopoverOpen, setAchvCntPopoverOpen] = useState(false);
  const [maxRndInGamePopoverOpen, setMaxRndInGamePopoverOpen] = useState(false);

//  console.log('TopPageList leaderboard', leaderboard);

  let activeTypeFilter;
  let activeTypeAction;

  if (isFiltered || millionairesOpen || size(filterLevels) > 0) {
    activeTypeFilter = constants.filterLeaderboardType.filter(item => item.id === activeType.toString())[0].label;
    activeTypeAction = constants.filterLeaderboardType.filter(item => item.id === activeType.toString())[0].action;
  }
  const addIndex = data => {

    return data.map((row, index) => ({ id: (isFiltered && row.searchPos) ? (row.searchPos) : ((order && row.userCount && order === constants.ORDER_BY_TYPE.desc) ? (row.userCount - index) : index + 1), ...row }))
  };

  // order === constants.ORDER_BY_TYPE.desc ? pos.id :

  // console.log('render TopPageList',leaderboard, leaderboard.length, showFields, order, orderby );
  useEffect(() => {
    if (filterLevels && filterLevels.length > 0) {
      leaderboard = filter(leaderboard, (obj) => {
          return filterLevels.some(item => {
              return obj.lvl &&
                  (constants.PLAYER_LEVEL_RANGE[item].from ? obj.lvl >= constants.PLAYER_LEVEL_RANGE[item].from : true) &&
                  (constants.PLAYER_LEVEL_RANGE[item].to ? obj.lvl < constants.PLAYER_LEVEL_RANGE[item].to : true);
          });
      });
    } 
    setSortLeaderboard(addIndex(leaderboard));
  }, [leaderboard]);
  
  useEffect(() => {
    if (order && orderby2) {
      if (orderby2 === constants.TOP_LEADERBOARD_ORDER.place) {

        if (order === constants.ORDER_BY_TYPE.asc) {
          const leaderboardWithId = addIndex(leaderboard);
          setSortLeaderboard(leaderboardWithId);
        } else if (order === constants.ORDER_BY_TYPE.desc) {
          if (isFiltered) {
            const leaderboardWithId = addIndex(leaderboard.reverse());
          //  setSortLeaderboard(reverse([...leaderboardWithId]));
            setSortLeaderboard(leaderboardWithId);
          } else {
            const leaderboardWithId = addIndex(leaderboard.reverse());
            setSortLeaderboard(leaderboardWithId);

          //  const leaderboardWithId = addIndex(leaderboard);
          //  setSortLeaderboard(reverse([...leaderboardWithId]));
          }
        }
      } else {
        if (orderby2 === constants.TOP_LEADERBOARD_ORDER.player) {
          const leaderboardWithId = addIndex(leaderboard);

          if (order === constants.ORDER_BY_TYPE.asc) {
            setSortLeaderboard(leaderboardWithId.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true })));
          } else {
            setSortLeaderboard(leaderboardWithId.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true })).reverse());
          }
        } else if (((orderby2 === 'place' || orderby2 === 'points') && activeType.toString() === '1')
          || ((orderby2 === 'place' || orderby2 === 'balance') && activeType.toString() === '2')
          || ((orderby2 === 'place' || orderby2 === 'points') && activeType.toString() === '3')) {

          if (order === constants.ORDER_BY_TYPE.asc) {
            console.log('test 3', isFiltered);
            const leaderboardWithId = addIndex(leaderboard);


            setSortLeaderboard(orderBy(leaderboardWithId, [orderby2.toString()], [order.toString()]).reverse());
          } else if (order === constants.ORDER_BY_TYPE.desc) {
            if (isFiltered) {
              const leaderboardWithId = addIndex(leaderboard);

              setSortLeaderboard(orderBy(leaderboardWithId, [orderby2.toString()], [order.toString()]).reverse());

            } else {
              const leaderboardWithId = addIndex(leaderboard.reverse());

              setSortLeaderboard(orderBy(leaderboardWithId, [orderby2.toString()], [order.toString()]).reverse());
            }
          }
        } else {

          const leaderboardWithId = addIndex(leaderboard);


        /*  if (order === constants.ORDER_BY_TYPE.asc) {
            console.log('test 3');
            const leaderboardWithId = addIndex(leaderboard);
          //  setSortLeaderboard(leaderboardWithId);

            console.log('leaderboardWithId', leaderboardWithId, orderby2, order, activeTypeFilter);

          //  if (activeTypeFilter === )
            setSortLeaderboard(orderBy(leaderboardWithId, [orderby2.toString()], [order.toString()]));
          } else if (order === constants.ORDER_BY_TYPE.desc) {
            if (isFiltered) {
              console.log('test 4');
              const leaderboardWithId = addIndex(leaderboard);
            //  setSortLeaderboard(reverse([...leaderboardWithId]));

              console.log('leaderboardWithId', leaderboardWithId);

              setSortLeaderboard(orderBy(reverse([...leaderboardWithId]), [orderby2.toString()], [order.toString()]));


             // setSortLeaderboard(leaderboardWithId);
            } else {
              console.log('test 5');
              const leaderboardWithId = addIndex(leaderboard.reverse());

              console.log('leaderboardWithId', leaderboardWithId);

              setSortLeaderboard(orderBy(leaderboardWithId, [orderby2.toString()], [order.toString()]));
            //  setSortLeaderboard(leaderboardWithId);


            //  const leaderboardWithId = addIndex(leaderboard);
            //  setSortLeaderboard(reverse([...leaderboardWithId]));
            }
          }  */

          setSortLeaderboard(orderBy(leaderboardWithId, [orderby2.toString()], [order.toString()]));
        }
      }
      // }
    }
  }, [order, orderby2]);

  const selectOptions = [];
  map(constants.TOP_LEADERBOARD_ORDER, (item) => {
    const STYLE_TOP_TABLE = classNames(
      'layout-body-main-table-header-orderby',
      {
        'layout-body-main-table-header-orderby_asc_active': item === orderby2 && order === constants.ORDER_BY_TYPE.asc,
        'layout-body-main-table-header-orderby_desc_active': item === orderby2 && order !== constants.ORDER_BY_TYPE.asc,
      }
    );
    selectOptions[item] = {
      fieldStyle: STYLE_TOP_TABLE,
    };
  });

  return (
    <div className='top-page-main-body-wrapper'>
      {
        (leaderboardLoading) && (
          <>
          <div className="d-flex align-items-center spinner-loading-section">
            <Spinner color={screenMode === constants.SCREEN_MODE.light ? constants.SCREEN_MODE.dark : constants.SCREEN_MODE.light} style={{ width: '5rem', height: '5rem' }} />
          </div>
          {/* <div id="place-popover" /> */}
          </>
        )
      }
      <>
        <div className="top-table-scrollarea-body-header" style={leaderboardLoading ? { display: 'none' } : {}}>
          <div className={`${selectOptions[constants.TOP_LEADERBOARD_ORDER.place].fieldStyle} layout-body-main-table-header-place`} onClick={() => handleOrderBy(constants.TOP_LEADERBOARD_ORDER.place)} onMouseEnter={() => setPlacePopoverOpen(true)} onMouseLeave={() => setPlacePopoverOpen(false)}>
            <div id="place-popover" className="d-inline-block layout-body-main-table-header-text-wrapper">{t('common.position')}</div>
          </div>
          <IconPopover text={t((order === constants.ORDER_BY_TYPE.asc && orderby2 === constants.TOP_LEADERBOARD_ORDER.place) ? 'popovers.sortDsc' : 'popovers.sortAsc')} placement="bottom" targetId="place-popover" popoverOpen={placePopoverOpen && ((order === constants.ORDER_BY_TYPE.asc && orderby2 === constants.TOP_LEADERBOARD_ORDER.place) || (!order && !orderby2))} />

          <div className={`${selectOptions[constants.TOP_LEADERBOARD_ORDER.player].fieldStyle} layout-body-main-table-header-player`} onClick={() => handleOrderBy(constants.TOP_LEADERBOARD_ORDER.player)} onMouseEnter={() => setPlayerPopoverOpen(true)} onMouseLeave={() => setPlayerPopoverOpen(false)}>
            <div id="player-popover" className="d-inline-block layout-body-main-table-header-text-wrapper">{t('top.player')}</div>
          </div>
          <IconPopover text={t((order !== null && orderby2 === constants.TOP_LEADERBOARD_ORDER.player) ? 'popovers.sortDsc' : 'popovers.sortAsc')} placement="bottom" targetId="player-popover" popoverOpen={playerPopoverOpen && ((order === constants.ORDER_BY_TYPE.asc && orderby2 === constants.TOP_LEADERBOARD_ORDER.player) || (!order && !orderby2))} />

          <div className={`${selectOptions[constants.TOP_LEADERBOARD_ORDER.level].fieldStyle} layout-body-main-table-header-level`} onClick={() => handleOrderBy(constants.TOP_LEADERBOARD_ORDER.level)} onMouseEnter={() => setLevelPopoverOpen(true)} onMouseLeave={() => setLevelPopoverOpen(false)}>
            <div id="level-popover" className="d-inline-block layout-body-main-table-header-text-wrapper">{t('common.level')}</div>
          </div>
          <IconPopover text={t((order !== null && orderby2 === constants.TOP_LEADERBOARD_ORDER.level) ? 'popovers.sortDsc' : 'popovers.sortAsc')} placement="bottom" targetId="level-popover" popoverOpen={levelPopoverOpen && ((order === constants.ORDER_BY_TYPE.asc && orderby2 === constants.TOP_LEADERBOARD_ORDER.level) || (!order && !orderby2))} />

          <div className={`${selectOptions[constants.TOP_LEADERBOARD_ORDER.ratings].fieldStyle} layout-body-main-table-header-rating`} onClick={() => handleOrderBy(constants.TOP_LEADERBOARD_ORDER.ratings)} onMouseEnter={() => setRatingsPopoverOpen(true)} onMouseLeave={() => setRatingsPopoverOpen(false)}>
            <div id="ratings-popover" className="d-inline-block layout-body-main-table-header-text-wrapper">{t('common.ratings')}</div>
          </div>
          <IconPopover text={t((order !== null && orderby2 === constants.TOP_LEADERBOARD_ORDER.ratings) ? 'popovers.sortDsc' : 'popovers.sortAsc')} placement="bottom" targetId="ratings-popover" popoverOpen={ratingsPopoverOpen && ((order === constants.ORDER_BY_TYPE.asc && orderby2 === constants.TOP_LEADERBOARD_ORDER.ratings) || (!order && !orderby2))} />

          <div className={`${selectOptions[constants.TOP_LEADERBOARD_ORDER.balance].fieldStyle} layout-body-main-table-header-balance`} onClick={() => handleOrderBy(constants.TOP_LEADERBOARD_ORDER.balance)} onMouseEnter={() => setBalancePopoverOpen(true)} onMouseLeave={() => setBalancePopoverOpen(false)}>
            <div id="balance-popover" className="d-inline-block layout-body-main-table-header-text-wrapper">{t('common.balance')}</div>
          </div>
          <IconPopover text={t((order !== null && orderby2 === constants.TOP_LEADERBOARD_ORDER.balance) ? 'popovers.sortDsc' : 'popovers.sortAsc')} placement="bottom" targetId="balance-popover" popoverOpen={balancePopoverOpen && ((order === constants.ORDER_BY_TYPE.asc && orderby2 === constants.TOP_LEADERBOARD_ORDER.balance) || (!order && !orderby2))} />

          <div className={`${selectOptions[constants.TOP_LEADERBOARD_ORDER.points].fieldStyle} layout-body-main-table-header-points`} onClick={() => handleOrderBy(constants.TOP_LEADERBOARD_ORDER.points)} onMouseEnter={() => setPointsPopoverOpen(true)} onMouseLeave={() => setPointsPopoverOpen(false)}>
            <div id="points-popover" className="d-inline-block layout-body-main-table-header-text-wrapper">{t('common.points')}</div>
          </div>
          <IconPopover text={t((order === constants.ORDER_BY_TYPE.asc && orderby2 === constants.TOP_LEADERBOARD_ORDER.points) ? 'popovers.sortDsc' : 'popovers.sortAsc')} placement="bottom" targetId="points-popover" popoverOpen={pointsPopoverOpen && ((order === constants.ORDER_BY_TYPE.asc && orderby2 === constants.TOP_LEADERBOARD_ORDER.points) || (!order && !orderby2))} />


          <div className={`${selectOptions[constants.TOP_LEADERBOARD_ORDER.rounds].fieldStyle} layout-body-main-table-header-rounds`} onClick={() => handleOrderBy(constants.TOP_LEADERBOARD_ORDER.rounds)} onMouseEnter={() => setPartiesPopoverOpen(true)} onMouseLeave={() => setPartiesPopoverOpen(false)}>
            <div id="parties-popover" className="d-inline-block layout-body-main-table-header-text-wrapper">{t('common.parties')}</div>
          </div>
          <IconPopover text={t((order !== null && orderby2 === constants.TOP_LEADERBOARD_ORDER.rounds) ? 'popovers.sortDsc' : 'popovers.sortAsc')} placement="bottom" targetId="parties-popover" popoverOpen={partiesPopoverOpen && ((order === constants.ORDER_BY_TYPE.asc && orderby2 === constants.TOP_LEADERBOARD_ORDER.rounds) || (!order && !orderby2))} />

          <div
            className={`${selectOptions[constants.TOP_LEADERBOARD_ORDER.gWon].fieldStyle} layout-body-main-table-header-gwon`}
            onClick={() => handleOrderBy(constants.TOP_LEADERBOARD_ORDER.gWon)}
            onMouseEnter={() => setGWonPopoverOpen(true)}
            onMouseLeave={() => setGWonPopoverOpen(false)}
            style={showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.gWon) === -1 ? {display: 'none'} : {}}

          >
            <div id="gWon-popover" className="d-inline-block layout-body-main-table-header-text-wrapper">{t(`top.${filter(constants.filterLeaderboardType, ['label', constants.LEADERBOARD_FILTER_TYPES.gWon])[0].tLabel}`)}</div>
          </div>
          <IconPopover text={t((order !== null && orderby2 === constants.TOP_LEADERBOARD_ORDER.gWon) ? 'popovers.sortDsc' : 'popovers.sortAsc')} placement="bottom" targetId="gWon-popover" popoverOpen={gWonPopoverOpen && ((order === constants.ORDER_BY_TYPE.asc && orderby2 === constants.TOP_LEADERBOARD_ORDER.gWon) || (!order && !orderby2))} />

          <div
            className={`${selectOptions[constants.TOP_LEADERBOARD_ORDER.bgWon].fieldStyle} layout-body-main-table-header-bgwon`}
            onClick={() => handleOrderBy(constants.TOP_LEADERBOARD_ORDER.bgWon)}
            onMouseEnter={() => setBgWonPopoverOpen(true)}
            onMouseLeave={() => setBgWonPopoverOpen(false)}
            style={showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.bgWon) === -1 ? {display: 'none'} : {}}

          >
            <div id="bgWon-popover" className="d-inline-block layout-body-main-table-header-text-wrapper">{t(`top.${filter(constants.filterLeaderboardType, ['label', constants.LEADERBOARD_FILTER_TYPES.bgWon])[0].tLabel}`)}</div>
          </div>
          <IconPopover text={t((order !== null && orderby2 === constants.TOP_LEADERBOARD_ORDER.bgWon) ? 'popovers.sortDsc' : 'popovers.sortAsc')} placement="bottom" targetId="bgWon-popover" popoverOpen={bgWonPopoverOpen && ((order === constants.ORDER_BY_TYPE.asc && orderby2 === constants.TOP_LEADERBOARD_ORDER.bgWon) || (!order && !orderby2))} />

          <div
            className={`${selectOptions[constants.TOP_LEADERBOARD_ORDER.sgWon].fieldStyle} layout-body-main-table-header-sgwon`}
            onClick={() => handleOrderBy(constants.TOP_LEADERBOARD_ORDER.sgWon)}
            onMouseEnter={() => setSgWonPopoverOpen(true)}
            onMouseLeave={() => setSgWonPopoverOpen(false)}
            style={showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.sgWon) === -1 ? {display: 'none'} : {}}
          >
            <div id="sgWon-popover" className="d-inline-block layout-body-main-table-header-text-wrapper">{t(`top.${filter(constants.filterLeaderboardType, ['label', constants.LEADERBOARD_FILTER_TYPES.sgWon])[0].tLabel}`)}</div>
          </div>
          <IconPopover text={t((order !== null && orderby2 === constants.TOP_LEADERBOARD_ORDER.sgWon) ? 'popovers.sortDsc' : 'popovers.sortAsc')} placement="bottom" targetId="sgWon-popover" popoverOpen={sgWonPopoverOpen && ((order === constants.ORDER_BY_TYPE.asc && orderby2 === constants.TOP_LEADERBOARD_ORDER.sgWon) || (!order && !orderby2))} />

          <div
            className={`${selectOptions[constants.TOP_LEADERBOARD_ORDER.tblsWon].fieldStyle} layout-body-main-table-header-tblswon`}
            onClick={() => handleOrderBy(constants.TOP_LEADERBOARD_ORDER.tblsWon)}
            onMouseEnter={() => setTblsWonPopoverOpen(true)}
            onMouseLeave={() => setTblsWonPopoverOpen(false)}
            style={showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.tblsWon) === -1 ? {display: 'none'} : {}}
          >
            <div id="tblsWon-popover" className="d-inline-block layout-body-main-table-header-text-wrapper">{t(`top.${filter(constants.filterLeaderboardType, ['label', constants.LEADERBOARD_FILTER_TYPES.tblsWon])[0].tLabel}`)}</div>
          </div>
          <IconPopover text={t((order !== null && orderby2 === constants.TOP_LEADERBOARD_ORDER.tblsWon) ? 'popovers.sortDsc' : 'popovers.sortAsc')} placement="bottom" targetId="tblsWon-popover" popoverOpen={tblsWonPopoverOpen && ((order === constants.ORDER_BY_TYPE.asc && orderby2 === constants.TOP_LEADERBOARD_ORDER.tblsWon) || (!order && !orderby2))} />


          <div
            className={`${selectOptions[constants.TOP_LEADERBOARD_ORDER.achvCnt].fieldStyle} layout-body-main-table-header-achvcnt`}
            onClick={() => handleOrderBy(constants.TOP_LEADERBOARD_ORDER.achvCnt)}
            onMouseEnter={() => setAchvCntPopoverOpen(true)}
            onMouseLeave={() => setAchvCntPopoverOpen(false)}
            style={showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.achvCnt) === -1 ? {display: 'none'} : {}}
          >
            <div id="achvCnt-popover" className="d-inline-block layout-body-main-table-header-text-wrapper">{t(`top.${filter(constants.filterLeaderboardType, ['label', constants.LEADERBOARD_FILTER_TYPES.achvCnt])[0].tLabel}`)}</div>
          </div>
          <IconPopover text={t((order !== null && orderby2 === constants.TOP_LEADERBOARD_ORDER.achvCnt) ? 'popovers.sortDsc' : 'popovers.sortAsc')} placement="bottom" targetId="achvCnt-popover" popoverOpen={achvCntPopoverOpen && ((order === constants.ORDER_BY_TYPE.asc && orderby2 === constants.TOP_LEADERBOARD_ORDER.achvCnt) || (!order && !orderby2))} />

          <div 
            className={`${selectOptions[constants.TOP_LEADERBOARD_ORDER.maxRndInGame].fieldStyle} layout-body-main-table-header-maxrndingame`}
            onClick={() => handleOrderBy(constants.TOP_LEADERBOARD_ORDER.maxRndInGame)}
            onMouseEnter={() => setMaxRndInGamePopoverOpen(true)}
            onMouseLeave={() => setMaxRndInGamePopoverOpen(false)}
            style={showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.maxRndInGame) === -1 ? {display: 'none'} : {}}
          >
            <div id="maxRndInGame-popover" className="d-inline-block layout-body-main-table-header-text-wrapper">{t(`top.${filter(constants.filterLeaderboardType, ['label', constants.LEADERBOARD_FILTER_TYPES.maxRndInGame])[0].tLabel}`)}</div>
          </div>
          <IconPopover text={t((order !== null && orderby2 === constants.TOP_LEADERBOARD_ORDER.maxRndInGame) ? 'popovers.sortDsc' : 'popovers.sortAsc')} placement="bottom" targetId="maxRndInGame-popover" popoverOpen={maxRndInGamePopoverOpen && ((order === constants.ORDER_BY_TYPE.asc && orderby2 === constants.TOP_LEADERBOARD_ORDER.maxRndInGame) || (!order && !orderby2))} />
          <div style={{display: 'inline-block', width: 70, maxWidth: 70, height: 20}} />
        </div>

        <TopPageMyPos screenMode={screenMode} openFilter={openFilter} myLeaderboard={myLeaderboard} renderRating={renderRating} showFields={showFields} />
        <ScrollAreaWrapper
          className="top-table-custom-scroll-body"
          contentClassName="top-scrollarea-body"
          show
          scrollbarId="top-page-scrollbar"
          rightOffset={0}
          topOffset={0}
          bottomOffset={0}
          vertical
        >
          {
            sortLeaderboard && sortLeaderboard.map((pos, index) => (
              (index <= constants.TOP_LIST_LIMIT.limit && (!isFiltered || (isFiltered && activeTypeFilter && pos[activeTypeAction]))) && (
                (pos.key && bannedUsers && !bannedUsers[pos.key]) && (
                  <Fragment key={pos.key}>
                    <MenuRow key={pos.key} nobackground>
                      <div key={pos.key} className="top-table-row">
                        <div className="top-table-row-text-place">
                          <div className="layout-divider" />
                          {(isFiltered || millionairesOpen || size(filterLevels) > 0) ? pos[activeTypeAction] : pos.id}
                        </div>
                        <div className="top-table-row-text-player network-logo">
                          <div className="top-table-row-player-image-frame">
                            <TopPlayeAvatar photo={pos.photo} playerInfo={pos} screenMode={screenMode} />
                          </div>
                          <div className="top-table-row-text">
                            {trimString(pos.name, 16)}
                          </div>
                          {
                            size(friends) > 0 && size(filter(friends, (item) => { return item.uid === pos.key; })) > 0 && (
                              <Media
                                src={filter(friends, (item) => { return item.uid === pos.key; })[0]?.socProvider === 'draugiem' ? draugiemLogoIcon : filter(friends, (item) => { return item.uid === pos.key; })[0]?.socProvider === 'facebook' ? facebookLogoIcon : ''}
                                alt=""
                                className="network-logo-top-icon"
                              />
                            )
                          }
                        </div>
                        <div className="top-table-row-text-level">
                          {renderRating(t, pos.lvl || 1)}
                        </div>
                        <div className="top-table-row-text-rating">
                          <div className="menu-table-row-player-icon-wrapper">
                            <Media src={ratingImage} className="menu-table-row-player-icon" />
                            <div className="menu-table-row-player-icon-text">
                              {pos ? (pos.rating || 1600) : '1600'}
                            </div>
                          </div>
                        </div>
                        <div className="top-table-row-text-balance">
                          <div className="menu-table-row-player-icon-wrapper">
                            <Media src={coinImage} className="menu-table-row-player-icon" />
                            <div className="menu-table-row-player-icon-text">
                              {pos ? (pos.balance || 0) : '0'}
                            </div>
                          </div>
                        </div>
                        <div className="top-table-row-text-points">
                          {pos.points || 0}
                        </div>
                        <div className="top-table-row-text-rounds">
                          {pos.gamesPlayed || 0}
                        </div>
                        <div className="top-table-row-text-friend">
                          {
                            size(filter(friends, item => item.uid === pos.key)) > 0 ? (
                              <div className='top-table-row-player-friend top-table-row-text d-flex align-items-center'
                                style={{
                                  background: 'linear-gradient(180deg, #333333 0%, #121212 100%)',
                                  width: '32px',
                                  height: '32px',
                                  minWidth: '32px',
                                  minHeight: '32px',
                                  borderRadius: '50%',
                                  marginRight: '7px',
                                  // marginTop: '7px',
                                }}
                              >
                                <Media
                                  className='top-table-row-player-friend top-table-row-text'
                                  disabled={isFriendLoading}
                                  src={removeFriendIcon}
                                  onClick={() => handleRemoveFriend(pos.key)}
                                  style={{
                                    width: '32px',
                                    height: '28px',
                                    minWidth: '32px',
                                    minHeight: '28px',
                                    objectFit: 'contain',
                                  }}
                                  onMouseEnter={() => setAddRemoveFriendPopoverOpen(pos.key)}
                                  onMouseLeave={() => setAddRemoveFriendPopoverOpen(null)}
                                  id={`remove-friend-${pos.key}`}
                                />
                                <IconPopover text={t('top.removeFriend')} popoverOpen={!!addRemoveFriendPopoverOpen && addRemoveFriendPopoverOpen === pos.key} targetId={`remove-friend-${pos.key}`} placement="top" />
                              </div>
                            ) : (
                              <>
                                <Media
                                  className={`top-table-row-player-friend top-table-row-text ${(size(filter(friends, item => item.uid === pos.key)) > 0 || isFriendLoading === pos.key) && 'top-table-row-player-friend-disabled'}`}
                                  disabled={isFriendLoading}
                                  src={addFriendIcon}
                                  onClick={() => handleSendAddFriend(pos.key)}
                                  onMouseEnter={() => setAddRemoveFriendPopoverOpen(pos.key)}
                                  onMouseLeave={() => setAddRemoveFriendPopoverOpen(null)}
                                  id={`add-friend-${pos.key}`}
                                  style={{top: 3}}

                                />
                                <IconPopover text={t('top.addAsFriend')} popoverOpen={!!addRemoveFriendPopoverOpen && addRemoveFriendPopoverOpen === pos.key} targetId={`add-friend-${pos.key}`} placement="top" />

                              </>
                            )
                          }
                         
                        </div>
                        <div className="top-table-row-text-block">
                          {(pos[activeTypeAction] !== myLeaderboard?.position) && (
                            pos.key && ignoredUsers && ignoredUsers[pos.key] ? (
                              <>
                                <Media
                                  className="top-table-row-player-block top-table-row-text"
                                  src={screenMode !== constants.SCREEN_MODE.light ? unblockImage : lightUnblockImage}
                                  onClick={() => unblock(pos.key)}
                                  style={screenMode === constants.SCREEN_MODE.light ? {top: 3, scale: '1.68'} : {}}
                                  onMouseEnter={() => setBlockUnblockPopoverOpen(pos.key)}
                                  onMouseLeave={() => setBlockUnblockPopoverOpen(null)}
                                  id={`unblock-${pos.key}`}
                                />
                                <IconPopover text={t('ignoredUsers.unBlock')} popoverOpen={!!blockUnblockPopoverOpen && blockUnblockPopoverOpen === pos.key} targetId={`unblock-${pos.key}`} placement="top" />
                              </>
                            ) : (
                              <>
                                <Media
                                  className="top-table-row-player-block top-table-row-text"
                                  src={screenMode !== constants.SCREEN_MODE.light ? blockImage : lightBlockImage}
                                  onClick={() => block(pos.key, pos.name)}
                                  style={screenMode === constants.SCREEN_MODE.light ? {top: 5, scale: '1.5'} : {}}
                                  onMouseEnter={() => setBlockUnblockPopoverOpen(pos.key)}
                                  onMouseLeave={() => setBlockUnblockPopoverOpen(null)}
                                  id={`block-${pos.key}`}
                                />
                                <IconPopover text={t('ignoredUsers.block')} popoverOpen={!!blockUnblockPopoverOpen && blockUnblockPopoverOpen === pos.key} targetId={`block-${pos.key}`} placement="top" />
                              </>
                            )
                          )}
                        </div>
                        {
                          showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.gWon) !== -1 && (
                            <div className="top-table-row-text-gwon">
                              {pos.gWon || 0}
                            </div>
                          )
                        }
                        {
                          showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.bgWon) !== -1 && (
                            <div className="top-table-row-text-bgwon">
                              {pos.bgWon || 0}
                            </div>
                          )
                        }
                        {
                          showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.sgWon) !== -1 && (
                            <div className="top-table-row-text-sgwon">
                              {pos.sgWon || 0}
                            </div>
                          )
                        }
                        {
                          showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.tblsWon) !== -1 && (
                            <div className="top-table-row-text-tblswon">
                              {pos.tblsWon || 0}
                            </div>
                          )
                        }
                        {
                          showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.achvCnt) !== -1 && (
                            <div className="top-table-row-text-achvcnt">
                              {pos.achvCnt || 0}
                            </div>
                          )
                        }
                        {
                          showFields.indexOf(constants.LEADERBOARD_FILTER_TYPES.maxRndInGame) !== -1 && (
                            <div className="top-table-row-text-maxrndingame">
                              {pos.maxRndInGame || 0}
                            </div>
                          )
                        }
                        <div style={{display: 'inline-block', width: 70, maxWidth: 70, height: 20}} />

                      </div>
                    </MenuRow>
                  </Fragment>
                )
              )
            ))}
        </ScrollAreaWrapper>
      </>
    </div>
  );
});

TopPageList.propTypes = {
  leaderboard: PropTypes.shape(),
  // t: PropTypes.func.isRequired,
  renderRating: PropTypes.func.isRequired,
  isFiltered: PropTypes.bool,
  activeType: PropTypes.string,
  ignoredUsers: PropTypes.shape(),
  bannedUsers: PropTypes.shape(),
  block: PropTypes.func.isRequired,
  unblock: PropTypes.func.isRequired,
  order: PropTypes.string,
  orderby: PropTypes.string,
  showFields: PropTypes.arrayOf(PropTypes.string.isRequired),
  handleOrderBy: PropTypes.func.isRequired,
  isFriendLoading: PropTypes.string,
};

TopPageList.defaultProps = {
  leaderboard: [],
  isFiltered: false,
  activeType: null,
  ignoredUsers: null,
  bannedUsers: null,
  order: null,
  orderby: null,
  //  type: null,
  showFields: [],
  isFriendLoading: null,
};

export default TopPageList;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';

// import Row from 'reactstrap/lib/Row';
import Media from 'reactstrap/lib/Media';
import classNames from 'classnames';
import ReactGA from 'react-ga';

import {
  isMobileSafari,
  isAndroid,
  isIOS,
  isIOS13,
  isMobile,
} from 'react-device-detect';

import SoundButton from './SoundButton';
// import LanguageSelect from './LanguageSelect';

import openFullscreen from '../../../../../images/redesign/top-row/minimized.svg';
import closeFullscreen from '../../../../../images/redesign/top-row/maximized.svg';

import lightOpenFullscreen from '../../../../../images/redesign/light-mode/icons/maximized.png';
import lightCloseFullscreen from '../../../../../images/redesign/light-mode/icons/minimized.svg';

import defaultFont from '../../../../../images/redesign/top-row/defaultFont.webp';
import defaultFontLight from '../../../../../images/redesign/light-mode/top-row/defaultFont.webp';
import largeFont from '../../../../../images/redesign/top-row/largeFont.webp';
import largeFontLight from '../../../../../images/redesign/light-mode/top-row/largeFont.webp';
import xLargeFont from '../../../../../images/redesign/top-row/xLargeFont.webp';
import xLargeFontLight from '../../../../../images/redesign/light-mode/top-row/xLargeFont.webp';
import maxFont from '../../../../../images/redesign/top-row/maxFont.png';
import toggleTopMenuSettingIcon from '../../../../../images/redesign/common/toggle-top-settng-menu.svg';
import leaveIcon from '../../../../../images/redesign/common/leave-icon.svg';

import DarkThemeModeIcon from '../../../../../images/redesign/mobile/icons/dark-theme-mode-icon.svg';
import DarkSelectedThemeIcon from '../../../../../images/redesign/mobile/icons/dark-theme-selected-mode-icon.svg';
import NormalSelectedThemeModeIcon from '../../../../../images/redesign/mobile/icons/normal-theme-selected-mode-icon.svg';
import LightSelectedThemeModeIcon from '../../../../../images/redesign/mobile/icons/light-theme-selected-mode-icon.svg';
import NormalThemeModeIcon from '../../../../../images/redesign/mobile/icons/normal-theme-mode-icon.svg';
import debuggerImg from '../../../../../images/debugIco.svg';

import * as constants from '../../../../../constants/constants';
import CustomModal from '../Components/Modal';
import {
  changeNotificationSettings,
} from '../../../../../actions/userSettings';
import LightThemeModeIcon from '../../../../../images/redesign/mobile/icons/light-theme-mode-icon.svg';

import {
  handleSetTheme,
} from '../../../../../actions/member';

import {
  setUserLoginStatistics,
} from '../../../../../actions/member';
import lvFlag from '../../../../../images/redesign/flags/lv.svg';
import ruFlag from '../../../../../images/redesign/flags/ru.svg';
import enFlag from '../../../../../images/redesign/flags/en.svg';
// import gearIcon from '../../../../../images/icons/gear.svg';
import IconPopover from '../Components/IconPopover';
import { Button, Popover } from 'reactstrap';
import { capitalizeFirstLetter } from '../../../../../utils/stringUtils';
// import { filter } from 'lodash';
import { isMobileDevice } from '../../../../../utils/deviceUtils';


const TopSettingsSection = React.memo(({
  designPlatform, role, toggleHolidayDebuggerModal, newDesign, maxGames, logout, t, config, isFullscreenEnabled, toggleFullscreen, showUserSettingsModal, uid, screenMode, notificationSettings, toggleNewDesign, changeNotification, fontMode, toggleFont, roomStatus, i18n, settingVisible, activeTab, returnToAdmin, adminReturnToken,
}) => {
  // const newDesign = useSelector(state => state.member.newDesign || null);
  console.log('TopSettingsSection: ', screenMode);
  const [isOpenedTopSettingModal, SetIsOpenedTopSettingModal] = useState(false);
  const [darkTheme, setDarkTheme] = useState(screenMode || constants.SCREEN_MODE.normal);
  const dispatch = useDispatch();
  const usersCount = useSelector(state => state.users.usersCount);
  const roomsCount = useSelector(state => state.users.roomsCount);

  const [mainSettingsPopoverOpen, setMainSettingsPopoverOpen] = useState(false);
  const [fontPopoverOpen, setFontPopoverOpen] = useState(false);
  const [fontPopoverMobileOpen, setFontPopoverMobileOpen] = useState(false);
  const [fullscreenPopoverOpen, setFullscreenPopoverOpen] = useState(false);
  const [fullscreenPopoverMobileOpen, setFullscreenPopoverMobileOpen] = useState(false);
  const [closeFullscreenPopoverOpen, setCloseFullscreenPopoverOpen] = useState(false);

  const [leavePopoverOpen, setLeavePopoverOpen] = useState(false);

  const [lightThemePopoverOpen, setLightThemePopoverOpen] = useState(false);
  const [normalThemePopoverOpen, setNormalThemePopoverOpen] = useState(false);
  const [darkThemePopoverOpen, setDarkThemePopoverOpen] = useState(false);

  const themePopoverSetters = { light: setLightThemePopoverOpen, normal: setNormalThemePopoverOpen, dark: setDarkThemePopoverOpen };
  const themePopoverVariables = { light: lightThemePopoverOpen, normal: normalThemePopoverOpen, dark: darkThemePopoverOpen };

  useEffect(() => {
    if (notificationSettings && notificationSettings.newDesign !== newDesign) {
      changeNotification(constants.USER_SETTINGS.new_design, uid);
    }
  }, [newDesign, notificationSettings])

  useEffect(() => {
    setDarkTheme(screenMode);
  }, [screenMode])

  const toggleNewDesignFunc = () => {

    if (newDesign) {
      console.log("switchDesign-new")
      try {
        localStorage.removeItem('newDesign'); //x
      } catch (err) {
        console.log(err);
      }
      toggleNewDesign(false);

      ReactGA.event({
        category: 'Menu',
        action: 'Old Design',
      });
    } else {
      console.log("switchDesign-old")
      try {
        localStorage.setItem('newDesign', true); //x
      } catch (err) {
        console.log(err);
      }
      toggleNewDesign(true);

      ReactGA.event({
        category: 'Menu',
        action: 'New Design',
      });
    }
    // changeNotification(constants.USER_SETTINGS.new_design, uid);
  };

  const toggleLanguage = (lang) => {
    i18n.changeLanguage(lang);

    ReactGA.event({
      category: 'Language',
      action: 'Change Language',
    });

    try {
      if (window && window.localStorage && window.localStorage.setItem) { //x
        localStorage.setItem('language', lang); //x
      }
    } catch (err) {
      console.log(err);
    }

    dispatch(setUserLoginStatistics(false, null, lang, null, null, null));
  }

  const toggleFontFunc = () => {
    console.log('toggleFontFunc');
    ReactGA.event({
      category: 'Menu',
      action: 'Change Font Size',
    });
    if (fontMode === constants.FONT_MODE.normal) {
      toggleFont(constants.FONT_MODE.large);
    } else if (fontMode === constants.FONT_MODE.large) {
      toggleFont(constants.FONT_MODE.xLarge);
    } else {
      toggleFont(constants.FONT_MODE.normal);
    }
  }

  const getFontMode = () => {
    console.log("screenMode font", fontMode, screenMode)
    if (screenMode === constants.SCREEN_MODE.light) {
      if (fontMode === constants.FONT_MODE.xLarge) {
        return xLargeFontLight;
      } else if (fontMode === constants.FONT_MODE.large) {
        return largeFontLight;
      } else {
        return defaultFontLight;
      }
    } else {
      if (fontMode === constants.FONT_MODE.xLarge) {
        return xLargeFont;
      } else if (fontMode === constants.FONT_MODE.large) {
        return largeFont;
      } else {
        return defaultFont;
      }
    }
  }
  console.log("screenMode font", fontMode, screenMode)

  const handleToggleTheme = React.useCallback((theme) => {
    theme === constants.SCREEN_MODE.dark && setDarkTheme(constants.SCREEN_MODE.dark);
    theme === constants.SCREEN_MODE.light && setDarkTheme(constants.SCREEN_MODE.light);
    theme === constants.SCREEN_MODE.normal && setDarkTheme(constants.SCREEN_MODE.normal);
    try {
      localStorage.setItem('screenMode', JSON.stringify(theme)); //x
    } catch (err) {
      console.log(err);
    }
    dispatch(handleSetTheme(theme));
  });

  const options = constants.SCREEN_MODE_OPTIONS;
  const selectedOption = darkTheme;

  return (
    <>
      <div className={`top-setting-menu-item-container top-setting-menu-item-container-holiday top-setting-menu-item-container-${screenMode} ${(roomStatus && !config.isInAppFrame()) && 'd-none'}`}>
        {(role === 'admin' || role === 'tester') && !roomStatus && (
          <Media alt="Holiday Debugger" className='debugger-holiday debugger-holiday-test debugger-holiday-new' src={debuggerImg} width={32} he={32} onClick={toggleHolidayDebuggerModal} />
        )}

        {(adminReturnToken) && (
          <Button
            className="btn layout-default-button debugger-holiday"
            onClick={() => returnToAdmin()}
            style={{ zIndex: 999, position: 'fixed', left: 205, top: 13 }}
          >
            Return To Admin
          </Button>
        )}

        {((isFullscreenEnabled || config.isInFacebookAppFrame()) && config.isInAppFrame()) &&
          <>
            <Media
              className="fullscreen-button-image fullscreen-button-image-iframe landscape-button"
              style={{ position: 'absolute', top: 3, right: 51 }} src={screenMode === constants.SCREEN_MODE.light ? lightCloseFullscreen : closeFullscreen}
              onClick={toggleFullscreen}
              onMouseEnter={() => setCloseFullscreenPopoverOpen(true)}
              onMouseLeave={() => setCloseFullscreenPopoverOpen(false)}
              id="fullscreen-button-popover-3"
            />
            <IconPopover text={t('popovers.fullscreenSettingOn')} placement="bottom" targetId="fullscreen-button-popover-3" popoverOpen={closeFullscreenPopoverOpen} />
          </>
        }
        {settingVisible !== 'zole' && (
          <>
            <Media
              src={toggleTopMenuSettingIcon}
              className={`${config.isInAppFrame() ? ((roomStatus || isFullscreenEnabled || config.isInFacebookAppFrame()) ? 'top-setting-menu-open-button-iframe' : 'd-none') : 'top-setting-menu-open-button'}`}
              onClick={() => SetIsOpenedTopSettingModal(true)}
              onMouseEnter={() => setMainSettingsPopoverOpen(true)}
              onMouseLeave={() => setMainSettingsPopoverOpen(false)}
              id="main-settings-button-popover"
              style={{ cursor: 'pointer' }}
            />
            <IconPopover text={t('popovers.mainSettings')} placement="bottom" targetId="main-settings-button-popover" popoverOpen={mainSettingsPopoverOpen} />
          </>
        )}

        {!config.isInAppFrame() &&
          <div className={`top-setting-menu-leave-wrapper ${roomStatus && 'top-setting-menu-leave-wrapper-ingame'}`} onClick={logout}>
            <Media src={leaveIcon} className="top-setting-menu-leave-button" id="top-setting-leave-popover" onMouseEnter={() => setLeavePopoverOpen(true)} onMouseLeave={() => setLeavePopoverOpen(false)}/>
            <span className="top-setting-menu-leave-text">{t('common.exit')}</span>
            <IconPopover text={t('common.exit')} placement="left" targetId="top-setting-leave-popover" popoverOpen={leavePopoverOpen} />

          </div>
        }
          {!roomStatus && 
          <div className="landscape-only-area">
            {(config.isInAppFrame() || !isMobileDevice()) && (
              <>
                <Media src={getFontMode()} className="landscape-button" alt={t('menu.settings')} onClick={toggleFontFunc} onMouseEnter={() => setFontPopoverMobileOpen(true)} onMouseLeave={() => setFontPopoverMobileOpen(false)} id="font-setting-popover-1" />
                <IconPopover text={t('popovers.fontSetting')} placement="left" targetId="font-setting-popover-1" popoverOpen={fontPopoverMobileOpen} />
              </>
            )}
            {!isMobileSafari && (
              <div onMouseEnter={() => setFullscreenPopoverMobileOpen(true)} onMouseLeave={() => setFullscreenPopoverMobileOpen(false)} id="fullscreen-button-popover-1">
                {isFullscreenEnabled ? (
                  <Media className="fullscreen-button-image landscape-button" src={screenMode === constants.SCREEN_MODE.light ? lightCloseFullscreen : closeFullscreen} onClick={toggleFullscreen} />
                ) : (
                  <Media className="fullscreen-button-image landscape-button" src={screenMode === constants.SCREEN_MODE.light ? lightOpenFullscreen : openFullscreen} onClick={toggleFullscreen} />
                )}
                <IconPopover text={t(isFullscreenEnabled ? 'popovers.fullscreenSettingOn' : 'popovers.fullscreenSettingOff')} placement="left" targetId="fullscreen-button-popover-1" popoverOpen={fullscreenPopoverMobileOpen} />
              </div>
            )}
            {
              designPlatform?.old && (
                <div className={`old-design-button old-design-button-${screenMode}`} onClick={toggleNewDesignFunc}>
                  {t('home.oldDesign')}
                </div>
              )
            }
          </div>
        }
        {/* <div className="mobile-online-stats"> */}
        <div className={`mobile-online-stats-wrapper mobile-online-stats-wrapper-font-${fontMode} mobile-online-stats-wrapper-screen-${screenMode} ${roomStatus && 'mobile-online-stats-wrapper-ingame'}`}>
          <span className="online-stats-text">{`${t('menu.onlineCount')}: `}</span>
          <span className="online-stats-count">{`${usersCount} ${t('menu.players')}`}</span>
          {/* <div className="online-stats-divider" /> */}
          <span className="online-stats-count">{`${roomsCount} ${t('menu.rooms')}`}</span>
        </div>
        {/* </div> */}
      </div>


      <CustomModal
        isOpen={isOpenedTopSettingModal}
        addClassName="top-setting-modal"
        // inlineClassName="no-backdrop"
        toggle={() => SetIsOpenedTopSettingModal(false)}
        body={
          <div className={classNames("top-settings", {
            'top-settings-tournament': maxGames,
          })} >
            <div className="top-settings-wrapper">
              <div className='theme-control-tools'>
                <div xs={4} className="migrate-to-old-design-section" onClick={() => {toggleNewDesignFunc(newDesign ? true : false); }}>
                  {
                    newDesign ? (
                      t('common:home.oldDesign')
                    ) : (
                      t('common:home.newDesign')
                    )
                  }
                </div>
                <div xs={4} className='theme-control-tool-section'>
                  <div className="switch-select">
                    {(options && options.length) ? options.map((option, index) => (
                      <>
                        <div
                          id={`${option.value}-theme-button-popover`}
                          onMouseEnter={() => themePopoverSetters[option.value](true)}
                          onMouseLeave={() => themePopoverSetters[option.value](false)}
                          onClick={(() => handleToggleTheme(option.value))}
                          key={index}
                          className={`switch-select-option ${index === 0 ? 'switch-select-option-first' : ''} ${(index === options.length - 1) ? 'switch-select-option-last' : ''} ${selectedOption === option.value ? 'switch-select-option-active' : ''}`}
                        >
                          <div className="switch-select-option-img">
                            {
                              (option.name === 'dark') ? <Media src={(selectedOption === option.value) ? DarkSelectedThemeIcon : DarkThemeModeIcon} className='dark-image' alt='dark-image' /> : (option.name === 'normal') ? <Media src={(selectedOption === option.value) ? NormalSelectedThemeModeIcon : NormalThemeModeIcon} className='normal-image' alt='normal-image' /> : <Media src={(selectedOption === option.value) ? LightSelectedThemeModeIcon : LightThemeModeIcon} className='light-image' alt='light-image' />
                            }
                          </div>
                        </div>
                        <IconPopover text={t(`popovers.${option.value}ThemeSetting`)} placement="bottom" targetId={`${option.value}-theme-button-popover`} popoverOpen={themePopoverVariables[option.value]} />
                      </>
                    )) : (null)}
                  </div>
                </div>
                {/* <div xs={4} onClick={logout} className="logout-section">
                  {roomStatus ? (
                    t('common:menu.exitRoom')
                  ) : (
                    t('common:menu.logout')
                  )}
                </div> */}
              </div>
              <div className='website-setting-tools'>
                <div className='language-select'>
                  <div className={classNames('language-label', { 'language-label-active': i18n.language === constants.sendLanguage.lv })}><Media onClick={() => toggleLanguage('lv')} src={lvFlag} className="language-image" /></div>
                  <div className={classNames('language-label', { 'language-label-active': i18n.language === constants.sendLanguage.en })}><Media onClick={() => toggleLanguage('en')} src={enFlag} className="language-image" /></div>
                  <div className={classNames('language-label', { 'language-label-active': i18n.language === constants.sendLanguage.ru })}><Media onClick={() => toggleLanguage('ru')} src={ruFlag} className="language-image" /></div>
                </div>
                <div className='extra-tools'>
                  {/* <div className='setting-section'>
                    <Media className="settings-button-image" src={gearIcon} alt={t('menu.settings')} onClick={showUserSettingsModal} />
                  </div> */}

                  {!isMobileSafari ? (
                    <>
                      {(!config.isInAppFrame() || roomStatus) &&
                        <div className="fullscreen-section" onMouseEnter={() => setFullscreenPopoverOpen(true)} onMouseLeave={() => setFullscreenPopoverOpen(false)} id="fullscreen-button-popover-2">
                          {isFullscreenEnabled ? (
                            <Media className="fullscreen-button-image" src={screenMode === constants.SCREEN_MODE.light ? lightCloseFullscreen : closeFullscreen} onClick={() => { SetIsOpenedTopSettingModal(false); toggleFullscreen(); }} />
                          ) : (
                            <Media className="fullscreen-button-image" src={screenMode === constants.SCREEN_MODE.light ? lightOpenFullscreen : openFullscreen} onClick={() => { SetIsOpenedTopSettingModal(false); toggleFullscreen(); }} />
                          )}
                          <IconPopover text={t(isFullscreenEnabled ? 'popovers.fullscreenSettingOn' : 'popovers.fullscreenSettingOff')} placement="bottom" targetId="fullscreen-button-popover-2" popoverOpen={fullscreenPopoverOpen} />
                        </div>
                      }
                    </>
                  ) : (null)}
                  <div className='font-setting' onMouseEnter={() => setFontPopoverOpen(true)} onMouseLeave={() => setFontPopoverOpen(false)} id="font-setting-popover-2">
                    <Media src={getFontMode()} className="font-button-image" alt={t('menu.settings')} onClick={toggleFontFunc} />
                    <IconPopover text={t('popovers.fontSetting')} placement="bottom" targetId="font-setting-popover-2" popoverOpen={fontPopoverOpen} setPopoverOpen={setFontPopoverOpen} />
                  </div>
                  <div className='sound-setting'>
                    <SoundButton uid={uid} screenMode={screenMode} t={t} />
                  </div>

                </div>

              </div>

              {!roomStatus &&
                <div className="mobile-online-stats">
                  <div className="mobile-online-stats-wrapper">
                    <span className="online-stats-text">{`${t('menu.onlineCount')}: `}</span>
                    <span className="online-stats-count">{`${usersCount} ${t('menu.players')}`}</span>
                    <div className="online-stats-divider" />
                    <span className="online-stats-count">{`${roomsCount} ${t('menu.rooms')}`}</span>
                  </div>
                </div>
              }

            </div>
          </div>
        }
      />
    </>
  );
});

TopSettingsSection.propTypes = {
  isFullscreenEnabled: PropTypes.bool,
  config: PropTypes.shape(),
  toggleFullscreen: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  fontMode: PropTypes.string,
  toggleFont: PropTypes.func.isRequired,
  maxGames: PropTypes.number,
  settingVisible: PropTypes.string,
  toggleHolidayDebuggerModal: PropTypes.func.isRequired,
  role: PropTypes.string,
  designPlatform: PropTypes.shape({}),
};

TopSettingsSection.defaultProps = {
  isFullscreenEnabled: false,
  config: {},
  fontMode: constants.FONT_MODE.normal,
  maxGames: null,
  settingVisible: '',
  role: null,
  designPlatform: {},
};

const mapStateToProps = state => ({
  notificationSettings: state.userSettings[state.member.uid] ? (state.userSettings[state.member.uid].notificationSettings) : (state.userSettings.default ? state.userSettings.default.notificationSettings : {}),
  uid: state.member.uid,
  newDesign: state.member.newDesign,
  maxGames: state.game.globalParams.tournamentRoom ? state.game.globalParams.maxGames : null,
  role: state.member.role || null,
});

const mapDispatchToProps = {
  changeNotification: changeNotificationSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['common'])(TopSettingsSection));

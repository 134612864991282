import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import ScrollArea from 'react-scrollbar';
import ScrollAreaButtons from './ScrollAreaButtons';

class ScrollAreaWrapper extends PureComponent {
  static propTypes = {
    scrollRef: PropTypes.shape(),
    rightOffset: PropTypes.number,
    speed: PropTypes.number,
    horizontal: PropTypes.bool,
    vertical: PropTypes.bool,
    disableAutoHeight: PropTypes.bool,
    scrollButtonsEnabled: PropTypes.bool,
  }

  static defaultProps = {
    scrollRef: {},
    rightOffset: 0,
    speed: 30,
    horizontal: false,
    vertical: true,
    disableAutoHeight: false,
    scrollButtonsEnabled: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      scrollPos: 0,
    };
  }

  componentDidMount() {
  //  console.log('componentDidMount');
  }

  componentDidUpdate() {
  //  console.log('componentDidUpdate');
  }

  updateScrollPos = (val) => {
    if (val.topPosition || val.topPosition === 0) {
      this.setState({ scrollPos: val.topPosition || 0 });
    }
  //  this.setState({ scrollPos: val.topPosition || 0 });
  }

  scroll = (newVal) => {
    this.tableScrollbar.scrollYTo(newVal);
  }

  render = () => {
    const {
      rightOffset, show, topOffset, bottomOffset, bgColor, className, contentClassName, horizontal, vertical, disableAutoHeight, scrollButtonsEnabled
    } = this.props;
    const { scrollPos } = this.state;

    //  console.log('ScrollAreaButtons render', { show });
    return (
      <Fragment>
        <ScrollAreaButtons
          scrollPos={scrollPos}
        //  show={show}
          show={
            this.tableScrollbar
            && this.tableScrollbar.state
            && this.tableScrollbar.state.realHeight
              > this.tableScrollbar.state.containerHeight
              ? true
              : null
          }
          scroll={this.scroll}
          speed={30}
          rightOffset={rightOffset || 0}
          topOffset={topOffset || 0}
          bottomOffset={bottomOffset || 0}
          enabled={scrollButtonsEnabled}
        />
        <ScrollArea
          speed={0.55}
        //  className={className}
          className={`${className} ${horizontal ? 'scrollarea-fit-content' : null}`}
          contentClassName={`${!disableAutoHeight ? 'h-auto' : 'h-100'} ${contentClassName} ${horizontal ? 'scrollarea-content-fit-content' : null}`}
          smoothScrolling
          stopScrollPropagation
          vertical={vertical}
          verticalContainerStyle={{
            background: 'transparent',
            opacity: 1,
            width: 11,
          }}
          verticalScrollbarStyle={{
            background: '#fff',
            borderRadius: 1,
            width: 10,
            minHeight: 10,
            minScrollSize: 25,
          }}
          horizontal={horizontal}
          horizontalContainerStyle={{
            background: 'transparent',
            opacity: 1,
            height: 11,
          }}
          horizontalScrollbarStyle={{
            background: '#fff',
            borderRadius: 1,
            height: 10,
            minWidth: 10,
            minScrollSize: 25,
          }}
          onScroll={this.updateScrollPos}
          ref={(el) => {
            this.tableScrollbar = el;
          }}
        >
          {this.props.children}
        </ScrollArea>
      </Fragment>
    );
  }
}

export default ScrollAreaWrapper;

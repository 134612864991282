import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import isEqual from 'react-fast-compare';
import classNames from 'classnames';

// import Row from 'reactstrap/lib/Row';
// import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';
import Popover from 'reactstrap/lib/Popover';
import PopoverBody from 'reactstrap/lib/PopoverBody';

import pro from '../../../../../../images/redesign/common/PRO.svg';
import regularSpeed from '../../../../../../images/redesign/common/karavs.svg';
import speed from '../../../../../../images/redesign/common/Room types speed.svg';
import lightning from '../../../../../../images/redesign/common/Room types lightning.svg';
import unlimited from '../../../../../../images/redesign/common/Room types unlimited.svg';
import defaultImage from '../../../../../../images/redesign/mobile/icons/default_avatar.svg';
import lightDefaultImage from '../../../../../../images/redesign/mobile/light-mode/icons/default_avatar.svg';
import normalDefaultImage from '../../../../../../images/redesign/mobile/normal-mode/icons/default_avatar.svg';

import waitingImage from '../../../../../../images/redesign/mobile/icons/waiting_avatar.svg';
import lightWaitingImage from '../../../../../../images/redesign/mobile/light-mode/icons/waiting_avatar.svg';
import normalWaitingImage from '../../../../../../images/redesign/mobile/normal-mode/icons/waiting_avatar.svg';

import minGamesBell from '../../../../../../images/redesign/rooms-table/Call bell.png';

import MenuRow from '../../../Components/Components/MenuRow';

import joinRoomIcon from '../../../../../../images/redesign/icons/join-room-icon.svg';
import PrivateRoomButton from '../../../../../../images/redesign/icons/private-room-icon.svg';
import leaveRoomIcon from '../../../../../../images/redesign/icons/leave-room-icon.svg';

import joinRoomLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/join-room-icon.svg';
import PrivateRoomLightButton from '../../../../../../images/redesign/mobile/light-mode/icons/private-room-icon.svg';
import leaveRoomLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/leave-room-icon.svg';

// import lockImg from '../../../../../images/redesign/rooms-table/lock.svg';
import * as constants from '../../../../../../constants/constants';
import { isRegularRoom } from '../../../../../../utils/roomUtils';
import IconPopover from '../../../Components/Components/IconPopover';
import { trimString } from '../../../../../../utils/stringUtils';

const RoomsTableJoinedRender = React.memo(({
  tooltipId, joinedRoom, uid, leaveRoom, activeTournament, popoverOpen, onHover, onHoverLeave, joinRoomClickedFunc, joinPrivateRoomConfirm, joinedRoomPassword, screenMode, // joinRoomClicked
}) => {
  const { t } = useTranslation('common');
  const [leaveRoomPopoverOpen, setLeaveRoomPopoverOpen] = useState(false);
  console.log('RoomsTableJoinedRender', { joinedRoom, joinedRoomPassword });

  if (!joinedRoom && leaveRoomPopoverOpen) {
    console.log('reset leaveRoomPopoverOpen');
    setLeaveRoomPopoverOpen(false);
  }

  // let digitsArr = [];

  // if (joinedRoomPassword) {
  //  digitsArr = joinedRoomPassword.toString().split('').map(Number);
  // }
  console.log(joinedRoom, 'joinedRoom');

  if (joinedRoom && joinedRoom.key && joinedRoom.globalParams && joinedRoom.playersList
        && ((joinedRoom.playersList.player1 && joinedRoom.playersList.player1.uid === uid)
            || (joinedRoom.playersList.player2 && joinedRoom.playersList.player2.uid === uid)
            || (joinedRoom.playersList.player3 && joinedRoom.playersList.player3.uid === uid)
            || (joinedRoom.playersList.player4 && joinedRoom.playersList.player4.uid === uid))) {
    return (
      <MenuRow key={joinedRoom.key} contentClassName="rooms-table-joined-row">
        <div key={joinedRoom.key} data-roomkey={joinedRoom.key} style={joinedRoom.removal ? { opacity: 0 } : { opacity: 1 }} className={`rooms-table-row rooms-table-row-${screenMode}`}>
          <div className="position-relative">
            <>
              <div className="rooms-table-row-speed">
                {isRegularRoom(joinedRoom) && (
                <Media className="rooms-table-row-speed-img-regular" src={regularSpeed} alt="" />
                )}
                {joinedRoom.globalParams.fastGame && (
                <Media className="rooms-table-row-speed-img" src={speed} alt="Ātrā" />
                )}
                {joinedRoom.globalParams.lightningGame && (
                <Media className="rooms-table-row-speed-img" src={lightning} alt="Zibens" />
                )}
                {joinedRoom.globalParams.unlimitedGame && ( // TODO 'unlimitedGame' path might change
                <Media className="rooms-table-row-speed-img" src={unlimited} alt="Bez limita" />
                )}
              </div>
              <div className="rooms-table-row-type-wrapper">
                <div className="rooms-table-row-bet">
                  {joinedRoom.globalParams.bet}
                </div>
                <div className="rooms-table-row-type rooms-table-row-type-joined">
                  {`${joinedRoom.globalParams.gameType === 'P' ? t('common.parastas') : t('common.galds')}${joinedRoom.globalParams.smallGame ? `, ${t('common:newGame.smallGame')}` : ''}`}
                </div>
              </div>

              <div className="rooms-table-row-minGames">
                {joinedRoom.globalParams.minGames > 1 ? (
                  <div className="rooms-table-row-minGames-wrapper">
                    <Media className="rooms-table-row-minGames-bell-img" src={minGamesBell} alt="Ātrā" />
                    <div className="rooms-table-row-minGames-bell-count">{joinedRoom.globalParams.minGames}</div>
                  </div>
                ) : (null)}
              </div>

              <div className="rooms-table-row-pro">
                {joinedRoom.globalParams.proGame ? (
                  <div className="rooms-table-row-pro-wrapper">
                    <Media className="rooms-table-row-pro-img" src={pro} alt="Ātrā" />
                  </div>
                ) : (null)}
              </div>
            </>
          </div>
          <div className="players-row-wrapper">
            <div>
              {(joinedRoom.playersList && joinedRoom.playersList.player1) ? (
                <>
                  <div className="rooms-table-row-player">
                    <div className={`rooms-table-row-player-image-frame ${joinedRoom.playersList.player1.photo ? '' : 'rooms-table-row-player-image-frame-default'}`}>
                      <Media className={`rooms-table-row-player-image ${joinedRoom.playersList.player1.photo ? '' : 'rooms-table-row-player-image-default'}`} src={joinedRoom.playersList.player1.photo || (screenMode === constants.SCREEN_MODE.light ? lightDefaultImage : (screenMode === constants.SCREEN_MODE.normal ? normalDefaultImage : defaultImage))} />
                    </div>
                    <div className="mx-2 rooms-table-row-player-name">{trimString(joinedRoom.playersList.player1.shortName, 10)}</div>
                  </div>
                </>
              ) : (
                <div className="rooms-table-row-player">
                  <div className="rooms-table-row-player-image-frame rooms-table-row-player-image-frame-default">
                    <Media className="rooms-table-row-player-image rooms-table-row-player-image-default" src={screenMode === constants.SCREEN_MODE.light ? lightWaitingImage : (screenMode === constants.SCREEN_MODE.normal ? normalWaitingImage : waitingImage)} />
                  </div>
                </div>
              )}
            </div>
            <div>
              {(joinedRoom.playersList && joinedRoom.playersList.player2) ? (
                <>
                  <div className="rooms-table-row-player">
                    <div className={`rooms-table-row-player-image-frame ${joinedRoom.playersList.player2.photo ? '' : 'rooms-table-row-player-image-frame-default'}`}>
                      <Media className={`rooms-table-row-player-image ${joinedRoom.playersList.player2.photo ? '' : 'rooms-table-row-player-image-default'}`} src={joinedRoom.playersList.player2.photo || (screenMode === constants.SCREEN_MODE.light ? lightDefaultImage : (screenMode === constants.SCREEN_MODE.normal ? normalDefaultImage : defaultImage))} />
                    </div>
                    <div className="mx-2 rooms-table-row-player-name">{trimString(joinedRoom.playersList.player2.shortName, 10)}</div>
                  </div>
                </>
              ) : (
                <div className="rooms-table-row-player">
                  <div className="rooms-table-row-player-image-frame rooms-table-row-player-image-frame-default">
                    <Media className="rooms-table-row-player-image rooms-table-row-player-image-default" src={screenMode === constants.SCREEN_MODE.light ? lightWaitingImage : (screenMode === constants.SCREEN_MODE.normal ? normalWaitingImage : waitingImage)} />
                  </div>
                </div>
              )}
            </div>
            <div>
              {(joinedRoom.playersList && joinedRoom.playersList.player3) ? (
                <>
                  <div className="rooms-table-row-player">
                    <div className={`rooms-table-row-player-image-frame ${joinedRoom.playersList.player3.photo ? '' : 'rooms-table-row-player-image-frame-default'}`}>
                      <Media className={`rooms-table-row-player-image ${joinedRoom.playersList.player3.photo ? '' : 'rooms-table-row-player-image-default'}`} src={joinedRoom.playersList.player3.photo || (screenMode === constants.SCREEN_MODE.light ? lightDefaultImage : (screenMode === constants.SCREEN_MODE.normal ? normalDefaultImage : defaultImage))} />
                    </div>
                    <div className="mx-2 rooms-table-row-player-name">{trimString(joinedRoom.playersList.player3.shortName, 10)}</div>
                  </div>
                </>
              ) : (
                <div className="rooms-table-row-player">
                  <div className="rooms-table-row-player-image-frame rooms-table-row-player-image-frame-default">
                    <Media className="rooms-table-row-player-image rooms-table-row-player-image-default" src={screenMode === constants.SCREEN_MODE.light ? lightWaitingImage : (screenMode === constants.SCREEN_MODE.normal ? normalWaitingImage : waitingImage)} />
                  </div>
                </div>
              )}
            </div>
            <div>
              {(joinedRoom.globalParams && joinedRoom.globalParams.fourPRoom) ? (
                <>
                  {(joinedRoom.playersList && joinedRoom.playersList.player4) ? (
                    <>
                      <div className="rooms-table-row-player">
                        <div className={`rooms-table-row-player-image-frame ${joinedRoom.playersList.player4.photo ? '' : 'rooms-table-row-player-image-frame-default'}`}>
                          <Media className={`rooms-table-row-player-image ${joinedRoom.playersList.player4.photo ? '' : 'rooms-table-row-player-image-default'}`} src={joinedRoom.playersList.player4.photo || (screenMode === constants.SCREEN_MODE.light ? lightDefaultImage : (screenMode === constants.SCREEN_MODE.normal ? normalDefaultImage : defaultImage))} />
                        </div>
                        <div className="mx-2 rooms-table-row-player-name">{trimString(joinedRoom.playersList.player4.shortName, 10)}</div>
                      </div>
                    </>
                  ) : (
                    <div className="rooms-table-row-player">
                      <div className="rooms-table-row-player-image-frame rooms-table-row-player-image-frame-default">
                        <Media className="rooms-table-row-player-image rooms-table-row-player-image-default" src={screenMode === constants.SCREEN_MODE.light ? lightWaitingImage : (screenMode === constants.SCREEN_MODE.normal ? normalWaitingImage : waitingImage)} />
                      </div>
                    </div>
                  )}
                </>
              ) : (null)}
            </div>
          </div>
          <div className="rooms-table-row-join-button-wrapper">
            {(joinedRoom.playersList.player1 && joinedRoom.playersList.player1.uid === uid)
              || (joinedRoom.playersList.player2 && joinedRoom.playersList.player2.uid === uid)
              || (joinedRoom.playersList.player3 && joinedRoom.playersList.player3.uid === uid)
              || (joinedRoom.playersList.player4 && joinedRoom.playersList.player4.uid === uid)
              ? (
                <Fragment>
                  <div style={{ display: 'inline-block', textAlign: 'center' }}>
                    {joinedRoomPassword && (
                      <>
                        <div className="rooms-table-row-password-text rooms-table-row-password-text-joined">
                          {t('roomsTable.roomCode')}
                        </div>
                        {joinedRoomPassword.toString().split('').map(digit => (
                          <div className="rooms-table-row-password-digit">
                            {digit}
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                  {joinedRoom.filled ? (
                    <Link
                      to={`/zole/${joinedRoom.key}`}
                      className={classNames(`room-return-button layout-float-right layout-default-button-${screenMode}`, {
                        'private-room-button': joinedRoom && joinedRoom.globalParams.privateRoom,
                      })}
                    >
                      <Media src={screenMode === constants.SCREEN_MODE.light ? joinRoomLightIcon : joinRoomIcon} alt="enter-room-button" className="" />
                      <Button
                        disabled
                        color="link"
                        className="display-none"
                        id="leave-room-button"
                      />
                    </Link>
                  ) : (
                    <>
                      <Button
                        disabled={joinedRoom.filled}
                        color="link"
                        className={classNames(`room-leave-button layout-float-right layout-default-button-${screenMode}`, {
                          'private-room-button': joinedRoom && joinedRoom.globalParams.privateRoom,
                        })}
                        onClick={() => leaveRoom(joinedRoom.key)}
                        id="leave-room-button"
                        onMouseEnter={() => setLeaveRoomPopoverOpen(true)}
                        onMouseLeave={() => setLeaveRoomPopoverOpen(false)}
                        name={joinedRoom.key}
                      >
                        {(joinedRoom && joinedRoom.globalParams.privateRoom) ? <Media src={screenMode === constants.SCREEN_MODE.light ? PrivateRoomLightButton : PrivateRoomButton} alt="private-room-button" className="" /> : <></>}
                        <Media src={screenMode === constants.SCREEN_MODE.light ? leaveRoomLightIcon : leaveRoomIcon} alt="leave-room-button" className="" />

                      </Button>
                        <IconPopover text={t('common:popovers.leaveRoom')} popoverOpen={leaveRoomPopoverOpen && !(!joinedRoom || joinedRoom.filled)} targetId="leave-room-button" joinedRoom={joinedRoom} />
                    </>
                  )}
                </Fragment>
              ) : (
                <Fragment>
                  {joinedRoom.globalParams.privateRoom ? (
                    <Button
                      color="link"
                      className={classNames('room-join-button menu-table-row-default-button', {
                        // 'disabled': (activeTournament || joinRoomClicked || joinedRoom),
                        'private-room-button': joinedRoom && joinedRoom.globalParams.privateRoom,
                      })}
                      onClick={() => joinPrivateRoomConfirm(joinedRoom.key, 'player1')}
                      id={`join-room-button-${joinedRoom.key}`}
                      onMouseEnter={() => onHover(joinedRoom.key)}
                      onMouseLeave={() => onHoverLeave(joinedRoom.key)}
                    >
                      {(joinedRoom && joinedRoom.globalParams.privateRoom) ? <Media src={screenMode === constants.SCREEN_MODE.light ? PrivateRoomLightButton : PrivateRoomButton} alt="private-room-button" className="" /> : <></>}
                      <Media src={screenMode === constants.SCREEN_MODE.light ? joinRoomLightIcon : joinRoomIcon} alt="enter-room-button" className="" />
                    </Button>
                  ) : (
                    <Button
                      className="menu-table-row-default-button room-join-button"
                      color="link"
                      onClick={() => joinRoomClickedFunc(joinedRoom.key, 'player1')}
                      id={`join-room-button-${joinedRoom.key}`}
                      onMouseEnter={() => onHover(joinedRoom.key)}
                      onMouseLeave={() => onHoverLeave(joinedRoom.key)}
                    >
                      <Media src={screenMode === constants.SCREEN_MODE.light ? joinRoomLightIcon : joinRoomIcon} alt="enter-room-button" className="" />
                    </Button>
                  )}
                  {(activeTournament && tooltipId) ? (
                    <Popover container={'div > div > div'} popperClassName="popover" placement="top" isOpen={!!(popoverOpen && popoverOpen[joinedRoom.key])} target={tooltipId}>
                      <PopoverBody className={`new-design-popover-${screenMode}`}>
                        <div>
                          {t('common.inTournamentMessage')}
                        </div>
                      </PopoverBody>
                    </Popover>
                  ) : (null)}
                </Fragment>
              )}
          </div>
        </div>
      </MenuRow>
    );
  }
  return null;
}, (prevProps, nextProps) => {
  if (!isEqual(prevProps.joinedRoom, nextProps.joinedRoom)) {
    return false;
  }

  if (prevProps.screenMode !== nextProps.screenMode) {
    return false; // props are not equal -> update the component
  }

  if (prevProps.uid !== nextProps.uid) {
    return false; // props are not equal -> update the component
  }

  if (prevProps.joinedRoomPassword !== nextProps.joinedRoomPassword) {
    return false; // props are not equal -> update the component
  }

  return true; // props are not equal -> update the component
});

RoomsTableJoinedRender.propTypes = {
  joinedRoom: PropTypes.shape(),
  uid: PropTypes.string,
  leaveRoom: PropTypes.func.isRequired,
  activeTournament: PropTypes.string,
  popoverOpen: PropTypes.shape(),
  onHover: PropTypes.func.isRequired,
  onHoverLeave: PropTypes.func.isRequired,
  joinRoomClickedFunc: PropTypes.func.isRequired,
  joinPrivateRoomConfirm: PropTypes.func.isRequired,
  joinedRoomPassword: PropTypes.string,
  screenMode: PropTypes.string,
  tooltipId: PropTypes.string,
};

RoomsTableJoinedRender.defaultProps = {
  joinedRoom: null,
  uid: null,
  activeTournament: null,
  popoverOpen: {},
  joinedRoomPassword: null,
  screenMode: null,
  tooltipId: null,
};

export default RoomsTableJoinedRender;

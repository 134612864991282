import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
// import {
//   IoMdClose,
// } from 'react-icons/io';
import { filter } from 'lodash';
import ScrollArea from 'react-scrollbar';
// import ScrollAreaButtons from '../../../UI/ScrollAreaButtons';
import isEqual from 'react-fast-compare';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';
import Input from 'reactstrap/lib/Input';
// import Form from 'reactstrap/lib/Form';

// import ScrollArea from 'react-scrollbar';
import Message from './Message';
import FilteredMessage from './FilteredMessage';
import Emotions from './Emotions';
import Toggle from '../../Components/Components/Toggle';
import EmotionsMobile from './EmotionsMobile';

// import chatIcon from '../../../../../images/icons/chat.webp';
// import supportIcon from '../../../../../images/redesign/chat/warning.svg';
import LightChatIcon from '../../../../../images/redesign/chat/help.svg';
import ChatIcon from '../../../../../images/redesign/chat/chat-help.svg';
import emotionIcon from '../../../../../images/icons/emo-new.svg';

import chatInSound from '../../../../../sounds/chat_notification.wav';
import * as constants from '../../../../../constants/constants';
import CustomModal from '../../Components/Components/Modal';
import IconPopover from '../../Components/Components/IconPopover';

class Chat extends React.Component {
  static propTypes = {
    chatMessages: PropTypes.shape(),
    uid: PropTypes.string,
    emotions: PropTypes.shape(),
    toggleChat: PropTypes.func.isRequired,
    sendMessage: PropTypes.func.isRequired,
    setEmotion: PropTypes.func.isRequired,
    toggleSupport: PropTypes.func.isRequired,
    playButtonSound: PropTypes.func.isRequired,
    fetchEmotions: PropTypes.func.isRequired,
    supportChatStatus: PropTypes.shape(),
    i18n: PropTypes.shape(),
    t: PropTypes.func.isRequired,
    userSettings: PropTypes.shape().isRequired,
    openChat: PropTypes.bool,
    fontMode: PropTypes.string,
    screenMode: PropTypes.string,
    roomId: PropTypes.string,
    reportedMessages: PropTypes.shape({}),
    name: PropTypes.string,
    mobileChatOpen: PropTypes.bool,
  }

  static defaultProps = {
    chatMessages: {},
    uid: null,
    emotions: {},
    openChat: false,
    supportChatStatus: null,
    i18n: null,
    screenMode: constants.SCREEN_MODE.normal,
    reportedMessages: {},
    mobileChatOpen: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      inputMessage: '',
      openEmotions: false,
      unreadMessages: 0,
      firstOpen: false,
      scrollPos: 0,
      smoothScrolling: false,
      isSending: false,
      onlyMessagesByPlayers: false,
      messagesShow: constants.ALL,
      messageLength: 0,
      supportPopoverOpen: false,
    };


    this.chatInAudio = new Audio(chatInSound);
  }

  componentDidMount() {
    this.scrollToBottom();

    setTimeout(() => {
      this.setState({ smoothScrolling: true });
    }, 3500);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { openChat } = this.props;
    if (nextProps.openChat !== openChat) {
      return true;
    }

    if (!isEqual(nextProps, this.props)) {
      this.scrollToBottom();
      return true;
    }

    if (!isEqual(nextState, this.state)) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps) {
    const {
      chatMessages, openChat, soundOn, supportChatStatus, 
    } = this.props;
    const oldSupportChatStatus = prevProps.supportChatStatus;
    const oldChatMessages = prevProps.chatMessages;
    const oldOpenChat = prevProps.openChat;
    const { unreadMessages } = this.state;

    if (openChat && !oldOpenChat) {
      this.scrollToBottom();
      this.setState({ unreadMessages: 0, openChat });
      return;
    } if (!openChat && oldOpenChat) {
      this.setState({ unreadMessages: 0, openChat });
      return;
    }

    if (!openChat && chatMessages && chatMessages.messages) {
      let newMessages = unreadMessages;
      const reverseMessageKeys = Object.keys(chatMessages.messages).reverse();

      /*  for (const key of reverseMessageKeys) {
        if (!oldChatMessages.messages[key] && chatMessages.messages[key].userUid !== 'game') {
          newMessages++;
        } else if (oldChatMessages.messages[key] && oldChatMessages.messages[key].userUid !== 'game') {
          break;
        }
        return null;
      } */
      let breakMap = false;
      reverseMessageKeys.map((key) => {
        if (!breakMap) {
          if (!oldChatMessages.messages[key] && chatMessages.messages[key].userUid !== 'game') {
            newMessages += 1;
          } else if (oldChatMessages.messages[key] && oldChatMessages.messages[key].userUid !== 'game') {
            breakMap = true;
          }
        }
        return null;
      });

      if (soundOn) {
        if (unreadMessages !== newMessages) {
          const playPromise = this.chatInAudio.play();
          if (playPromise !== undefined) {
            playPromise
              .then(_ => {
                // Automatic playback started!
                // Show playing UI.
                console.log("audio played auto");
              })
              .catch(error => {
                // Auto-play was prevented
                // Show paused UI.
                console.log("playback prevented");
              });
          }
        } else if ((!oldSupportChatStatus || oldSupportChatStatus.read === true) && supportChatStatus && supportChatStatus.read === false) {
          const playPromise = this.chatInAudio.play();
          if (playPromise !== undefined) {
            playPromise
              .then(_ => {
                // Automatic playback started!
                // Show playing UI.
                console.log("audio played auto");
              })
              .catch(error => {
                // Auto-play was prevented
                // Show paused UI.
                console.log("playback prevented");
              });
          }
        }
      }

      this.setState({ unreadMessages: newMessages });
    }
  }

  scrollToBottom = () => {
    if (this.messagesScrollbar) {
      setTimeout(() => {
        if (this.messagesScrollbar) {
          this.messagesScrollbar.scrollBottom();
        }
      }, 0);
    }
  }

  scrollToBottomInstant = () => {
    if (this.messagesScrollbar) {
      this.messagesScrollbar.scrollBottom();
    }
  }

  handleChange = (event) => {
    if (event.key !== 'Enter' || (event.key === 'Enter' && event.shiftKey)) {
      const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

      this.setState({
        [event.target.name]: value,
        messageLength: event.target.value ? event.target.value.length : 0
      });
    }
  }

  handleEnter = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      this.handleSubmit();
    }
  }

  handleSubmit = () => {
    const { sendMessage } = this.props;
    const { inputMessage, isSending } = this.state;

    if (!isSending) {
      this.setState({ isSending: true });
      try {
        sendMessage(inputMessage).then((res) => {
          if (res === 'success') {
            this.setState({ inputMessage: '', isSending: false });
          } else {
            this.setState({ isSending: false });
          }
        }).catch(e => {
          this.setState({ isSending: false });
        });
      } catch (e) {
        this.setState({ isSending: false });
      }
      
    } else {
      setTimeout(() => {
        this.setState({ isSending: false });
      }, 1000);
    }
  }


  openChat = () => {
    const { toggleChat, openChat } = this.props;

    toggleChat();

    this.scrollToBottom();

    if (!openChat) {
      this.setState({ openEmotions: false, prevChatState: null });
    }
  }

  openEmotions = () => {
    const {
      toggleChat, openChat, playButtonSound, // fetchEmotions, emotions,
    } = this.props;
    //  const { openEmotions } = this.state;

  //  fetchEmotions();

    if (openChat) {
    //  toggleChat();
    }

    playButtonSound();
    this.setState(prevState => ({ openEmotions: !prevState.openEmotions, firstOpen: true, prevChatState: openChat }));
  }

  setEmotion = (key) => {
    const { setEmotion } = this.props;
    const { prevChatState } = this.state;

    setEmotion(key);
    //  .then((res) => {
    if (prevChatState) {
    //  this.openChat();
    } else {
      this.setState({ openEmotions: false, prevChatState: null });
    }
    this.setState({
      openEmotions: false,
    })
  //  });
  }

  updateScrollPos = (val) => {
    this.setState({ scrollPos: val.topPosition || 0 });
  }

  scroll = (newVal) => {
    this.messagesScrollbar.scrollYTo(newVal);
  }

  handleOnlyPlayerMessage = (e) => {
    const value = e.target.checked;
    console.log(value, "handle chat");
    let messageStatus;
    if (value) {
      messageStatus = constants.PLAYER;
    } else {
      messageStatus = constants.ALL;
    }

    this.setState({
      onlyMessagesByPlayers: value,
      messagesShow: messageStatus,
    });
  }

  renderMessages = (key, message, uid) => {
    const { fontMode, roomId, reportedMessages, name, screenMode } = this.props;
    const { messagesShow } = this.state;
    const isReportedMessage = filter(reportedMessages, (item) => { return  item.msgKey === key && item.roomId === roomId})[0] ? true : false;

    if (message.isFiltered && message.filteredMessage && (messagesShow === constants.PLAYER)) {
      return (
        <Fragment key={key}>
          <FilteredMessage uid={uid} message={message} fontMode={fontMode} screenMode={screenMode} />
        </Fragment>
      );
    }
    return (
      <Fragment key={key}>
        <Message isReportedMessage={isReportedMessage} name={name} uid={uid} roomId={roomId} messageKey={key} message={message} messagesShow={messagesShow} fontMode={fontMode} screenMode={screenMode} />
      </Fragment>
    );
  }

  setSupportPopoverOpen = (isOpen) => {
    this.setState({supportPopoverOpen: isOpen});
  }

  render() {
    const {
      t,
      chatMessages,
      uid,
      toggleChat,
      openChat,
      emotions,
      toggleSupport,
      supportChatStatus,
      screenMode,
      mobileChatOpen,
    } = this.props;
    const {
      inputMessage, openEmotions, onlyMessagesByPlayers, scrollPos, smoothScrolling, messageLength, supportPopoverOpen,// unreadMessages, firstOpen, scrollPos, smoothScrolling,
    } = this.state;

    return (
      <>
        {/* <div className={`room-chat ${openChat ? ('room-chat-open') : ('room-chat-closed')} ${mobileChatOpen ? ('room-chat-open-mobile') : ('room-chat-closed-mobile')}`}> */}
        <div className={`room-chat ${openChat ? ('room-chat-open') : ('room-chat-closed')} ${mobileChatOpen ? ('room-chat-open-mobile') : ('room-chat-closed-mobile')}`}>
          <Row className={`room-chat-header ${openChat ? ('') : ('room-chat-header-closed')}`}>
            <div>
              <Button
                className={openChat ? 'room-chat-close-button' : 'room-chat-open-button'}
                color="link"
                onClick={() => { toggleChat(); }}
              >
                <div className={openChat ? 'room-chat-close-button-icon' : 'room-chat-open-button-icon'} />
              </Button>
            </div>
            {!openChat && chatMessages && chatMessages.status
            && !chatMessages.status[uid] && (
              <div>
              <span className="contact-support-button-dot" />
              </div>
            )}
            <div>
              <Toggle checked={onlyMessagesByPlayers} name="onlyMessagesByPlayers" onChange={this.handleOnlyPlayerMessage} text={t('chatOnly')} inlineLabelClassName="chat-only-label" toggleTheme />
            </div>
          </Row>
          <Row className={`room-chat-body room-chat-body-${screenMode}`}>
            <Col className="room-chat-body-wrapper" md="12">
              {/* <ScrollAreaButtons
                scrollPos={scrollPos}
                show={this.messagesScrollbar && this.messagesScrollbar.state && this.messagesScrollbar.state.realHeight > this.messagesScrollbar.state.containerHeight ? true : null}
                scroll={this.scroll}
                speed={30}
                rightOffset={-1}
                topOffset={0}
                bottomOffset={0}
              /> */}
              <ScrollArea
                speed={0.55}
                className="chat-body-scroll-area chat-body-new-scroll-area ml-2"
                contentClassName="chat-body-scroll-content chat-body-fontMode-change"
                smoothScrolling={smoothScrolling}
                verticalContainerStyle={{
                  background: 'transparent',
                  opacity: 1,
                  width: 4,
                  left: 0,
                }}
                verticalScrollbarStyle={{
                  background: '#000',
                  opacity: 0.7,
                  borderRadius: 1,
                  width: 4,
                }}
                minScrollSize={30}
                horizontal={false}
                onScroll={this.updateScrollPos}
                ref={(el) => { this.messagesScrollbar = el; }}
              >
                {chatMessages && chatMessages.messages && Object.keys(chatMessages.messages).map(key => this.renderMessages(key, chatMessages.messages[key], uid))}
              </ScrollArea>
            </Col>
          </Row>
        </div>

        <Emotions emotions={emotions} setEmotion={this.setEmotion} openEmotions={openEmotions} openEmotionsFunc={this.openEmotions} />
        <CustomModal
          headerShow={false}
          isOpen={openEmotions}
          addClassName="emotion-mobile d-none"
          // inlineClassName="d-none"
          toggle={this.openEmotions}
          body={(
            <EmotionsMobile emotions={emotions} setEmotion={this.setEmotion} openEmotions={openEmotions} openEmotionsFunc={this.openEmotions} />
          )}
          footerClose={false}
        />

        <div className={classNames("room-chat-bar room-chat-bar-mobile", {
          'room-chat-bar-mobile-close': !mobileChatOpen,
        })}>
          <div className="room-chat-bar-left">
            <Row>
              <Col xs="12" className="room-chat-bar-section" style={{ height: '100%' }}>
                <Input
                  className="room-chat-bar-input"
                  style={{ width: 'calc(70% - 30px)', display: 'inline-block' }}
                  type="textarea"
                  name="inputMessage"
                  id="inputMessage"
                  autoComplete="off"
                  placeholder={`${t('common:common.writeMessage')}...`}
                  value={inputMessage}
                  onChange={this.handleChange}
                  onKeyPress={this.handleEnter}
                  maxLength={200}
                />
                <div className="room-chat-bar-emotions-button">
                  <span className={`room-chat-counter ${(messageLength >= 200) ? 'overcounter' : ''}`}>{`${messageLength}/200`}</span>
                  <Media src={emotionIcon} className="room-chat-bar-button-emotions" style={{ }} onClick={this.openEmotions} />
                  <Button
                    className={`room-chat-bar-button-send ${openChat ? ('room-chat-bar-right-button') : ('room-chat-bar-right-button-closed')}`}
                    style={{ }}
                    onClick={this.handleSubmit}
                  >
                    {t('common:common.send')}
                  </Button>
                </div>

              </Col>
            </Row>
          </div>
          <div className={`room-chat-bar-right ${supportChatStatus && supportChatStatus.read === false ? 'support-reply' : ''}`}>
            <div className="room-chat-bar-button-support" id="support-popover">
              <Media src={screenMode === constants.SCREEN_MODE.dark ? ChatIcon : LightChatIcon} className="" onClick={toggleSupport} onMouseEnter={() => this.setSupportPopoverOpen(true)} onMouseLeave={() => this.setSupportPopoverOpen(false)} />
            </div>
            <IconPopover text={t('common:popovers.contactSupport')} placement="top" targetId="support-popover" popoverOpen={supportPopoverOpen} />
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation(['game', 'common'])(Chat);

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import HelpEditorSection from './HelpEditorSection';

const HelpEditor = ({ showNotification }) => (
  <Fragment>
    <DndProvider backend={HTML5Backend}>
      <HelpEditorSection showNotification={showNotification} />
    </DndProvider>
  </Fragment>
);

HelpEditor.propTypes = {
  showNotification: PropTypes.func.isRequired,
};

HelpEditor.defaultProps = {
};

export default HelpEditor;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';

import Button from 'reactstrap/lib/Button';
import Media from 'reactstrap/lib/Media';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Progress from 'reactstrap/lib/Progress';

import closeImg from '../../../images/icons/close.png';

import {
  closeLevelNotification,
  unBlockUser,
} from '../../../actions/member';

import {
  FONT_MODE,
  MENU_NAVIGATION
} from '../../../constants/constants';

class Notification extends React.Component {
  static propTypes = {
  //  leaveRoom: PropTypes.func,
    closeModal: PropTypes.func.isRequired,
    //  closeResultModal: PropTypes.func.isRequired,
    openModal: PropTypes.bool,
    modalType: PropTypes.string,
    insufficientBalanceAmount: PropTypes.number,
    insufficientBalanceBet: PropTypes.string,
    ignoredMessageName: PropTypes.string,
    ignoredMessageUid: PropTypes.string,
    t: PropTypes.func.isRequired,
    switchTabs: PropTypes.func.isRequired,
    newLevel: PropTypes.string,
    closeLevelUpNotification: PropTypes.func.isRequired,
    unBlockAnUser: PropTypes.func.isRequired,
    fontMode: PropTypes.string,
  }

  static defaultProps = {
    modalType: '',
    ignoredMessageName: '',
    ignoredMessageUid: '',
    insufficientBalanceAmount: null,
    insufficientBalanceBet: null,
    newLevel: '',
    openModal: false,
    fontMode: FONT_MODE.normal,
  }

  constructor(props) {
    super(props);
    this.state = {
    //  activeTab: '1',
    };

    this.closeModal = this.closeModal.bind(this);
    this.closeLevelUpNotification = this.closeLevelUpNotification.bind(this);
    this.buyMoney = this.buyMoney.bind(this);
  }

  closeModal() {
    const { closeModal, modalType } = this.props;
    if (modalType === 'levelUp') {
      this.closeLevelUpNotification();
    } else {
      closeModal();
    }
  }

  closeLevelUpNotification() {
    const { closeLevelUpNotification, closeModal } = this.props;

    closeLevelUpNotification().then(() => {
      closeModal();
    });
  }

  buyMoney() {
    const { closeModal, switchTabs } = this.props;
    switchTabs(MENU_NAVIGATION.buyPageMoneyPacks);
    closeModal();
  }

  render() {
    const {
      t,
      newLevel,
      modalType,
      openModal,
      ignoredMessageName,
      ignoredMessageUid,
      unBlockAnUser,
      insufficientBalanceAmount,
      insufficientBalanceBet,
      fontMode,
    } = this.props;

    if (!openModal || !modalType) {
      return null;
    }

    return (
      <div>
        <Modal container={'div > div'} isOpen={(openModal && modalType)} toggle={this.closeModal} className={`notification old-modal root-font-${fontMode}`}>
          <ModalHeader
            toggle={this.closeModal}
            className="notification-header"
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.closeModal} />
            }
          >{t('common:home.notifications')}</ModalHeader>
          <ModalBody className="notification-body">

            {modalType && modalType === 'noBalanceMenu' && (
              <div>
                {t('noMoneyMenuDescription', { amount: insufficientBalanceAmount, bet: insufficientBalanceBet })}
              </div>
            )}

            {modalType && modalType === 'noBalanceCreateRoom' && (
              <div>
                {t('noMoneyCreateRoomDescription', { amount: insufficientBalanceAmount, bet: insufficientBalanceBet })}
              </div>
            )}

            {modalType && modalType === 'noBalanceTournament' && (
              <div>
                {t('noBalanceTournament')}
              </div>
            )}

            {modalType && modalType === 'proRoomMenu' && (
              <div>
                {t('onlyProDescription')}
              </div>
            )}

            {modalType && modalType === 'emailNotVerified' && (
              <div>
                {t('emailNotVerified')}
              </div>
            )}

            {modalType && modalType === 'tournamentAlreadyRegistered' && (
              <div>
                {t('tournamentAlreadyRegistered')}
              </div>
            )}

            {modalType && modalType === 'tournamentMaxPlayers' && (
              <div>
                {t('common:tournaments.tournamentMaxPlayers')}
              </div>
            )}

            {modalType && modalType === 'alreadyRegisteredAnotherTournament' && (
              <div>
                {t('common:tournaments.alreadyRegisteredAnotherTournament')}
              </div>
            )}

            {modalType && modalType === 'wrongCode' && (
              <div>
                {t('common:common.wrongCode')}
              </div>
            )}

            {modalType && modalType === 'proBetMenu' && (
              <div>
                {t('onlyProBet')}
              </div>
            )}

            {modalType && modalType === 'youIgnoredPlayer' && (
              <div>
                {t('youIgnoredPlayer', { player: ignoredMessageName })}
              </div>
            )}

            {modalType && modalType === 'playerIgnoredYou' && (
              <div>
                {t('playerIgnoredYou', { player: ignoredMessageName })}
              </div>
            )}

            {modalType && modalType === 'multiAccount' && (
              <div>
                {t('multiAccount')}
              </div>
            )}

            {modalType && modalType === 'levelUp' && (
              <Fragment>
                <div>
                  {t('newLevel')}
                </div>
                <div className="levelProgress-old">
                  <Progress
                    color="success"
                    value={100}
                    className="levelProgress-old-bar"
                  />
                  <div className="levelProgress-old-level-wrapper" style={{ left: '45%' }}>
                    <div
                      className="levelProgress-old-level"
                      style={{ top: 10 }}
                    >
                      {newLevel}
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
          </ModalBody>
          <ModalFooter className="notification-footer">
            {modalType && (modalType === 'proRoomMenu' || modalType === 'proBetMenu' || modalType === 'emailNotVerified') && (
              <Button className="notification-footer-button" color="link" onClick={this.closeModal}>{t('ok')}</Button>
            )}

            {modalType && modalType === 'noBalance' && (
              <Button className="notification-footer-button" color="link" onClick={this.closeModal}>{t('buyMoney')}</Button>
            )}

            {modalType && (modalType === 'noBalanceMenu' || modalType === 'noBalanceTournament') && (
              <Fragment>
                <Button className="notification-footer-button" color="link" onClick={this.buyMoney}>{t('buyMoney')}</Button>
                <Button className="notification-footer-button" color="link" onClick={this.closeModal}>{t('close')}</Button>
              </Fragment>
            )}

            {modalType && modalType === 'noBalanceCreateRoom' && (
              <Fragment>
                <Button className="notification-footer-button" color="link" onClick={this.buyMoney}>{t('buyMoney')}</Button>
                <Button className="notification-footer-button" color="link" onClick={this.closeModal}>{t('close')}</Button>
              </Fragment>
            )}

            {modalType && modalType === 'youIgnoredPlayer' && (
              <>
                {ignoredMessageUid ? (
                  <Button className="notification-footer-button" onClick={() => { unBlockAnUser(ignoredMessageUid); this.closeModal(); }}>
                    {t('unblock')}
                  </Button>
                ) : (null)}
                <Button className="notification-footer-button" color="link" onClick={this.closeModal}>{t('close')}</Button>
              </>
            )}

            {modalType && modalType === 'playerIgnoredYou' && (
              <Button className="notification-footer-button" color="link" onClick={this.closeModal}>{t('ok')}</Button>
            )}

            {modalType && modalType === 'multiAccount' && (
              <Button className="notification-footer-button" color="link" onClick={this.closeModal}>{t('ok')}</Button>
            )}

            {modalType && modalType === 'levelUp' && (
              <Button className="notification-footer-button" color="link" onClick={this.closeLevelUpNotification}>{t('ok')}</Button>
            )}

            {modalType && modalType === 'wrongCode' && (
              <Button className="notification-footer-button" color="link" onClick={this.closeModal}>{t('close')}</Button>
            )}
          </ModalFooter>
        </Modal>

      </div>
    );
  }
}

const mapDispatchToProps = {
  closeLevelUpNotification: closeLevelNotification,
  unBlockAnUser: unBlockUser,
};

export default connect(null, mapDispatchToProps)(withTranslation(['notifications', 'common'])(Notification));

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';

import { removeAutomated } from '../../../actions/game';

const AutomatedOverlay = React.memo(({
  roomId, resetAutomated, automated, t,
}) => (
  <Fragment>
    <div style={{
      width: '100%', height: '100%', position: 'fixed', background: 'rgba(130,130,130,0.6)', zIndex: 9999,
    }}
    >
      <div
        style={{
          position: 'relative',
          width: '70%',
          marginRight: 'auto',
          marginLeft: 'auto',
          maxWidth: 300,
          left: '0%',
          top: '40%',
          zIndex: 999,
          color: '#FFF',
          textAlign: 'center',
          padding: 15,
          paddingBottom: 0,
          paddingTop: 7,
        }}
        className="automation"
      >
        <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
          <div className='play-automate-content'>
            {t('automatedMessage')}
          </div>
          <Row>
            <Col>
              <Button color="danger" className="automation-button" onClick={() => resetAutomated(roomId)}>
                {t('return')}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  </Fragment>
));

AutomatedOverlay.propTypes = {
  fastGame: PropTypes.bool,
  resetAutomated: PropTypes.func.isRequired,
};

AutomatedOverlay.defaultProps = {
  fastGame: null,
};

const mapStateToProps = (state, ownProps) => ({
  fastGame: state.game.globalParams.fastGame,
});

const mapDispatchToProps = {
  resetAutomated: removeAutomated,
};

export default connect(mapStateToProps, mapDispatchToProps)(AutomatedOverlay);

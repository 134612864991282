import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { Helmet } from 'react-helmet';
import { size, map, isEqual } from 'lodash';
import Spinner from 'reactstrap/lib/Spinner';

import { connect } from 'react-redux';
import classNames from 'classnames';

import Moment from 'react-moment';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';
import Form from 'reactstrap/lib/Form';
import FormGroup from 'reactstrap/lib/FormGroup';
import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import Alert from 'reactstrap/lib/Alert';
import * as EmailValidator from 'email-validator';

import CustomModal from '../../../Components/Components/Modal';

import { Firebase } from '../../../../../../lib/firebase';

import MenuRow from '../../../Components/Components/MenuRow';

import ScrollAreaWrapper from '../../../Components/ScrollAreaWrapper';
import MyInfoSubHeader from './MyInfoSubHeader';
import LandScapeLayoutMobileHeader from '../../Layout/Mobile/LandScapeLayoutMobileHeader';

import defaultImage from '../../../../../../images/redesign/common/default_image.svg';
import coinImage from '../../../../../../images/redesign/common/coin.svg';
import noFriendsLight from '../../../../../../images/redesign/my-info/no-friends-light.svg';
import noFriendsDark from '../../../../../../images/redesign/my-info/no-friends-dark.svg';
import ratingImage from '../../../../../../images/redesign/common/rating.svg';
import draugiemLogoIcon from '../../../../../../images/icons/draugiem-lv.svg';
import facebookLogoIcon from '../../../../../../images/icons/social-facebook.svg';
import * as constants from '../../../../../../constants/constants';

import {
  sendMoney,
  inviteFriendEmail,
  getFriends,
  removeFriend,
} from '../../../../../../actions/member';
import { errorMessages } from '../../../../../../constants/messages';
import ErrorNotification from '../../../Components/Components/ErrorNotification';
import coinImg from '../../../../../../images/redesign/common/coin.svg';
import removeFriendIcon from '../../../../../../images/redesign/mobile/icons/blocked-players-active-dark-icon.svg';

class Friends extends React.Component {
  static propTypes = {
    socProvider: PropTypes.string,
    //  role: PropTypes.string,
    friends: PropTypes.shape(),
    achievementsNotification: PropTypes.bool,
    t: PropTypes.func.isRequired,
    SendMoneyToFriend: PropTypes.func.isRequired,
    inviteFriendEmailFunc: PropTypes.func.isRequired,
    fetchFBFriends: PropTypes.func.isRequired,
    fetchDraugiemFriends: PropTypes.func.isRequired,
    changeTab: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    screenMode: PropTypes.string,
    showUserSettingsModal: PropTypes.func.isRequired,
    handleClickStart: PropTypes.func.isRequired,
    OpenMobileDropdownModal: PropTypes.func.isRequired,
    activeTab: PropTypes.string,
    fetchFriends: PropTypes.func.isRequired,
    removeAFriend: PropTypes.func.isRequired,
  }

  static defaultProps = {
    socProvider: '',
    //  role: '',
    friends: {},
    achievementsNotification: false,
    isLoading: false,
    screenMode: constants.SCREEN_MODE.normal,
    activeTab: constants.MENU_NAVIGATION.friends,
  }

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      amount: 0,
      inviteFriendModalOpen: false,
      inviteEmail: '',
      alertOpen: false,
      alertType: '',
      message: '',
      devMode: false,
      friendId: '',
      inviteStatus: false,
    };
  }

  componentWillMount() {
    const {
      socProvider, fetchFriends
    } = this.props;
    const { hostname } = window.location;

    let devMode = false;

    if (hostname && (hostname.includes('dev') || hostname.includes('localhost'))) {
      devMode = true;
    }

    this.setState({ devMode });

    if (socProvider === 'facebook') {
      Firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          window.FB.getLoginStatus((response) => {
            if (response.status === 'connected') {
              const { accessToken } = response.authResponse;

              window.FB.api(
                `/${user.providerData[0].uid}/friends`,
                'GET',
                { access_token: accessToken },
                (resp) => {
                  //  this.setState({ friends: resp.data });

                  console.log(resp);
                  if (resp && resp.data) {
                    fetchFriends(resp).then((res) => {
                      console.log(res);
                    });
                  }
                },
              );
            }
          });
        } else {
          //  console.log('no user');
        }
      });
    } else if (socProvider === 'draugiem') {

      fetchFriends(devMode);
    } else {
      fetchFriends();
    }
  }

  // componentWillReceiveProps(nextProps) {
  //   const { friends, fetchFriends } = this.props;
  //   if (!isEqual(friends, nextProps.friends)) {
  //     fetchFriends();
  //   }
  // }

  handleChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    if (event.target.name === 'amount') {
      if (value.toString().length > 3) return;
      
      this.setState({
        [event.target.name]: value
          .replace(/[^0-9]/g, '')  // Remove non-numeric characters
          .replace(/^0+/, '')  // Remove leading zeros
      });
    } else {
      this.setState({
        [event.target.name]: value,
      });
    }
  }

  inviteFriend = () => {
    const { socProvider } = this.props;

    if (socProvider === 'facebook') {
      window.FB.ui({
        method: 'apprequests',
        message: 'Come Play Zole',
      });
    } else {
      // Draugiem invite
    }
  }

  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
      alertOpen: false,
      message: '',
      alertType: '',
      amount: 0,
    }));
  }

  openModal = (id) => {
    console.log(id);
    this.setState(prevState => ({
      modal: !prevState.modal,
      friendId: id,
    }));
  }

  sendMoney = () => {
    const { SendMoneyToFriend } = this.props;

    const { friendId, amount } = this.state;

    if (!friendId) {
      return this.setState({
        alertOpen: true,
        alertType: constants.FROUNT_SIDE_STATUS.danger,
        message: 'noSelectedFriend'
      })
    }
    if (amount === 0) {
      return this.setState({
        alertOpen: true,
        alertType: constants.FROUNT_SIDE_STATUS.danger,
        message: 'noAmount'
      })
    }
    if (amount > 100) {
      return this.setState({
        alertOpen: true,
        alertType: constants.FROUNT_SIDE_STATUS.danger,
        message: 'sentPerDayMoney',
        amount: 0,
      });
    }
    if (friendId && amount) {
      SendMoneyToFriend(friendId, amount).then(res => {
        if (res && res.status === constants.FROUNT_SIDE_STATUS.success) {
          this.setState({
            alertOpen: true,
            alertType: constants.FROUNT_SIDE_STATUS.success,
            message: 'sentMoney',
            amount: 0,
          })
        } else {
          if (res.message === errorMessages.sentPerDayMoneyLimit) {
            this.setState({
              alertOpen: true,
              alertType: constants.FROUNT_SIDE_STATUS.danger,
              message: errorMessages.sentPerDayMoneyLimit,
              restMoney: res.restMoney || 0,
              amount: 0,
            });
          } else {
            this.setState({
              alertOpen: true,
              alertType: constants.FROUNT_SIDE_STATUS.danger,
              message: 'failedSentMoney',
              amount: 0,
            });
          }
        }
      });
      // this.setState(prevState => ({
      //   modal: !prevState.modal,
      // }));
    }
  }

  renderRating = (t, rating) => (
    <div className="my-info-ratings-step-wrapper" style={{ justifyContent: 'flex-start' }}>
      {!rating || rating < 10 ? (<div className="my-info-ratings-step my-info-ratings-step-weak my-info-ratings-step-active" style={{height: 16, lineHeight: '100%'}}>{t('myInfo.weak')}</div>) : (null)}
      {(rating >= 10 && rating < 20) ? (<div className="my-info-ratings-step my-info-ratings-step-beginner my-info-ratings-step-active" style={{height: 16, lineHeight: '100%'}}>{t('myInfo.beginner')}</div>) : (null)}
      {(rating >= 20 && rating < 30) ? (<div className="my-info-ratings-step my-info-ratings-step-untrained my-info-ratings-step-active" style={{height: 16, lineHeight: '100%'}}>{t('myInfo.untrained')}</div>) : (null)}
      {(rating >= 30 && rating < 40) ? (<div className="my-info-ratings-step my-info-ratings-step-medium my-info-ratings-step-active" style={{height: 16, lineHeight: '100%'}}>{t('myInfo.medium')}</div>) : (null)}
      {(rating >= 40 && rating < 50) ? (<div className="my-info-ratings-step my-info-ratings-step-good my-info-ratings-step-active" style={{height: 16, lineHeight: '100%'}}>{t('myInfo.good')}</div>) : (null)}
      {(rating >= 50 && rating < 60) ? (<div className="my-info-ratings-step my-info-ratings-step-great my-info-ratings-step-active" style={{height: 16, lineHeight: '100%'}}>{t('myInfo.great')}</div>) : (null)}
      {(rating >= 60 && rating < 70) ? (<div className="my-info-ratings-step my-info-ratings-step-fan my-info-ratings-step-active" style={{height: 16, lineHeight: '100%'}}>{t('myInfo.fan')}</div>) : (null)}
      {rating >= 70 ? (<div className="my-info-ratings-step my-info-ratings-step-pro my-info-ratings-step-active" style={{height: 16, lineHeight: '100%'}}>{t('myInfo.pro')}</div>) : (null)}
    </div>
  );

  toggleInviteFriend = () => {
    const { inviteFriendEmail } = this.state;

    this.setState(previous => ({
      inviteFriendModalOpen: !previous.inviteFriendModalOpen,
      alertOpen: false,
      alertType: '',
      message: '',
      inviteEmail: '',
    }));
  }

  handleInviteFriendEmail = () => {
    const { inviteFriendEmailFunc } = this.props;
    const { inviteEmail, inviteStatus } = this.state;
    if (!inviteEmail || !EmailValidator.validate(inviteEmail)) {
      return this.setState({
        alertOpen: true,
        alertType: constants.FROUNT_SIDE_STATUS.danger,
        message: errorMessages.wrongEmail
      })
    }
    if (inviteStatus) {
      return this.setState({
        alertOpen: true,
        alertType: constants.FROUNT_SIDE_STATUS.danger,
        message: errorMessages.pendingInviteEmail
      });
    }
    inviteFriendEmailFunc(inviteEmail).then(res => {
      if (res && res.status === constants.FROUNT_SIDE_STATUS.success) {
        this.setState({
          alertOpen: true,
          alertType: constants.FROUNT_SIDE_STATUS.success,
          message: 'verifiedEmailSent',
          inviteStatus: true,
        });
        this.intervalID = setInterval(() => {
          this.setState({
            inviteStatus: false,
          });
        }, 60000);
      } else {
        this.setState({
          alertOpen: true,
          alertType: res.status,
          message: res.message
        })
      }
    });
  }

  handleRemoveFriend = (selectId) => {
    const { removeAFriend } = this.props;
    removeAFriend(selectId);
  }

  render() {
    const {
      t, isLoading, changeTab, socProvider, achievementsNotification, friends, screenMode, activeTab, showUserSettingsModal, handleClickStart, OpenMobileDropdownModal,
    } = this.props;
    const {
      modal,
      amount,
      inviteEmail,
      inviteFriendModalOpen,
      alertOpen,
      alertType,
      message,
      devMode,
      restMoney,
    } = this.state;
    console.log("friendsList", friends)
    return (
      <div className="layout-mobile-body layout-mobile-body-friends-page">
        <Helmet>
          <title>
            Zole - {t('route.friends')}
          </title>
        </Helmet>

        <div className="layout-mobile-body-top">
          {/* <Row className="layout-mobile-subheader d-none">
            <MyInfoSubHeader changeTab={changeTab} activeTab={activeTab} />
          </Row> */}
        </div>
        <div className="layout-mobile-body-main layout-body-main-friends">
          <div className="layout-mobile-body-main-title d-none ml-3">{t('common:myInfo.friends')}</div>
          <div className="friends-list">
            <div className="friends-list-wrapper">
              <div className="friends-list-header">
                <div className="left-section"></div>
                <div className="middle-section">
                  <div className="item item-rating">
                    {t('common.ratings')}
                  </div>
                  <div className="item item-balance">
                    {t('common.balance')}
                  </div>
                  <div className="item item-points text-center">
                    {t('common.points')}
                  </div>
                  <div className="item item-gamesPlayed text-center">
                    {t('common:myInfo.gamesPlayed')}
                  </div>
                </div>
                <div className="middle-right-section">
                  {t('common:friends.unFriend')}
                </div>
                <div className="right-section">
                  {t('common.sendMoney')}
                </div>

              </div>
              <div
                className="layout-mobile-body-main-scrollarea friends-list-scrollarea"
              >
                {
                  isLoading ? (
                    <div className="top-table-spinner-loading-section d-flex align-items-center">
                      <Spinner color="light" style={{ width: '5rem', height: '5rem' }} />
                    </div>
                  ) : (
                    friends && size(friends) > 0 ? (
                      map(friends, (item, index) => (
                        <MenuRow key={index}>
                          <div className="friends-list-body">
                            <div className="left-section">
                              <div className={`image-frame image-frame-${screenMode}`}>
                                <Media className="profile-image" src={item.photo || defaultImage} />
                                {item.online ? <div className="online-bage" /> : <div className="offline-bage" />}
                                <Media
                                  src={item?.socProvider === 'draugiem' ? draugiemLogoIcon : item?.socProvider === 'facebook' ? facebookLogoIcon : ''}
                                  alt=""
                                  className="network-logo-mobile-icon"
                                />
                              </div>
                            </div>
                            <div className="middle-section">
                              <div className="top-part">
                                <div className="name-wrapper">{item.name}</div>
                                <div className="rating-wrapper">
                                  {this.renderRating(t, item.lvl || 1600)}
                                </div>
                              </div>
                              <div className="bottom-part">
                                <div className="item item-rating">
                                  <Media src={ratingImage} className="icon" />
                                  <div className="icon-text">
                                    {item ? (item.rating || 1600) : '1600'}
                                  </div>
                                </div>
                                <div className="item item-balance">
                                  <Media src={coinImage} className="icon" />
                                  <div className="icon-text">
                                    {item ? (item.bal || 1600) : '1600'}
                                  </div>
                                </div>
                                <div className="item item-points text-center">
                                  {item ? (item.totalPnts || 1600) : '1600'}
                                </div>
                                <div className="item item-gamesPlayed text-center">
                                  {item ? (item.gPlayed || 1600) : '1600'}
                                </div>
                              </div>
                            </div>
                            <div className="middle-right-section">
                              {/* <Button color="link" className="menu-table-row-default-button" onClick={() => this.handleRemoveFriend(item.uid)} >
                                {t('common:top.removeFriend')}
                              </Button> */}
                              <div className='remove-friend-button top-table-row-player-mobile-friend top-table-row-text d-flex align-items-center'
                                style={{
                                  background: 'linear-gradient(180deg, #333333 0%, #121212 100%)',
                                  width: '36px',
                                  height: '36px',
                                  minWidth: '36px',
                                  minHeight: '36px',
                                  borderRadius: '50%',
                                  // marginRight: '-10px',
                                  // marginTop: '7px',
                                }}
                              >
                                <Media
                                  className='top-table-row-text top-table-row-player-mobile-friend'
                                  src={removeFriendIcon}
                                  onClick={() => this.handleRemoveFriend(item.uid)}
                                  style={{
                                    width: '36px',
                                    height: '36px',
                                    minWidth: '36pxx',
                                    minHeight: '36px',
                                    objectFit: 'contain',
                                    marginTop: '0px',
                                    marginRight: '0px',
                                    cursor: 'pointer',
                                  }}
                                />
                              </div>
                            </div>
                            <div className="right-section">
                              { devMode &&
                                <Media src={coinImage} className="send-money-button" onClick={() => this.openModal(item.uid)} />
                              }
                            </div>
                          </div>
                          {/* <Row className="ignore-table-row m-0" key={`ignore-table-row-${index}`}>
                            <div className="ignore-table-row-text-lg ignore-list-body-player">
                              <div className="ignore-table-row-player d-flex">
                                <div className="top-table-row-player-image-frame ignore-table-row-player-image-frame mr-2 network-logo">
                                  <Media className={`ignore-table-row-player-image ${item.photo ? '' : 'ignore-table-row-player-image-default'}`} src={item.photo || defaultImage} />
                                  {item.online ? <div className="online-bage" /> : <div className="offline-bage" />}
                                  <Media
                                    src={item?.socProvider === 'draugiem' ? draugiemLogoIcon : item?.socProvider === 'facebook' ? facebookLogoIcon : ''}
                                    alt=""
                                    className="network-logo-mobile-icon"
                                  />
                                </div>
                                <div className="ignore-table-row-mobile-text-group">
                                  <div className="ignore-table-row-mobile-first-line">
                                    <div className="ignore-table-row-text">
                                      {item.name}
                                    </div>
                                    {this.renderRating(t, item.rating || 1600)}
                                  </div>

                                  <div className="ignore-table-row-mobile-second-line-landing">
                                    <div className="ignore-list-body-column-ratings-md">
                                        <div className="menu-table-row-player-icon-wrapper-landing">
                                          <Media src={ratingImage} className="menu-table-row-player-icon" />
                                          <div className="menu-table-row-player-icon-text">
                                            {item ? (item.rating || 1600) : '1600'}
                                          </div>
                                        </div>
                                    </div>

                                    <div className="ignore-list-body-column-balance-md">
                                      <div className="menu-table-row-player-icon-wrapper-landing">
                                        <Media src={coinImage} className="menu-table-row-player-icon" />
                                        <div className="menu-table-row-player-icon-text">
                                          {item ? (item.bal || 1600) : '1600'}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="ignore-list-body-column-points-md">
                                      {item ? (item.totalPnts || 1600) : '1600'}
                                    </div>
                                    <div className="ignore-list-body-column-games-md">
                                      {item ? (item.gPlayed || 1600) : '1600'}
                                    </div>
                                  </div>
                                </div>
                                <div className="ignore-list-body-column-send-md">
                                  {
                                    devMode && (
                                      <div className="ignore-list-body-column-send-md-button">
                                        <Media src={coinImage} className="menu-table-row-player-icon" onClick={() => this.openModal(item.uid)} />
                                      </div>
                                    )
                                  }
                                </div>
                              </div>
                            </div>
                          </Row> */}
                        </MenuRow>
                      ))
                    ) : (
                      <div className="top-table-spinner-loading-section d-flex align-items-center flex-column layout-body-main-friends-no-friends">
                        <Media
                          src={(screenMode === (constants.SCREEN_MODE.light)) ? noFriendsLight : (screenMode === constants.SCREEN_MODE.dark || screenMode === constants.SCREEN_MODE.normal)
                            ? noFriendsDark : null}
                          className="no-friends"
                        />
                        {t('home.noFriend')}
                        {console.log('screen mode:', constants.SCREEN_MODE.light)}
                      </div>
                    )
                  )}
              </div>
            </div>
          </div>
        </div>

        <CustomModal
          isOpen={modal}
          toggle={this.toggle}
          size="md"
          title={t('sendMoney.sendMoneyFriend')}
          footer={(
            <>
              <Button color="link" className="btn notification-footer-button" onClick={this.sendMoney}>{t('common.send')}</Button>
              <Button color="link" className="btn notification-footer-button" onClick={this.toggle}>{t('common.cancel')}</Button>
            </>
          )}
          body={(
            <>
              <Fragment>
                <ErrorNotification
                  isVisible={alertOpen}
                  // text={t(`sendMoney.${message}`)}
                  text={
                    <>
                      {message === errorMessages.sentPerDayMoney ? (
                        <div className='d-flex flex-row'>
                          <span>
                            {t('sendMoney.sentPerDayMoney')}
                          </span>
                          <Media
                            src={coinImg}
                            width={18}
                            className='ml-1 mr-1'
                          />
                          <span>
                            {t('sendMoney.perFriend')}
                          </span>
                        </div>
                      ) : message === errorMessages.sentPerDayMoneyLimit ? (
                        <div className='d-flex flex-column'>
                          <div className='d-flex flex-row'>
                            <span>
                              {t('sendMoney.sentPerDayMoney')}
                            </span>
                            <Media
                              src={coinImg}
                              width={18}
                              className='ml-1 mr-1'
                            />
                            <span>
                              {t('sendMoney.perDayFriend')}
                            </span>
                          </div>
                          <div>
                            {t(`sendMoney.sentPerDayMoneyLimit`, { restMoney })}
                          </div>
                        </div>
                      ) : (
                        t(`sendMoney.${message}`)
                      )}
                    </>
                  }
                  isSuccess={alertType === constants.FROUNT_SIDE_STATUS.success}
                />
              </Fragment>
              <Form className="friends-modal-body-form">
                <FormGroup>
                  <Label for="amount">{t('common:sendMoney.enterText')}</Label>
                  <Input type="text" max={100} min={0} name="amount" id="amount" value={amount} placeholder="0" onChange={this.handleChange} />
                </FormGroup>
              </Form>
            </>
          )}
        />

        <CustomModal
          isOpen={inviteFriendModalOpen}
          toggle={this.toggleInviteFriend}
          size="md"
          title={t('sendMoney.inviteFriend')}
          footer={(
            <>
              <Button color="link" className="btn notification-footer-button" onClick={this.handleInviteFriendEmail}>{t('common.send')}</Button>
              <Button color="link" className="btn notification-footer-button" onClick={this.toggleInviteFriend}>{t('common.cancel')}</Button>
            </>
          )}
          body={(
            <>
              <Fragment>
                <ErrorNotification isVisible={alertOpen} text={t(`member.${message}`)} isSuccess={alertType === constants.FROUNT_SIDE_STATUS.success}/>
                {/*  <Alert color={alertType} isOpen={alertOpen}>
                  <h5 className="alert-heading alert-heading-message">{alertType === constants.FROUNT_SIDE_STATUS.success ? t('support.success') : t('support.appearError')}</h5>
                  {message === errorMessages.sentPerDayMoney ? (
                    <div className='d-flex flex-row alert-heading alert-heading-body'>
                      <span>
                        {t('sendMoney.sentPerDayMoney')}
                      </span>
                      <Media
                        src={coinImg}
                        width={18}
                        className='ml-1 mr-1'
                      />s
                      <span>
                        {t('sendMoney.perFriend')}
                      </span>
                    </div>
                  ) : message === errorMessages.sentPerDayMoneyLimit ? (
                    <div className='d-flex flex-column alert-heading alert-heading-body'>
                      <div className='d-flex flex-row'>
                        <span>
                          {t('sendMoney.sentPerDayMoney')}
                        </span>
                        <Media
                          src={coinImg}
                          width={18}
                          className='ml-1 mr-1'
                        />
                        <span>
                          {t('sendMoney.perDayFriend')}
                        </span>
                      </div>
                      <div>
                        {t(`sendMoney.sentPerDayMoneyLimit`, {restMoney})}
                      </div>
                    </div>
                  ) : (
                    <p className="alert-heading alert-heading-body">{t(`sendMoney.${message}`)}</p>
                  )}
                </Alert> */}
              </Fragment>
              <Form className="friends-modal-body-form">
                <FormGroup>
                  <Label for="amount">{t('home.email')}</Label>
                  <Input type="text" name="inviteEmail" id="inviteEmail" value={inviteEmail} placeholder={t('home.email')} onChange={this.handleChange} />
                </FormGroup>
              </Form>
            </>
          )}
        />
      </div>
    );
  }
}


const mapStateToProps = state => ({
  friends: state.member.friends || {},
  fbFriends: state.member.fbFriends || {},
  socProvider: state.member.socProvider || null,
  role: state.member.role || null,
  isLoading: state.member.isLoading || false,
});

const mapDispatchToProps = {
  SendMoneyToFriend: sendMoney,
  inviteFriendEmailFunc: inviteFriendEmail,
  fetchFriends: getFriends,
  removeAFriend: removeFriend
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Friends));

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import classNames from 'classnames';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
// import Media from 'reactstrap/lib/Media';
import Pagination from 'reactstrap/lib/Pagination';
import PaginationItem from 'reactstrap/lib/PaginationItem';
import PaginationLink from 'reactstrap/lib/PaginationLink';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import Popover from 'reactstrap/lib/Popover';
import PopoverBody from 'reactstrap/lib/PopoverBody';

import { map } from 'lodash';

import Moment from 'react-moment';

import RoomLogs from './RoomLogs';
import Statistics from './MissedRoomsTotals';
import SuspiciousTransactionsPlayers from './SuspiciousTransactionsPlayers';
import SuspiciousFoldPlayers from './SuspiciousFoldPlayers';

import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import 'react-widgets/dist/css/react-widgets.css';

import {
  getSuspiciousPlayers,
  getSuspiciousPlayersRooms,
  getRoomLog,
} from '../../../actions/admin';

import * as constants from '../../../constants/constants';
import { UncontrolledPopover } from 'reactstrap';

class SuspiciousPlayers extends React.Component {
  static propTypes = {
    member: PropTypes.shape({
      email: PropTypes.string,
    }),
    suspiciousPlayers: PropTypes.shape({}),
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    fetchSuspiciousPlayers: PropTypes.func.isRequired,
    fetchRoomLog: PropTypes.func.isRequired,
  }

  static defaultProps = {
    member: {},
    suspiciousPlayers: [],
  }

  constructor(props) {
    super(props);
    this.state = {
      suspiciousTypes: constants.SUSPICIOUS_PLAYERS,
    };
  }

  componentDidMount() {
    const { fetchSuspiciousPlayers } = this.props;

    fetchSuspiciousPlayers();

  }

  openModal = (player1, player2) => {
    const { fetchSuspiciousPlayersRooms } = this.props;

    fetchSuspiciousPlayersRooms(player1, player2);

    this.setState({
      openModal: true,
    });
  }

  openRoomModal = (room) => {
    const { fetchRoomLog } = this.props;

    console.log('openRoomModal', room);

    fetchRoomLog(room.roomId);

    this.setState({
      roomId: room.roomId,
      room,
      openRoomModal: true,
    });
  }

  toggle = () => {
    this.setState(prevState => ({
      openModal: !prevState.openModal,
    }));
  }

  toggleRoom = () => {
    this.setState(prevState => ({
      openRoomModal: !prevState.openRoomModal,
    }));
  }

  handleClick = (e, index) => {
    e.preventDefault();

    this.setState({
      currentPage: index,
    });
  }

  handleSubsection = (item) => {
    const { suspiciousTypes } = this.state;
    console.log(item, "selectSusType");

    if (suspiciousTypes !== item) {
      this.setState({
        suspiciousTypes: item,
      });
    }
  }

  table = () => {
    const {
      suspiciousPlayers,
      suspiciousPlayersArr,
      t,
    } = this.props;

    // console.log('suspiciousPlayersArr', suspiciousPlayersArr);

    return (
      <Fragment>
        {suspiciousPlayersArr.map((player, index) => (
          (player.dateAdded && player.player1 && player.player2 && player.count) ? (
            <Fragment key={player.key}>
              <tr key={player.key} className={classNames({ 'allUsers-table-row odd': (index % 2 === 0), 'allUsers-table-row even ': index % 2 !== 0 })}>
                <td className="allUsers-table-col">
                  {player.dateAdded && (
                    <Moment format="DD-MM-YYYY, HH:mm:ss" locale="lv">
                      {player.dateAdded}
                    </Moment>
                  )}
                </td>
                <td className="allUsers-table-col">
                  {player.player1Name}
                </td>
                <td className="allUsers-table-col">
                  {player.player2Name}
                </td>
                <td className="allUsers-table-col">
                  {player.count}
                </td>
                <td className="allUsers-table-col">
                  {player.suspiciousPlays}
                </td>
                <td className="allUsers-table-col">
                  <Button onClick={() => this.openModal(player.player1, player.player2)}>
                    View
                  </Button>
                </td>
              </tr>
            </Fragment>
          ) : (null)
        ))}
      </Fragment>
    );
  }

  render() {
    const { suspiciousPlayers, t, roomData, suspiciousPlayersRooms } = this.props;

    const {
      openModal, openRoomModal, roomId, room, rooms, bets, states, partyEnded, handEnded, speeds, roomsCount, suspiciousTypes,
    } = this.state;

    // console.log('suspiciousPlayers', { suspiciousPlayers });
    // console.log('suspiciousPlayersRooms', { suspiciousPlayersRooms });
    console.log('suspicisousType', suspiciousTypes);

    return (
      <Fragment>
        <UncontrolledPopover popperClassName={`popover`} placement="right" trigger="focus" target="admin-help-button-suspicious-players">
          <PopoverBody className="popover-body">
            A list of players whose actions look suspicious: has made at least 5 purchases per day, has folded at least 5 times per room, or has suspiciously played the cards.
          </PopoverBody>
        </UncontrolledPopover>
        <div style={{ marginTop: 100, color: '#fff' }}>
          <h2>
            {/* {t('suspiciousPlayers')} */}
            Suspicious Players
            <Button className="admin-help-button" id="admin-help-button-suspicious-players">
              ?
            </Button>
          </h2>
          <h2>
            {
              map(constants.SUSPICIOUSPLAYERS_TYPES, item => (
                <Button className={classNames('supicious-transactions-label', {'active': suspiciousTypes === item.id })} onClick={() => this.handleSubsection(item.id)}>{item.tLabel}</Button>
              ))
            }
          </h2>
          {
            suspiciousTypes === constants.TRANSACTIONS ? (
              <SuspiciousTransactionsPlayers />
            ) : suspiciousTypes === constants.FOLD ? (
              <SuspiciousFoldPlayers openRoomModal={this.openRoomModal} />
            ) : (
              <table style={{ width: '100%', fontSize: 12, color: '#fff' }}>
                <colgroup>
                  <col span="1" className="" />
                </colgroup>
                <thead>
                  <tr>
                    <th style={{ textAlign: 'center' }}>
                      {t('date')}
                    </th>
                    <th style={{ textAlign: 'center' }}>
                      Player 1
                    </th>
                    <th style={{ textAlign: 'center' }}>
                      Player 2
                    </th>
                    <th style={{ textAlign: 'center' }}>
                      {/* {t('count')} */}
                      Count
                    </th>
                    <th style={{ textAlign: 'center' }}>
                      {/* {t('count')} */}
                      Giveaway
                    </th>
                    <th style={{ textAlign: 'center' }}>
                      {/* {t('view')} */}
                      View
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.table()}
                </tbody>
              </table>
            )
          }
        </div>

        <Modal container={`div > div`} isOpen={openModal} toggle={this.toggle} style={{ maxWidth: '65%' }}>
          <ModalHeader toggle={this.toggle}>
            Data
          </ModalHeader>
          <ModalBody>
          <table style={{ width: '100%', fontSize: 12, color: '#fff' }}>
            <colgroup>
              <col span="1" className="" />
            </colgroup>
            <thead>
              <tr>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('date')} */}
                  Date
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('roomId')} */}
                  Room Id
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('bet')} */}
                  Bet
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('minGames')} */}
                  Min games
                </th>
                <th style={{ textAlign: 'center' }}>
                  Suspicious cases
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('view')} */}
                  View
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(suspiciousPlayersRooms).map((key, index) => (
                <Fragment key={suspiciousPlayersRooms[key].roomId}>
                  <tr
                    key={suspiciousPlayersRooms[key].roomId}
                    className={`allUsers-table-row ${index % 2 === 0 ? ('odd') : ('even')}`}
                    style={suspiciousPlayersRooms[key].suspiciousPlays ? { color: 'red', fontWeight: 'bold' } : null}
                  >
                    <td className="allUsers-table-col">
                      {suspiciousPlayersRooms[key].date && (
                        <Moment format="DD-MM-YYYY, HH:mm:ss" locale="lv">
                          {suspiciousPlayersRooms[key].date}
                        </Moment>
                      )}
                    </td>
                    <td className="allUsers-table-col">
                      {suspiciousPlayersRooms[key].roomId}
                    </td>
                    <td className="allUsers-table-col">
                      {suspiciousPlayersRooms[key].bet}
                    </td>
                    <td className="allUsers-table-col">
                      {suspiciousPlayersRooms[key].minGames}
                    </td>
                    <td className="allUsers-table-col">
                      {suspiciousPlayersRooms[key].suspiciousPlays}
                    </td>
                    <td className="allUsers-table-col">
                      <Button onClick={() => this.openRoomModal(suspiciousPlayersRooms[key])}>
                        {/* {t('view')} */}
                        View
                      </Button>
                    </td>
                  </tr>
                </Fragment>
              ))}
            </tbody>
          </table>

          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>Close</Button>
          </ModalFooter>
        </Modal>

        <Modal container={`div > div`} isOpen={openRoomModal} toggle={this.toggleRoom} style={{ maxWidth: '65%' }}>
          <ModalHeader toggle={this.toggleRoom}>
            {room ? `Room: ${room.roomId}, Bet: ${room.bet}, ${room.minGames ? `Min games: ${room.minGames}` : ''}, ${room.fourPRoom ? '4' : '3'} Players` : ''}
          </ModalHeader>
          <ModalBody>
            <RoomLogs roomData={roomData} roomId={roomId} roomParams={room} />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleRoom}>Close</Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  member: state.member || {},
  suspiciousPlayers: state.admin.suspiciousPlayers || {},
  suspiciousPlayersArr: state.admin.suspiciousPlayersArr || [],
  suspiciousPlayersRooms: state.admin.suspiciousPlayersRooms || {},
  roomData: state.admin.roomData || {},
});

const mapDispatchToProps = {
  fetchSuspiciousPlayers: getSuspiciousPlayers,
  fetchSuspiciousPlayersRooms: getSuspiciousPlayersRooms,
  fetchRoomLog: getRoomLog,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('admin')(SuspiciousPlayers));

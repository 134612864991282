import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import { connect } from 'react-redux';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';

import ScrollAreaWrapper from '../UI/ScrollAreaWrapper';

import myInfoImg from '../../../images/icons/my_profile.png';
import coinImg from '../../../images/coin.svg';

import { getAchievements, claimAchievement } from '../../../actions/member';
import * as constants from '../../../constants/constants';

//winSmall90, winSmall120, dealtNoTrumps, dealtOnlyQueensJacks, dealtOnly789

class Achievements extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    fetchAchievements: PropTypes.func.isRequired,
    changeTab: PropTypes.func.isRequired,
    claimAchievementFunc: PropTypes.func.isRequired,
    member: PropTypes.shape({
      achievements: PropTypes.shape({}),
      gamesPlayed: PropTypes.number,
      gamesWon: PropTypes.number,
    }),
    i18n: PropTypes.shape(),
    userAchievements: PropTypes.shape(),
    claimedAchievements: PropTypes.shape(),
    achievementsNotification: PropTypes.bool,
    gamesPlayed: PropTypes.number,
    gamesWon: PropTypes.number,
    activeTab: PropTypes.string,
    setAchievementsNotification: PropTypes.func.isRequired,
  };

  static defaultProps = {
    member: {},
    i18n: {},
    achievementsNotification: false,
    userAchievements: {},
    claimedAchievements: {},
    gamesPlayed: 0,
    gamesWon: 0,
    activeTab: 0,
  };

  constructor(props) {
    super(props);

    this.state = {
      achievementTab: 0,
      listScrollPos: 0,
      achScrollPos: 0,
      //  achievements: [],
      achievementsIds: constants.ACHIEVEMENT_IDS,
      achievementsData: {
        gamesPlayed: {
          borders: [
            1, 5, 10, 50, 100, 250, 500, 1000, 5000, 10000, 50000, 100000,
            250000, 500000, 1000000,
          ],
          rewards: [
            25, 50, 100, 300, 500, 750, 1000, 1500, 2000, 3000, 5000, 10000,
            15000, 25000, 50000,
          ],
        },
        gamesWon: {
          borders: [
            1, 3, 5, 10, 25, 50, 100, 250, 500, 1000, 5000, 10000, 25000,
            100000, 500000,
          ],
          rewards: [
            25, 50, 100, 300, 500, 750, 1000, 1500, 2000, 3000, 5000, 10000,
            15000, 25000, 50000,
          ],
        },
        highestEarned: {
          borders: [
            1000, 2500, 5000, 7500, 10000, 25000, 50000, 75000, 100000, 250000,
            500000, 1000000, 3000000, 5000000, 10000000,
          ],
          rewards: [
            100, 250, 500, 750, 1000, 1250, 1500, 1750, 2000, 3000, 5000, 7500,
            10000, 15000, 25000,
          ],
        },
        earnedInADay: {
          borders: [
            750, 1000, 1500, 2000, 3000, 5000, 7500, 10000, 15000, 25000, 50000,
            75000, 100000, 250000, 500000,
          ],
          rewards: [
            10, 30, 50, 70, 90, 300, 500, 700, 900, 1100, 2000, 3000, 4000,
            5000, 6000,
          ],
        },
        maxSuccessionWins: {
          borders: [3, 5, 7, 10, 15, 20, 25, 30, 40, 50, 60, 70, 80, 90, 100],
          rewards: [
            50, 60, 70, 80, 90, 200, 300, 400, 500, 600, 1000, 1500, 2000, 2500,
            3000,
          ],
        },
        maxSuccessionLosses: {
          borders: [3, 5, 7, 10, 15, 20, 25, 30, 40, 50, 60, 70, 80, 90, 100],
          rewards: [
            50, 60, 70, 80, 90, 200, 300, 400, 500, 600, 1000, 1500, 2000, 2500,
            3000,
          ],
        },
        zolePlayed: {
          borders: [
            1, 3, 5, 10, 100, 250, 500, 1000, 5000, 10000, 50000, 100000,
            250000, 500000, 1000000,
          ],
          rewards: [
            10, 20, 30, 40, 50, 100, 150, 200, 250, 300, 500, 600, 700, 800,
            1000,
          ],
        },
        zoleWon: {
          borders: [
            1, 5, 10, 25, 50, 75, 100, 250, 500, 1000, 2500, 5000, 10000, 25000,
            50000,
          ],
          rewards: [
            50, 60, 70, 80, 90, 200, 300, 400, 500, 600, 1000, 1500, 2000, 2500,
            3000,
          ],
        },
        fastGamesPlayed: {
          borders: [
            1, 3, 5, 10, 100, 250, 500, 1000, 5000, 10000, 50000, 100000,
            250000, 500000, 1000000,
          ],
          rewards: [
            10, 20, 30, 40, 50, 100, 150, 200, 250, 300, 500, 600, 700, 800,
            1000,
          ],
        },
        lightningGamesPlayed: {
          borders: [
            1, 3, 5, 10, 100, 250, 500, 1000, 5000, 10000, 50000, 100000,
            250000, 500000, 1000000,
          ],
          rewards: [
            10, 20, 30, 40, 50, 100, 150, 200, 250, 300, 500, 600, 700, 800,
            1000,
          ],
        },
        unlimitedGamesPlayed: {
          borders: [
            1, 3, 5, 10, 100, 250, 500, 1000, 5000, 10000, 50000, 100000,
            250000, 500000, 1000000,
          ],
          rewards: [
            10, 20, 30, 40, 50, 100, 150, 200, 250, 300, 500, 600, 700, 800,
            1000,
          ],
        },
        mazaZolePlayed: {
          borders: [
            1, 3, 5, 10, 100, 250, 500, 1000, 5000, 10000, 50000, 100000,
            250000, 500000, 1000000,
          ],
          //borders: [1, 3, 5, 10, 100, 250, 500, 1000, 10000, 25000, 50000],
          rewards: [
            10, 20, 30, 40, 50, 100, 150, 200, 250, 300, 500, 600, 700, 800,
            1000,
          ],
        },
        mazaZoleWon: {
          borders: [
            1, 5, 10, 25, 50, 75, 100, 250, 500, 1000, 2500, 5000, 10000, 25000,
            50000,
          ],
          rewards: [
            50, 60, 70, 80, 90, 200, 300, 400, 500, 600, 1000, 1500, 2000, 2500,
            3000,
          ],
        },
        bonusSpins: {
          borders: [
            3, 5, 7, 10, 15, 20, 25, 30, 40, 50, 75, 100, 250, 500, 1000,
          ],
          rewards: [
            20, 30, 40, 50, 60, 100, 200, 300, 400, 500, 700, 900, 1100, 1300,
            1500,
          ],
        },
        joinedTournaments: {
          borders: [
            3, 5, 7, 10, 15, 20, 25, 30, 40, 50, 75, 100, 250, 500, 1000,
          ],
          rewards: [
            10, 20, 30, 40, 50, 100, 150, 200, 250, 300, 500, 600, 700, 800,
            1000,
          ],
        },
        giftsReceived: {
          borders: [
            1, 3, 5, 10, 25, 50, 75, 100, 250, 500, 750, 1000, 2000, 3000, 5000,
          ],
          rewards: [
            10, 20, 30, 40, 50, 100, 150, 200, 250, 300, 500, 600, 700, 800,
            1000,
          ],
        },
        reachedTournamentTop10: {
          borders: [1, 3, 5, 7, 10, 15, 20, 25, 30, 35, 50, 75, 100, 250, 500],
          rewards: [
            50, 60, 70, 80, 90, 200, 300, 400, 500, 600, 1000, 1500, 2000, 2500,
            3000,
          ],
        },
        reachedTop100: {
          borders: [
            1, 3, 5, 7, 14, 30, 60, 90, 120, 150, 180, 270, 365, 730, 1095,
          ],
          rewards: [
            50, 60, 70, 80, 90, 200, 300, 400, 500, 600, 1000, 1500, 2000, 2500,
            3000,
          ],
        },
        giftsSent: {
          borders: [
            1, 3, 5, 10, 25, 50, 75, 100, 250, 500, 750, 1000, 2000, 3000, 5000,
          ],
          rewards: [
            10, 20, 30, 40, 50, 100, 150, 200, 250, 300, 500, 600, 700, 800,
            1000,
          ],
        },
        maxParties: {
          borders: [
            3, 5, 7, 10, 15, 20, 25, 50, 75, 100, 150, 200, 250, 500, 1000,
          ],
          rewards: [
            20, 30, 40, 50, 60, 100, 200, 300, 400, 500, 700, 900, 1100, 1300,
            1500,
          ],
        },
        supportMessagesSent: {
          borders: [1, 2, 3, 5, 7, 10, 15, 20, 25, 50, 75, 100, 250, 500, 1000],
          rewards: [
            50, 60, 70, 80, 90, 200, 300, 400, 500, 600, 1000, 1500, 2000, 2500,
            3000,
          ],
        },
        storePurchase: {
          borders: [1, 2, 3, 5, 10, 15, 20, 25, 30, 35, 50, 75, 100, 250, 500],
          rewards: [
            50, 60, 70, 80, 90, 200, 300, 400, 500, 600, 1000, 1500, 2000, 2500,
            3000,
          ],
        },
        galdinsPlayed: {
          borders: [
            1, 3, 5, 10, 100, 250, 500, 1000, 5000, 10000, 50000, 100000,
            250000, 500000, 1000000,
          ],
          rewards: [
            10, 20, 30, 40, 50, 100, 150, 200, 250, 300, 500, 600, 700, 800,
            1000,
          ],
        },
        galdinsWon: {
          borders: [
            1, 5, 10, 25, 50, 75, 100, 250, 500, 1000, 2500, 5000, 10000, 25000,
            50000,
          ],
          rewards: [
            50, 60, 70, 80, 90, 200, 300, 400, 500, 600, 1000, 1500, 2000, 2500,
            3000,
          ],
        },
        galdinsLose: {
          borders: [
            1, 5, 10, 25, 50, 75, 100, 250, 500, 1000, 2500, 5000, 10000, 25000,
            50000,
          ],
          rewards: [
            50, 60, 70, 80, 90, 200, 300, 400, 500, 600, 1000, 1500, 2000, 2500,
            3000,
          ],
        },
        maxDailyLogin: {
          borders: [1, 3, 5, 7, 14, 30, 60, 90, 120, 150, 180, 270, 365, 730, 1095],
          rewards: [50, 100, 250, 500, 750, 1000, 1500, 2000, 2500, 3000, 5000, 7500, 10000, 15000, 25000],
        },
      },
      achievementNotif: {
        singleAchievements: false,
        gamesPlayed: false,
        gamesWon: false,
        highestEarned: false,
        earnedInADay: false,
        maxSuccessionWins: false,
        maxSuccessionLosses: false,
        zolePlayed: false,
        zoleWon: false,
        fastGamesPlayed: false,
        lightningGamesPlayed: false,
        unlimitedGamesPlayed: false,
        mazaZolePlayed: false,
        mazaZoleWon: false,
        bonusSpins: false,
        joinedTournaments: false,
        giftsReceived: false,
        reachedTournamentTop10: false,
        reachedTop100: false,
        giftsSent: false,
        maxParties: false,
        supportMessagesSent: false,
        storePurchase: false,
        galdinsPlayed: false,
        galdinsWon: false,
        galdinsLose: false,
        maxDailyLogin: false,
      },
      achievements: ['singleAchievementsTitle', 'gamesPlayedTitle', 'gamesWonTitle', 'balanceTitle', 'earnedInADayTitle', 'maxSuccessionWinsTitle', 'maxSuccessionLossesTitle', 'zolePlayedTitle', 'zoleWonTitle',
        'fastGamesPlayedTitle', 'lightningGamesPlayedTitle', 'unlimitedGamesPlayedTitle', 'mazaZolePlayedTitle', 'mazaZoleWonTitle', 'bonusSpinsTitle', 'joinedTournamentsTitle', 'reachedTournamentTop10Title',
        'reachedTop100Title', 'giftsReceivedTitle', 'giftsSentTitle', 'maxPartiesTitle', 'supportMessagesSentTitle', 'storePurchasesTitle', 'galdiniPlayedTitle', 'galdiniWonTitle', 'galdiniLoseTitle', 'maxDailyLoginTitle'],
    };
  }

  componentDidMount() {
    const { fetchAchievements } = this.props;

    fetchAchievements();

    //  this.setState({ achievements: [ t('singleAchievements'), t('gamesPlayed'), t('gamesWon'), t('balance'), t('earnedInADay'), t('maxSuccessionWins'), t('maxSuccessionLosses'), t('zolePlayed'), t('zoleWon'),
    //  t('fastGamesPlayed'), t('mazaZolePlayed'), t('mazaZoleWon'), t('bonusSpins'), t('joinedTournaments'), t('giftsReceived'),
    //  t('reachedTournamentTop10'), t('reachedTop100'), t('giftsSent'), t('maxParties'), t('supportMessagesSent'), t('storePurchase'), t('galdiniPlayed'), t('galdiniWon'), t('galdiniLose') ] });
  }

  changeAchievementTab = (tab) => {
    this.setState({ achievementTab: tab });
  };

  findAchievement = (
    key,
    borders,
    rewards,
    userAchievements,
    claimedAchievements,
    rev,
  ) => {
    const {
      t,
      achievementsNotification,
      setAchievementsNotification,
      claimAchievementFunc,
      i18n,
    } = this.props;
    const { achievementNotif, achievementsIds, achievementTab } = this.state;
    let name = '';
    let firstName = '';
    let secondName = '';
    let thirdName = '';
    if (key === 'gamesPlayed') {
      firstName = t('firstGame');
      name = t('gamesPlayed');
    } else if (key === 'gamesWon') {
      firstName = t('firstWin');
      thirdName = t('3wins');
      name = t('wins');
    } else if (key === 'zolePlayed') {
      firstName = t('zolePlayed');
      thirdName = t('3zolesPlayed');
      name = t('zolesPlayed');
    } else if (key === 'zoleWon') {
      firstName = t('zoleWon');
      name = t('zolesWon');
    } else if (key === 'mazaZolePlayed') {
      firstName = t('mazaZolePlayed');
      thirdName = t('3mazasZolesPlayed');
      name = t('mazasZolesPlayed');
    } else if (key === 'mazaZoleWon') {
      firstName = t('mazaZoleWon');
      name = t('mazasZolesWon');
    } else if (key === 'giftsSent') {
      firstName = t('giftSent');
      thirdName = t('3giftsSent');
      name = t('giftsSent');
    } else if (key === 'giftsReceived') {
      firstName = t('giftReceived');
      thirdName = t('3giftsReceived');
      name = t('giftsReceived');
    } else if (key === 'storePurchase') {
      firstName = t('storePurchase');
      secondName = t('2storePurchases');
      thirdName = t('3storePurchases');
      name = t('storePurchases');
    } else if (key === 'supportMessagesSent') {
      firstName = t('supportMessageSent');
      thirdName = t('3supportMessagesSent');
      name = t('supportMessagesSent');
    } else if (key === 'maxParties') {
      thirdName = t('3maxParties');
      name = t('maxParties');
    } else if (key === 'maxSuccessionWins') {
      thirdName = t('3maxSuccessionWins');
      name = t('maxSuccessionWins');
    } else if (key === 'maxSuccessionLosses') {
      name = t('maxSuccessionLosses');
      thirdName = t('3maxSuccessionLosses');
    } else if (key === 'fastGamesPlayed') {
      firstName = t('fastGamePlayed');
      thirdName = t('3fastGamesPlayed');
      name = t('fastGamesPlayed');
    } else if (key === 'lightningGamesPlayed') {
      firstName = t('lightningGamePlayed');
      thirdName = t('3lightningGamesPlayed');
      name = t('lightningGamesPlayed');
    } else if (key === 'unlimitedGamesPlayed') {
      firstName = t('unlimitedGamePlayed');
      thirdName = t('3unlimitedGamesPlayed');
      name = t('unlimitedGamesPlayed');
    } else if (key === 'bonusSpins') {
      thirdName= t('3bonusSpins');
      name = t('bonusSpins');
    } else if (key === 'joinedTournaments') {
      thirdName = t('3joinedTournaments');
      name = t('joinedTournaments');
    } else if (key === 'reachedTournamentTop10') {
      firstName = t('reachedFirstTournamentTop10');
      thirdName = t('3reachedTournamentTop10');
      name = t('reachedTournamentTop10');
    } else if (key === 'highestEarned') {
      name = t('balance');
    } else if (key === 'earnedInADay') {
      name = t('earnedInADay');
    } else if (key === 'reachedTop100') {
      firstName = t('reachedFirstTop100');
      thirdName = t('3reachedTop100');
      name = t('reachedTop100');
    } else if (key === 'galdinsPlayed') {
      firstName = t('galdinsPlayed');
      thirdName = t('3galdiniPlayed');
      name = t('galdiniPlayed');
    } else if (key === 'galdinsWon') {
      firstName = t('galdinsWon');
      name = t('galdiniWon');
    } else if (key === 'galdinsLose') {
      firstName = t('galdinsLose');
      name = t('galdiniLose');
    } else if (key === 'maxDailyLogin') {
      firstName = t('maxDailyLogin');
      thirdName = t('3maxDailyLogins');
      name = t('maxDailyLogins');
    }


    const noCounter = [
      'gamesPlayed',
      'gamesWon',
      'fastGamesPlayed',
      'lightningGamesPlayed',
      'unlimitedGamesPlayed',
      'maxDailyLogin',
      'reachedTournamentTop10',
    ];

    let reward = 0;

    const claimedAchievementsVal = claimedAchievements || 0;

    borders.map((item, index) => {
      console.log('achievements reward test', userAchievements, claimedAchievementsVal);

      if (item <= userAchievements && claimedAchievementsVal <= index) {
        reward += rewards[index];
      }
      return null;
    });

    console.log('achievements reward', reward, achievementsNotification, achievementNotif[key]);

    if (reward) {
      if (!achievementsNotification) {
        setAchievementsNotification(true);
      }
      if (achievementNotif && !achievementNotif[key]) {
        this.setState(prevState => ({
          achievementNotif: {
            ...prevState.achievementNotif,
            [key]: true,
          },
        }));
      }
    }

    if (!reward && achievementNotif[key]) {
      if (achievementsNotification) {
        setAchievementsNotification(false);
      }

      this.setState(prevState => ({
        achievementNotif: {
          ...prevState.achievementNotif,
          [key]: false,
        },
      }));
    }
    if (achievementsIds && achievementsIds[achievementTab] === key) {
      return (
        <Row className="ach-table-row aaaa1">
          {borders.map((item, index) => {
            let img;

            //  if (item <= userAchievements && claimedAchievementsVal <= index) {
            //    reward = reward + rewards[index];
            //  }

            let imgKey = key;
            if (key === 'highestEarned') {
              imgKey = 'balance';
            }

            try {
              if (borders[index + 1]) {
                // eslint-disable-next-line
                img = require(`../../../images/Ach/${imgKey}_${borders[index]}_${borders[index + 1]}.png`);
              } else {
                // eslint-disable-next-line
                img = require(`../../../images/Ach/${imgKey}_${borders[index]}.png`);
              }
            } catch (e) {
              console.log(e);
            }

            const val = borders[index] >= 1000 ? `${borders[index] / 1000}k` : borders[index];

          //  console.log('userAchievements userAchievements', userAchievements, borders[index], borders[index + 1]);

            let grayscale = !(borders[index + 1] && userAchievements >= borders[index]) || (!borders[index + 1] && userAchievements >= borders[index]);
            let allFinished = false;
            if (!borders[index + 1] && userAchievements >= borders[index]) {
              grayscale = false;
              allFinished = true;
            }

            return (
              // eslint-disable-next-line
              <Col xs="3" sm="3" className="ach-column" key={key + index}>
                <div className="ach-column-div">
                  <Media
                    src={img}
                    className={`${
                      grayscale ? 'grayscale' : null
                    } ach-column-img`}
                  />
                </div>
                {!rev ? (
                  <span>
                    {`${
                      index === 0 && noCounter.includes(key) ? '' : val
                      } ${(key === 'bonusSpins' || key === 'joinedTournaments' || key === 'maxParties' || key === 'maxSuccessionWins' || key === 'maxSuccessionLosses') && i18n.language === constants.sendLanguage.ru ? (
                      index === 0 ? thirdName : name
                    ) : (key === 'giftsReceived' || key === 'reachedTop100' || key === 'giftsSent' || key === 'galdinsPlayed' || key === 'maxDailyLogin' || key === 'gamesWon' || key === 'zolePlayed' || key === 'fastGamesPlayed' || key === 'reachedTournamentTop10' || key === 'mazaZolePlayed' || key === 'lightningGamesPlayed' || key === 'unlimitedGamesPlayed') && i18n.language === constants.sendLanguage.ru ? (
                      index === 0 ? firstName : index === 1 ? thirdName : name
                    ) : (key === 'storePurchase' && i18n.language === constants.sendLanguage.ru) ? (
                      index === 0 ? firstName : index === 1 ? secondName : index === 2 ? thirdName : name
                    ) : (key === 'supportMessagesSent') && i18n.language === constants.sendLanguage.ru ? (
                      index === 0 ? firstName : (index === 1 || index === 2) ? thirdName : name
                    ) : (
                      index === 0 && firstName ? firstName : name
                    )}`}
                  </span>
                ) : (
                  <span>
                    {
                      (key === 'highestEarned' || key === 'earnedInADay') ? (
                        `${index === 0 && firstName ? firstName : name} ${index === 0 && noCounter.includes(key) ? '' : val}`
                      ) : (
                        `${(key === 'bonusSpins' || key === 'joinedTournaments' || key === 'maxParties' || key === 'maxSuccessionWins' || key === 'maxSuccessionLosses') && i18n.language === constants.sendLanguage.ru ? (
                            index === 0 ? thirdName : name
                            ) : (key === 'giftsReceived' || key === 'reachedTop100' || key === 'giftsSent' || key === 'galdinsPlayed' || key === 'maxDailyLogin' || key === 'gamesWon' || key === 'zolePlayed' || key === 'fastGamesPlayed' || key === 'reachedTournamentTop10' || key === 'mazaZolePlayed' || key === 'lightningGamesPlayed' || key === 'unlimitedGamesPlayed') && i18n.language === constants.sendLanguage.ru ? (
                            index === 0 ? firstName : index === 1 ? thirdName : name
                          ) : (key === 'storePurchase' && i18n.language === constants.sendLanguage.ru) ? (
                            index === 0 ? firstName : index === 1 ? secondName : index === 2 ? thirdName : name
                          ) : (key === 'supportMessagesSent') && i18n.language === constants.sendLanguage.ru ? (
                            index === 0 ? firstName : (index === 1 || index === 2) ? thirdName : name
                          ) : (
                            index === 0 && firstName ? firstName : name
                          )} ${
                            noCounter.includes(key) ? '' : val
                        }`
                      )
                    }
                  </span>
                )}
                <div>
                  <span className={classNames({ "my-info-achievements-count": !grayscale, "my-info-achievements-uncount": grayscale })}>{!grayscale ? (!allFinished ? borders[index] : userAchievements) : userAchievements || 0} / {borders[index]}</span>
                </div>
                <Row>
                  <Col>
                    <div className="ach-collect-button-text">
                      {rewards[index]}
                    </div>
                    <Media src={coinImg} className="ach-collect-button-coin" />
                  </Col>
                </Row>
              </Col>
            );
          })}

          {/* <Col sm="12">
            <Button
              color="link"
              disabled={reward === 0}
              className="ach-collect-button"
              onClick={() => claimAchievementFunc(key)}
            >
              <div className="ach-collect-button-text">
                {`${t('take')} ${reward}`}
              </div>
              <Media src={coinImg} className="ach-collect-button-coin" />
            </Button>
          </Col> */}
        </Row>
      );
    }
    return null;
  };

  singleTimeAchievements = (key, userAchievements, claimedAchievements) => {
    const {
      t,
      setAchievementsNotification,
      achievementsNotification,
      claimAchievementFunc,
    } = this.props;
    const { achievementNotif, achievementTab, achievementsIds } = this.state;

    let name = '';
    let reward = 0;

    if (key === 'winLarge61') {
      name = t('winLarge61');
      if (userAchievements && !claimedAchievements) {
        reward = 300;
      }
    } else if (key === 'winLarge91') {
      name = t('winLarge91');
      if (userAchievements && !claimedAchievements) {
        reward = 500;
      }
    } else if (key === 'winLarge120') {
      name = t('winLarge120');
      if (userAchievements && !claimedAchievements) {
        reward = 600;
      }
    } else if (key === 'winSmall60') {
      name = t('winSmall60');
      if (userAchievements && !claimedAchievements) {
        reward = 300;
      }
    } else if (key === 'winZoleAll') {
      name = t('winZoleAll');
      if (userAchievements && !claimedAchievements) {
        reward = 600;
      }
    } else if (key === 'winZoleTwoAces') {
      name = t('winZoleTwoAces');
      if (userAchievements && !claimedAchievements) {
        reward = 1000;
      }
    } else if (key === 'loseLarge60') {
      name = t('loseLarge60');
      if (userAchievements && !claimedAchievements) {
        reward = 300;
      }
    } else if (key === 'loseLarge30') {
      name = t('loseLarge30');
      if (userAchievements && !claimedAchievements) {
        reward = 150;
      }
    } else if (key === 'loseLarge0') {
      name = t('loseLarge0');
      if (userAchievements && !claimedAchievements) {
        reward = 150;
      }
    } else if (key === 'take3Aces') {
      name = t('take3Aces');
      if (userAchievements && !claimedAchievements) {
        reward = 333;
      }
    } else if (key === 'take0Points') {
      name = t('take0Points');
      if (userAchievements && !claimedAchievements) {
        reward = 100;
      }
    } else if (key === 'takeTwoAces') {
      name = t('takeTwoAces');
      if (userAchievements && !claimedAchievements) {
        reward = 1000;
      }
    } else if (key === 'takeTwoNines') {
      name = t('takeTwoNines');
      if (userAchievements && !claimedAchievements) {
        reward = 1000;
      }
    } else if (key === 'loseZoleTwoNines') {
      name = t('loseZoleTwoNines');
      if (userAchievements && !claimedAchievements) {
        reward = 2000;
      }
    } else if (key === 'loseTableAllTricks') {
      name = t('loseTableAllTricks');
      if (userAchievements && !claimedAchievements) {
        reward = 3000;
      }
    } else if (key === 'winSmall90') {
      name = t('winSmall90');
      if (userAchievements && !claimedAchievements) {
        reward = 500;
      }
    } else if (key === 'winSmall120') {
      name = t('winSmall120');
      if (userAchievements && !claimedAchievements) {
        reward = 2000;
      }
    } else if (key === 'dealtOnlyQueensJacks') {
      name = t('dealtOnlyQueensJacks');
      if (userAchievements && !claimedAchievements) {
        reward = 1000;
      }
    } else if (key === 'dealtNoTrumps') {
      name = t('dealtNoTrumps');
      if (userAchievements && !claimedAchievements) {
        reward = 2000;
      }
    } else if (key === 'dealtOnly789') {
      name = t('dealtOnly789');
      if (userAchievements && !claimedAchievements) {
        reward = 3000;
      }
    }

    console.log('achievements reward 2', reward);

    if (reward) {
      if (!achievementsNotification) {
        setAchievementsNotification(true);
      }

      if (achievementNotif && !achievementNotif.singleAchievements) {
        this.setState(prevState => ({
          achievementNotif: {
            ...prevState.achievementNotif,
            singleAchievements: true,
          },
        }));
      }
    }

    //  const claimedAchievementsVal = claimedAchievements ? claimedAchievements : 0;

    let img;

    try {
      // eslint-disable-next-line
      if (constants.BIG_SIZE_ACH.includes(key)) {
        img = require(`../../../images/Ach/${key}_1.svg`);
      } else {
        img = require(`../../../images/Ach/${key}_1.png`);
      }
    } catch (e) {
      console.log(e);
    }

    const grayscale = !userAchievements;

    if (
      achievementsIds
      && achievementsIds[achievementTab] === 'singleAchievements'
    ) {
      return (
        <Col xs="4" sm="4" className="ach-column" key={key}>
          <div className="ach-column-div">
            <Media
              src={img}
              className={`${grayscale ? 'grayscale' : null} ach-column-img`}
            />
          </div>
          <span>{`${name}`}</span>

          <div className="ach-collect-button-text">
            {constants.UNIQUE_ACHIEVEMNT_DATA[key].reward}
          </div>
          <Media src={coinImg} className="ach-collect-button-coin" />
          {/* <Button
            color="link"
            disabled={reward === 0}
            className="ach-collect-button ach-collect-button-single"
            onClick={() => claimAchievementFunc(key)}
          >
            <div className="ach-collect-button-text">
              {`${t('take')} ${reward}`}
            </div>
            <Media src={coinImg} className="ach-collect-button-coin" />
          </Button> */}
        </Col>
      );
    }
    return null;
  };

  updateListScrollPos = (val) => {
    this.setState({ listScrollPos: val.topPosition || 0 });
  };

  updateAchScrollPos = (val) => {
    this.setState({ achScrollPos: val.topPosition || 0 });
  };

  scrollAch = (newVal) => {
    this.achScrollbar.scrollYTo(newVal);
  };

  scrollAchList = (newVal) => {
    this.achListScrollbar.scrollYTo(newVal);
  };

  render() {
    const {
      t,
      changeTab,
      achievementsNotification,
      i18n,
      claimedAchievements,
      userAchievements,
      gamesPlayed,
      gamesWon,
      activeTab,
    } = this.props;

    const {
      achievementTab,
      achievementsData,
      achievementNotif,
      achievements,
      achievementsIds,
      achScrollPos,
      listScrollPos,
    } = this.state;

    console.log('userAchievements', userAchievements, claimedAchievements);
    //  const { claimedAchievements, userAchievements, gamesPlayed, gamesWon } = member;

    return (
      <div className="my-info">
        {activeTab && activeTab === '11' ? (
          <Helmet>
            <title>
              Zole - {t('common:route.achievements')}
            </title>
            <meta name="description" content={t('common:route.achievements')} />
          </Helmet>
        ) : null}
        <Row className="my-info-header">
          {/*  <Col xs="4" sm="4">
            <Media src={myInfoImg} className="my-info-header-image" />
            <div className="my-info-header-text">
              {t('common:myInfo.achievements')}
            </div>
          </Col>
          <Col className="menu-topTab">
            <Button color="link" className="my-info-header-button" onClick={() => changeTab('9')}>
              {t('common:myInfo.friends')}
            </Button>
          </Col>
          <Col className="menu-topTab">
            <Button color="link" className={`my-info-header-button ${i18n.language === 'ru' ? 'my-info-header-button-ru' : null}`} onClick={() => changeTab('10')}>
              {t('common:myInfo.ignoredPlayers')}
            </Button>
          </Col>
          <Col className="menu-topTab" style={{ marginRight: 15 }}>
            <Button color="link" className={`my-info-header-button active ${achievementsNotification ? 'my-info-header-button-notification' : ''}`} onClick={() => changeTab('11')}>
              {t('common:myInfo.achievements')}
            </Button>
          </Col>
          <Col className="menu-topTab" style={{ marginRight: 15 }}>
            <Button color="link" className="my-info-header-button" onClick={() => changeTab('12')}>
              {t('common:myInfo.results')}
            </Button>
          </Col>  */}

          <Col xs="3">
            <Media src={myInfoImg} className="my-info-header-image" />
            <div className="my-info-header-text">
              {t('common:myInfo.achievements')}
            </div>
          </Col>
          <Col xs="9" style={{ paddingRight: 30 }}>
            <Row>
              {/* Stop for production mode(change xs=3 to xs2 for first 3) */}
              <Col xs="2" className="menu-topTab">
                <Button
                  color="link"
                  className="my-info-header-button"
                  onClick={() => changeTab(constants.MENU_NAVIGATION.friends)}
                >
                  {t('common:myInfo.friends')}
                </Button>
              </Col>
              <Col xs="2" className="menu-topTab">
                <Button
                  color="link"
                  className={`my-info-header-button`}
                  onClick={() => changeTab(constants.MENU_NAVIGATION.ignoredUsers)}
                >
                  {t('common:myInfo.ignoredPlayers')}
                </Button>
              </Col>
              <Col xs="2" className="menu-topTab achievements-menu">
                <Button
                  color="link"
                  // className={`my-info-header-button active ${
                  //   achievementsNotification
                  //     ? 'my-info-header-button-notification'
                  //     : ''
                  // }`}
                  className={`my-info-header-button active`}
                  onClick={() => changeTab(constants.MENU_NAVIGATION.archievements)}
                >
                  {t('common:myInfo.achievements')}
                </Button>
              </Col>
              <Col xs="2" className="menu-topTab">
                <Button
                  color="link"
                  className="my-info-header-button"
                  onClick={() => changeTab(constants.MENU_NAVIGATION.gameHistory)}
                >
                  {t('common:myInfo.results')}
                </Button>
              </Col>
              {/* Stop for production mode */}
              <Col xs="2" className="menu-topTab">
                <Button color="link" className="my-info-header-button" onClick={() => changeTab(constants.MENU_NAVIGATION.weeklyStatus)}>
                  {t('common:myInfo.weeklyStatus')}
                </Button>
              </Col>
              <Col xs="2" className="menu-topTab">
                <Button color="link" className="my-info-header-button" onClick={() => changeTab(constants.MENU_NAVIGATION.gameLogs)}>
                  {t('common:myInfo.gameLogs')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="my-info-content-joyride" style={{ marginTop: 20 }}>
          <Col xs="5" sm="5" className="ach-list-wrapper">
            {/*  <ScrollArea
              speed={0.65}
              className="ach-table-scrollarea"
              contentClassName="money-history-table-body"
              smoothScrolling
              stopScrollPropagation
              verticalContainerStyle={{
                background: 'transparent',
                opacity: 1,
                width: 11,
              }}
              verticalScrollbarStyle={{
                background: '#fff',
                borderRadius: 0,
                width: 10,
              }}
              horizontal={false}
              onScroll={this.updateListScrollPos}
              ref={(el) => { this.achListScrollbar = el; }}
            >
              {achievements.map((item, index) => (
                <Button
                  key={item}
                  color="link"
                  className={`ach-list-button ${(achievementTab === index) ? ('ach-list-button-active') : ''} ${achievementsIds && achievementNotif && achievementNotif[achievementsIds[index]] ? ('ach-list-button-claim') : ''}`}
                  onClick={() => this.changeAchievementTab(index)}
                >
                  {t(item)}
                </Button>
              ))
              }
            </ScrollArea>
            <ScrollAreaButtons
              scrollPos={listScrollPos}
            //  scrollRef={this.achListScrollbar}
            //  scrollRef={this.achListScrollbar && this.achListScrollbar.state ? this.achListScrollbar.state : null}
              show
              scroll={this.scrollAchList}
              speed={30}
              rightOffset={2}
              topOffset={0}
            />  */}
            <ScrollAreaWrapper
              className="ach-table-scrollarea"
              //  contentClassName="money-history-table-body"
              show
              rightOffset={2}
              topOffset={0}
              bottomOffset={0}
            >
              {achievements.map((item, index) => (
                <Button
                  key={item}
                  color="link"
                  className={`ach-list-button ${
                    achievementTab === index ? 'ach-list-button-active' : ''
                  }`}
                  onClick={() => this.changeAchievementTab(index)}
                >
                  {t(item)}
                </Button>
              ))}
            </ScrollAreaWrapper>
          </Col>
          <Col xs="7" sm="7" className="ach-table-scrollarea-wrapper">
            {/*  <ScrollAreaButtons
              scrollPos={achScrollPos}
            //  scrollRef={this.achScrollbar}
            //  scrollRef={this.achScrollbar && this.achScrollbar.state ? this.achScrollbar.state : null}
              show
              scroll={this.scrollAch}
              speed={30}
              rightOffset={8}
              topOffset={0}
            />
            <ScrollArea
              speed={0.65}
              className="ach-table-scrollarea"
              contentClassName="money-history-table-body"
              smoothScrolling
              stopScrollPropagation
              verticalContainerStyle={{
                background: 'transparent',
                opacity: 1,
                width: 11,
              }}
              verticalScrollbarStyle={{
                background: '#fff',
                borderRadius: 0,
                width: 10,
              }}
              onScroll={this.updateAchScrollPos}
              horizontal={false}
              ref={(el) => { this.achScrollbar = el; }}
            > */}
            <ScrollAreaWrapper
              className="ach-table-scrollarea"
              //  contentClassName="money-history-table-body"
              show
              rightOffset={8}
              topOffset={0}
              bottomOffset={0}
            >
              {achievementTab === 0 && userAchievements && claimedAchievements && (
                <Row className="ach-table-row">
                  {this.singleTimeAchievements(
                    'winLarge61',
                    userAchievements.winLarge61,
                    claimedAchievements.winLarge61,
                  )}
                  {this.singleTimeAchievements(
                    'winLarge91',
                    userAchievements.winLarge91,
                    claimedAchievements.winLarge91,
                  )}
                  {this.singleTimeAchievements(
                    'winLarge120',
                    userAchievements.winLarge120,
                    claimedAchievements.winLarge120,
                  )}
                  {this.singleTimeAchievements(
                    'winSmall60',
                    userAchievements.winSmall60,
                    claimedAchievements.winSmall60,
                  )}
                  {this.singleTimeAchievements(
                    'winSmall90',
                    userAchievements.winSmall90,
                    claimedAchievements.winSmall90,
                  )}
                  {this.singleTimeAchievements(
                    'winSmall120',
                    userAchievements.winSmall120,
                    claimedAchievements.winSmall120,
                  )}
                  {this.singleTimeAchievements(
                    'winZoleAll',
                    userAchievements.winZoleAll,
                    claimedAchievements.winZoleAll,
                  )}
                  {this.singleTimeAchievements(
                    'take3Aces',
                    userAchievements.take3Aces,
                    claimedAchievements.take3Aces,
                  )}
                  {this.singleTimeAchievements(
                    'take0Points',
                    userAchievements.take0Points,
                    claimedAchievements.take0Points,
                  )}
                  {this.singleTimeAchievements(
                    'winZoleTwoAces',
                    userAchievements.winZoleTwoAces,
                    claimedAchievements.winZoleTwoAces,
                  )}
                  {this.singleTimeAchievements(
                    'loseLarge60',
                    userAchievements.loseLarge60,
                    claimedAchievements.loseLarge60,
                  )}
                  {this.singleTimeAchievements(
                    'loseLarge30',
                    userAchievements.loseLarge30,
                    claimedAchievements.loseLarge30,
                  )}
                  {this.singleTimeAchievements(
                    'loseLarge0',
                    userAchievements.loseLarge0,
                    claimedAchievements.loseLarge0,
                  )}
                  {this.singleTimeAchievements(
                    'takeTwoAces',
                    userAchievements.takeTwoAces,
                    claimedAchievements.takeTwoAces,
                  )}
                  {this.singleTimeAchievements(
                    'takeTwoNines',
                    userAchievements.takeTwoNines,
                    claimedAchievements.takeTwoNines,
                  )}
                  {this.singleTimeAchievements(
                    'loseZoleTwoNines',
                    userAchievements.loseZoleTwoNines,
                    claimedAchievements.loseZoleTwoNines,
                  )}
                  {this.singleTimeAchievements(
                    'loseTableAllTricks',
                    userAchievements.loseTableAllTricks,
                    claimedAchievements.loseTableAllTricks,
                  )}
                  {this.singleTimeAchievements(
                    'dealtOnlyQueensJacks',
                    userAchievements.dealtOnlyQueensJacks,
                    claimedAchievements.dealtOnlyQueensJacks,
                  )}
                  {this.singleTimeAchievements(
                    'dealtNoTrumps',
                    userAchievements.dealtNoTrumps,
                    claimedAchievements.dealtNoTrumps,
                  )}
                  {this.singleTimeAchievements(
                    'dealtOnly789',
                    userAchievements.dealtOnly789,
                    claimedAchievements.dealtOnly789,
                  )}
                </Row>
              )}
              {/*  {achievementTab === 1 && (
                <Fragment> */}
              {claimedAchievements && userAchievements && ( // && Object.keys(claimedAchievements).length > 0 - ADDING THIS HIDES ACHIEVEMENTS FOR NEW USERS WHO HAVE 0 CLAIMED ACHIEVEMENTS
                <Fragment>
                  {this.findAchievement(
                    'gamesPlayed',
                    achievementsData.gamesPlayed.borders,
                    achievementsData.gamesPlayed.rewards,
                    gamesPlayed,
                    claimedAchievements.gamesPlayed,
                  )}
                  {/*    </Fragment>
              )}
              {achievementTab === 2 && (
                <Fragment>  */}
                  {this.findAchievement(
                    'gamesWon',
                    achievementsData.gamesWon.borders,
                    achievementsData.gamesWon.rewards,
                    gamesWon,
                    claimedAchievements.gamesWon,
                  )}
                  {/*    </Fragment>
              )}
              {achievementTab === 3 && (
                <Fragment> */}
                  {this.findAchievement(
                    'highestEarned',
                    achievementsData.highestEarned.borders,
                    achievementsData.highestEarned.rewards,
                    userAchievements.highestEarned,
                    claimedAchievements.highestEarned,
                    true,
                  )}
                  {/*    </Fragment>
              )}
              {achievementTab === 4 && (
                <Fragment>  */}
                  {this.findAchievement(
                    'earnedInADay',
                    achievementsData.earnedInADay.borders,
                    achievementsData.earnedInADay.rewards,
                    userAchievements.earnedInADay,
                    claimedAchievements.earnedInADay,
                    true,
                  )}
                  {/*    </Fragment>
              )} */}

                  {this.findAchievement(
                    'maxSuccessionWins',
                    achievementsData.maxSuccessionWins.borders,
                    achievementsData.maxSuccessionWins.rewards,
                    userAchievements.maxSuccessionWins,
                    claimedAchievements.maxSuccessionWins,
                  )}

                  {this.findAchievement(
                    'maxSuccessionLosses',
                    achievementsData.maxSuccessionLosses.borders,
                    achievementsData.maxSuccessionLosses.rewards,
                    userAchievements.maxSuccessionLosses,
                    claimedAchievements.maxSuccessionLosses,
                  )}

                  {this.findAchievement(
                    'zolePlayed',
                    achievementsData.zolePlayed.borders,
                    achievementsData.zolePlayed.rewards,
                    userAchievements.zolePlayed,
                    claimedAchievements.zolePlayed,
                  )}

                  {this.findAchievement(
                    'zoleWon',
                    achievementsData.zoleWon.borders,
                    achievementsData.zoleWon.rewards,
                    userAchievements.zoleWon,
                    claimedAchievements.zoleWon,
                  )}

                  {this.findAchievement(
                    'fastGamesPlayed',
                    achievementsData.fastGamesPlayed.borders,
                    achievementsData.fastGamesPlayed.rewards,
                    userAchievements.fastGamesPlayed,
                    claimedAchievements.fastGamesPlayed,
                  )}

                  {this.findAchievement(
                    'lightningGamesPlayed',
                    achievementsData.lightningGamesPlayed.borders,
                    achievementsData.lightningGamesPlayed.rewards,
                    userAchievements.lightningGamesPlayed,
                    claimedAchievements.lightningGamesPlayed,
                  )}

                  {this.findAchievement(
                    'unlimitedGamesPlayed',
                    achievementsData.unlimitedGamesPlayed.borders,
                    achievementsData.unlimitedGamesPlayed.rewards,
                    userAchievements.unlimitedGamesPlayed,
                    claimedAchievements.unlimitedGamesPlayed,
                  )}

                  {this.findAchievement(
                    'mazaZolePlayed',
                    achievementsData.mazaZolePlayed.borders,
                    achievementsData.mazaZolePlayed.rewards,
                    userAchievements.mazaZolePlayed,
                    claimedAchievements.mazaZolePlayed,
                  )}

                  {this.findAchievement(
                    'mazaZoleWon',
                    achievementsData.mazaZoleWon.borders,
                    achievementsData.mazaZoleWon.rewards,
                    userAchievements.mazaZoleWon,
                    claimedAchievements.mazaZoleWon,
                  )}

                  {this.findAchievement(
                    'bonusSpins',
                    achievementsData.bonusSpins.borders,
                    achievementsData.bonusSpins.rewards,
                    userAchievements.bonusSpins,
                    claimedAchievements.bonusSpins,
                  )}

                  {this.findAchievement(
                    'joinedTournaments',
                    achievementsData.joinedTournaments.borders,
                    achievementsData.joinedTournaments.rewards,
                    userAchievements.joinedTournaments,
                    claimedAchievements.joinedTournaments,
                  )}

                  {this.findAchievement(
                    'giftsReceived',
                    achievementsData.giftsReceived.borders,
                    achievementsData.giftsReceived.rewards,
                    userAchievements.giftsReceived,
                    claimedAchievements.giftsReceived,
                  )}

                  {this.findAchievement(
                    'reachedTournamentTop10',
                    achievementsData.reachedTournamentTop10.borders,
                    achievementsData.reachedTournamentTop10.rewards,
                    userAchievements.reachedTournamentTop10,
                    claimedAchievements.reachedTournamentTop10,
                  )}

                  {this.findAchievement(
                    'reachedTop100',
                    achievementsData.reachedTop100.borders,
                    achievementsData.reachedTop100.rewards,
                    userAchievements.reachedTop100,
                    claimedAchievements.reachedTop100,
                  )}

                  {this.findAchievement(
                    'giftsSent',
                    achievementsData.giftsSent.borders,
                    achievementsData.giftsSent.rewards,
                    userAchievements.giftsSent,
                    claimedAchievements.giftsSent,
                  )}

                  {this.findAchievement(
                    'maxParties',
                    achievementsData.maxParties.borders,
                    achievementsData.maxParties.rewards,
                    userAchievements.maxParties,
                    claimedAchievements.maxParties,
                  )}

                  {this.findAchievement(
                    'supportMessagesSent',
                    achievementsData.supportMessagesSent.borders,
                    achievementsData.supportMessagesSent.rewards,
                    userAchievements.supportMessagesSent,
                    claimedAchievements.supportMessagesSent,
                  )}

                  {this.findAchievement(
                    'storePurchase',
                    achievementsData.storePurchase.borders,
                    achievementsData.storePurchase.rewards,
                    userAchievements.storePurchase,
                    claimedAchievements.storePurchase,
                  )}

                  {this.findAchievement(
                    'galdinsPlayed',
                    achievementsData.galdinsPlayed.borders,
                    achievementsData.galdinsPlayed.rewards,
                    userAchievements.galdinsPlayed,
                    claimedAchievements.galdinsPlayed,
                  )}

                  {this.findAchievement(
                    'galdinsWon',
                    achievementsData.galdinsWon.borders,
                    achievementsData.galdinsWon.rewards,
                    userAchievements.galdinsWon,
                    claimedAchievements.galdinsWon,
                  )}

                  {this.findAchievement(
                    'galdinsLose',
                    achievementsData.galdinsLose.borders,
                    achievementsData.galdinsLose.rewards,
                    userAchievements.galdinsLose,
                    claimedAchievements.galdinsLose,
                  )}

                  {this.findAchievement(
                    'maxDailyLogin',
                    achievementsData.maxDailyLogin.borders,
                    achievementsData.maxDailyLogin.rewards,
                    userAchievements.maxDailyLogin,
                    claimedAchievements.maxDailyLogin,
                  )}
                </Fragment>
              )}
            </ScrollAreaWrapper>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  claimedAchievements: state.member.claimedAchievements || {},
  userAchievements: state.member.userAchievements || {},
  gamesPlayed: state.member.gamesPlayed || 0,
  gamesWon: state.member.gamesWon || 0,
});

const mapDispatchToProps = {
  fetchAchievements: getAchievements,
  claimAchievementFunc: claimAchievement,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation(['achievements', 'common'])(Achievements));

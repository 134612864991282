import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { Helmet } from 'react-helmet';

import { connect } from 'react-redux';

// import isEqual from 'react-fast-compare';

// import moment from 'moment';
// import Moment from 'react-moment';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';

import {
  Table, Column, HeaderCell, Cell, ColumnGroup,
} from 'rsuite-table';

import DateFormat from '../UI/DateFormat';
// import ScrollGamesHistory from '../UI/ScrollGamesHistory';

// import ScrollArea from 'react-scrollbar';
// import ScrollAreaButtons from '../UI/ScrollAreaButtons';

// import CustomDate from '../UI/CustomDate';

import myInfoImg from '../../../images/icons/my_profile.png';

import 'rsuite-table/dist/css/rsuite-table.css';
import * as constants from '../../../constants/constants';

import {
  getGamesHistory,
} from '../../../actions/member';

class GamesHistory extends React.Component {
  static propTypes = {
    gamesHistory: PropTypes.arrayOf(PropTypes.shape({})),
  //  userSettings: PropTypes.shape({
  //    soundOn: PropTypes.bool,
 //   }),
    //  uid: PropTypes.string,
    i18n: PropTypes.shape(),
    achievementsNotification: PropTypes.bool,
    isFullscreen: PropTypes.bool,
    isFullscreenEnabled: PropTypes.bool,
    fetchGamesHistory: PropTypes.func.isRequired,
    changeTab: PropTypes.func.isRequired,
    fontMode: PropTypes.func,
    t: PropTypes.func.isRequired,
  }

  static defaultProps = {
    gamesHistory: [],
  //  userSettings: {},
    //  uid: null,
    i18n: null,
    achievementsNotification: false,
    isFullscreen: false,
    isFullscreenEnabled: false,
    fontMode: constants.FONT_MODE.xLarge,
  }

  constructor(props) {
    super(props);
    this.state = {
      // scrollPos: 0,
      hoverIndex: null,
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentWillMount() {
    const { fetchGamesHistory } = this.props;

    fetchGamesHistory();
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  /*  componentDidUpdate() {
    const { isFullscreen } = this.props;
    const { tableHeight } = this.state;


    //  (isFullscreen && innerHeight > 600) ? ((innerHeight * 0.62) - 10) : 360
    let newTableHeight;

    if (isFullscreen && window.innerHeight > 600) {
      newTableHeight = (window.innerHeight * 0.62) - 10;
    } else {
      newTableHeight = 360;
    }
    if (newTableHeight !== tableHeight) {
      console.log('change table height', { tableHeight, newTableHeight });
      this.setState({ tableHeight: newTableHeight });
    }
  } */

  /* shouldComponentUpdate(nextProps) {
    console.log('shouldComponentUpdate');
    if (!isEqual(nextProps, this.props)) {
      return true;
    }

    if (nextProps.isFullscreen !== this.props.isFullscreen) {
      return true;
    }


    return true;
  }  */

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    const { fontMode } = this.props;
    let headerHeight;
    
    const scrollBarHeight = 12;
    if (fontMode === constants.FONT_MODE.normal) {
      headerHeight = 44;
    } else if (fontMode === constants.FONT_MODE.large) {
      headerHeight = 50;
    } else { // all good
      headerHeight = 57;
    }
    const minRowsToShow = 3;
    const rowHeight = 32;
    const minHeight = headerHeight + (minRowsToShow * rowHeight) + scrollBarHeight;
    
    let wrapperHeight = 0.65 * window.innerHeight /*+ 65*/;
    const offset = 80; // adjust value so table is appropriate size for every height resolution
    let precentOfPageHeight = (wrapperHeight - offset) / window.innerHeight;
    // if(window.innerHeight >= 1080)

    const tableHeightTemp = window.innerHeight * precentOfPageHeight;
    const x = Math.floor((tableHeightTemp - minHeight) / rowHeight);
    let tableHeight = minHeight + rowHeight * x;
    if (tableHeight < minHeight) {
      tableHeight = minHeight;
    }
    this.setState({ width: window.innerWidth, height: tableHeight });
  }

  updateScrollPos = (val) => {
    if (this.messagesScrollbar2) {
      setTimeout(() => {
        if (this.messagesScrollbar2) {
          this.messagesScrollbar2.scrollXTo(val.leftPosition);
        }
      }, 0);
    }

    this.setState({ scrollPos: val.topPosition || 0 });
  }

  scroll = (x, y) => {
    if (this.datesRef && this.tableRef) {
      if (y < this.datesRef.minScrollY + 28) {
        y = this.datesRef.minScrollY + 28;
      }
      this.datesRef.scrollTop(-y);
      this.tableRef.scrollTop(-y);
    }
  }

  scroll2 = (x, y) => {
    if (this.datesRef && this.tableRef && this.tableRef.scrollY !== y) {
      if (y < this.datesRef.minScrollY + 28) {
        y = this.datesRef.minScrollY + 28;
      }
      this.datesRef.scrollTop(-y);
      this.tableRef.scrollTop(-y);
    }
  }

  scrollWithButton = (type) => {
    if (this.datesRef && this.tableRef) {
      const currentY = this.tableRef.scrollY;
      const currentX = this.tableRef.scrollX;

      let newY;
      let newX;

      if (type === 'down') {
        newY = -this.tableRef.scrollY + 30;
      } else if (type === 'up') {
        newY = -this.tableRef.scrollY - 30;
      } else if (type === 'left') {
        newX = -this.tableRef.scrollX - 30;
      } else if (type === 'right') {
        newX = -this.tableRef.scrollX + 30;
      }

      if (newY < 0) {
        newY = 0;
      } else if (newY > 2000) {
      //  newY = 2000;
      }

      if (newX < 0) {
        newX = 0;
      }

      if (type === 'down' || type === 'up') {
        this.datesRef.scrollTop(newY);
        this.tableRef.scrollTop(newY);
      } else if (type === 'left' || type === 'right') {
        this.datesRef.scrollLeft(newX);
        this.tableRef.scrollLeft(newX);
      }
    }
  }

  //  updateScrollPos2 = (val) => {
  //    this.setState({ scrollPos2: val.topPosition || 0 });
  //  }

  playButtonSound = () => {
    const { soundOn } = this.props;

      if (soundOn) {
        this.buttonClickedAudio.play();
      }
  }

  handleMouseHover = (rowId) => {
    this.setState({ hoverIndex: rowId });
  }

  handleMouseLeave = () => {
    this.setState({ hoverIndex: null });
  }

  render() {
    const {
      gamesHistory, changeTab, t, achievementsNotification, i18n, isFullscreen, innerHeight, isFullscreenEnabled, fontMode,
    } = this.props;

    const { tableHeight, width, height, hoverIndex } = this.state;
    const longColumnWidth = i18n.language === 'ru' && fontMode !== constants.FONT_MODE.normal ? (fontMode === constants.FONT_MODE.large ? 130 : 135) : 118;
    console.log(gamesHistory, "gamesHistory===")

    return (
      <div className="my-info">
        <Helmet>
          <title>
            Zole - {t('myInfo.results')}
          </title>
        </Helmet>
        <Row className="my-info-header mb-4">
          {/*  <Col xs="4" sm="4" className={`${i18n.language === 'ru' ? 'my-info-header-col-ru' : null}`}>
            <Media src={myInfoImg} className={`my-info-header-image ${i18n.language === 'ru' ? 'my-info-header-image-ru' : null}`} />
            <div className={`my-info-header-text ${i18n.language === 'ru' ? 'my-info-header-text-ru' : null}`}>
              {t('myInfo.results')}
            </div>
          </Col>
          <Col className="menu-topTab">
            <Button color="link" className="my-info-header-button" onClick={() => changeTab('9')}>
              {t('myInfo.friends')}
            </Button>
          </Col>
          <Col className="menu-topTab">
            <Button color="link" className={`my-info-header-button ${i18n.language === 'ru' ? 'my-info-header-button-ru' : null}`} onClick={() => changeTab('10')}>
              {t('myInfo.ignoredPlayers')}
            </Button>
          </Col>
          <Col className="menu-topTab" style={{ marginRight: 15 }}>
            <Button color="link" className={`my-info-header-button ${achievementsNotification ? 'my-info-header-button-notification' : ''}`} onClick={() => changeTab('11')}>
              {t('myInfo.achievements')}
            </Button>
          </Col>
          <Col className="menu-topTab" style={{ marginRight: 15 }}>
            <Button color="link" className="my-info-header-button active" onClick={() => changeTab('12')}>
              {t('myInfo.results')}
            </Button>
          </Col>  */}
          <Col xs="3">
            <Media src={myInfoImg} className="my-info-header-image" />
            <div className={`my-info-header-text ${i18n.language === 'ru' ? 'my-info-header-text-ru' : null}`}>
              {t('myInfo.results')}
            </div>
          </Col>
          <Col xs="9" style={{ paddingRight: 30 }}>
            <Row>
              {/* Stop for production mode(change xs=3 to xs2 for first 3) */}
              <Col xs="2" className="menu-topTab">
                <Button color="link" className="my-info-header-button" onClick={() => changeTab(constants.MENU_NAVIGATION.friends)}>
                  {t('myInfo.friends')}
                </Button>
              </Col>
              <Col xs="2" className="menu-topTab">
                <Button color="link" className={`my-info-header-button`} onClick={() => changeTab(constants.MENU_NAVIGATION.ignoredUsers)}>
                  {t('myInfo.ignoredPlayers')}
                </Button>
              </Col>
              <Col xs="2" className="menu-topTab">
                <Button color="link" className={`my-info-header-button`} onClick={() => changeTab(constants.MENU_NAVIGATION.archievements)}>
                  {t('myInfo.achievements')}
                </Button>
              </Col>
              <Col xs="2" className="menu-topTab results-menu-tab">
                <Button color="link" className="my-info-header-button active" onClick={() => changeTab(constants.MENU_NAVIGATION.gameHistory)}>
                  {t('myInfo.results')}
                </Button>
              </Col>
              {/* Stop for production mode */}
              <Col xs="2" className="menu-topTab">
                <Button color="link" className="my-info-header-button" onClick={() => changeTab(constants.MENU_NAVIGATION.weeklyStatus)}>
                  {t('myInfo.weeklyStatus')}
                </Button>
              </Col>
              <Col xs="2" className="menu-topTab">
                <Button color="link" className="my-info-header-button" onClick={() => changeTab(constants.MENU_NAVIGATION.gameLogs)}>
                  {t('myInfo.gameLogs')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="my-info-content-joyride">
          <Col sm="12">
            <Row className="game-history">
              <Col
                sm="12"
                className="game-history-table"
                style={{height: height - 8}}
              //  style={{ height: 300 }}
              >

                {/*  <ScrollArea
                speed={0.65}
                className="chat-body-scroll-area"
                contentClassName="online-users-ReactTableContainer"
              //  onScroll={this.handleScroll2}
                smoothScrolling
                verticalContainerStyle={{
                  background: 'transparent',
                  opacity: 1,
                  width: 11,
                }}
                verticalScrollbarStyle={{
                  background: '#fff',
                  borderRadius: 1,
                  width: 10,
                  minScrollSize: 25,
                }}
                horizontalContainerStyle={{
                  background: 'transparent',
                  opacity: 1,
                  width: 11,
                }}
                horizontalScrollbarStyle={{
                  background: '#fff',
                  borderRadius: 1,
                  width: 10,
                  minScrollSize: 25,
                }}
                vertical={false}
                onScroll={this.updateScrollPos2}
                ref={(el) => { this.messagesScrollbar2 = el; }}
              >
                <div style={{ width: 1200 }}>  */}


                {(gamesHistory && gamesHistory.length) ? (
                  <>
                    {/* <ScrollGamesHistory
                      datesRef={this.datesRef}
                      tableRef={this.tableRef}
                      scroll={this.scrollWithButton}
                    /> */}
                    <div
                      className="game-history-table-dates"
                      style={{
                        width: 118,
                        float: 'left',
                        height: '100%',
                        //  fontSize: 14,
                        //  border: '1px solid #FFF',
                      }}
                    >
                      <Table
                        // style={{ fontSize: 14 }}
                        //  sortType={sortType}
                        //  sortColumn={sortColumn}

                        className="game-history-table-section-date"
                        data={[...gamesHistory, {last: true}]}
                        virtualized
                        hover={false}
                        // minHeight={300}
                        //  height={300}
                        // height={isFullscreen ? ((height > 600) ? ((height * 0.60) + 8) : 360) : 360}
                        height={height}
                        //  autoHeight={true}
                        shouldUpdateScroll={false}
                        ref={(el) => { this.datesRef = el; }}
                        onScroll={(x, y) => { this.scroll2(x, y); }}
                        //  onScroll={(x, y) => { this.scroll(x, y) }}
                        rowHeight={32}
                        rowClassName={(rowData, rowIndex) => {
                          if (rowData) {
                            const index = gamesHistory.findIndex(element => element.date === rowData.date);

                            if (rowData.last) return 'game-history-table-row-last'
                            if (index % 2) {
                              return `game-history-table-row-odd ${index === hoverIndex ? 'game-history-table-row-test' : ''}`;
                            }
                            return `game-history-table-row-even ${index === hoverIndex ? 'game-history-table-row-test' : ''}`;
                          }
                          return '';
                        }}
                      >
                        <Column width={118}>
                          <HeaderCell>{t('results.date')}</HeaderCell>
                          <Cell dataKey="date">
                            {(rowData, rowIndex) => {
                              if (!rowData.last){
                                if (!rowData.date || rowData.date === 'total') {
                                  return <div onMouseEnter={() => this.handleMouseHover(rowIndex)} onMouseLeave={this.handleMouseLeave}>{t('results.total')}</div>
                                }
                              } else {
                                return <div>...</div>
                              }

                                return (
                                  <div onMouseEnter={() => this.handleMouseHover(rowIndex)} onMouseLeave={this.handleMouseLeave}>
                                    <DateFormat formatType={constants.FORMAT_DATE_TYPE.pointsMonth} date={rowData.date} />
                                  </div>
                                );
                            }}
                          </Cell>
                        </Column>
                      </Table>
                      {/*  <div style={{ height: 40, padding: 8 }}>
                      Datums
                    </div>
                    <div style={{ height: 260 }}>
                      <ScrollArea
                          speed={0.65}
                          className="chat-body-scroll-area"
                          contentClassName="online-users-ReactTableContainer"
                        //  onScroll={this.handleScroll2}
                        //  smoothScrolling
                          horizontal={false}
                          vertical={false}
                          stopScrollPropagation={true}
                        //  onScroll={this.updateScrollPos2}
                          ref={(el) => { this.scrollAreaRef = el; }}
                        >
                        <div style={{ height: 32, padding: 8 }}>
                          {t('results.total')}
                        </div>
                        {gamesHistory.map(date => (
                          <div style={{ height: 32, padding: 8 }}>
                            <Moment format="DD.MM" locale="lv">
                              {date.date}
                            </Moment>
                          </div>
                        ))}
                      </ScrollArea>
                    </div>  */}
                    </div>
                    <div className="game-history-table-wrap-section" style={{ width: '100%', height: '100%' }}>
                      <Table
                        // style={{ fontSize: 14 }}
                    //  sortType={sortType}
                    //  sortColumn={sortColumn}
                        className="game-history-table-section-body"
                        data={[...gamesHistory, {last: true}]}
                        virtualized
                        // minHeight={300}
                    //  height={300}

                    //    height={(isFullscreen && height > 600) ? ((height * 0.60) + 8) : 360} // (window.innerHeight * 0.62) - 10

                        // height={isFullscreen ? ((height > 600) ? ((height * 0.60) + 8) : 360) : 360}
                        // height={360}
                        height={height}
                    //  autoHeight={true}
                        shouldUpdateScroll={false}
                    //  onSortColumn={this.sort}
                    //  rowKey='uid'
                        onScroll={(x, y) => { this.scroll(x, y); }}
                        ref={(el) => { this.tableRef = el; }}
                        rowHeight={32}
                        hover={false}
                        rowClassName={(rowData) => {
                          if (rowData) {
                            const index = gamesHistory.findIndex(element => element.date === rowData.date);

                            if (rowData.last) return 'game-history-table-row-last'
                            if (index % 2) {
                              return `game-history-table-row-odd ${index === hoverIndex ? 'game-history-table-row-test' : ''}`;
                            }
                            return `game-history-table-row-even ${index === hoverIndex ? 'game-history-table-row-test' : ''}`;
                          }
                          return '';
                          //  return (`${`${(rowData && rowData.newUser) ? ' admin-table-new-user' : ''}`}`)
                        }}
                      >
                        {/*  <Column width={118} fixed>
                        <HeaderCell>{t('results.date')}</HeaderCell>
                        <Cell dataKey="date">
                          {(rowData, rowIndex) => {
                            if (!rowData.date || rowData.date === 'total') {
                              return t('results.total');
                            }
                            return (
                              <div>
                                <Moment format="DD.MM" locale="lv">
                                  {rowData.date}
                                </Moment>
                              </div>
                            )
                          }}
                        </Cell>
                      </Column>  */}
                        <ColumnGroup header={t('results.wins')}>
                          <Column width={118}>
                            <HeaderCell>#</HeaderCell>
                            <Cell dataKey="wins">
                              {(rowData, rowIndex) => (
                                <div onMouseEnter={() => this.handleMouseHover(rowIndex)} onMouseLeave={this.handleMouseLeave}>
                                  {' '}
                                  {rowData.wins ? rowData.wins : 0}
                                  {' '}
                                </div>
                              )}
                            </Cell>
                          </Column>
                          <Column width={118}>
                            <HeaderCell>%</HeaderCell>
                            <Cell dataKey="wins">
                              {(rowData, rowIndex) => {
                                const wins = rowData.wins || 0;
                                const loses = rowData.loses || 0;
                                return (
                                  <div onMouseEnter={() => this.handleMouseHover(rowIndex)} onMouseLeave={this.handleMouseLeave}>
                                    {' '}
                                    {rowData.wins ? (`${(wins + loses) ? (`${Math.round((rowData.wins / (wins + loses)) * 100)}%`) : 0}`) : 0}
                                    {' '}
                                  </div>
                                );
                              }}
                            </Cell>
                          </Column>
                        </ColumnGroup>
                        <ColumnGroup header={t('results.loses')}>
                          <Column width={118}>
                            <HeaderCell>#</HeaderCell>
                            <Cell dataKey="loses">
                              {(rowData, rowIndex) => (
                                <div onMouseEnter={() => this.handleMouseHover(rowIndex)} onMouseLeave={this.handleMouseLeave}>
                                  {' '}
                                  {rowData.loses ? rowData.loses : 0}
                                  {' '}
                                </div>
                              )}
                            </Cell>
                          </Column>
                          <Column width={118}>
                            <HeaderCell>%</HeaderCell>
                            <Cell dataKey="loses">
                              {(rowData, rowIndex) => {
                                const wins = rowData.wins || 0;
                                const loses = rowData.loses || 0;

                                const winsPercentage = Math.round((wins / (wins + loses)) * 100);
                                let losesPercentage = Math.round((loses / (wins + loses)) * 100);

                                if ((winsPercentage + losesPercentage) > 100) {
                                  losesPercentage -= 1;
                                } else if ((winsPercentage + losesPercentage) < 100) {
                                  losesPercentage += 1;
                                }

                                return (
                                  <div onMouseEnter={() => this.handleMouseHover(rowIndex)} onMouseLeave={this.handleMouseLeave}>
                                    {' '}
                                    {rowData.loses ? (`${(wins + loses) ? (`${losesPercentage}%`) : 0}`) : 0}
                                    {' '}
                                  </div>
                                );
                              }}
                              {/*  {rowData => (
                                <div>
                                  {' '}
                                  {rowData.loses ? (`${rowData.roundsPlayed ? (`${Math.floor((rowData.loses / rowData.roundsPlayed) * 100)}%`) : 0}`) : 0}
                                  {' '}
                                </div>
                              )} */}
                            </Cell>
                          </Column>
                        </ColumnGroup>

                        <Column width={longColumnWidth}>
                          <HeaderCell>
                            <span className={`avoidwrap mr-1`}>
                              {`${t('results.wins')}`}
                            </span>
                            <span className={`avoidwrap`}>
                              {`(${t('results.asSmall')})`}
                            </span>
                          </HeaderCell>
                          {/*  <Cell dataKey="smallWins" /> */}
                          <Cell dataKey="smallWins">
                            {(rowData, rowIndex) => (
                              <div onMouseEnter={() => this.handleMouseHover(rowIndex)} onMouseLeave={this.handleMouseLeave}>
                                {' '}
                                {rowData.smallWins ? rowData.smallWins : 0}
                                {' '}
                              </div>
                            )}
                          </Cell>
                        </Column>
                        <Column width={longColumnWidth}>
                          <HeaderCell>
                            <span className={`avoidwrap mr-1`}>
                              {`${t('results.wins')} `}
                            </span>
                            <span className={`avoidwrap`}>
                              {` (${t('results.asLarge')})`}
                            </span>
                          </HeaderCell>
                          {/*  <Cell dataKey="largeWins" /> */}
                          <Cell dataKey="largeWins">
                            {(rowData, rowIndex) => (
                              <div onMouseEnter={() => this.handleMouseHover(rowIndex)} onMouseLeave={this.handleMouseLeave}>
                                {' '}
                                {rowData.largeWins ? rowData.largeWins : 0}
                                {' '}
                              </div>
                            )}
                          </Cell>
                        </Column>
                        <Column width={longColumnWidth}>
                          <HeaderCell>
                            <span className={`avoidwrap mr-1`}>
                              {`${t('results.loses')} `}
                            </span>
                            <span className={`avoidwrap`}>
                              {` (${t('results.asSmall')})`}
                            </span>
                          </HeaderCell>
                          {/*  <Cell dataKey="smallLoses" /> */}
                          <Cell dataKey="smallLoses">
                            {(rowData, rowIndex) => (
                              <div onMouseEnter={() => this.handleMouseHover(rowIndex)} onMouseLeave={this.handleMouseLeave}>
                                {' '}
                                {rowData.smallLoses ? rowData.smallLoses : 0}
                                {' '}
                              </div>
                            )}
                          </Cell>
                        </Column>
                        <Column width={longColumnWidth}>
                          <HeaderCell>
                            <span className={`avoidwrap mr-1`}>
                              {`${t('results.loses')} `}
                            </span>
                            <span className={`avoidwrap`}>
                              {` (${t('results.asLarge')})`}
                            </span>
                          </HeaderCell>
                          {/*  <Cell dataKey="largeLoses" /> */}
                          <Cell dataKey="largeLoses">
                            {(rowData, rowIndex) => (
                              <div onMouseEnter={() => this.handleMouseHover(rowIndex)} onMouseLeave={this.handleMouseLeave}>
                                {' '}
                                {rowData.largeLoses ? rowData.largeLoses : 0}
                                {' '}
                              </div>
                            )}
                          </Cell>
                        </Column>
                        <Column width={longColumnWidth}>
                          <HeaderCell>
                            <span className={`avoidwrap mr-1`}>
                              {`${t('results.wins')} `}
                            </span>
                            <span className={`avoidwrap`}>
                              {` (${t('results.zole')})`}
                            </span>
                          </HeaderCell>
                          {/*  <Cell dataKey="zoleWins" /> */}
                          <Cell dataKey="zoleWins">
                            {(rowData, rowIndex) => (
                              <div onMouseEnter={() => this.handleMouseHover(rowIndex)} onMouseLeave={this.handleMouseLeave}>
                                {' '}
                                {rowData.zoleWins ? rowData.zoleWins : 0}
                                {' '}
                              </div>
                            )}
                          </Cell>
                        </Column>
                        <Column width={longColumnWidth}>
                          <HeaderCell>
                            <span className={`avoidwrap mr-1`}>
                              {`${t('results.loses')} `}
                            </span>
                            <span className={`avoidwrap`}>
                              {` (${t('results.zole')})`}
                            </span>
                          </HeaderCell>
                          {/*  <Cell dataKey="zoleLoses" /> */}
                          <Cell dataKey="zoleLoses">
                            {(rowData, rowIndex) => (
                              <div onMouseEnter={() => this.handleMouseHover(rowIndex)} onMouseLeave={this.handleMouseLeave}>
                                {' '}
                                {rowData.zoleLoses ? rowData.zoleLoses : 0}
                                {' '}
                              </div>
                            )}
                          </Cell>
                        </Column>
                        <Column width={longColumnWidth}>
                          <HeaderCell>
                            <span className={`avoidwrap mr-1`}>
                              {`${t('results.wins')}`}
                            </span>
                            <span className={`avoidwrap`}>
                              {` (${t('results.table')})`}
                            </span>
                          </HeaderCell>
                          <Cell dataKey="galdinsWins">
                            {(rowData, rowIndex) => (
                              <div onMouseEnter={() => this.handleMouseHover(rowIndex)} onMouseLeave={this.handleMouseLeave}>
                                {' '}
                                {rowData.galdinsWins ? rowData.galdinsWins : 0}
                                {' '}
                              </div>
                            )}
                          </Cell>
                        </Column>
                        <Column width={longColumnWidth}>
                          <HeaderCell>
                            <span className={`avoidwrap mr-1`}>
                              {`${t('results.loses')}`}
                            </span>
                            <span className={`avoidwrap`}>
                              {` (${t('results.table')})`}
                            </span>
                          </HeaderCell>
                          <Cell dataKey="galdinsLoses">
                            {(rowData, rowIndex) => (
                              <div onMouseEnter={() => this.handleMouseHover(rowIndex)} onMouseLeave={this.handleMouseLeave}>
                                {' '}
                                {rowData.galdinsLoses ? rowData.galdinsLoses : 0}
                                {' '}
                              </div>
                            )}
                          </Cell>
                        </Column>
                        <Column width={118}>
                          <HeaderCell>{t('results.rooms')}</HeaderCell>
                          {/*  <Cell dataKey="roomsPlayed" /> */}
                          <Cell dataKey="roomsPlayed">
                            {(rowData, rowIndex) => (
                              <div onMouseEnter={() => this.handleMouseHover(rowIndex)} onMouseLeave={this.handleMouseLeave}>
                                {' '}
                                {rowData.roomsPlayed ? rowData.roomsPlayed : 0}
                                {' '}
                              </div>
                            )}
                          </Cell>
                        </Column>
                        <Column width={118}>
                          <HeaderCell>{t('results.parties')}</HeaderCell>
                          {/*  <Cell dataKey="roundsPlayed" /> */}
                          <Cell dataKey="roundsPlayed">
                            {(rowData, rowIndex) => {
                              const wins = rowData.wins || 0;
                              const loses = rowData.loses || 0;

                              // {rowData.roundsPlayed ? rowData.roundsPlayed : 0}

                              return (
                                <div onMouseEnter={() => this.handleMouseHover(rowIndex)} onMouseLeave={this.handleMouseLeave}>
                                  {' '}
                                  {(wins + loses) ? (wins + loses) : 0}
                                  {' '}
                                </div>
                              );
                            }}
                          </Cell>
                        </Column>
                        <Column width={118}>
                          <HeaderCell>{t('results.coins')}</HeaderCell>
                          {/*  <Cell dataKey="balChange" /> */}
                          <Cell dataKey="balChange">
                            {(rowData, rowIndex) => (
                              <div onMouseEnter={() => this.handleMouseHover(rowIndex)} onMouseLeave={this.handleMouseLeave}>
                                {' '}
                                {rowData.balChange ? rowData.balChange : 0}
                                {' '}
                              </div>
                            )}
                          </Cell>
                        </Column>
                        <Column width={118}>
                          <HeaderCell>{t('results.totalMoney')}</HeaderCell>
                          {/*  <Cell dataKey="totalMoney" /> */}
                          <Cell dataKey="totalMoney">
                            {(rowData, rowIndex) => (
                              <div onMouseEnter={() => this.handleMouseHover(rowIndex)} onMouseLeave={this.handleMouseLeave}>
                                {' '}
                                {rowData.totalMoney ? rowData.totalMoney : (rowData.balChange ? rowData.balChange : 0)}
                                {' '}
                              </div>
                            )}
                          </Cell>
                        </Column>
                        <Column width={118}>
                          <HeaderCell>{t('results.points')}</HeaderCell>
                          {/*  <Cell dataKey="pointsChange" /> */}
                          <Cell dataKey="pointsChange">
                            {(rowData, rowIndex) => (
                              <div onMouseEnter={() => this.handleMouseHover(rowIndex)} onMouseLeave={this.handleMouseLeave}>
                                {' '}
                                {rowData.pointsChange ? rowData.pointsChange : 0}
                                {' '}
                              </div>
                            )}
                          </Cell>
                        </Column>
                        <Column width={118}>
                          <HeaderCell>{t('results.rating')}</HeaderCell>
                          <Cell dataKey="rating">
                            {(rowData, rowIndex) => (
                              <div onMouseEnter={() => this.handleMouseHover(rowIndex)} onMouseLeave={this.handleMouseLeave}> 0 </div>
                            )}
                          </Cell>
                        </Column>
                      </Table>
                    </div>
                  </>
                ) : (null)}

                {/*  <ScrollArea
                  speed={0.0}
                  className="game-history-header-scroll-area"
                  contentClassName="online-users-ReactTableContainer"
                  contentStyle={{ width: 'fit-content' }}
                  horizontalContainerStyle={{
                    background: 'transparent',
                    opacity: 1,
                    height: 1,
                  }}
                  horizontalScrollbarStyle={{
                    background: 'transparent',
                    borderRadius: 1,
                    opacity: 1,
                    height: 0,
                    minScrollSize: 25,
                  }}
                  vertical={false}
                  ref={(el) => { this.messagesScrollbar2 = el; }}
                >
                  <Row style={{ width: 1200 }}>

                    <Col sm="1" className="game-history-table-row-col game-history-table-row-col-text">
                      {t('results.date')}
                    </Col>

                    <Col sm="1">
                      <Row>
                        <Col className="game-history-table-row-col game-history-table-row-col-text">
                          {t('results.wins')}
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="6" className="game-history-table-row-col game-history-table-row-col-text">
                          #
                        </Col>
                        <Col sm="6" className="game-history-table-row-col game-history-table-row-col-text">
                          %
                        </Col>
                      </Row>
                    </Col>

                    <Col sm="1">
                      <Row>
                        <Col className="game-history-table-row-col game-history-table-row-col-text">
                          {t('results.loses')}
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="6" className="game-history-table-row-col game-history-table-row-col-text">
                          #
                        </Col>
                        <Col sm="6" className="game-history-table-row-col game-history-table-row-col-text">
                          %
                        </Col>
                      </Row>
                    </Col>

                    <Col className="game-history-table-row-col game-history-table-row-col-text">
                      {`${t('results.wins')} (${t('results.asSmall')})`}
                    </Col>

                    <Col className="game-history-table-row-col game-history-table-row-col-text">
                      {`${t('results.wins')} (${t('results.asLarge')})`}
                    </Col>

                    <Col className="game-history-table-row-col game-history-table-row-col-text">
                      {`${t('results.loses')} (${t('results.asSmall')})`}
                    </Col>

                    <Col className="game-history-table-row-col game-history-table-row-col-text">
                      {`${t('results.loses')} (${t('results.asLarge')})`}
                    </Col>

                    <Col className="game-history-table-row-col game-history-table-row-col-text">
                      {`${t('results.wins')} (${t('results.zole')})`}
                    </Col>

                    <Col className="game-history-table-row-col game-history-table-row-col-text">
                      {`${t('results.loses')} (${t('results.asLarge')})`}
                    </Col>

                    <Col className="game-history-table-row-col game-history-table-row-col-text">
                      {`${t('results.wins')} (${t('results.table')})`}
                    </Col>

                    <Col className="game-history-table-row-col game-history-table-row-col-text">
                      {t('results.rooms')}
                    </Col>

                    <Col className="game-history-table-row-col game-history-table-row-col-text">
                      {t('results.parties')}
                    </Col>

                    <Col className="game-history-table-row-col game-history-table-row-col-text">
                      {t('results.coins')}
                    </Col>

                    <Col className="game-history-table-row-col game-history-table-row-col-text">
                      {t('results.points')}
                    </Col>

                    <Col className="game-history-table-row-col game-history-table-row-col-text">
                      {t('results.rating')}
                    </Col>
                  </Row>
                </ScrollArea>

                <ScrollAreaButtons
                  scrollPos={scrollPos}
                  scroll={this.scroll}
                  speed={30}
                  rightOffset={8}
                //  show={true}
                />
                <ScrollArea
                  speed={0.65}
                  className="game-history-body-scroll-area"
                  contentClassName="online-users-ReactTableContainer"
                  contentStyle={{ width: 'fit-content' }}
                //  onScroll={this.handleScroll}
                  smoothScrolling
                  verticalContainerStyle={{
                    background: 'transparent',
                    opacity: 1,
                    width: 11,
                  }}
                  verticalScrollbarStyle={{
                    background: '#fff',
                    borderRadius: 1,
                    width: 10,
                    minScrollSize: 25,
                  }}
                  horizontalContainerStyle={{
                    background: 'transparent',
                    opacity: 1,
                  //  width: 11,
                    height: 11,
                  }}
                  horizontalScrollbarStyle={{
                    background: '#fff',
                    borderRadius: 1,
                  //  width: 10,
                    height: 10,
                    minScrollSize: 25,
                  }}
                  horizontal={true}
                  onScroll={this.updateScrollPos}
                  ref={(el) => { this.messagesScrollbar = el; }}
                >  */}
                {/*  <Row className="game-history-table-row">
                  <Col sm="4" className="game-history-table-row-col game-history-table-row-col-text">
                    Uzvaras
                  </Col>
                  <Col sm="4" className="game-history-table-row-col game-history-table-row-col-text">
                    {gamesHistoryTotal.wins ? (`${gamesHistoryTotal.wins} (${gamesHistoryTotal.roundsPlayed ? (`${gamesHistoryTotal.wins / gamesHistoryTotal.roundsPlayed}`) : 0}%)`) : 0}
                  </Col>
                </Row>
                <Row className="game-history-table-row">
                  <Col sm="4" className="game-history-table-row-col game-history-table-row-col-text">
                    Zudējumi
                  </Col>
                  <Col sm="4" className="game-history-table-row-col game-history-table-row-col-text">
                    {gamesHistoryTotal.loses ? (`${gamesHistoryTotal.loses} (${gamesHistoryTotal.roundsPlayed ? (`${gamesHistoryTotal.loses / gamesHistoryTotal.roundsPlayed}`) : 0}%)`) : 0}
                  </Col>
                </Row>
                <Row className="game-history-table-row">
                  <Col sm="4" className="game-history-table-row-col game-history-table-row-col-text">
                    Uzvaras kā mazais
                  </Col>
                  <Col sm="4" className="game-history-table-row-col game-history-table-row-col-text">
                    {gamesHistoryTotal.smallWins ? gamesHistoryTotal.smallWins : 0}
                  </Col>
                </Row>
                <Row className="game-history-table-row">
                  <Col sm="4" className="game-history-table-row-col game-history-table-row-col-text">
                    Uzvaras kā lielais
                  </Col>
                  <Col sm="4" className="game-history-table-row-col game-history-table-row-col-text">
                    {gamesHistoryTotal.largeWins ? gamesHistoryTotal.largeWins : 0}
                  </Col>
                </Row>
                <Row className="game-history-table-row">
                  <Col sm="4" className="game-history-table-row-col game-history-table-row-col-text">
                    Zaudējumi kā mazais
                  </Col>
                  <Col sm="4" className="game-history-table-row-col game-history-table-row-col-text">
                    {gamesHistoryTotal.smallLoses ? gamesHistoryTotal.smallLoses : 0}
                  </Col>
                </Row>
                <Row className="game-history-table-row">
                  <Col sm="4" className="game-history-table-row-col game-history-table-row-col-text">
                    Zaudējumi kā lielais
                  </Col>
                  <Col sm="4" className="game-history-table-row-col game-history-table-row-col-text">
                    {gamesHistoryTotal.largeLoses ? gamesHistoryTotal.largeLoses : 0}
                  </Col>
                </Row>
                <Row className="game-history-table-row">
                  <Col sm="4" className="game-history-table-row-col game-history-table-row-col-text">
                    Uzvaras Zole
                  </Col>
                  <Col sm="4" className="game-history-table-row-col game-history-table-row-col-text">
                    {gamesHistoryTotal.zoleWins ? gamesHistoryTotal.zoleWins : 0}
                  </Col>
                </Row>
                <Row className="game-history-table-row">
                  <Col sm="4" className="game-history-table-row-col game-history-table-row-col-text">
                    Zaudējumi Zole
                  </Col>
                  <Col sm="4" className="game-history-table-row-col game-history-table-row-col-text">
                    {gamesHistoryTotal.zoleLoses ? gamesHistoryTotal.zoleLoses : 0}
                  </Col>
                </Row>
                <Row className="game-history-table-row">
                  <Col sm="4" className="game-history-table-row-col game-history-table-row-col-text">
                    Zaudējumi Galdiņš
                  </Col>
                  <Col sm="4" className="game-history-table-row-col game-history-table-row-col-text">
                    {gamesHistoryTotal.galdinsLoses ? gamesHistoryTotal.galdinsLoses : 0}
                  </Col>
                </Row>

                <Row className="game-history-table-row">
                  <Col sm="4" className="game-history-table-row-col game-history-table-row-col-text">
                    Istabas
                  </Col>
                  <Col sm="4" className="game-history-table-row-col game-history-table-row-col-text">
                    {gamesHistoryTotal.roomsPlayed ? gamesHistoryTotal.roomsPlayed : 0}
                  </Col>
                </Row>
                <Row className="game-history-table-row">
                  <Col sm="4" className="game-history-table-row-col game-history-table-row-col-text">
                    Partijas
                  </Col>
                  <Col sm="4" className="game-history-table-row-col game-history-table-row-col-text">
                    {gamesHistoryTotal.roundsPlayed ? gamesHistoryTotal.roundsPlayed : 0}
                  </Col>
                </Row>
                <Row className="game-history-table-row">
                  <Col sm="4" className="game-history-table-row-col game-history-table-row-col-text">
                    Monētas
                  </Col>
                  <Col sm="4" className="game-history-table-row-col game-history-table-row-col-text">
                    {gamesHistoryTotal.balChange ? gamesHistoryTotal.balChange : 0}
                  </Col>
                </Row>
                <Row className="game-history-table-row">
                  <Col sm="4" className="game-history-table-row-col game-history-table-row-col-text">
                    Punkti
                  </Col>
                  <Col sm="4" className="game-history-table-row-col game-history-table-row-col-text">
                    {gamesHistoryTotal.pointsChange ? gamesHistoryTotal.pointsChange : 0}
                  </Col>
                </Row>
                <Row className="game-history-table-row">
                  <Col sm="4" className="game-history-table-row-col game-history-table-row-col-text">
                    Reitings
                  </Col>
                  <Col sm="4" className="game-history-table-row-col game-history-table-row-col-text">

                  </Col>
                </Row>  */}

                {/*  <Row style={{ width: 1200, position: 'absolute', top: scrollPos }}>

                  <Col sm="1" className="game-history-table-row-col game-history-table-row-col-text">

                  </Col>

                  <Col sm="1">
                    <Row>
                      <Col className="game-history-table-row-col game-history-table-row-col-text">
                        Uzvaras
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6" className="game-history-table-row-col game-history-table-row-col-text">
                        #
                      </Col>
                      <Col sm="6" className="game-history-table-row-col game-history-table-row-col-text">
                        %
                      </Col>
                    </Row>
                  </Col>

                  <Col sm="1">
                    <Row>
                      <Col className="game-history-table-row-col game-history-table-row-col-text">
                        Zaudējumi
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6" className="game-history-table-row-col game-history-table-row-col-text">
                        #
                      </Col>
                      <Col sm="6" className="game-history-table-row-col game-history-table-row-col-text">
                        %
                      </Col>
                    </Row>
                  </Col>

                  <Col className="game-history-table-row-col game-history-table-row-col-text">
                    Uzvaras (kā mazais)
                  </Col>

                  <Col className="game-history-table-row-col game-history-table-row-col-text">
                    Uzvaras (kā lielais)
                  </Col>

                  <Col className="game-history-table-row-col game-history-table-row-col-text">
                    Zaudējumi (kā mazais)
                  </Col>

                  <Col className="game-history-table-row-col game-history-table-row-col-text">
                    Zaudējumi (kā lielais)
                  </Col>

                  <Col className="game-history-table-row-col game-history-table-row-col-text">
                    Uzvaras (Zole)
                  </Col>

                  <Col className="game-history-table-row-col game-history-table-row-col-text">
                    Zaudējumi (Zole)
                  </Col>

                  <Col className="game-history-table-row-col game-history-table-row-col-text">
                    Zaudējumi (galdiņš)
                  </Col>

                  <Col className="game-history-table-row-col game-history-table-row-col-text">
                    Istabas
                  </Col>

                  <Col className="game-history-table-row-col game-history-table-row-col-text">
                    Partijas
                  </Col>

                  <Col className="game-history-table-row-col game-history-table-row-col-text">
                    Monētas
                  </Col>

                  <Col className="game-history-table-row-col game-history-table-row-col-text">
                    Punkti
                  </Col>

                  <Col className="game-history-table-row-col game-history-table-row-col-text">
                    Reitings
                  </Col>
                </Row> */}

                {/*    <Row style={{ width: 1200 }}>

                  <Col sm="1" className="game-history-table-row-col game-history-table-row-col-text">
                    {t('results.total')}
                  </Col>

                  <Col sm="1">
                    <Row>
                      <Col  sm="6" style={{ whiteSpace: 'nowrap' }} className="game-history-table-row-col game-history-table-row-col-text">
                        {gamesHistoryTotal.wins ? gamesHistoryTotal.wins : 0}
                      </Col>
                      <Col  sm="6" style={{ whiteSpace: 'nowrap' }} className="game-history-table-row-col game-history-table-row-col-text">
                        {gamesHistoryTotal.wins ? (`${gamesHistoryTotal.roundsPlayed ? (`${Math.round((gamesHistoryTotal.wins / gamesHistoryTotal.roundsPlayed) * 100)}%`) : 0}`) : 0}
                      </Col>
                    </Row>
                  </Col>

                  <Col sm="1">
                    <Row>
                      <Col sm="6"  style={{ whiteSpace: 'nowrap' }} className="game-history-table-row-col game-history-table-row-col-text">
                        {gamesHistoryTotal.loses ? gamesHistoryTotal.loses : 0}
                      </Col>
                      <Col sm="6"  style={{ whiteSpace: 'nowrap' }} className="game-history-table-row-col game-history-table-row-col-text">
                        {gamesHistoryTotal.loses ? (`${gamesHistoryTotal.roundsPlayed ? (`${Math.round((gamesHistoryTotal.loses / gamesHistoryTotal.roundsPlayed) * 100)}%`) : 0}`) : 0}
                      </Col>
                    </Row>
                  </Col>

                  <Col className="game-history-table-row-col game-history-table-row-col-text">
                    {gamesHistoryTotal.smallWins ? gamesHistoryTotal.smallWins : 0}
                  </Col>

                  <Col className="game-history-table-row-col game-history-table-row-col-text">
                    {gamesHistoryTotal.largeWins ? gamesHistoryTotal.largeWins : 0}
                  </Col>

                  <Col className="game-history-table-row-col game-history-table-row-col-text">
                    {gamesHistoryTotal.smallLoses ? gamesHistoryTotal.smallLoses : 0}
                  </Col>

                  <Col className="game-history-table-row-col game-history-table-row-col-text">
                    {gamesHistoryTotal.largeLoses ? gamesHistoryTotal.largeLoses : 0}
                  </Col>

                  <Col className="game-history-table-row-col game-history-table-row-col-text">
                    {gamesHistoryTotal.zoleWins ? gamesHistoryTotal.zoleWins : 0}
                  </Col>

                  <Col className="game-history-table-row-col game-history-table-row-col-text">
                    {gamesHistoryTotal.zoleLoses ? gamesHistoryTotal.zoleLoses : 0}
                  </Col>

                  <Col className="game-history-table-row-col game-history-table-row-col-text">
                    {gamesHistoryTotal.galdinsLoses ? gamesHistoryTotal.galdinsLoses : 0}
                  </Col>

                  <Col className="game-history-table-row-col game-history-table-row-col-text">
                    {gamesHistoryTotal.roomsPlayed ? gamesHistoryTotal.roomsPlayed : 0}
                  </Col>

                  <Col className="game-history-table-row-col game-history-table-row-col-text">
                    {gamesHistoryTotal.roundsPlayed ? gamesHistoryTotal.roundsPlayed : 0}
                  </Col>

                  <Col className="game-history-table-row-col game-history-table-row-col-text">
                    {gamesHistoryTotal.balChange ? gamesHistoryTotal.balChange : 0}
                  </Col>

                  <Col className="game-history-table-row-col game-history-table-row-col-text">
                    {gamesHistoryTotal.pointsChange ? gamesHistoryTotal.pointsChange : 0}
                  </Col>

                  <Col className="game-history-table-row-col game-history-table-row-col-text">

                  </Col>
                </Row>

                {gamesHistory && gamesHistory.length && gamesHistory.map(dayHistory => (
                  <Row style={{ width: 1200 }}>
                    <Col sm="1" className="game-history-table-row-col game-history-table-row-col-text">
                      <Moment format="DD.MM" locale="lv">
                        {dayHistory.date}
                      </Moment>
                    </Col>

                    <Col sm="1">
                      <Row>
                        <Col sm="6" style={{ whiteSpace: 'nowrap' }} className="game-history-table-row-col game-history-table-row-col-text">
                          {dayHistory.wins ? dayHistory.wins : 0}
                        </Col>
                        <Col sm="6"  style={{ whiteSpace: 'nowrap' }} className="game-history-table-row-col game-history-table-row-col-text">
                          {dayHistory.wins ? (`${dayHistory.roundsPlayed ? (`${Math.round((dayHistory.wins / dayHistory.roundsPlayed) * 100)}%`) : 0}`) : 0}
                        </Col>
                      </Row>
                    </Col>

                    <Col sm="1">
                      <Row>
                        <Col sm="6"  style={{ whiteSpace: 'nowrap' }} className="game-history-table-row-col game-history-table-row-col-text">
                          {dayHistory.loses ? dayHistory.loses : 0}
                        </Col>
                        <Col sm="6"  style={{ whiteSpace: 'nowrap' }} className="game-history-table-row-col game-history-table-row-col-text">
                          {dayHistory.loses ? (`${dayHistory.roundsPlayed ? (`${Math.round((dayHistory.loses / dayHistory.roundsPlayed) * 100)}%`) : 0}`) : 0}
                        </Col>
                      </Row>
                    </Col>

                    <Col className="game-history-table-row-col game-history-table-row-col-text">
                      {dayHistory.smallWins ? dayHistory.smallWins : 0}
                    </Col>

                    <Col className="game-history-table-row-col game-history-table-row-col-text">
                      {dayHistory.largeWins ? dayHistory.largeWins : 0}
                    </Col>

                    <Col className="game-history-table-row-col game-history-table-row-col-text">
                      {dayHistory.smallLoses ? dayHistory.smallLoses : 0}
                    </Col>

                    <Col className="game-history-table-row-col game-history-table-row-col-text">
                      {dayHistory.largeLoses ? dayHistory.largeLoses : 0}
                    </Col>

                    <Col className="game-history-table-row-col game-history-table-row-col-text">
                      {dayHistory.zoleWins ? dayHistory.zoleWins : 0}
                    </Col>

                    <Col className="game-history-table-row-col game-history-table-row-col-text">
                      {dayHistory.zoleLoses ? dayHistory.zoleLoses : 0}
                    </Col>

                    <Col className="game-history-table-row-col game-history-table-row-col-text">
                      {dayHistory.galdinsLoses ? dayHistory.galdinsLoses : 0}
                    </Col>

                    <Col className="game-history-table-row-col game-history-table-row-col-text">
                      {dayHistory.roomsPlayed ? dayHistory.roomsPlayed : 0}
                    </Col>

                    <Col className="game-history-table-row-col game-history-table-row-col-text">
                      {dayHistory.roundsPlayed ? dayHistory.roundsPlayed : 0}
                    </Col>

                    <Col className="game-history-table-row-col game-history-table-row-col-text">
                      {dayHistory.balChange ? dayHistory.balChange : 0}
                    </Col>

                    <Col className="game-history-table-row-col game-history-table-row-col-text">
                      {dayHistory.pointsChange ? dayHistory.pointsChange : 0}
                    </Col>

                    <Col className="game-history-table-row-col game-history-table-row-col-text">

                    </Col>
                  </Row>
                ))}

                </ScrollArea>
              {/*  </div>
                </ScrollArea>  */}
              </Col>
            </Row>

          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  soundOn: state.userSettings.soundOn,
  gamesHistory: state.member.gamesHistory || [],
  gamesHistoryTotal: state.member.gamesHistoryTotal || {},
});

const mapDispatchToProps = {
  fetchGamesHistory: getGamesHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(GamesHistory));

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { Helmet } from 'react-helmet';
import { filter, size } from 'lodash';

import { connect } from 'react-redux';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
// import Input from 'reactstrap/lib/Input';

import { Firebase } from '../../../../../lib/firebase';
// import ScrollAreaWrapper from '../../Components/ScrollAreaWrapper';

import RoomsTableRender from './RoomsTableRender';
import RoomsTableJoinedRender from './RoomsTableJoinedRender';

import CustomModal from '../../Components/Components/Modal';
import { TextInput } from '../../Components/Components/TextInput';

import Header from './Components/Header';
import NewGame from './Components/NewGame';
import FilterComponent from './Components/Filter';

import {
  SCREEN_MODE,
  FROUNT_SIDE_STATUS,
  MENU_NAVIGATION,
} from '../../../../../constants/constants';

import playerJoinedSound from '../../../../../sounds/player_joined.wav';
import buttonClickedSound from '../../../../../sounds/click_feedback.flac';

import {
  joinRoom,
  leaveRoomMenu,
  changeRoomsFilter,
  resetRoomError,
} from '../../../../../actions/room';
import {
  unBlockUser, getFriends, clickCampaign,
} from '../../../../../actions/member';
import { resetFilterRoomsExit } from '../../../../../actions/userSettings';
import { isFourDigitString } from '../../../../../utils/stringUtils';
import { Link } from 'react-router-dom';
import { Media } from 'reactstrap';

const GameTypeMap = [
  { name: 'P', value: 'P' },
  { name: 'PM', value: 'PM' },
  { name: 'G', value: 'G' },
  { name: 'MG', value: 'MG' },
  { name: 'Privāta', value: 'Privāta' },
];


const GameBetMap = [
  // { name: '1:1', value: '1:1' },
  // { name: '1:5', value: '1:5' },
  { name: '1:10', value: '1:10' },
  { name: '1:25', value: '1:25' },
  { name: '1:50', value: '1:50' },
  { name: '1:100', value: '1:100' },
  { name: '1:250', value: '1:250' },
  { name: '1:500', value: '1:500' },
  { name: '1:1000', value: '1:1000' },
  { name: '1:5000', value: '1:5000' },
  { name: '1:10000', value: '1:10000' },
];

class RoomsTable extends React.Component {
  static propTypes = {
    rooms: PropTypes.shape(),
    joinedRooms: PropTypes.shape(),
    joinRoomFunc: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    uid: PropTypes.string,
    activeTournament: PropTypes.string,
    privateRoomPassword: PropTypes.string,
    t: PropTypes.func.isRequired,
    leaveRoom: PropTypes.func.isRequired,
    userSettings: PropTypes.shape().isRequired,
    lvl: PropTypes.number,
    changeFilter: PropTypes.func.isRequired,
    joinRoomError: PropTypes.shape(),
    resetError: PropTypes.func.isRequired,
    screenMode: PropTypes.string,
    filters: PropTypes.shape(),
    resetFilterRooms: PropTypes.func.isRequired,
    // fontMode: PropTypes.string,
    tournaments: PropTypes.arrayOf(PropTypes.shape()),
    myTournamentsData: PropTypes.shape({}),
    offset: PropTypes.number,
    unBlockUserFunc: PropTypes.func.isRequired,
    fetchFriends: PropTypes.func.isRequired,
    friends: PropTypes.shape(),
    socProvider: PropTypes.string,
    setScreenView: PropTypes.func.isRequired,
    campaignData: PropTypes.shape(),
    i18n: PropTypes.shape(),
    devMode: PropTypes.bool,
    clickACampaign: PropTypes.func.isRequired,
  }

  static defaultProps = {
    uid: '',
    rooms: {},
    joinedRooms: {},
    activeTournament: null,
    privateRoomPassword: null,
    joinRoomError: {},
    screenMode: SCREEN_MODE.normal,
    // userSettings: {},
    socProvider: '',
    filters: {
      speed: [],
      bet: [],
      minGames: [],
      fourPRoom: [],
      gameType: [],
      maza: false,
      privateRoom: false,
      pro: false,
      friendsRoom: false,
    },
    friends: {},
    tournaments: [],
    myTournamentsData: {},
    offset: 0,
    lvl: null,
    campaignData: [],
    devMode: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      joinRoomClicked: false,
      typeFilter: '-',
      betFilter: '-',
      rooms: [],
      joinedRoom: null,
      password: [],
      privateRoomConfirm: false,
      privateRoomConfirmError: '',
      privateRoomId: '',
      prevRooms: {},
      gameTypeFilterOpen: false,
      gameBetFilterOpen: false,
      gameTypeFilterMap: null,
      gameBetFilterMap: null,
      popoverOpen: {},
      errorModalIgnored: false,
      errorRoomId: '',
      errorPassword: '',
      blockedUId: '',
      tooltipId: null,
      // filters: {
      //   speed: [],
      //   bet: [],
      //   minGames: [],
      //   fourPRoom: [],
      //   gameType: [],
      //   maza: false,
      //   privateRoom: false,
      //   pro: false,
      // },
    };

    this.digit1 = React.createRef();
    this.digit2 = React.createRef();
    this.digit3 = React.createRef();
    this.digit4 = React.createRef();

    this.playerJoinedAudio = new Audio(playerJoinedSound);
    this.buttonClickedAudio = new Audio(buttonClickedSound);
  }

  componentDidMount() {
    const { t, rooms, joinedRooms, resetError, userSettings, fetchFriends, socProvider, setScreenView } = this.props;
    const {
      typeFilter,
      betFilter,
      gameTypeFilterMap,
      gameBetFilterMap,
    } = this.state;
    const { hostname } = window.location;

    resetError();
    let devMode = false;

    if (hostname && (hostname.includes('dev') || hostname.includes('localhost'))) {
      devMode = true;
    }

    setScreenView('rooms');

    // const { roomsFilter } = userSettings;

    // if (roomsFilter) {
    //   this.setState({ filters: roomsFilter });
    // } else {
    //   this.setState({
    //     filters: {
    //       speed: [],
    //       bet: [],
    //       minGames: [],
    //       fourPRoom: [],
    //       gameType: [],
    //       maza: false,
    //       privateRoom: false,
    //       pro: false,
    //     },
    //   });
    // }
    if (!gameTypeFilterMap || !gameBetFilterMap) {
      this.setState({ gameTypeFilterMap: { '-': t('common.all'), ...GameTypeMap }, gameBetFilterMap: { '-': t('common.all'), ...GameBetMap } });
    }

    const roomsArray = [];

    if (rooms) {
      this.setState({ prevRooms: { ...rooms } });

      Object.keys(rooms).map((key) => {
        roomsArray.push({
          filled: this._roomFilled(rooms[key]),
          key,
          ...rooms[key],
        });
        return null;
      });

      if (roomsArray && roomsArray.length > 0) {
        let typeFilteredRooms = roomsArray;
        let betFilteredRooms = [];

        if (typeFilter === 'P') {
          typeFilteredRooms = roomsArray.filter(room => room.globalParams && room.globalParams.gameType === 'P' && !room.globalParams.smallGame);
        } else if (typeFilter === 'PM') {
          typeFilteredRooms = roomsArray.filter(room => room.globalParams && room.globalParams.gameType === 'P' && room.globalParams.smallGame);
        } else if (typeFilter === 'G') {
          typeFilteredRooms = roomsArray.filter(room => room.globalParams && room.globalParams.gameType === 'G' && !room.globalParams.smallGame);
        } else if (typeFilter === 'MG') {
          typeFilteredRooms = roomsArray.filter(room => room.globalParams && room.globalParams.gameType === 'G' && room.globalParams.smallGame);
        } else if (typeFilter === 'Privāta') {
          typeFilteredRooms = roomsArray.filter(room => room.globalParams && room.globalParams.privateRoom);
        }

        if (!betFilter || betFilter === '-') {
          betFilteredRooms = typeFilteredRooms;
        } else {
          betFilteredRooms = typeFilteredRooms.filter(room => room.globalParams && room.globalParams.bet === betFilter);
        }

        this.setState({ rooms: [...betFilteredRooms] });
      }
    }

    if (rooms) {
      let joinedRoom;

      if (joinedRooms) {
        Object.keys(joinedRooms).map((joinedRoomKey) => {
          joinedRoom = { ...rooms[joinedRoomKey], key: joinedRoomKey } || null;
          return null;
        });
      }

      this.setState({ joinedRoom });
    }

    if (socProvider === 'facebook') {
      Firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          window.FB.getLoginStatus((response) => {
            if (response.status === 'connected') {
              const { accessToken } = response.authResponse;

              window.FB.api(
                `/${user.providerData[0].uid}/friends`,
                'GET',
                { access_token: accessToken },
                (resp) => {
                  //  this.setState({ friends: resp.data });

                  console.log(resp);
                  if (resp && resp.data) {
                    fetchFriends(resp).then((res) => {
                      console.log(res);
                    });
                  }
                },
              );
            }
          });
        } else {
          //  console.log('no user');
        }
      });
    } else if (socProvider === 'draugiem') {

      fetchFriends(devMode);
    } else {
      fetchFriends();
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      t, rooms, joinedRooms, userSettings,
    } = nextProps;

    const {
      gameTypeFilterMap,
      gameBetFilterMap,
      joinedRoom: curJoinedRoom,
    } = this.state;

    if (!gameTypeFilterMap || !gameBetFilterMap) {
      this.setState({ gameTypeFilterMap: { '-': t('common.all'), ...GameTypeMap }, gameBetFilterMap: { '-': t('common.all'), ...GameBetMap } });
    }

    this.filterRooms(rooms, null, null);

    if (rooms) { // && !curJoinedRoom
      let joinedRoom;

      if (joinedRooms) {
        Object.keys(joinedRooms).map((joinedRoomKey) => {
          if (rooms[joinedRoomKey]) {
            joinedRoom = {
              filled: this._roomFilled(rooms[joinedRoomKey]),
              key: joinedRoomKey,
              ...rooms[joinedRoomKey],
            };
          }
          return null;
        });
      }

      /*  if (userSettings && userSettings.soundOn) {
          const { joinedRoom: joinedRoomState } = this.state;
          if (joinedRoomState && joinedRoomState.playersList && joinedRoom && joinedRoom.playersList) {
            const prevPl = joinedRoomState.playersList.playerList;
            const newPl = joinedRoom.playersList.playerList;
  
            if (!prevPl && newPl) {
            } else if (prevPl && newPl && Object.keys(prevPl).length < Object.keys(newPl).length) {
            }
          }
        } */

      this.setState({ joinedRoom });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { t, joinRoomError, joinedRooms, resetError, campaignData, loggedInUserDataFetched, adsHidden } = this.props;
    const { errorModalOpen, privateRoomConfirmError, password, bannerSet } = this.state;

    // console.log('componentDidUpdate', joinRoomError, errorModalOpen, prevProps.joinRoomError, joinedRooms, prevProps.joinedRooms);

    // if (size(prevProps.joinRoomError) === 0 && size(joinedRooms) > 0) {
    if (joinedRooms && !prevProps.joinedRooms) {
      this.closePrivateRoomEnter();
    }
    // if ((joinRoomError && joinRoomError.error && size(prevProps.joinRoomError.error) === 0)) {
    if (joinRoomError && joinRoomError.error && !prevProps.joinRoomError) {
      console.log('set error', joinRoomError.error);
      if (joinRoomError.error === 'wrong password') {
        this.setState({ joinRoomClicked: false, privateRoomConfirmError: t('roomsTable.wrongCode') });
      } else if (joinRoomError.error === 'room closed') {
        this.setState({ joinRoomClicked: false, privateRoomConfirmError: t('roomsTable.roomClosed') });
      } else if (joinRoomError.error === 'Room full') {
        this.setState({ joinRoomClicked: false, privateRoomConfirmError: t('roomsTable.placeTaken') });
      } else if (joinRoomError.error === 'insufficient balance') {
        if (joinRoomError.roomId) {
          this.setState({
            joinRoomClicked: false, errorModalOpen: true, errorModalBuyMoney: true, errorModalText: t('notifications:noMoneyMenuDescription', { amount: joinRoomError.balNeeded, bet: joinRoomError.bet }), errorModalHeader: t('common:home.notifications'),
          });
        } else {
          this.setState({
            joinRoomClicked: false, errorModalOpen: true, errorModalBuyMoney: true, errorModalText: t('notifications:noMoneyCreateRoomDescription', { amount: joinRoomError.balNeeded, bet: joinRoomError.bet }), errorModalHeader: t('common:home.notifications'),
          });
        }
      } else if (joinRoomError.error === 'pro room') {
        this.setState({
          joinRoomClicked: false, errorModalOpen: true, errorModalText: t('notifications:proRoomMenu'), errorModalHeader: t('notifications:proRoomMenuHeader'),
        });
      } else if (joinRoomError.error === 'pro bet') {
        this.setState({
          joinRoomClicked: false, errorModalOpen: true, errorModalText: t('notifications:proBetMenu'), errorModalHeader: t('common:home.notifications'),
        });
      } else if (joinRoomError.error === 'Ignored') {
        if (joinRoomError.type === 'you ignored') {
          this.setState({
            joinRoomClicked: false, errorModalOpen: true, errorModalIgnored: true, errorRoomId: joinRoomError?.roomId, errorPassword: joinRoomError?.password, blockedUId: joinRoomError?.uid, errorModalText: t('notifications:youIgnoredPlayer'), errorModalHeader: t('notifications:IgnoreHeader'),
          });
        } else {
          this.setState({
            joinRoomClicked: false, errorModalOpen: true, errorModalText: t('notifications:playerIgnoredYou'), errorModalHeader: t('notifications:IgnoreHeader'),
          });
        }
      } else if (joinRoomError.error === 'you ignored') {
        this.setState({
          joinRoomClicked: false, errorModalOpen: true, errorModalIgnored: true, errorRoomId: joinRoomError?.roomId, errorPassword: joinRoomError?.password, blockedUId: joinRoomError?.uid, errorModalText: t('notifications:youIgnoredPlayer'), errorModalHeader: t('notifications:IgnoreHeader'),
        });
      } else if (joinRoomError.error === 'ignored you') {
        this.setState({
          joinRoomClicked: false, errorModalOpen: true, errorModalText: t('notifications:playerIgnoredYou'), errorModalHeader: t('notifications:IgnoreHeader'),
        });
      } else if (joinRoomError.error === 'multiAccount') {
        this.setState({
          joinRoomClicked: false, errorModalOpen: true, errorModalText: t('notifications:multiAccount'), errorModalHeader: t('notifications:IgnoreHeader'),
        });
      } else if (joinRoomError.error === 'email not verified') {
        this.setState({
          joinRoomClicked: false, errorModalOpen: true, errorModalText: t('notifications:emailNotVerified'), errorModalHeader: t('notifications:emailNotVerifiedHeader'),
        });
      } else if (joinRoomError.error === 'in active tournament') {
        this.setState({
          joinRoomClicked: false, errorModalOpen: true, errorModalText: t('notifications:inActiveTournament'), errorModalHeader: t('common:home.confirm'),
        });
      } else {
        this.setState({ joinRoomClicked: false });
      }
      resetError();
    }

    if (loggedInUserDataFetched && (!adsHidden || adsHidden < Date.now())) {
      if (!bannerSet && campaignData && campaignData[0] && campaignData[0]?.externalBanner) {
        const scriptTag = document.createElement("script");

        console.log('campaignData[0]?.adFormMeasure', campaignData[0]?.adFormMeasure);

        if (campaignData[0]?.adFormMeasure) {
          // const targetLink2 = `${campaignData[0]?.externalBanner}?redirect=${campaignData[0]?.adFormMeasure};C=0`;

          const targetLink2 = `${campaignData[0]?.externalBanner}${campaignData[0]?.adFormMeasure}`;

          console.log('targetLink2', targetLink2);

          scriptTag.src = targetLink2;
          scriptTag.async = true;
        } else {
          scriptTag.src = campaignData[0]?.externalBanner;
          scriptTag.async = true;
        }

        console.log('campaignData[0]?.externalBanner', campaignData[0]?.externalBanner);

        // scriptTag.addEventListener('load', () => this.setState({ bannerSet: true }));

        scriptTag.addEventListener('load', () => {
          const promise1 = new Promise((r) => setTimeout(r, 1000));
          console.log('scriptTag load inputs 1');

          try {
            Promise.all([promise1]).then(() => {
              const inputs = document.getElementsByTagName('iframe');
              let bannerIframe = null;

              console.log('scriptTag load inputs 2', inputs);

              console.log('inputs.length', inputs.length);
              if (inputs) {
                for (var i = 0; i < inputs.length; i++) {
                  console.log('input', inputs[i].clientHeight, inputs[i].clientWidth, inputs[i].offsetHeight);

                  if (inputs[i].clientHeight === 120 && inputs[i].clientWidth === 920) {
                    bannerIframe = inputs[i];
                  }
                }
              }
              console.log('scriptTag load bannerIframe', bannerIframe);
              //  console.log('scriptTag load bannerIframe document', bannerIframe.document);
              //  console.log('scriptTag load bannerIframe contentDocument', bannerIframe.contentDocument);
              if (bannerIframe && bannerIframe.contentDocument) {
                // bannerIframe.document.addEventListener("click", this.handleClickCampaignExternal2);

                console.log('bannerIframe.contentDocument', bannerIframe.contentDocument);

                bannerIframe.contentDocument.body.addEventListener('mousedown', this.handleClickCampaignExternal2);
              } else if (bannerIframe && bannerIframe.document) {
                console.log('bannerIframe.document', bannerIframe.document);

                bannerIframe.document.addEventListener("click", this.handleClickCampaignExternal2);
              }
            });
          } catch (err) {
            console.log(err);
          }

          this.setState({ bannerSet: true });
        });

        const bannerRef3 = document.getElementById('bannerRef');

        console.log('bannerRef3', bannerRef3);

        if (bannerRef3 && campaignData[0]?.externalBanner) {
          bannerRef3.appendChild(scriptTag);
          this.setState({ bannerSet: true });
        }
      }
    }
  }

  componentWillUnmount() {
    try {
      if (this.timeoutID) clearTimeout(this.timeoutID);
      const { resetFilterRooms, uid } = this.props;
      resetFilterRooms(uid);
    } catch (err) {
      console.log('err componentWillUnmount');
    }
  }

  _roomFilled = (room) => {
    if (room.playersList && room.globalParams) {
      if (!room.globalParams.fourPRoom
        && room.playersList.player1 && room.playersList.player1.uid
        && room.playersList.player2 && room.playersList.player2.uid
        && room.playersList.player3 && room.playersList.player3.uid) {
        return true;
      } if (room.globalParams.fourPRoom
        && room.playersList.player1 && room.playersList.player1.uid
        && room.playersList.player2 && room.playersList.player2.uid
        && room.playersList.player3 && room.playersList.player3.uid
        && room.playersList.player4 && room.playersList.player4.uid) {
        return true;
      }
      return false;
    }
    return false;
  }

  filterRooms = (rooms, passedTypeFilter, passedBetFilter) => {
    const {
      rooms: lastRooms,
      prevRooms,
      privateRoomId,
    } = this.state;

    let {
      typeFilter,
      betFilter,
    } = this.state;

    if (passedTypeFilter) {
      typeFilter = passedTypeFilter;
    }

    if (passedBetFilter) {
      betFilter = passedBetFilter;
    }

    if (Object.keys(rooms).length > 0 || Object.keys(prevRooms).length > 0) {
      if (privateRoomId) {
        const joinedPrivateRoom = rooms[privateRoomId];

        if (!joinedPrivateRoom) {
          this.setState({
            password: [], privateRoomConfirm: false, privateRoomId: '', privateRoomConfirmError: '',
          });
        }
      }

      const exRooms = {};
      const newRooms = { ...rooms };

      Object.keys(prevRooms).map((roomKey) => {
        if (!rooms[roomKey]) {
          exRooms[roomKey] = prevRooms[roomKey];
          exRooms[roomKey].removal = true;

          this.timeoutID = setTimeout(() => {
            const { prevRooms: prevRooms2 } = this.state;
            delete prevRooms2[roomKey];

            const remainingRooms = lastRooms.filter(_room => _room.key !== roomKey);

            //  console.log('set rooms 1');

            //  this.setState({
            //    rooms: remainingRooms,
            //    prevRooms: prevRooms2,
            //  });
          }, 2000);
        } else {
          exRooms[roomKey] = { ...rooms[roomKey], removal: this._roomFilled(rooms[roomKey]) };
        }

        delete newRooms[roomKey];
        return null;
      });

      const allRooms = { ...exRooms, ...newRooms };

      const roomsArray = [];

      Object.keys(allRooms).map((key) => {
        if (allRooms[key].filled === true) {
          return null;
        }

        roomsArray.push({
          filled: allRooms[key].removal || this._roomFilled(allRooms[key]),
          key,
          ...allRooms[key],
        });
        return null;
      });

      //  if (!isEqual(prevRooms, allRooms)) {
      //  console.log('set rooms 2', { allRooms });
      //  this.setState({ prevRooms: allRooms });
      //  }

      if (roomsArray && roomsArray.length > 0) {
        let typeFilteredRooms = [...roomsArray];
        let betFilteredRooms = [];

        if (typeFilter === 'P') {
          typeFilteredRooms = roomsArray.filter(room => room.globalParams && room.globalParams.gameType === 'P' && !room.globalParams.smallGame);
        } else if (typeFilter === 'PM') {
          typeFilteredRooms = roomsArray.filter(room => room.globalParams && room.globalParams.gameType === 'P' && room.globalParams.smallGame);
        } else if (typeFilter === 'G') {
          typeFilteredRooms = roomsArray.filter(room => room.globalParams && room.globalParams.gameType === 'G' && !room.globalParams.smallGame);
        } else if (typeFilter === 'MG') {
          typeFilteredRooms = roomsArray.filter(room => room.globalParams && room.globalParams.gameType === 'G' && room.globalParams.smallGame);
        } else if (typeFilter === 'Privāta') {
          typeFilteredRooms = roomsArray.filter(room => room.globalParams && room.globalParams.privateRoom);
        }

        if (!betFilter || betFilter === '-') {
          betFilteredRooms = [...typeFilteredRooms];
        } else {
          betFilteredRooms = typeFilteredRooms.filter(room => room.globalParams && room.globalParams.bet === betFilter);
        }

        //  if (!isEqual(lastRooms, betFilteredRooms)) {
        //  console.log('set rooms 3');
        //  this.setState({ rooms: betFilteredRooms });
        //  }
      }
    }
  }

  filterType = (gameTypeKey) => {
    const { rooms, changeFilter } = this.props;
    this.playButtonSound();

    this.setState({ typeFilter: gameTypeKey });

    changeFilter('type', gameTypeKey);

    this.filterRooms(rooms, gameTypeKey, null);
  }

  filterBet = (betKey) => {
    const { rooms, changeFilter } = this.props;
    this.playButtonSound();

    this.setState({ betFilter: betKey });

    changeFilter('bet', betKey);

    this.filterRooms(rooms, null, betKey);
  }

  closePrivateRoomEnter = () => {
    const { resetError } = this.props;
    this.setState({
      password: [], privateRoomConfirm: false, privateRoomId: '', privateRoomConfirmError: '',
    });
    resetError();
  }

  handleDelete = (e) => {
    const { password } = this.state;

    if (e.target) {
      const { value, name } = e.target;

      let newVal;

      if (e.keyCode === 8) {
        newVal = null;
      } else if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) {
        if (e.keyCode === 48 || e.keyCode === 96) {
          newVal = 0;
        } else if (e.keyCode === 49 || e.keyCode === 97) {
          newVal = 1;
        } else if (e.keyCode === 50 || e.keyCode === 98) {
          newVal = 2;
        } else if (e.keyCode === 51 || e.keyCode === 99) {
          newVal = 3;
        } else if (e.keyCode === 52 || e.keyCode === 100) {
          newVal = 4;
        } else if (e.keyCode === 53 || e.keyCode === 101) {
          newVal = 5;
        } else if (e.keyCode === 54 || e.keyCode === 102) {
          newVal = 6;
        } else if (e.keyCode === 55 || e.keyCode === 103) {
          newVal = 7;
        } else if (e.keyCode === 56 || e.keyCode === 104) {
          newVal = 8;
        } else if (e.keyCode === 57 || e.keyCode === 105) {
          newVal = 9;
        }
      } else {
        return;
      }

      if (!newVal || !isNaN(newVal)) {
        if (newVal || newVal === 0) {
          password[name] = newVal.toString();
        } else {
          password[name] = null;
        }

        if (newVal || newVal === 0) {
          if (name === 0 || name === '0') {
            if (this.digit2) {
              this.digit2.current.focus();
            }
          }
          if (name === 1 || name === '1') {
            if (this.digit3) {
              this.digit3.current.focus();
            }
          }
          if (name === 2 || name === '2') {
            if (this.digit4) {
              this.digit4.current.focus();
            }
          }
        } else {
          if (name === 1 || name === '1') {
            if (this.digit1) {
              if (value || value === 0 || value === '0') {
                this.digit2.current.focus();
              } else {
                this.digit1.current.focus();
              }
            }
          }
          if (name === 2 || name === '2') {
            if (this.digit2) {
              if (value || value === 0 || value === '0') {
                this.digit3.current.focus();
              } else {
                this.digit2.current.focus();
              }
            }
          }
          if (name === 3 || name === '3') {
            if (this.digit3) {
              if (value || value === 0 || value === '0') {
                this.digit4.current.focus();
              } else {
                this.digit3.current.focus();
              }
            }
          }
        }

        this.setState({
          password,
        });
      }
    }
  }

  joinPrivateRoomConfirm = (e) => {
    const { activeTournament, finishedTournamentLastRound, joinedRooms } = this.props;
    console.log("this.props: ", joinedRooms)
    const { joinRoomClicked, joinedRoom } = this.state;

    const roomId = e.target.name;

    if (joinRoomClicked || (activeTournament && !finishedTournamentLastRound) || joinedRoom) {
      return null;
    }

    this.setState({
      privateRoomConfirm: (!joinedRooms),
      privateRoomId: roomId,
      privateRoomConfirmError: '',
    });

    return null;
  }

  joinPrivateRoomClicked = async (pwd) => {
    const {
      joinRoomFunc, rooms, activeTournament, finishedTournamentLastRound, t, lvl,
    } = this.props;
    const { privateRoomId, password, joinRoomClicked, joinedRoom } = this.state;

    if (rooms[privateRoomId].globalParams.proGame && lvl < 10) {
      this.setState({
        errorModalOpen: true, errorModalText: t('notifications:needLevelForProJoin'), errorModalHeader: t('game:message'), privateRoomConfirm: false,
      });
      return null;
    }

    if (joinRoomClicked || (activeTournament && !finishedTournamentLastRound) || joinedRoom) {
      return null;
    }

    const checkTournament = await this.checkTournamentLine();
    console.log(checkTournament, "checkTournament")
    if (checkTournament) {
      this.setState({
        errorModalOpen: true, errorModalText: t('notifications:inActiveTournament'), errorModalHeader: t('common:home.confirm'),
      });
      return null;
    }

    // if (password && password.length === 4) {
    this.setState({ joinRoomClicked: true });

    const passString = pwd || password;

    let fourPRoomVar = false;
    let betVar = null;

    if (rooms[privateRoomId] && rooms[privateRoomId].globalParams) {
      betVar = rooms[privateRoomId].globalParams.bet;
      fourPRoomVar = rooms[privateRoomId].globalParams.fourPRoom;
    }

    this.playButtonSound();

    await joinRoomFunc(privateRoomId, passString, fourPRoomVar).then((res) => {
      console.log({ res }, "join passowrd")
      if (res.data.data.status === 'success') {
        if (res.data.data.password) {
          this.setState({
            joinRoomClicked: false,
            privateRoomPassword: res.data.data.password,
            showPrivateRoomPassword: true,
          });
        } else {
          this.setState({
            joinRoomClicked: false,
            privateRoomConfirm: false,
            privateRoomId: null,
          });
        }
      } else if (res.data.data.status === 'error') {
        const { error, balNeeded, type } = res.data.data;

        if (error) {
          if (error === 'wrong password') {
            this.setState({ joinRoomClicked: false, privateRoomConfirmError: t('roomsTable.wrongCode') });
          } else if (error === 'room closed') {
            this.setState({ joinRoomClicked: false, privateRoomConfirmError: t('roomsTable.roomClosed') });
          } else if (error === 'position taken') {
            this.setState({ joinRoomClicked: false, privateRoomConfirmError: t('roomsTable.placeTaken') });
          } else if (error === 'insufficient balance') {
            this.setState({
              joinRoomClicked: false, errorModalOpen: true, errorModalBuyMoney: true, errorModalText: t('notifications:noMoneyMenuDescription', { amount: balNeeded, bet: betVar }), errorModalHeader: t('notifications:noMoneyMenuDescriptionHeader'),
            });
          } else if (error === 'pro room') {
            this.setState({
              joinRoomClicked: false, errorModalOpen: true, errorModalText: t('notifications:proRoomMenu'), errorModalHeader: t('notifications:proRoomMenuHeader'),
            });
          } else if (error === 'pro bet') {
            this.setState({
              joinRoomClicked: false, errorModalOpen: true, errorModalText: t('notifications:proBetMenu'), errorModalHeader: t('common:home.notifications'),
            });
          } else if (error === 'Ignored') {
            if (type === 'you ignored') {
              this.setState({
                joinRoomClicked: false, errorModalOpen: true, errorModalIgnored: true, errorModalText: t('notifications:youIgnoredPlayer'), errorModalHeader: t('notifications:IgnoreHeader'),
              });
            } else {
              this.setState({
                joinRoomClicked: false, errorModalOpen: true, errorModalText: t('notifications:playerIgnoredYou'), errorModalHeader: t('notifications:IgnoreHeader'),
              });
            }
          } else if (error === 'you ignored') {
            this.setState({
              joinRoomClicked: false, errorModalOpen: true, errorModalIgnored: true, errorModalText: t('notifications:youIgnoredPlayer'), errorModalHeader: t('notifications:IgnoreHeader'),
            });
          } else if (error === 'ignored you') {
            this.setState({
              joinRoomClicked: false, errorModalOpen: true, errorModalText: t('notifications:playerIgnoredYou'), errorModalHeader: t('notifications:IgnoreHeader'),
            });
          } else if (error === 'email not verified') {
            this.setState({
              joinRoomClicked: false, errorModalOpen: true, errorModalText: t('notifications:emailNotVerified'), errorModalHeader: t('notifications:emailNotVerifiedHeader'),
            });
          } else if (error === 'in active tournament') {
            this.setState({
              joinRoomClicked: false, errorModalOpen: true, errorModalText: t('notifications:inActiveTournament'), errorModalHeader: t('common:home.confirm'),
            });
          } else {
            this.setState({ joinRoomClicked: false });
          }
        }
      }
    }).catch((err) => {
      console.log(err);
      this.setState({ joinRoomClicked: false });
    });
    // } else {
    //   this.setState({ joinRoomClicked: false, privateRoomConfirmError: t('roomsTable.passwordLeng') });
    // }

    return null;
  }

  showPrivateRoomPassword = () => {
    const { privateRoomPassword } = this.props;

    if (privateRoomPassword) {
      this.setState({
        showPrivateRoomPassword: true,
      });
    }
  }

  closePrivateRoomPassword = () => {
    this.setState({
      showPrivateRoomPassword: false,
    });
  }

  joinRoomClicked = async (e) => {
    e.persist();

    const {
      t, joinRoomFunc, rooms, activeTournament, finishedTournamentLastRound, resetError, lvl,
    } = this.props;
    const { joinRoomClicked, joinedRoom } = this.state;

    if (joinRoomClicked || (activeTournament && !finishedTournamentLastRound) || joinedRoom) {
      return null;
    }

    const checkTournament = await this.checkTournamentLine();
    if (checkTournament) {
      this.setState({
        errorModalOpen: true, errorModalText: t('notifications:inActiveTournament'), errorModalHeader: t('common:home.confirm'),
      });
      return null;
    }

    resetError();

    if (e.target && e.target.name) {
      const roomId = e.target.name;

      if (rooms[roomId].globalParams.proGame && lvl < 10) {
        this.setState({
          errorModalOpen: true, errorModalText: t('notifications:needLevelForProJoin'), errorModalHeader: t('game:message'),
        });
        return null;
      }

      console.log('joinRoomClicked', { roomId });

      let betVar = null;
      let fourPRoomVar = false;

      if (rooms[roomId] && rooms[roomId].globalParams) {
        betVar = rooms[roomId].globalParams.bet;
        fourPRoomVar = rooms[roomId].globalParams.fourPRoom;
      }

      this.setState({ joinRoomClicked: true });
      this.playButtonSound();

      await joinRoomFunc(roomId, '', fourPRoomVar).then((res) => {
        if (res.data.data.status === 'success') {
          if (res.data.data.password) {
            this.setState({
              joinRoomClicked: false,
              privateRoomPassword: res.data.data.password,
              showPrivateRoomPassword: true,
            });
          } else {
            this.setState({
              joinRoomClicked: false,
            });
          }
        } else if (res.data.data.status === 'error') {
          const { error, balNeeded, type } = res.data.data;

          if (error) {
            if (error === 'insufficient balance') {
              this.setState({
                joinRoomClicked: false, errorModalOpen: true, errorModalBuyMoney: true, errorModalText: t('notifications:noMoneyMenuDescription', { amount: balNeeded, bet: betVar }), errorModalHeader: t('notifications:noMoneyMenuDescriptionHeader'),
              });
            } else if (error === 'pro room') {
              this.setState({
                joinRoomClicked: false, errorModalOpen: true, errorModalText: t('notifications:proRoomMenu'), errorModalHeader: t('notifications:proRoomMenuHeader'),
              });
            } else if (error === 'pro bet') {
              this.setState({
                joinRoomClicked: false, errorModalOpen: true, errorModalText: t('notifications:proBetMenu'), errorModalHeader: t('common:home.notifications'),
              });
            } else if (error === 'Ignored') {
              if (type === 'you ignored') {
                this.setState({
                  joinRoomClicked: false, errorModalOpen: true, errorModalIgnored: true, errorModalText: t('notifications:youIgnoredPlayer'), errorModalHeader: t('notifications:IgnoreHeader'),
                });
              } else {
                this.setState({
                  joinRoomClicked: false, errorModalOpen: true, errorModalText: t('notifications:playerIgnoredYou'), errorModalHeader: t('notifications:IgnoreHeader'),
                });
              }
            } else if (error === 'you ignored') {
              this.setState({
                joinRoomClicked: false, errorModalOpen: true, errorModalIgnored: true, errorModalText: t('notifications:youIgnoredPlayer'), errorModalHeader: t('notifications:IgnoreHeader'),
              });
            } else if (error === 'ignored you') {
              this.setState({
                joinRoomClicked: false, errorModalOpen: true, errorModalText: t('notifications:playerIgnoredYou'), errorModalHeader: t('notifications:IgnoreHeader'),
              });
            } else if (error === 'email not verified') {
              this.setState({
                joinRoomClicked: false, errorModalOpen: true, errorModalText: t('notifications:emailNotVerified'), errorModalHeader: t('notifications:emailNotVerifiedHeader'),
              });
            } else if (error === 'in active tournament') {
              this.setState({
                joinRoomClicked: false, errorModalOpen: true, errorModalText: t('notifications:inActiveTournament'), errorModalHeader: t('common:home.confirm'),
              });
            } else if (error === 'wrong password') {
              this.setState({
                joinRoomClicked: false, errorModalOpen: true, errorModalText: t('notifications:wrongCode'), errorModalHeader: t('notifications:wrongCodeHeader'),
              });
            } else {
              this.setState({ joinRoomClicked: false });
            }
          }
        }
        //  this.setState({ joinRoomClicked: false });
      }).catch((err) => {
        console.log(err);
        this.setState({ joinRoomClicked: false });
      });
    }

    return null;
  };

  errJoinRoomClicked = async (rId) => {

    const {
      t, joinRoomFunc, rooms, activeTournament, finishedTournamentLastRound, resetError, lvl,
    } = this.props;
    const { joinRoomClicked, joinedRoom } = this.state;

    if (joinRoomClicked || (activeTournament && !finishedTournamentLastRound) || joinedRoom) {
      return null;
    }

    const checkTournament = await this.checkTournamentLine();
    if (checkTournament) {
      this.setState({
        errorModalOpen: true, errorModalText: t('notifications:inActiveTournament'), errorModalHeader: t('common:home.confirm'),
      });
      return null;
    }

    resetError();

    if (rId) {
      const roomId = rId;

      if (rooms[roomId].globalParams.proGame && lvl < 10) {
        this.setState({
          errorModalOpen: true, errorModalText: t('notifications:needLevelForProJoin'), errorModalHeader: t('game:message'),
        });
        return null;
      }

      console.log('joinRoomClicked', { roomId });

      let betVar = null;
      let fourPRoomVar = false;

      if (rooms[roomId] && rooms[roomId].globalParams) {
        betVar = rooms[roomId].globalParams.bet;
        fourPRoomVar = rooms[roomId].globalParams.fourPRoom;
      }

      this.setState({ joinRoomClicked: true });
      this.playButtonSound();

      await joinRoomFunc(roomId, '', fourPRoomVar).then((res) => {
        if (res.data.data.status === 'success') {
          if (res.data.data.password) {
            this.setState({
              joinRoomClicked: false,
              privateRoomPassword: res.data.data.password,
              showPrivateRoomPassword: true,
            });
          } else {
            this.setState({
              joinRoomClicked: false,
            });
          }
        } else if (res.data.data.status === 'error') {
          const { error, balNeeded, type } = res.data.data;

          if (error) {
            if (error === 'insufficient balance') {
              this.setState({
                joinRoomClicked: false, errorModalOpen: true, errorModalBuyMoney: true, errorModalText: t('notifications:noMoneyMenuDescription', { amount: balNeeded, bet: betVar }), errorModalHeader: t('notifications:noMoneyMenuDescriptionHeader'),
              });
            } else if (error === 'pro room') {
              this.setState({
                joinRoomClicked: false, errorModalOpen: true, errorModalText: t('notifications:proRoomMenu'), errorModalHeader: t('notifications:proRoomMenuHeader'),
              });
            } else if (error === 'pro bet') {
              this.setState({
                joinRoomClicked: false, errorModalOpen: true, errorModalText: t('notifications:proBetMenu'), errorModalHeader: t('common:home.notifications'),
              });
            } else if (error === 'Ignored') {
              if (type === 'you ignored') {
                this.setState({
                  joinRoomClicked: false, errorModalOpen: true, errorModalIgnored: true, errorModalText: t('notifications:youIgnoredPlayer'), errorModalHeader: t('notifications:IgnoreHeader'),
                });
              } else {
                this.setState({
                  joinRoomClicked: false, errorModalOpen: true, errorModalText: t('notifications:playerIgnoredYou'), errorModalHeader: t('notifications:IgnoreHeader'),
                });
              }
            } else if (error === 'you ignored') {
              this.setState({
                joinRoomClicked: false, errorModalOpen: true, errorModalIgnored: true, errorModalText: t('notifications:youIgnoredPlayer'), errorModalHeader: t('notifications:IgnoreHeader'),
              });
            } else if (error === 'ignored you') {
              this.setState({
                joinRoomClicked: false, errorModalOpen: true, errorModalText: t('notifications:playerIgnoredYou'), errorModalHeader: t('notifications:IgnoreHeader'),
              });
            } else if (error === 'email not verified') {
              this.setState({
                joinRoomClicked: false, errorModalOpen: true, errorModalText: t('notifications:emailNotVerified'), errorModalHeader: t('notifications:emailNotVerifiedHeader'),
              });
            } else if (error === 'in active tournament') {
              this.setState({
                joinRoomClicked: false, errorModalOpen: true, errorModalText: t('notifications:inActiveTournament'), errorModalHeader: t('common:home.confirm'),
              });
            } else if (error === 'wrong password') {
              this.setState({
                joinRoomClicked: false, errorModalOpen: true, errorModalText: t('notifications:wrongCode'), errorModalHeader: t('notifications:wrongCodeHeader'),
              });
            } else {
              this.setState({ joinRoomClicked: false });
            }
          }
        }
        //  this.setState({ joinRoomClicked: false });
      }).catch((err) => {
        console.log(err);
        this.setState({ joinRoomClicked: false });
      });
    }

    return null;
  };

  closeErrorModal = () => {
    const { resetError } = this.props;
    resetError();
    this.setState({
      errorModalOpen: false, errorModalText: null, errorModalHeader: null, errorModalBuyMoney: false, errorModalIgnored: false,
    });

    this.closePrivateRoomEnter();
  }

  buyMoney = () => {
    const { toggle, resetError } = this.props;

    resetError();

    this.setState({
      errorModalOpen: false, errorModalText: null, errorModalHeader: null, errorModalBuyMoney: false,
    });

    toggle(MENU_NAVIGATION.buyPageMoneyPacks);
  }

  toggleGameTypeFilter = () => {
    this.setState(prevState => ({
      gameTypeFilterOpen: !prevState.gameTypeFilterOpen,
    }));
  }

  toggleGameBetFilter = () => {
    this.setState(prevState => ({
      gameBetFilterOpen: !prevState.gameBetFilterOpen,
    }));
  }

  onHover = (e) => {
    const { activeTournament, finishedTournamentLastRound } = this.props;
    const { popoverOpen, tooltipId } = this.state;

    console.log('e', { e: e.target.name });

    let id = e.target.name;

    if (id && activeTournament && !finishedTournamentLastRound && popoverOpen && !popoverOpen[id]) {
      this.setState(prevState => ({
        popoverOpen: {
          ...prevState.popoverOpen,
          [id]: true,
        },
        tooltipId: `join-room-button-${id}`,
      }));
    }
  }

  onHoverLeave = (e) => {
    if (e.target) {
      console.log('onHoverLeave 2', { e: e.target.name });

      this.setState({
        //  popoverOpen: {
        //    ...prevState.popoverOpen,
        //    [e.target.name]: false,
        //  },
        popoverOpen: {},
        tooltipId: null,
      });
    }
  }

  playButtonSound = () => {
    const { userSettings, uid } = this.props;

    if (userSettings && uid) {
      const userSettings2 = userSettings[uid] || userSettings.default || {};

      if (userSettings2 && userSettings2.soundOn) {
        const playPromise = this.buttonClickedAudio.play();
        if (playPromise !== undefined) {
          playPromise
            .then(_ => {
              // Automatic playback started!
              // Show playing UI.
              console.log("audio played auto");
            })
            .catch(error => {
              // Auto-play was prevented
              // Show paused UI.
              console.log("playback prevented");
            });
        }
      }
    }
  }

  openCreate = () => {
    const { activeTournament, finishedTournamentLastRound, setScreenView } = this.props;
    const { joinRoomClicked, joinedRoom } = this.state;

    if ((activeTournament && !finishedTournamentLastRound) || joinRoomClicked || joinedRoom) {
      return;
    }

    setScreenView('createRoom');

    this.setState({ createModalOpen: true });
  }

  closeCreate = () => {
    this.setState({ createModalOpen: false });
  }

  openFilter = () => {
    this.setState({ filterModalOpen: true });
  }

  closeFilter = () => {
    this.setState({ filterModalOpen: false });
  }

  handleInputChange = (value, type) => {
    const { t } = this.props;
    const { privateRoomConfirmError } = this.state;

    if (isNaN(value)) {
      this.setState({ privateRoomConfirmError: t('roomsTable.numCheck') });
    } else if (value.length > 4) {
      this.setState({ privateRoomConfirmError: t('roomsTable.passwordLeng') });
    } else {
      this.setState({ privateRoomConfirmError: null });
    }
    this.setState({ password: value });
    if (isFourDigitString(value)) {
      this.joinPrivateRoomClicked(value);
      if (privateRoomConfirmError === '') {
        this.closePrivateRoomEnter();
      }
    }
  }

  checkTournamentLine = () => {
    const { tournaments, myTournamentsData, offset } = this.props;

    if (size(myTournamentsData) > 0) {
      if (size(tournaments) > 0) {

        if (myTournamentsData.tournamentId) {
          const curTournament = tournaments.find(item => item.id === myTournamentsData.tournamentId);

          if (curTournament && curTournament.currentState === 'registration') {
            if (curTournament && curTournament.startDate && curTournament.startDate - (1000 * 60 * 5) < (Date.now() + offset)) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  joinUnBlockFunc = () => {
    const { rooms, unBlockUserFunc } = this.props;
    const { errorRoomId, errorPassword, blockedUId } = this.state;

    const joinRoom = rooms[errorRoomId];

    if (blockedUId) {
      unBlockUserFunc(blockedUId).then(res => {
        this.closeErrorModal();
        if (res.status === FROUNT_SIDE_STATUS.success) {
          if (errorPassword) {
            this.joinPrivateRoomClicked();
          } else {
            this.errJoinRoomClicked(errorRoomId)
          }
        }
      });
    } else {
      if (errorPassword) {
        this.joinPrivateRoomClicked();
      } else {
        this.errJoinRoomClicked(errorRoomId)
      }
    }
  }

  handleClickCampaign = (targetLink, adFormMeasure, campaignKey) => {
    const { clickACampaign, devMode } = this.props;

    if (adFormMeasure) {
      const targetLink2 = `${targetLink}?redirect=${adFormMeasure};C=0`;

      console.log('targetLink2', targetLink2);

      window.open(targetLink2, '_blank');
      clickACampaign(campaignKey, 'lobby', 'new', devMode);
    } else {
      window.open(targetLink, '_blank');
      clickACampaign(campaignKey, 'lobby', 'new', devMode);
    }
  }

  handleClickCampaignExternal2 = () => {
    const { clickACampaign, devMode, campaignData } = this.props;

    console.log('handleClickCampaignExternal2');

    if (campaignData[0] && campaignData[0]?.key) {
      clickACampaign(campaignData[0]?.key, 'lobby', 'new', devMode);
    }
  }

  render() {
    const {
      devMode,
      i18n,
      campaignData,
      friends,
      uid,
      t,
      leaveRoom,
      privateRoomPassword,
      activeTournament,
      finishedTournamentLastRound,
      rooms: roomsProps,
      activeRoom,
      toggle,
      joinedRoomPassword,
      joinRoomError,
      screenMode,
      /*fontMode,*/
      filters,
      adsHidden,
      loggedInUserDataFetched,
    } = this.props;

    const {
      joinRoomClicked,
      typeFilter,
      betFilter,
      rooms,
      privateRoomConfirm,
      privateRoomConfirmError,
      password,
      joinedRoom,
      showPrivateRoomPassword,
      gameTypeFilterOpen,
      gameBetFilterOpen,
      gameTypeFilterMap,
      gameBetFilterMap,
      popoverOpen,
      createModalOpen,
      filterModalOpen,
      errorModalOpen,
      errorModalText,
      errorModalBuyMoney,
      errorModalHeader,
      errorModalIgnored,
      tooltipId,
    } = this.state;

    let digitsArr = [];

    if (showPrivateRoomPassword && privateRoomPassword) {
      digitsArr = privateRoomPassword.toString().split('').map(Number);
    }

    let campaignShow = false;

    console.log('adsHidden', adsHidden, Date.now(), loggedInUserDataFetched);

    console.log(!adsHidden || adsHidden < Date.now());
    console.log(adsHidden < Date.now());

    if (loggedInUserDataFetched && (!adsHidden || adsHidden < Date.now())) {
      if (campaignData) {
        if (devMode && filter(campaignData.selectedEnvs, item => item.value === 'dev')[0]) {
          campaignShow = true;
        } else if (!devMode && filter(campaignData.selectedEnvs, item => item.value === 'prod')[0]) {
          campaignShow = true;
        }
        campaignShow = filter(campaignData[0]?.selectedDesigns, item => item?.value === 'new')[0] ? true : false;
      }
    } else {
      campaignShow = false;
    }

    return (
      <Fragment>
        <Helmet>
          <title>
            Zole - {t('route.roomsTable')}
          </title>
        </Helmet>
        <div className="layout-body rooms-table-layout-body">
          <div className={`layout-body-top layout-body-top-${screenMode}`}>
            <Header
              //  createRoom={createRoom}
              changeTab={toggle}
              openCreate={this.openCreate}
              openFilter={this.openFilter}
              disableCreate={((activeTournament && !finishedTournamentLastRound) || joinRoomClicked || joinedRoom)}
              activeTournament={activeTournament}
              finishedTournamentLastRound={finishedTournamentLastRound}
              screenMode={screenMode}
            //fontMode={fontMode}
            //  privateRoomPassword={privateRoomPassword}
            />
          </div>
          {/* {campaignData[0].selectedDesigns?.new && filter(campaignData[0]?.selectedPlatforms, item => item.value.toLowerCase() === 'lobby')[0] && ( */}

          <div width="100%" style={{ width: '100%', cursor: 'pointer', position: 'relative' }}>
            <a onClick={() => this.handleClickCampaign(campaignData[0]?.pageToOpen, campaignData[0]?.adFormMeasure, campaignData[0]?.key)} width="100%" style={{ width: '100%', cursor: 'pointer' }} >
              <div id="bannerRef" style={{ overflow: 'hidden' }} />
            </a>
          </div>

          {(campaignShow && campaignData && campaignData[0] && !campaignData[0]?.externalBanner) && (
            <div className={`layout-body-top layout-body-top-${screenMode}`}>
              <a onClick={() => this.handleClickCampaign(campaignData[0]?.pageToOpen, campaignData[0]?.adFormMeasure, campaignData[0]?.key)} width="100%" style={{ width: '100%', cursor: 'pointer' }} >
                <Media style={{ objectFit: 'contain' }} src={i18n.language === 'lv' ? campaignData[0].imageUrl : i18n.language === 'en' ? campaignData[0].imageUrlEn : i18n.language === 'ru' ? campaignData[0].imageUrlRu : campaignData[0].imageUrl} width="100%" alt="" />
              </a>
            </div>
          )}
          <div className="layout-body-main">
            <div
              className="layout-body-main-scrollarea h-100"
              contentClassName="layout-body-main-scrollarea-body"
              show
              rightOffset={0}
              topOffset={0}
              bottomOffset={0}
            >
              {/* <div style={{position: 'absolute', right: 0, top: 0, width: 100, height: 300, background: 'rgba(255,255,255,0.2)'}}></div> */}

              <RoomsTableJoinedRender
                rooms={rooms}
                joinedRoom={joinedRoom}
                joinedRoomPassword={joinedRoomPassword}
                uid={uid}
                leaveRoom={leaveRoom}
                activeTournament={activeTournament}
                finishedTournamentLastRound={finishedTournamentLastRound}
                popoverOpen={popoverOpen}
                joinRoomClicked={joinRoomClicked}
                screenMode={screenMode}
              />
              <RoomsTableRender
                rooms={rooms}
                roomsProps={roomsProps}
                joinedRoom={joinedRoom}
                uid={uid}
                leaveRoom={leaveRoom}
                activeTournament={activeTournament}
                finishedTournamentLastRound={finishedTournamentLastRound}
                popoverOpen={popoverOpen}
                joinRoomClicked={joinRoomClicked}
                onHover={this.onHover}
                onHoverLeave={this.onHoverLeave}
                joinRoomClickedFunc={this.joinRoomClicked}
                joinPrivateRoomConfirm={this.joinPrivateRoomConfirm}
                screenMode={screenMode}
                filters={filters}
                t={t}
                friends={friends}
                tooltipId={tooltipId}
              />
            </div>
            {/* <div className={`menu-table-row-filler-out menu-table-row-filler-out-${screenMode}`} /> */}
          </div>

          <CustomModal
            addClassName="room-create-modal"
            isOpen={createModalOpen}
            toggle={this.closeCreate}
            size="lg"
            title={t('roomsTable.createRoom')}
            footer={null}
            body={(
              <>
                <NewGame
                  //  createRoom={createRoom}
                  privateRoomPassword={privateRoomPassword}
                  showPrivateRoomPassword={showPrivateRoomPassword}
                  closePrivateRoomPassword={this.closePrivateRoomPassword}
                  changeTab={toggle}
                  closeNewGame={this.closeCreate}
                />
              </>
            )}
          />

          <CustomModal
            isOpen={filterModalOpen}
            toggle={this.closeFilter}
            size="lg"
            width={992}
            title={t('roomsTable.filterRooms')}
            footer={null}
            addClassName="rooms-table-filter-modal"
            modalVerticalCentering
            body={(
              <>
                <FilterComponent
                  gameTypeFilterMap={gameTypeFilterMap}
                  gameTypeFilterOpen={gameTypeFilterOpen}
                  typeFilter={typeFilter}
                  gameBetFilterMap={gameBetFilterMap}
                  gameBetFilterOpen={gameBetFilterOpen}
                  betFilter={betFilter}
                  toggleGameTypeFilter={this.toggleGameTypeFilter}
                  toggleGameBetFilter={this.toggleGameBetFilter}
                  filterType={this.filterType}
                  filterBet={this.filterBet}
                  closeFilter={this.closeFilter}
                />
              </>
            )}
          />

          <CustomModal
            isOpen={privateRoomConfirm}
            toggle={this.closePrivateRoomEnter}
            size="md"
            title={t('roomsTable.enterCode')}
            footer={(
              <Button color="link" onClick={this.joinPrivateRoomClicked}>
                {t('common.ok')}
              </Button>
            )}
            body={(
              <>
                <Row>
                  <Col className="sm-8 d-flex align-items-center mb-4">
                    {/* {privateRoomConfirmError && (
                    <div className="room-password-error">
                      {privateRoomConfirmError}
                    </div>
                    )} */}
                    {t('roomsTable.privateJoinContent')}
                  </Col>
                </Row>
                <Row>
                  <Col className="sm-8 d-flex align-items-center">
                    <TextInput
                      contentEditable
                      width={'100%'}
                      inputType={'password'}
                      placeholder={t('roomsTable.roomPassword')}
                      initialValue={password}
                      inlineMessageVisible
                      onChange={(value, type) => this.handleInputChange(value, type)}
                      inputStatus={privateRoomConfirmError ? FROUNT_SIDE_STATUS.danger : null}
                      inputMessage={privateRoomConfirmError || null}
                      autoFocus
                      maxLength={4}
                    />
                  </Col>
                </Row>
                {/* <Input
                  className="room-password-digit"
                  type="text"
                  name={0}
                  id="digit1"
                  value={password[0] || ''}
                  onKeyUp={this.handleDelete}
                  onChange={this.handleChangeDigit}
                  innerRef={this.digit1}
                />
                <Input
                  className="room-password-digit"
                  type="text"
                  name={1}
                  id="digit2"
                  value={password[1] || ''}
                  onKeyUp={this.handleDelete}
                  onChange={this.handleChangeDigit}
                  innerRef={this.digit2}
                />
                <Input
                  className="room-password-digit"
                  type="text"
                  name={2}
                  id="digit3"
                  value={password[2] || ''}
                  onKeyUp={this.handleDelete}
                  onChange={this.handleChangeDigit}
                  innerRef={this.digit3}
                />
                <Input
                  className="room-password-digit"
                  type="text"
                  name={3}
                  id="digit4"
                  value={password[3] || ''}
                  onKeyUp={this.handleDelete}
                  onChange={this.handleChangeDigit}
                  innerRef={this.digit4}
                /> */}
              </>
            )}
          />

          <CustomModal
            isOpen={showPrivateRoomPassword}
            toggle={this.closePrivateRoomPassword}
            size="lg"
            title={t('roomsTable.yourCode')}
            footer={(
              <Button color="link" onClick={this.closePrivateRoomPassword}>
                {t('common.ok')}
              </Button>
            )}
            body={(
              <>
                {digitsArr.map(digit => (
                  <div className="room-password-digit">
                    {digit}
                  </div>
                ))}
              </>
            )}
          />

          <CustomModal
            isOpen={errorModalOpen}
            toggle={this.closeErrorModal}
            verticalCentering
            largeText
            title={errorModalHeader}
            body={(
              <div className='text-center'>
                {errorModalText}
              </div>
            )}
            footer={(
              <>
                {errorModalBuyMoney ? (
                  <Fragment>
                    <Button color="link" onClick={this.buyMoney}>
                      {t('myInfo.buyMoney')}
                    </Button>
                    <Button color="link" onClick={this.closeErrorModal}>
                      {t('common.close')}
                    </Button>
                  </Fragment>
                ) : errorModalIgnored ? (
                  <Fragment>
                    <Button color="link" onClick={this.joinUnBlockFunc} >
                      {t('ignoredUsers.unBlock')}
                    </Button>
                    <Button color="link" onClick={this.closeErrorModal}>
                      {t('common.close')}
                    </Button>
                  </Fragment>
                ) : (
                  <Button color="link" onClick={this.closeErrorModal}>
                    {t('common.ok')}
                  </Button>
                )}
              </>
            )}
          />

        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  console.log('mapStateToProps state', state)
  return ({
    userSettings: state.userSettings[state.member.uid] || state.userSettings.default || {},
    lvl: state.member.level,
    filters: state.userSettings[state.member.uid]?.roomsFilter || {},
    rooms: state.rooms.rooms || {},
    uid: state.member.uid || '',
    adsHidden: state.member.adsHidden || null,
    loggedInUserDataFetched: state.member.loggedInUserDataFetched || null,
    activeTournament: state.member.activeTournament || null,
    finishedTournamentLastRound: state.member.finishedTournamentLastRound || null,
    activeRoom: state.member.activeRoom || null,
    joinedRooms: state.member.joinedRooms || null,
    joinedRoomPassword: state.member.joinedRoomPassword || state.rooms.joinedRoomPassword || null,
    joinRoomError: state.member.joinRoomError || null,
    tournaments: (state.tournaments && state.tournaments.tournaments) ? state.tournaments.tournaments : {},
    myTournamentsData: (state.tournaments && state.tournaments.myTournamentsData) ? state.tournaments.myTournamentsData : {},
    offset: state.member.offset || 0,
    friends: state.member.friends || {},
    fbFriends: state.member.fbFriends || {},
    socProvider: state.member.socProvider || null,
  });
};

const mapDispatchToProps = {
  joinRoomFunc: joinRoom,
  leaveRoom: leaveRoomMenu,
  changeFilter: changeRoomsFilter,
  resetError: resetRoomError,
  resetFilterRooms: resetFilterRoomsExit,
  unBlockUserFunc: unBlockUser,
  fetchFriends: getFriends,
  clickACampaign: clickCampaign,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['common', 'notifications'])(RoomsTable));

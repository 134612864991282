import React, { useState } from 'react';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import { Button, Col, Media, Row } from 'reactstrap';
import tutorialImage from '../../../../../images/icons/help2.svg';
import lightInfoImg from '../../../../../images/redesign/light-mode/player/info.svg';
import TournamentTutorial from './Components/TournamentTutorial';
import { MENU_NAVIGATION, SCREEN_MODE } from '../../../../../constants/constants';

const TournamentsHeader = ({activeTab, changeTab, screenMode}) => {
    const { t } = useTranslation('common');
    const [showTournamentTutorial, setShowTournamentTutorial] = useState(false);

    const toggleShowTournamentTutorial = () => {    
        if (!showTournamentTutorial) {
          ReactGA.event({
            category: 'Menu',
            action: 'Tournaments Help',
          });
        }
        setShowTournamentTutorial(!showTournamentTutorial);
    }

    return (
        <div className="layout-body-top">
            <Row className="layout-subheader">
            <Col xs="10">
                <div className="layout-subheader-link">
                <Button color="link" className={`layout-subheader-link-text active-tournaments-button ${activeTab === MENU_NAVIGATION.tournaments && 'layout-subheader-link-text-active'}`} onClick={() => changeTab(MENU_NAVIGATION.tournaments)}>
                    {t('tournaments.activeTournaments')}
                </Button>
                </div>
                <div className="layout-subheader-link">
                <Button color="link" className={`layout-subheader-link-text layout-subheader-link-text future-tournaments-button ${activeTab === MENU_NAVIGATION.futureTournaments && 'layout-subheader-link-text-active'}`} onClick={() => changeTab(MENU_NAVIGATION.futureTournaments)}>
                    {t('tournaments.futureTournaments')}
                </Button>
                </div>
                <div className="layout-subheader-link">
                <Button color="link" className={`layout-subheader-link-text past-tournaments-button ${activeTab === MENU_NAVIGATION.tournamentsHistory && 'layout-subheader-link-text-active'}`} onClick={() => changeTab(MENU_NAVIGATION.tournamentsHistory)}>
                    {t('tournaments.tournamentHistory')}
                </Button>
                </div>
            </Col>
            <Col xs="2">
                <Media className="tournament-header-tutorial" src={screenMode === SCREEN_MODE.light ? lightInfoImg : tutorialImage} onClick={() => toggleShowTournamentTutorial()} />
            </Col>
            </Row>
            <TournamentTutorial showTournamentTutorial={showTournamentTutorial} toggleShowTournamentTutorial={toggleShowTournamentTutorial} />
        </div>
    );
};

export default TournamentsHeader;
import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';
// import ReactTableContainer from 'react-table-container';
import { Helmet } from 'react-helmet';
import Spinner from 'reactstrap/lib/Spinner';

import { filter } from 'lodash';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';
import TabPane from 'reactstrap/lib/TabPane';
import TabContent from 'reactstrap/lib/TabContent';
import Dropdown from 'reactstrap/lib/Dropdown';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import Popover from 'reactstrap/lib/Popover';
import PopoverBody from 'reactstrap/lib/PopoverBody';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Alert from 'reactstrap/lib/Alert';

// import ScrollArea from 'react-scrollbar';
// import ScrollAreaButtons from '../UI/ScrollAreaButtons';

// import ScrollAreaWrapper from '../UI/ScrollAreaWrapper';

import TopPageList from './TopPageList';
import TopPageMillionaires from './TopPageMillionaires';

import leaderboardImg from '../../../images/icons/leaderboard.svg';
import coinImg from '../../../images/coin.svg';
import closeImg from '../../../images/icons/close.png';

import buttonClickedSound from '../../../sounds/click_feedback.flac';
import * as constants from '../../../constants/constants';

import {
  getLeaderboardFiltered,
  //  getLeaderboardMillionaires,
  getLeaderboardSearch,
} from '../../../actions/leaderboard';

import {
  blockUser,
  unBlockUser,
  getIgnoredPlayers,
  getFriends,
  addFriend,
  removeFriend,
} from '../../../actions/member';
import {
  getBannedUsers,
} from '../../../actions/users';

import {
  FONT_MODE,
} from '../../../constants/constants';

const FilterMap = {
  5: 'Visu laiku',
  1: 'Šodien',
  2: 'Šonedēļ',
  3: 'Šomēnes',
  4: 'Šogad',
};

const FilterMapRu = {
  5: 'Все время',
  1: 'Сегодня',
  2: 'На этой неделе',
  3: 'Этот месяц',
  4: 'Этот год',
};

const FilterMapEn = {
  5: 'All Time',
  1: 'Today',
  2: 'This Week',
  3: 'This Month',
  4: 'This Year',
};

const TypeMap = {
  1: 'Punkti',
  2: 'Bilance',
  3: 'Izspēlētās spēles',
  4: 'Uzvarētās lielās zoles',
  5: 'Uzvarētās mazās zoles',
  6: 'Uzvarētie galdi',
  7: 'Sasniegumu skaits',
  8: 'Raundi vienā spēlē',
};

const TypeMapRu = {
  1: 'Очки',
  2: 'Баланс',
  3: 'Количество сыгранных партий',
  4: 'Победы в Золе',
  5: 'Победы в маленькой Золе',
  6: 'Победы в столике',
  7: 'Количество достижений',
  8: 'Макс. партий в одной комнате',
};

const TypeMapEn = {
  1: 'Points',
  2: 'Balance',
  3: 'Games played',
  4: 'Large zole won',
  5: 'Small zole won',
  6: 'Tables won',
  7: 'Amount of achievements',
  8: 'Max Rounds In one room',
};

class TopPage extends React.Component {
  static propTypes = {
    leaderboardData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    t: PropTypes.func.isRequired,
    fetchLeaderboardFiltered: PropTypes.func.isRequired,
    //  fetchLeaderboardMillionaires: PropTypes.func.isRequired,
    fetchLeaderboardSearch: PropTypes.func.isRequired,
    blockUserFunc: PropTypes.func.isRequired,
    unBlockUserFunc: PropTypes.func.isRequired,
    fetchIgnoredPlayers: PropTypes.func.isRequired,
    fetchBannedUsers: PropTypes.func.isRequired,
    bannedUsers: PropTypes.shape({}),
    notificationSettings: PropTypes.shape({
      ignorePlayer: PropTypes.bool,
    }),
  //  userSettings: PropTypes.shape({
  //    soundOn: PropTypes.bool,
   // }),
    uid: PropTypes.string,
    ignoredUsers: PropTypes.shape({}),
    i18n: PropTypes.shape(),
    name: PropTypes.string,
    fontMode: PropTypes.string,
    fetchFriends: PropTypes.func.isRequired,
    friends: PropTypes.shape({}),
    addAFriend: PropTypes.func.isRequired,
    removeAFriend: PropTypes.func.isRequired,
  }

  static defaultProps = {
    notificationSettings: {},
   // userSettings: {},
    ignoredUsers: {},
    bannedUsers: {},
    i18n: null,
    uid: null,
    name: null,
    fontMode: FONT_MODE.normal,
    friends: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      activeTab: '5',
      activeType: '1',
      sortingOptionTempId: null,
      extraColumnTempId: null,
      extraColumnTempValue: null,
      filterOpen: false,
      typeFilterOpen: false,
      scrollPos: 0,
      millionairesOpen: false,
      showOnlyActive: false,
      searchValue: '',
      isFiltered: false,
      alertType: '',
      alertStatus: false,
      message: '',
      showFields: {
        showFieldArray: [],
      },
      showNoOlderMeOpen: false,
      popoverOpen: false,
      leaderboardTypeModalOpen: false,
    };

    this.toggleFilter = this.toggleFilter.bind(this);

    this.buttonClickedAudio = new Audio(buttonClickedSound);
  }

  componentDidMount() {
    const { fetchFriends, fetchLeaderboardFiltered, fetchIgnoredPlayers, fetchBannedUsers } = this.props;
    const { millionairesOpen, showOnlyActive, showNoOlderMeOpen } = this.state;
    fetchLeaderboardFiltered('totalPnts', 'allTime', showOnlyActive, millionairesOpen, showNoOlderMeOpen);
    fetchIgnoredPlayers();
    fetchBannedUsers();
    fetchFriends();
  }

  // componentWillReceiveProps(nextProps) {
  //   const { friends, fetchFriends } = this.props;
  //   console.log(friends, nextProps.friends, "checkDiffFriends")
  //   if (size(friends) !== size(nextProps.friends)) {
  //     fetchFriends();
  //   }
  // }

  // componentWillReceiveProps(nexProps) {
  //   const { leaderboardData } = this.props;
  //   const nextLeaderboardData = nexProps.leaderboardData;
  //   const {
  //     leaderboard,
  //     leaderboardSearch,
  //     leaderboardMillionaires,
  //     leaderboardMillionairesSearch,
  //     myLeaderboard,
  //     myLeaderboardSearch
  //   } = leaderboardData;

  //   const {
  //     nextLeaderboard,
  //     nextLeaderboardSearch,
  //     nextLeaderboardMillionaires,
  //     nextLeaderboardMillionairesSearch,
  //     nextMyLeaderboard,
  //     nextMyLeaderboardSearch
  //   } = nextLeaderboardData;

  //   if (myLeaderboard !== nextMyLeaderboard || myLeaderboardSearch !== nextMyLeaderboardSearch || leaderboard !== nextLeaderboard || leaderboardSearch !== nextLeaderboardSearch || leaderboardMillionaires !== nextLeaderboardMillionaires || leaderboardMillionairesSearch !== nextLeaderboardMillionairesSearch) {
  //     this.fetchLeaderboardSearch();
  //   }
  // }

  // componentWillUnmount() {
  //   this.fetchLeaderboardSearch();
  // }

  toggle = (key) => {
    const {
      fetchLeaderboardFiltered, fetchLeaderboardSearch,
    } = this.props;

    if (key) {
      const { searchValue, activeTab, activeType, showOnlyActive, millionairesOpen, showNoOlderMeOpen } = this.state;
    //  console.log(key, "check key");
      let filterType = '';
      filterType = constants.filterLeaderboardType.filter(itemKey => itemKey.id === activeType.toString())[0].label;
      let dateType = '';
      dateType = constants.filterLeaderboardActive.filter(itemKey => itemKey.id === key.toString())[0].label;

      if (!searchValue) {
        if (activeTab.toString() !== key.toString()) {
          this.playButtonSound();
          fetchLeaderboardFiltered(filterType, dateType, showOnlyActive, millionairesOpen, showNoOlderMeOpen);
        }
      } else {
        fetchLeaderboardSearch(searchValue, filterType, dateType, showOnlyActive, millionairesOpen, showNoOlderMeOpen);
      }
      this.setState({
        activeTab: key,
        isFiltered: false,
      });
      // }
    }
  }

  toggleType = (key) => {
    const {
      fetchLeaderboardFiltered, fetchLeaderboardSearch,
    } = this.props;

    if (key) {
      const { searchValue, activeType, activeTab, showOnlyActive, millionairesOpen, showFields, showNoOlderMeOpen } = this.state;
    //  console.log(key, "check key");

      let dateType = '';
      dateType = constants.filterLeaderboardActive.filter(itemKey => itemKey.id === activeTab.toString())[0].label;
      let keyType = '';
      keyType = constants.filterLeaderboardType.filter(itemKey => itemKey.id === key.toString())[0].label;

      if (constants.SHOW_PLACE_FILEDS.indexOf(keyType) !== -1) {
        const { showFieldArray } = showFields;
        const showFieldArrayTemp = [...showFieldArray];
        const index = showFieldArrayTemp.indexOf(keyType);

        if (index === -1) {
          if (showFieldArrayTemp.length > 0) {
            showFieldArrayTemp.pop();
          }
          showFieldArrayTemp.push(keyType);
        } else {
          showFieldArrayTemp.splice(index, 1);
        }
        this.setState(prevState => ({
          showFields: {
            ...prevState.showFields,
            showFieldArray: showFieldArrayTemp,
          },
        }));
        return;
      }

      if (!searchValue) {
        if (activeType.toString() !== key.toString()) {
          this.playButtonSound();
          fetchLeaderboardFiltered(keyType, dateType, showOnlyActive, millionairesOpen, showNoOlderMeOpen);
        }
      } else {
        fetchLeaderboardSearch(searchValue, keyType, dateType, showOnlyActive, millionairesOpen, showNoOlderMeOpen);
      }
      this.setState({
        activeType: key,
      });
    }
  }

  setSortingOptionTemp = (item) => {
    this.setState({sortingOptionTempId: item.id});
  }
  setExtraColumnTemp = (item) => {
    const { extraColumnTempId } = this.state;
    this.setState({
      extraColumnTempId: extraColumnTempId !== item.id ? item.id : null,
      extraColumnTempValue: extraColumnTempId !== item.id ? item.value : null,
    });
  }

  updateScrollPos = (val) => {
    if (val.topPosition || val.topPosition === 0) {
      this.setState({ scrollPos: val.topPosition || 0 });
    }
  }

  scroll = (newVal) => {
    this.tableScrollbar.scrollYTo(newVal);
  }

  playButtonSound = () => {
    const { soundOn, uid } = this.props;

      if (soundOn) {
        const playPromise = this.buttonClickedAudio.play();
        if (playPromise !== undefined) {
          playPromise
            .then(_ => {
              // Automatic playback started!
              // Show playing UI.
              console.log("audio played auto");
            })
            .catch(error => {
              // Auto-play was prevented
              // Show paused UI.
              console.log("playback prevented");
            });
        }
      }
  }

  toggleFilter = () => {
    this.setState(prevState => ({
      filterOpen: !prevState.filterOpen,
      typeFilterOpen: false,
    }));
  }

  toggleTypeFilter = () => {
    this.setState(prevState => ({
      filterOpen: false,
      typeFilterOpen: !prevState.typeFilterOpen,
    }));
  }

  toggleLeaderboardTypeModalOpen = () => {
    const { leaderboardTypeModalOpen, showFields, activeType, sortingOptionTempId, extraColumnTempId, extraColumnTempValue } = this.state;
    if (!leaderboardTypeModalOpen) {
      this.setState({
        sortingOptionTempId: activeType,
        extraColumnTempId: showFields.showFieldArray[0] ? constants.filterLeaderboardExtraColumns.find(item => item.value === showFields.showFieldArray[0]).id : null,
        extraColumnTempValue: showFields.showFieldArray[0] || null,
        leaderboardTypeModalOpen: true
      });
    } else {
      if (activeType !== sortingOptionTempId) {
        this.toggleType(sortingOptionTempId);
      }
      if (showFields.showFieldArray[0] !== extraColumnTempValue) {
        if (extraColumnTempValue) {
          this.toggleType(extraColumnTempId);
        } else if (showFields.showFieldArray[0]) {
          this.toggleType(constants.filterLeaderboardExtraColumns.find(item => item.value === showFields.showFieldArray[0]).id);
        }
      }

      this.setState({
        sortingOptionTempId: null,
        extraColumnTempId: null,
        extraColumnTempValue: null,
        leaderboardTypeModalOpen: false
      });
    }
  }

  toggleMillionaires = () => {
    const { fetchLeaderboardSearch, fetchLeaderboardFiltered } = this.props;
    const { millionairesOpen, showOnlyActive, searchValue, activeTab, activeType, showNoOlderMeOpen } = this.state;

    let filterType = '';
    filterType = constants.filterLeaderboardType.filter(itemKey => itemKey.id === activeType.toString())[0].label;
    let dateType = '';
    dateType = constants.filterLeaderboardActive.filter(itemKey => itemKey.id === activeTab.toString())[0].label;

    this.setState(prevState => ({
      millionairesOpen: !prevState.millionairesOpen,
    }));

    if (searchValue) {
      fetchLeaderboardSearch(searchValue, filterType, dateType, showOnlyActive, !millionairesOpen, showNoOlderMeOpen);
    } else {
      fetchLeaderboardFiltered(filterType, dateType, showOnlyActive, !millionairesOpen, showNoOlderMeOpen);
    }
  }

  toggleShowNoOlderMeOpen = () => {
    const { fetchLeaderboardSearch, fetchLeaderboardFiltered } = this.props;
    const { showNoOlderMeOpen, millionairesOpen, showOnlyActive, searchValue, activeTab, activeType, } = this.state;

    let filterType = '';
    filterType = constants.filterLeaderboardType.filter(itemKey => itemKey.id === activeType.toString())[0].label;
    let dateType = '';
    dateType = constants.filterLeaderboardActive.filter(itemKey => itemKey.id === activeTab.toString())[0].label;

    this.setState(prevState => ({
      showNoOlderMeOpen: !prevState.showNoOlderMeOpen,
    }));

    if (searchValue) {
      fetchLeaderboardSearch(searchValue, filterType, dateType, showOnlyActive, millionairesOpen, !showNoOlderMeOpen);
    } else {
      fetchLeaderboardFiltered(filterType, dateType, showOnlyActive, millionairesOpen, !showNoOlderMeOpen);
    }
  }

  toggleShowActive = () => {
    const { fetchLeaderboardSearch, fetchLeaderboardFiltered } = this.props;
    const { millionairesOpen, showOnlyActive, searchValue, activeTab, activeType, showNoOlderMeOpen } = this.state;

    let filterType = '';
    filterType = constants.filterLeaderboardType.filter(itemKey => itemKey.id === activeType.toString())[0].label;

    let dateType = '';
    dateType = constants.filterLeaderboardActive.filter(itemKey => itemKey.id === activeTab.toString())[0].label;

    this.setState(prevState => ({
      showOnlyActive: !prevState.showOnlyActive,
    }));

    if (searchValue) {
      fetchLeaderboardSearch(searchValue, filterType, dateType, !showOnlyActive, millionairesOpen, showNoOlderMeOpen);
    } else {
      fetchLeaderboardFiltered(filterType, dateType, !showOnlyActive, millionairesOpen, showNoOlderMeOpen);
    }
  }

  handleChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    this.setState({
      [event.target.name]: value,
    });
  }

  handleKeyPress = (target) => {
    if (target.charCode === constants.ENTERKEY) {
      this.fetchLeaderboardSearch();
    }
  }

  fetchLeaderboardSearch = () => {
    const { fetchFriends, fetchLeaderboardSearch, fetchLeaderboardFiltered, t } = this.props;
    const { searchValue, activeTab, activeType, showOnlyActive, millionairesOpen, showNoOlderMeOpen } = this.state;

    let dateType = '';
    dateType = constants.filterLeaderboardActive.filter(itemKey => itemKey.id === activeTab.toString())[0].label;
    let keyType = '';
    keyType = constants.filterLeaderboardType.filter(itemKey => itemKey.id === activeType.toString())[0].label;

    this.setState({
      popoverOpen: false,
    });
    fetchFriends();
    if ((searchValue.length === 0) || (searchValue.length !== 0 && searchValue.length < 4)) {
      this.setState({
        alertStatus: true,
        alertType: constants.FROUNT_SIDE_STATUS.danger,
        message: t("top.wrongSearch"),
        isFiltered: false,
      });
      setTimeout(() => {
        this.setState({
          alertStatus: false,
          alertType: '',
          message: '',
        });
      }, 10000);
      fetchLeaderboardFiltered(keyType, dateType, showOnlyActive, millionairesOpen, showNoOlderMeOpen);
      return;
    }

    if (searchValue) {
      fetchLeaderboardSearch(searchValue, keyType, dateType, showOnlyActive, millionairesOpen, showNoOlderMeOpen);
      this.setState({
        isFiltered: true,
      });
    } else {
      this.playButtonSound();

      fetchLeaderboardFiltered(keyType, dateType, showOnlyActive, millionairesOpen, showNoOlderMeOpen);
      this.setState({
        isFiltered: false,
      });
    }
  }

  onHover = () => {
    this.setState({ popoverOpen: true });
  }

  onHoverLeave = () => {
    this.setState({ popoverOpen: false });
  }

  onHoverActivePlayer = () => {
    this.setState({ popoverOpen4ActivePlayer: true });
  }

  onHoverLeaveActivePlayer = () => {
    this.setState({ popoverOpen4ActivePlayer: false });
  }

  block = (id, name) => {
    const { blockUserFunc, notificationSettings } = this.props;
    this.playButtonSound();
    //  blockUserFunc(id, name).then((res) => {
    //  });

    if (notificationSettings && notificationSettings.ignorePlayer === false) {
      blockUserFunc(id, name);
    } else {
      this.setState(prevState => ({
        blockUserModal: !prevState.blockUserModal,
        blockUserSelectedUid: id,
        blockUserSelectedName: name,
      }));
    }
  }

  blockUserConfirm = () => {
    const { blockUserFunc } = this.props;
    const { blockUserSelectedUid, blockUserSelectedName } = this.state;

    if (blockUserSelectedUid && blockUserSelectedName) {
      blockUserFunc(blockUserSelectedUid, blockUserSelectedName);

      this.playButtonSound();

      this.setState({
        blockUserModal: false,
        blockUserSelectedUid: null,
        blockUserSelectedName: null,
      });
    }
  };

  closeBlockModal = () => {
    this.setState({
      blockUserModal: false,
      blockUserSelectedUid: null,
      blockUserSelectedName: null,
    });
  }

  unblock = (id) => {
    const { unBlockUserFunc } = this.props;

    this.playButtonSound();
    unBlockUserFunc(id).then(() => {
    //  if (res && res.status === 'success') {
    //    fetchIgnoredPlayers();
    //  }
    });
  }

  handleSendAddFriend = (selectId) => {
    const { addAFriend, fetchFriends } = this.props;
    addAFriend(selectId);
  }

  handleRemoveFriend = (selectId) => {
    const { removeAFriend } = this.props;
    removeAFriend(selectId);
  }

  render() {
    const {
      leaderboardData,
      ignoredUsers,
      t,
      i18n,
      bannedUsers,
      name,
      fontMode,
      friends,
    } = this.props;

    const {
      activeTab,
      filterOpen,
      typeFilterOpen,
      activeType,
      scrollPos,
      millionairesOpen,
      searchValue,
      isFiltered,
      popoverOpen,
      popoverOpen4ActivePlayer,
      blockUserModal,
      blockUserSelectedName,
      showOnlyActive,
      alertStatus,
      alertType,
      message,
      showFields,
      showNoOlderMeOpen,
      leaderboardTypeModalOpen,
      sortingOptionTempId,
      extraColumnTempId,
    } = this.state;

    const {
      leaderboard,
      leaderboardMillionaires,
      myLeaderboard,
      // leaderboardYear,
      // leaderboardMonth,
      // leaderboardWeek,
      // leaderboardDaily,
      // myLeaderboardYear,
      // myLeaderboardMonth,
      // myLeaderboardWeek,
      // myLeaderboardDaily,

      leaderboardSearch,
      leaderboardMillionairesSearch,
      myLeaderboardSearch,
      // leaderboardYearSearch,
      // leaderboardMonthSearch,
      // leaderboardWeekSearch,
      // leaderboardDailySearch,
      // myLeaderboardYearSearch,
      // myLeaderboardMonthSearch,
      // myLeaderboardWeekSearch,
      // myLeaderboardDailySearch,
      leaderboardLoading,
    } = leaderboardData;

    console.log('leaderboardData', leaderboardData);

    return (
      <div className="top" style={{display: 'flex', flexDirection: 'column'}}>
        <Helmet>
          <title>
            Zole - {t('top.top')}
          </title>
        </Helmet>
        <Row className="top-tabs">
          <Col xs="12">
            <Row className="top-tabs-header">
              <Col xs="4">
                <Media src={leaderboardImg} className="top-tabs-header-image" />
                <div className="top-tabs-header-text">
                  {t('top.top')}
                </div>
              </Col>
              <Col xs="8" className="top-list-filter-options">
                <Row>
                  <Col xs="6">
                    <div className="top-select dropdown">
                      <button type="button" class="dropdown-toggle btn btn-secondary" onClick={this.toggleLeaderboardTypeModalOpen}>
                        {t(`top.${filter(constants.filterLeaderboardSortingType, ['id', activeType.toString()])[0].tLabel}`)}
                      </button>
                    </div>

                    <Modal container={'div > div'} isOpen={leaderboardTypeModalOpen} toggle={this.toggleLeaderboardTypeModalOpen} className={`old-modal root-font-${fontMode}`} >
                      <ModalBody className="notification-body">
                      <div className="top-leaderboard-modal">
                        <div className="top-leaderboard-modal-section">
                          <div className="top-leaderboard-modal-section-title">
                            {t('top.sortByTitle')}
                          </div>
                          {constants.filterLeaderboardSortingType.map(item => (
                            <div className="top-leaderboard-modal-section-sorting-wrapper" onClick={() => this.setSortingOptionTemp(item)}>
                              <input type="radio" checked={sortingOptionTempId === item.id} />
                              <label className="top-leaderboard-modal-section-sorting-label" key={item.name}>
                                {t(`top.${item.name}`)}
                              </label>
                            </div>
                          ))}

                          <div className="top-leaderboard-modal-section-description">
                            <div className="top-leaderboard-modal-section-description-first-msg">
                              {t('top.sortByDescription1')}
                            </div>
                            {t('top.sortByDescription2')}
                          </div>
                        </div>

                        <div className="top-leaderboard-modal-section">
                          <div className="top-leaderboard-modal-section-title">
                            {t('top.extraColumnTitle')}
                          </div>
                          {constants.filterLeaderboardExtraColumns.map(item => (
                            <div key={item.name} className="top-leaderboard-modal-section-toggle-wrapper" onClick={() => /*this.toggleType(item.id)*/this.setExtraColumnTemp(item)}>
                              <input type="radio" checked={extraColumnTempId === item.id/*showFields.showFieldArray.includes(item.label)*/} />
                              <label className="top-leaderboard-modal-section-toggle-label">
                                {t(`top.${item.tLabel}`)}
                              </label>
                            </div>
                          ))}
                        </div>

                      </div>
                      </ModalBody>
                      <ModalFooter className="notification-footer">
                        <Button type="button" className="btn notification-footer-button" onClick={this.toggleLeaderboardTypeModalOpen}>
                          {t('common.ok')}
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </Col>
                  <Col xs="6">
                    <Dropdown className="top-select" isOpen={filterOpen} toggle={this.toggleFilter}>
                      <DropdownToggle caret>
                        {i18n && i18n.language === 'lv' && (
                          FilterMap[activeTab]
                        )}
                        {i18n && i18n.language === 'ru' && (
                          FilterMapRu[activeTab]
                        )}
                        {i18n && i18n.language === 'en' && (
                          FilterMapEn[activeTab]
                        )}
                      </DropdownToggle>
                      <DropdownMenu>
                        {Object.keys(FilterMap).map(key => (
                          <DropdownItem key={key} onClick={() => { this.toggle(key); }}>
                            {i18n && i18n.language === 'lv' && (
                              FilterMap[key]
                            )}
                            {i18n && i18n.language === 'ru' && (
                              FilterMapRu[key]
                            )}
                            {i18n && i18n.language === 'en' && (
                              FilterMapEn[key]
                            )}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="top-search">
              <Col xs="4" className="top-search-check-options" style={{ fontSize: 12 }}>
                <Label check
                  onMouseEnter={this.onHoverActivePlayer}
                  onMouseLeave={this.onHoverLeaveActivePlayer}
                  style={{marginTop: 2, marginLeft: 20}}>
                  <Input
                    type="checkbox"
                    id="top-checkbox-popover"
                    name="showOnlyActive"
                    onChange={this.toggleShowActive}
                    checked={showOnlyActive}
                    onMouseEnter={this.onHoverActivePlayer}
                    onMouseLeave={this.onHoverLeaveActivePlayer}
                  />
                 {t('top.showOnlyActivePlayers')}
                </Label>
                <Popover
                  container={'div > div'}
                  placement="bottom"
                  isOpen={popoverOpen4ActivePlayer}
                  target="top-checkbox-popover"
                  popperClassName={`popover-font-${fontMode}`}
                >
                  <PopoverBody>
                    <div>
                      {t('top.showActivePlayerRule') }
                    </div>
                  </PopoverBody>
                </Popover>
                <Label check
                  style={{marginTop: 2, marginLeft: 20}}>
                  <Input
                    type="checkbox"
                    id="millionairesOpen"
                    name="millionairesOpen"
                    onChange={this.toggleMillionaires}
                    checked={millionairesOpen}
                  />
                 {t('top.showActiveMillionaires')}
                </Label>
                <Label check
                  style={{marginTop: 2, marginLeft: 20}}>
                  <Input
                    type="checkbox"
                    id="showNoOlderMeOpen"
                    name="showNoOlderMeOpen"
                    onChange={this.toggleShowNoOlderMeOpen}
                    checked={showNoOlderMeOpen}
                  />
                 {t('top.showNoOlderMe')}
                </Label>
              </Col>
              <Col xs="8" className="top-search-part">
                <Row>
                  <Col xs="8" className="top-search-input">
                    <Input
                      type="text"
                      name="searchValue"
                      id="searchValue"
                      value={searchValue}
                      onChange={this.handleChange}
                      onKeyPress={this.handleKeyPress}
                    />
                    {
                      alertType && alertStatus && message && (
                        <Alert sm="12" className="new-design-alert" color={alertType} isOpen={alertStatus}>
                          {message}
                        </Alert>
                      )
                    }
                  </Col>
                  <Col xs="4" className="top-search-button">
                    <Button
                      color="link"
                      className="menu-topTab-wrapper"
                      id="top-search-popover"
                      onClick={this.fetchLeaderboardSearch}
                      onMouseEnter={this.onHover}
                      onMouseLeave={this.onHoverLeave}
                    >
                      {t('top.search')}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="top-list-part">
          <Col xs="12" className="h-100">
            {millionairesOpen ? (
                <TopPageMillionaires
                  leaderboardLoading={leaderboardLoading}
                  showFields={showFields.showFieldArray}
                  t={t}
                  activeType={activeType}
                  block={this.block}
                  unblock={this.unblock}
                  isFiltered={isFiltered}
                  leaderboardMillionaires={isFiltered ? leaderboardMillionairesSearch : leaderboardMillionaires}
                  ignoredUsers={ignoredUsers}
                  bannedUsers={bannedUsers}
                  i18n={i18n}
                  friends={friends}
                  handleSendAddFriend={this.handleSendAddFriend}
                />
            ) : (
                    leaderboardLoading ? (
                      <div className="top-table-spinner-loading-section d-flex align-items-center">
                        <Spinner color="light" style={{ width: '5rem', height: '5rem' }} />
                      </div>
                    ) : (
                      <TopPageList
                        t={t}
                        i18n={i18n}
                        isFiltered={isFiltered}
                        activeType={activeType}
                        ignoredUsers={ignoredUsers}
                        bannedUsers={bannedUsers}
                        block={this.block}
                        unblock={this.unblock}
                        leaderboard={isFiltered ? leaderboardSearch : leaderboard}
                        myLeaderboard={isFiltered ? myLeaderboardSearch : myLeaderboard}
                        showFields={showFields.showFieldArray}
                        name={name}
                        leaderboardLoading={leaderboardLoading}
                        friends={friends}
                        handleSendAddFriend={this.handleSendAddFriend}
                        handleRemoveFriend={this.handleRemoveFriend}
                      />
                    )

            )}
          </Col>
        </Row>
        <Modal container={'div > div'} isOpen={blockUserModal} toggle={() => this.closeBlockModal()} className={`notification old-modal root-font-${fontMode}`}>
          <ModalHeader
            toggle={() => this.closeBlockModal(null, null)}
            className="notification-header"
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={() => this.closeBlockModal(null, null)} />
            }
          >{t('home.confirm')}</ModalHeader>
          <ModalBody className="notification-body">
            {t('common.blockPlayerConfirm', { player: blockUserSelectedName })}
          </ModalBody>
          <ModalFooter className="notification-footer">
            <Button color="link" className="btn notification-footer-button" onClick={this.blockUserConfirm}>
              {t('common.yes')}
            </Button>
            <Button color="link" type="button" className="btn notification-footer-button" onClick={() => this.closeBlockModal(null, null)}>
              {t('common.no')}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
//  userSettings: state.userSettings[state.member.uid] || state.userSettings.default || {},
  soundOn: state.userSettings.soundOn,
  leaderboardData: state.leaderboard || {},
  uid: state.member.uid || '',
  ignoredUsers: state.member.ignoredUsers || null,
  bannedUsers: state.users.bannedUsers || {},
  name: state.member.name || '',
  friends: state.member.friends || {},
});

const mapDispatchToProps = {
  fetchLeaderboardFiltered: getLeaderboardFiltered,
  //  fetchLeaderboardMillionaires: getLeaderboardMillionaires,
  fetchLeaderboardSearch: getLeaderboardSearch,
  blockUserFunc: blockUser,
  unBlockUserFunc: unBlockUser,
  fetchIgnoredPlayers: getIgnoredPlayers,
  fetchBannedUsers: getBannedUsers,
  fetchFriends: getFriends,
  addAFriend: addFriend,
  removeAFriend: removeFriend
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(TopPage));

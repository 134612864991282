import React, { Fragment, createRef } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import Row from 'reactstrap/lib/Row';
import Media from 'reactstrap/lib/Media';
import CustomDate from '../../../Components/Components/CustomDate';
import MyInfoSubHeader from './MyInfoSubHeader';
import * as constants from '../../../../../../constants/constants';
import classNames from 'classnames';

import {
  unBlockUser,
  getIgnoredPlayers,
  getIgnoredByPlayers,
} from '../../../../../../actions/member';

import unblockImage from '../../../../../../images/redesign/common/unblock.png';
import defaultImage from '../../../../../../images/redesign/common/default_image.svg';
import coinImage from '../../../../../../images/redesign/common/coin.svg';
import ratingImage from '../../../../../../images/redesign/common/rating.svg';
import buttonClickedSound from '../../../../../../sounds/click_feedback.flac';

import { Col, Label, Spinner } from 'reactstrap';

class IgnoredUsers extends React.Component {
  static propTypes = {
    ignoredUsers: PropTypes.shape({}),
    ignoredByUsers: PropTypes.shape({}),
    achievementsNotification: PropTypes.bool,
  //  userSettings: PropTypes.shape({
  //    soundOn: PropTypes.bool,
  //  }),
    uid: PropTypes.string,
    t: PropTypes.func.isRequired,
    unBlockUserFunc: PropTypes.func.isRequired,
    fetchIgnoredPlayers: PropTypes.func.isRequired,
    fetchIgnoredByPlayers: PropTypes.func.isRequired,
    changeTab: PropTypes.func.isRequired,
  //  showUserSettingsModal: PropTypes.func.isRequired,
    handleClickStart: PropTypes.func.isRequired,
    OpenMobileDropdownModal: PropTypes.func.isRequired,
    activeTab: PropTypes.string,
    screenMode: PropTypes.string,
    fontMode: PropTypes.string,
    ignoredSwitchChecked: PropTypes.bool.isRequired,
    toggleIgnoredSwitchChecked: PropTypes.func.isRequired,
  }

  static defaultProps = {
  //  userSettings: {},
    ignoredUsers: null,
    ignoredByUsers: null,
    achievementsNotification: false,
    uid: null,
    screenMode: constants.SCREEN_MODE.normal,
    fontMode: constants.FONT_MODE.xLarge,
    activeTab: constants.MENU_NAVIGATION.ignoredUsers,
    ignoredSwitchChecked: false,
  }

  constructor(props) {
    super(props);

    this.state = {
      //  scrollPos: 0,
      ignoredUsers: null,
      ignoredByUsers: null,
    };
    this.inputRef = createRef();
    this.buttonClickedAudio = new Audio(buttonClickedSound);
  }

  componentWillMount() {
    const { fetchIgnoredPlayers, fetchIgnoredByPlayers } = this.props;

    fetchIgnoredPlayers();
    fetchIgnoredByPlayers();
  }

  componentWillReceiveProps(nextProps, nextState) {
    this.setState({
      ignoredUsers: nextProps.ignoredUsers ? nextProps.ignoredUsers : null,
      ignoredByUsers: nextProps.ignoredByUsers ? nextProps.ignoredByUsers : null,
    })
  }

  unblock = (id) => {
    const { unBlockUserFunc, fetchIgnoredPlayers } = this.props;

    this.playButtonSound();
    unBlockUserFunc(id).then((res) => {
      if (res && res.status === 'success') {
        fetchIgnoredPlayers();
      }
    });
  }

  /*  updateScrollPos = (val) => {
    this.setState({ scrollPos: val.topPosition || 0 });
  }

  scroll = (newVal) => {
    this.messagesScrollbar.scrollYTo(newVal);
  } */

  playButtonSound = () => {
    const { soundOn, uid } = this.props;

    if (soundOn) {
      this.buttonClickedAudio.play();
    }
  }

  renderRating = (t, rating) => (
    <div className="my-info-ratings-step-wrapper" style={{height: '61%'}}>
      {!rating || rating < 10 ? (<div className="my-info-ratings-step my-info-ratings-step-weak my-info-ratings-step-active">{t('myInfo.weak')}</div>) : (null)}
      {(rating >= 10 && rating < 20) ? (<div className="my-info-ratings-step my-info-ratings-step-beginner my-info-ratings-step-active">{t('myInfo.beginner')}</div>) : (null)}
      {(rating >= 20 && rating < 30) ? (<div className="my-info-ratings-step my-info-ratings-step-untrained my-info-ratings-step-active">{t('myInfo.untrained')}</div>) : (null)}
      {(rating >= 30 && rating < 40) ? (<div className="my-info-ratings-step my-info-ratings-step-medium my-info-ratings-step-active">{t('myInfo.medium')}</div>) : (null)}
      {(rating >= 40 && rating < 50) ? (<div className="my-info-ratings-step my-info-ratings-step-good my-info-ratings-step-active">{t('myInfo.good')}</div>) : (null)}
      {(rating >= 50 && rating < 60) ? (<div className="my-info-ratings-step my-info-ratings-step-great my-info-ratings-step-active">{t('myInfo.great')}</div>) : (null)}
      {(rating >= 60 && rating < 70) ? (<div className="my-info-ratings-step my-info-ratings-step-fan my-info-ratings-step-active">{t('myInfo.fan')}</div>) : (null)}
      {rating >= 70 ? (<div className="my-info-ratings-step my-info-ratings-step-pro my-info-ratings-step-active">{t('myInfo.pro')}</div>) : (null)}
    </div>
  );

  handleInputRef = () => {
    this.inputRef.current.click();
  };

  render() {
    const {
      activeTab, handleClickStart, OpenMobileDropdownModal, screenMode, changeTab, t, achievementsNotification, fontMode, ignoredSwitchChecked, toggleIgnoredSwitchChecked,
    } = this.props;

    const { ignoredUsers, ignoredByUsers } = this.state;

    const sortedKeysIgnoredUsers = ignoredUsers ? Object.keys(ignoredUsers).sort((a, b) => {
      return ignoredUsers[b].date - ignoredUsers[a].date;
    }) : [];

    const sortedKeysIgnoredByUsers = ignoredByUsers ? Object.keys(ignoredByUsers).sort((a, b) => {
      return ignoredByUsers[b].date - ignoredByUsers[a].date;
    }) : [];

    const cutoffDate = 1717597859999; // dates 05-06-2024, 17:00 and before need to be displayed as "long time ago" for ignoredByUsers

    return (
      <div className="layout-mobile-body layout-mobile-body-ignored-page">
        <Helmet>
          <title>
            Zole - {t('route.ignoredPlayers')}
          </title>
        </Helmet>
        <div className="layout-mobile-body-top">
          {/* <Row className="layout-mobile-subheader d-none">
            <MyInfoSubHeader changeTab={changeTab} activeTab={activeTab} />
          </Row> */}
        </div>
        <div className="layout-mobile-body-main">
          <div className="top-page-main-body-section">
            <div className="layout-mobile-body-main-title d-none">{t('route.ignoredPlayers')}</div>
            <div className="top-page-main-body-wrapper">
            <Row>
              <Col xs="6" className="my-info-ignored-users-switch-section">
                <Label className={(ignoredSwitchChecked) ? 'disable' : 'active'}>{t(`ignoredUsers.${constants.IGNORED_USERS_SWITCH_OPTION.iIgnore}`)}</Label>
                <Label className="new-switch-wrapper" onClick={() => this.handleInputRef}>
                  <input
                    type="checkbox"
                    name="switchOption"
                    checked={ignoredSwitchChecked}
                    ref={this.inputRef}
                    onClick={() => toggleIgnoredSwitchChecked()}
                  />
                  <span
                    className={classNames('switch', {
                      'switch-best': ignoredSwitchChecked,
                      'switch-worst': !ignoredSwitchChecked,
                    })}
                  >
                    <span className="switch-handle" />
                  </span>
                </Label>
                <Label className={(ignoredSwitchChecked) ? 'active' : 'disable'}>{t(`ignoredUsers.${constants.IGNORED_USERS_SWITCH_OPTION.ignoresMe}`)}</Label>
              </Col>
            </Row>
              {ignoredSwitchChecked ? (
                <>
                  <div className="ignored-users-mobile-header">
                    <div className="rating">
                      {t('common.ratings')}
                    </div>
                    <div className="balance">
                      {t('common.balance')}
                    </div>
                    <div className="date">
                      {t('common.date')}
                    </div>
                  </div>
                  { !ignoredByUsers ? (
                    <div className="top-table-spinner-loading-section d-flex align-items-center">
                      <Spinner color={screenMode === constants.SCREEN_MODE.light ? 'dark' : 'light'} style={{ width: '5rem', height: '5rem' }} />
                    </div>
                  ) : (
                    <>
                      {
                        ignoredByUsers && sortedKeysIgnoredByUsers.length !== 0 && (
                          <div
                            className="layout-mobile-body-main-scrollarea  top-table-custom-scroll-body w-100"
                            contentClassName="layout-body-main-scrollarea-body top-scrollarea-body"
                            show
                            rightOffset={0}
                            topOffset={30}
                            bottomOffset={0}
                          >
                            {(ignoredByUsers && sortedKeysIgnoredByUsers.length > 0) && sortedKeysIgnoredByUsers.map((key, index) => (
                              ignoredByUsers[key].name
                              && ignoredByUsers[key].date && (
                              <Fragment>
                                {ignoredByUsers[key] && (
                                  <div className="ignored-users-mobile-body">
                                    <div className="menu-table-row-player-image-frame">
                                      <Media className={`menu-table-row-player-image ${ignoredByUsers[key].photo ? '' : 'menu-table-row-player-image-default'}`} src={ignoredByUsers[key].photo || defaultImage} />
                                    </div>
                                    <div className="center-section">
                                      <div className="top-part">
                                        <div className="name">
                                          {ignoredByUsers[key].name}
                                        </div>
                                        <div className="rating">
                                          {this.renderRating(t, ignoredByUsers[key].lvl || 1600)}
                                        </div>
                                      </div>
                                      <div className="bottom-part">
                                        <div className="rating">
                                          <Media src={ratingImage} className="menu-table-row-player-icon" />
                                          {ignoredByUsers[key] ? (ignoredByUsers[key].rating || 1600) : '1600'}
                                        </div>

                                        <div className="balance">
                                          <Media src={coinImage} className="menu-table-row-player-icon" />
                                          {ignoredByUsers[key] ? (ignoredByUsers[key].bal || 1600) : '1600'}
                                        </div>

                                        <div className="date">
                                          {ignoredByUsers[key].date > cutoffDate ? (
                                            <CustomDate format="DD-MM-YYYY, hh:mm" date={ignoredByUsers[key].date} />
                                          ) : (
                                            t('common.longTimeAgo')
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Fragment>
                              )))}
                          </div>
                        )
                      }
                      {
                        ignoredByUsers && Object.keys(ignoredByUsers).length === 0 && (
                          <div className="d-flex align-items-center h-100 my-info-ignored-users-empty-entries">{t('ignoredUsers.ignoredByUsersEmptyEntries')}</div>
                        )
                      }
                    </>
                  )}
                  {/* <div className="menu-table-row-filler-out" /> */}
                </>
              ) : (
                <>
                  <div className="ignored-users-mobile-header">
                    <div className="rating">
                      {t('common.ratings')}
                    </div>
                    <div className="balance">
                      {t('common.balance')}
                    </div>
                    <div className="date">
                      {t('common.date')}
                    </div>
                  </div>
                  { !ignoredUsers ? (
                    <div className="top-table-spinner-loading-section d-flex align-items-center">
                      <Spinner color={screenMode === constants.SCREEN_MODE.light ? 'dark' : 'light'} style={{ width: '5rem', height: '5rem' }} />
                    </div>
                  ) : (
                    <>
                      {
                        ignoredUsers && sortedKeysIgnoredUsers.length !== 0 && (
                          <div
                            className="layout-mobile-body-main-scrollarea  top-table-custom-scroll-body w-100"
                            contentClassName="layout-body-main-scrollarea-body top-scrollarea-body"
                            show
                            rightOffset={0}
                            topOffset={30}
                            bottomOffset={0}
                          >
                            {(ignoredUsers && sortedKeysIgnoredUsers.length > 0) && sortedKeysIgnoredUsers.map((key, index) => (
                              ignoredUsers[key].name
                              && ignoredUsers[key].date && (
                              <Fragment>
                                {ignoredUsers[key] && (
                                  <div className="ignored-users-mobile-body">
                                    <div className="menu-table-row-player-image-frame">
                                      <Media className={`menu-table-row-player-image ${ignoredUsers[key].photo ? '' : 'menu-table-row-player-image-default'}`} src={ignoredUsers[key].photo || defaultImage} />
                                    </div>
                                    <div className="center-section">
                                      <div className="top-part">
                                        <div className="name">
                                          {ignoredUsers[key].name}
                                        </div>
                                        <div className="rating">
                                          {this.renderRating(t, ignoredUsers[key].lvl || 1600)}
                                        </div>
                                      </div>
                                      <div className="bottom-part">
                                        <div className="rating">
                                          <Media src={ratingImage} className="menu-table-row-player-icon" />
                                          {ignoredUsers[key] ? (ignoredUsers[key].rating || 1600) : '1600'}
                                        </div>

                                        <div className="balance">
                                          <Media src={coinImage} className="menu-table-row-player-icon" />
                                          {ignoredUsers[key] ? (ignoredUsers[key].bal || 1600) : '1600'}
                                        </div>

                                        <div className="date">
                                          <CustomDate format="DD-MM-YYYY, hh:mm" date={ignoredUsers[key].date} />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="unblock-section">
                                      <Media className="menu-table-row-player-block layout-float-right" src={unblockImage} onClick={() => this.unblock(key)} />
                                    </div>
                                  </div>
                                )}
                              </Fragment>
                              )))}
                          </div>
                        )
                      }
                      {
                        ignoredUsers && Object.keys(ignoredUsers).length === 0 && (
                          <div className="d-flex align-items-center h-100 my-info-ignored-users-empty-entries">{t('ignoredUsers.ignoredUsersEmptyEntries')}</div>
                        )
                      }
                    </>
                  )}
                  {/* <div className="menu-table-row-filler-out" /> */}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  soundOn: state.userSettings.soundOn,
  ignoredUsers: state.member.ignoredUsers,
  ignoredByUsers: state.member.ignoredByUsers,
});

const mapDispatchToProps = {
  unBlockUserFunc: unBlockUser,
  fetchIgnoredPlayers: getIgnoredPlayers,
  fetchIgnoredByPlayers: getIgnoredByPlayers,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(IgnoredUsers));

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import Button from 'reactstrap/lib/Button';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Pagination from 'reactstrap/lib/Pagination';
import PaginationItem from 'reactstrap/lib/PaginationItem';
import PaginationLink from 'reactstrap/lib/PaginationLink';
import Media from 'reactstrap/lib/Media';
import Form from 'reactstrap/lib/Form';
import FormGroup from 'reactstrap/lib/FormGroup';

import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import { connect } from 'react-redux';

import Moment from 'react-moment';
import moment from 'moment';
import { map, filter } from 'lodash';
import * as constants from '../../../constants/constants';
import { errorMessages } from '../../../constants/messages';

import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css';

import DateFormat from '../UI/DateFormat';

import pencilImg from '../../../images/icons/pencil.svg';
import checkImg from '../../../images/icons/check.png';
import cancelImg from '../../../images/icons/close_old.png';
import closeImg from '../../../images/icons/close.png';

import {
  getAllEvents,
  addNewEvent,
  supportMessageFileUpload,
  deleteEvent,
  editSelectEvent,
} from '../../../actions/admin';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';

moment.locale('lv');
momentLocalizer();

class EventsBanner extends React.Component {
  static propTypes = {
    loading: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
    supportAMessageFileUpload: PropTypes.func.isRequired,
    allEvents: PropTypes.shape([]),
    fetchAllEvents: PropTypes.func.isRequired,
    addANewEvent: PropTypes.func.isRequired,
    editASelectEvent: PropTypes.func.isRequired,
    deleteAEvent: PropTypes.func.isRequired,
  }

  static defaultProps = {
    allEvents: [],
  }

  constructor(props) {
    super(props);
    this.state = {
      filterType: 'ALL',
      currentPage: 0,
      pageSize: 50,
      enTitle: '',
      enDescription: '',
      preview: null,
      imageUrl: '',
      startDate: new Date(),
      endDate: new Date(),
      pageToOpen: constants.PAGE_OPEN_LINK.futureTournaments,
      showInBanner: false,
      uploadFile: null,
      ableToSave: false,
      newEventModalOpen: false,
      deleteModal: false,
      selectEventId: '',
      openModal: false,
      oldData: {},
      lvTitle: '',
      ruTitle: '',
      lvDescription: '',
      ruDescription: '',
      devMode: false,
      bannerImageType: '',
      externalLink: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.hiddenFileInput = React.createRef(null);
    this.handleEditPhoto = this.handleEditPhoto.bind(this);
    this.onCrop = this.onCrop.bind(this);
  }

  componentDidMount() {
    const { fetchAllEvents } = this.props;
    const { filterType } = this.state;

    fetchAllEvents(filterType);
  }

  handleClick = (e, index) => {
    e.preventDefault();
    this.setState({
      currentPage: index,
    });
  }

  // edit existing admin functions
  toggleDeleteModal = (key) => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
      selectEventId: key,
    }));
  }

  changePageToOpen = (e) => {
    this.setState({ pageToOpen: e.target.value });
  }

  changeBannerImageType = (e) => {
    const { bannerImageType } = this.state;
    if (e.target.value !== bannerImageType && e.target.value !== '') {
      this.setState({
        bannerImageType: e.target.value,
        imageUrl: require(`../../../images/banner/${e.target.value}.png`),
        showCrop: false,
        preview: null,
      });
    } else {
      if (e.target.value === '') {
        this.setState({
          bannerImageType: '',
          imageUrl: null,
        });
      }
    }
  }

  deleteEvent = () => {
    const { deleteAEvent, showNotification, t } = this.props;
    const { selectEventId } = this.state;

    if (selectEventId !== '') {
      deleteAEvent(selectEventId).then((res) => {
        if (res && res.status === 'success') {
          showNotification('Success', 'Success', 'success');
        }

        if (res.status === 'error') {
          return showNotification('Error', res.message, 'danger');
        }
        this.setState({
          deleteModal: false,
          selectEventId: '',
        });
      });
    }
  }

  toggle = () => {
    this.setState(prevState => ({
      openModal: !prevState.openModal,
    }));
  }

  openModal = (id) => {
    const { allEvents } = this.props;
    const selectEvent = allEvents[id];
    this.setState({
      selectEventId: allEvents[id].key,
      enTitle: selectEvent.titleEN || '',
      lvTitle: selectEvent.titleLV || '',
      ruTitle: selectEvent.titleRU || '',
      startDate: new Date(selectEvent.startDate) || null,
      endDate: new Date(selectEvent.endDate) || null,
      enDescription: selectEvent.descriptionEN || '',
      lvDescription: selectEvent.descriptionLV || '',
      ruDescription: selectEvent.descriptionRU || '',
      imageUrl: selectEvent.imageUrl || '',
      pageToOpen: selectEvent.pageToOpen || 'tournaments',
      externalLink: selectEvent.externalLink || false,
      showInBanner: selectEvent.showInBanner || false,
      devMode: selectEvent.devMode || false,
      openModal: true,
      deleteModal: false,
      newEventModalOpen: false,
      oldData: allEvents[id],
      bannerImageType: selectEvent.bannerImageType,
    });
  }

  // Add new admin functions
  openAddEventModal = () => {
    this.setState({
      startDate: new Date(),
      endDate: new Date(),
      enTitle: '',
      ruTitle: '',
      lvTitle: '',
      enDescription: '',
      lvDescription: '',
      ruDescription: '',
      imageUrl: null,
      preview: null,
      showCrop: false,
      showInBanner: false,
      devMode: false,
      ableToSave: false,
      pageToOpen: 'tournaments',
      externalLink: false,
      openModal: false,
      newEventModalOpen: true,
      oldData: {},
      bannerImageType: '',
    });
  }

  closeNewEventModal = () => {
    this.setState({ newEventModalOpen: false });
  }

  changeStartDate = (date) => {
    this.setState({ startDate: date });
  }

  changeEndDate = (date) => {
    this.setState({ endDate: date });
  }

  initStateValue = () => {
    this.setState({
      startDate: new Date(),
      endDate: new Date(),
      enTitle: '',
      ruTitle: '',
      lvTitle: '',
      enDescription: '',
      lvDescription: '',
      ruDescription: '',
      imageUrl: null,
      preview: null,
      showCrop: false,
      showInBanner: false,
      devMode: false,
      ableToSave: false,
      pageToOpen: 'tournaments',
      newEventModalOpen: false,
      oldData: {},
      bannerImageType: '',
      externalLink: false,
    });
  }

  addNewEvent = () => {
    const { addANewEvent, showNotification, t } = this.props;
    const {
      startDate,
      endDate,
      enTitle,
      lvTitle,
      ruTitle,
      enDescription,
      lvDescription,
      ruDescription,
      imageUrl,
      pageToOpen,
      showInBanner,
      showCrop,
      ableToSave,
      devMode,
      bannerImageType,
      externalLink,
    } = this.state;

    if (!showCrop && !ableToSave) {
      addANewEvent({
        startDate, endDate, enTitle, lvTitle, ruTitle, enDescription, lvDescription, ruDescription, imageUrl, pageToOpen, showInBanner, devMode, bannerImageType, externalLink,
      }).then((res) => {
        if (res && res.status === 'success') {
          showNotification('Success', "Event successfully added.", 'success');
        }

        if (res.status === 'error') {
          if (res.message === errorMessages.missingData) {
            showNotification('Error', "Missed the data.", 'danger');
          } else if (res.message === errorMessages.wrongEventDate) {
            showNotification('Error', "The End date was inputed wrong.", 'danger');
          } else if (res.message === errorMessages.missingLVTitle) {
            showNotification('Error', "Missed the title by Latvian.", 'danger');
          } else if (res.message === errorMessages.missingENTitle) {
            showNotification('Error', "Missed the title by English.", 'danger');
          } else if (res.message === errorMessages.missingRUTitle) {
            showNotification('Error', "Missed the title by Russian.", 'danger');
          } else if (res.message === errorMessages.missingLVDescription) {
            showNotification('Error', "Missed the description by Latvian.", 'danger');
          } else if (res.message === errorMessages.missingENDescription) {
            showNotification('Error', "Missed the description by English.", 'danger');
          } else if (res.message === errorMessages.missingRUDescription) {
            showNotification('Error', "Missed the description by Russian.", 'danger');
          } else if (res.message === errorMessages.missingPhoto) {
            showNotification('Error', "Please upload the event image.", 'danger');
          } else {
            showNotification('Error', res.message, 'danger');
          }
          return;
        }

        this.initStateValue();
      }).catch(error => showNotification('Error!', error.message, 'danger'));
    } else {
      showNotification('Warning', "If you don't apply or close uploaded image, you can't add new event.", 'warning');
    }
  }

  editSelectEvent = () => {
    const { editASelectEvent, showNotification, t } = this.props;
    const {
      selectEventId,
      startDate,
      endDate,
      enTitle,
      lvTitle,
      ruTitle,
      enDescription,
      lvDescription,
      ruDescription,
      imageUrl,
      pageToOpen,
      showInBanner,
      showCrop,
      ableToSave,
      oldData,
      devMode,
      bannerImageType,
      externalLink,
    } = this.state;

    if (!showCrop && !ableToSave) {
      editASelectEvent({
        selectEventId, startDate, endDate, enTitle, lvTitle, ruTitle, enDescription, lvDescription, ruDescription, imageUrl, pageToOpen, showInBanner, oldData, devMode, bannerImageType, externalLink,
      }).then((res) => {
        if (res && res.status === 'success') {
          showNotification('Success', 'Success', 'success');
        }

        if (res.status === 'error') {
          if (res.message === errorMessages.missingData) {
            showNotification('Error', "Missed the data.", 'danger');
          } else if (res.message === errorMessages.wrongEventDate) {
            showNotification('Error', "The End date was inputed wrong.", 'danger');
          } else if (res.message === errorMessages.missingLVTitle) {
            showNotification('Error', "Missed the title by Latvian.", 'danger');
          } else if (res.message === errorMessages.missingENTitle) {
            showNotification('Error', "Missed the title by English.", 'danger');
          } else if (res.message === errorMessages.missingRUTitle) {
            showNotification('Error', "Missed the title by Russian.", 'danger');
          } else if (res.message === errorMessages.missingLVDescription) {
            showNotification('Error', "Missed the description by Latvian.", 'danger');
          } else if (res.message === errorMessages.missingENDescription) {
            showNotification('Error', "Missed the description by English.", 'danger');
          } else if (res.message === errorMessages.missingRUDescription) {
            showNotification('Error', "Missed the description by Russian.", 'danger');
          } else if (res.message === errorMessages.missingPhoto) {
            showNotification('Error', "Please upload the event image.", 'danger');
          } else {
            showNotification('Error', res.message, 'danger');
          }
          return;
        }

        this.initStateValue();
        this.setState({
          openModal: false,
        });
      }).catch(error => showNotification('Error!', error.message, 'danger'));
    } else {
      showNotification('Warning', "If you don't apply or close uploaded image, you can't add new event.", 'warning');
    }
  }

  handleChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    this.setState({
      [event.target.name]: value,
    });
  }

  selectType = (e) => {
    const { fetchAllEvents } = this.props;
    this.setState({ filterType: e.target.value });

    fetchAllEvents(e.target.value);
  }

  onCrop = (preview) => {
    this.setState({ preview });
  }

  handleEditPhoto = (value) => {
    console.log(value);
    this.setState({ showCrop: !value });
  }

  uploadEventFile = () => {
    const { supportAMessageFileUpload } = this.props;
    const { uploadFile } = this.state;

    this.setState({ ableToSave: true });
    if (uploadFile !== null) {
      supportAMessageFileUpload(uploadFile).then((data) => {
        console.log('value@@@@@@@@@@@@@@@@', data);
        this.setState({
          imageUrl: data.url,
          ableToSave: false,
        });
      }).catch((e) => {
        this.setState({
          preview: null,
          ableToSave: false,
        });
      });
    }
  }

  handleImageClick = () => {
    this.hiddenFileInput.current.click();
  };

  _handleImageChange = (e) => {
    e.preventDefault();

    try {
      const { showNotification, t } = this.props;
      const typeList = 'image/jpg,image/png,image/jpeg, image/pjp, image/pjpeg, image/jfif';
      const reader = new FileReader();
      const file = e.target.files[0];

      if (!typeList.includes(file.type) || file.type === '') {
        showNotification('Error!', "Unsupported file!", 'danger');
        return e.target.value === '';
      }

      if (file.size / 1024 > 3072) {
        showNotification('Warning', "You can't upload more than 3MB", 'warning');
        return e.target.value === '';
      }

      reader.onloadend = () => {
        this.setState({
          preview: reader.result,
          uploadFile: file,
        });
      };
      reader.readAsDataURL(file);
    } catch (err) {
      const { showNotification, t } = this.props;
      showNotification('Error!', err.message, 'danger');

      return e.target.value === '';
    }
  }

  toggleShowInBanner = () => {
    this.setState(prevState => ({
      showInBanner: !prevState.showInBanner,
    }));
  }

  toggleDevMode = () => {
    this.setState(prevState => ({
      devMode: !prevState.devMode,
    }));
  }

  toggleExternalLink = () => {
    const { externalLink } = this.state;

    if (externalLink) {
      this.setState({
        pageToOpen: constants.PAGE_OPEN_LINK.futureTournaments,
      });
    } else {
      this.setState({
        pageToOpen: '',
      });
    }
    this.setState(prevState => ({
      externalLink: !prevState.externalLink,
    }));
  }

  table() {
    const {
      t,
      allEvents,
      i18n,
    } = this.props;

    const {
      currentPage,
      pageSize,
    } = this.state;
    const pagesCount = Math.ceil(Object.keys(allEvents).length / pageSize);

    return (
      <Fragment>
        {allEvents && Object.keys(allEvents)
          .slice(
            currentPage * pageSize,
            (currentPage + 1) * pageSize,
          )
          .map((key, index) => (
            <Fragment key={key}>
              <tr key={key} className={`allUsers-table-row ${index % 2 === 0 ? ('odd') : ('even')}`}>
                <td className="allUsers-table-col">
                  <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateMinute} date={allEvents[key].startDate} />
                </td>
                <td className="allUsers-table-col">
                  <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateMinute} date={allEvents[key].endDate} />
                </td>

                <td className="allUsers-table-col">
                  {allEvents[key].titleLV}
                </td>
                <td className="allUsers-table-col">
                  {allEvents[key].descriptionLV}
                </td>
                <td className="allUsers-table-col">
                  {allEvents[key].pageToOpen}
                </td>
                <td className="allUsers-table-col">
                  {allEvents[key].showInBanner ? 'Show' : 'Not Show'}
                </td>
                <td className="allUsers-table-col">
                  {allEvents[key].devMode ? 'Yes' : 'No'}
                </td>
                <td className="allUsers-table-col">
                  <Button color="primary" onClick={() => this.openModal(key)}>
                    {/* {t('edit')} */}
                    Edit
                  </Button>
                </td>
                <td className="allUsers-table-col">
                  <Button color="primary" onClick={() => this.toggleDeleteModal(allEvents[key].key)}>
                    {/* {t('delete')} */}
                    Delete
                  </Button>
                </td>
              </tr>
            </Fragment>
          ))}
        {pagesCount && Object.keys(allEvents).length > pageSize && (
          <div className="pagination-wrapper">
            <Pagination aria-label="Page navigation example">
              <PaginationItem disabled={currentPage <= 0}>
                <PaginationLink
                  style={{ color: '#000' }}
                  onClick={e => this.handleClick(e, currentPage - 1)}
                  previous
                  href="#"
                />
              </PaginationItem>

              <PaginationItem disabled={currentPage === 0}>
                <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, 0)} href="#">
                  1
                </PaginationLink>
              </PaginationItem>

              {[...Array(pagesCount)].map((page, i) => {
                if (i > currentPage - 3 && i < currentPage + 3) {
                  return (
                    <PaginationItem active={i === currentPage} key={page}>
                      <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, i)} href="#">
                        {i + 1}
                      </PaginationLink>
                    </PaginationItem>
                  );
                }

                return null;
              })}

              {currentPage < (pagesCount - 3) && (
                <PaginationItem disabled={currentPage >= pagesCount - 1}>
                  <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, pagesCount - 1)} href="#">
                    {pagesCount}
                  </PaginationLink>
                </PaginationItem>
              )}

              <PaginationItem disabled={currentPage >= pagesCount - 1}>
                <PaginationLink
                  style={{ color: '#000' }}
                  onClick={e => this.handleClick(e, currentPage + 1)}
                  next
                  href="#"
                />
              </PaginationItem>
            </Pagination>
          </div>
        )}
      </Fragment>
    );
  }

  render() {
    const {
      t,
      loading,
    } = this.props;

    const {
      newEventModalOpen,
      filterType,
      enTitle,
      enDescription,
      preview,
      imageUrl,
      startDate,
      endDate,
      showCrop,
      showInBanner,
      pageToOpen,
      deleteModal,
      openModal,
      lvTitle,
      ruTitle,
      lvDescription,
      ruDescription,
      devMode,
      bannerImageType,
      externalLink,
    } = this.state;

    console.log(imageUrl, preview, "eventImage===")
    return (
      <Fragment>
        <UncontrolledPopover popperClassName={`popover`} placement="right" trigger="focus" target="admin-help-button-events-banner">
          <PopoverBody className="popover-body">
            The administration can add an event to show a banner for the players in the right bottom corner. It has to have a title and description in all listed languages, an image, and either an external URL or an in-game target page. This is especially useful to promote tournaments or discounts in the shop. 
          </PopoverBody>
        </UncontrolledPopover>
        <div style={{ marginTop: 100, color: '#fff' }}>
          <Row>
            <Col sm="4">
              <h2>
                {/* {t('events')} */}
                Events
                <Button className="admin-help-button" id="admin-help-button-events-banner">
                  ?
                </Button>
              </h2>
            </Col>
            <Col sm="4">
              <Input type="select" className="event-type-select" value={filterType} onChange={this.selectType}>
                {/* <option value="ALL" className="background-222">{t('allEvents')}</option> */}
                <option value="ALL" className="background-222">All Events</option>
                {/* <option value="PAST" className="background-222">{t('pastEvents')}</option> */}
                <option value="PAST" className="background-222">Past Events</option>
                {/* <option value="CURRENT" className="background-222">{t('currentEvents')}</option> */}
                <option value="CURRENT" className="background-222">Current Events</option>
                {/* <option value="PLAN" className="background-222">{t('plannedEvents')}</option> */}
                <option value="PLAN" className="background-222">Planned Events</option>
              </Input>
            </Col>
            <Col sm="4">
              <Button color="primary" onClick={this.openAddEventModal}>
                {/* {t('addEvent')} */}
                Add Event
              </Button>
            </Col>
          </Row>
          <table style={{ width: '100%', fontSize: 12 }}>
            <colgroup>
              <col span="1" className="" />
            </colgroup>
            <thead>
              <tr>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('startDate')} */}
                  Start Date
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('endDate')} */}
                  End Date
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('title')} */}
                  Title
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('description')} */}
                  Description
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('openPage')} */}
                  Open Page
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('showOption')} */}
                  Show Option
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('devMode')} */}
                  Only Dev
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('edit')} */}
                  Edit
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('delete')} */}
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              {this.table()}
            </tbody>
          </table>

        </div>
        {/* Delete Event modal */}
        <Modal container={'div > div'} isOpen={deleteModal} toggle={() => this.toggleDeleteModal('')} className="notification">
          <ModalHeader
            toggle={() => this.toggleDeleteModal('')}
            className="notification-header"
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={() => this.toggleDeleteModal('')} />
            }
          />
          <ModalBody className="notification-body">
            {/* {t('deleteEventQuiz')} */}
            Do you really want to delete the event?
          </ModalBody>
          <ModalFooter className="notification-footer">
            <Button className="btn notification-footer-button" onClick={this.deleteEvent}>
              {/* {t('yes')} */}
              Yes
            </Button>
            <Button type="button" className="btn notification-footer-button" onClick={() => this.toggleDeleteModal('')}>
              {/* {t('no')} */}
              No
            </Button>
          </ModalFooter>
        </Modal>

        {/* Edit Event modal */}
        <Modal container={'div > div'} size="lg" isOpen={openModal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
            {/* {t('edit')} */}
            Edit
          </ModalHeader>
          <ModalBody>
            <Form className="form-style">
              <FormGroup>
                <Label for="startDate">
                  {/* {t('startDate')} */}
                  Start Date
                </Label>
                <Row>
                  <Col md="12">
                    <DateTimePicker
                      format={constants.FORMAT_DATE_TYPE.pointDate}
                      culture={constants.sendLanguage.lv}
                      onChange={this.changeStartDate}
                      value={startDate}
                      disabled={loading}
                      defaultValue={new Date()}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Label for="endDate">
                  {/* {t('endDate')} */}
                  End Date
                </Label>
                <Row>
                  <Col md="12">
                    <DateTimePicker
                      format={constants.FORMAT_DATE_TYPE.pointDate}
                      culture={constants.sendLanguage.lv}
                      onChange={this.changeEndDate}
                      value={endDate}
                      disabled={loading}
                      defaultValue={new Date()}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Label for="name">
                  {/* {t('lvTitle')} */}
                  Title By Latvian
                </Label>
                <Input
                  type="text"
                  name="lvTitle"
                  id="lvTitle"
                  value={lvTitle}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="name">
                  {/* {t('enTitle')} */}
                  Title By English
                </Label>
                <Input
                  type="text"
                  name="enTitle"
                  id="enTitle"
                  value={enTitle}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="name">
                  {/* {t('ruTitle')} */}
                  Title By Russian
                </Label>
                <Input
                  type="text"
                  name="ruTitle"
                  id="ruTitle"
                  value={ruTitle}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="lvDescription">
                  {/* {t('lvDescription')} */}
                  Description By Latvian
                </Label>
                <Input
                  type="textarea"
                  name="lvDescription"
                  id="lvDescription"
                  value={lvDescription}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="enDescription">
                  {/* {t('enDescription')} */}
                  Description By English
                </Label>
                <Input
                  type="textarea"
                  name="enDescription"
                  id="enDescription"
                  value={enDescription}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="ruDescription">
                  {/* {t('ruDescription')} */}
                  Description By Russian
                </Label>
                <Input
                  type="textarea"
                  name="ruDescription"
                  id="ruDescription"
                  value={ruDescription}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup style={{ position: 'relative' }}>
                <Label>
                  {/* {t('bannerImage')} */}
                  Banner Image
                </Label>
                <div className="banner-image">
                  {
                    !preview && !imageUrl ? (
                      // <Label className="banner-image-label">{t('uploadImage')}</Label>
                      <Label className="banner-image-label">Please upload the banner image for event.</Label>
                    ) : (
                      <img className="event-banner-image" src={preview || imageUrl} alt="Preview" />
                    )
                  }
                </div>
                {!showCrop && (
                  <div className="event-banner-edit" style={{position: 'absolute', top: '0px', right: '0px'}}>
                    <Media style={{ width: '24px', height: '24px' }} onClick={() => { this.handleEditPhoto(showCrop); }} src={pencilImg} className="menu-player-edit-image" disabled={loading} />
                  </div>
                )}
                {!showCrop && (
                  <div className="event-banner-cancel" style={{position: 'absolute', top: '0px', right: '30px'}}>
                    <Media style={{ width: '24px', height: '24px' }} onClick={() => { this.setState({ imageUrl: '', preview: '' }); }} src={cancelImg} className="menu-player-edit-image" disabled={loading} />
                  </div>
                )}
                {showCrop && (
                  <div className="event-banner-edit" style={{position: 'absolute', top: '0px', right: '0px'}}>
                    <Media style={{ width: '24px', height: '24px' }} onClick={() => { this.handleEditPhoto(showCrop); this.uploadEventFile(); }} src={checkImg} className="menu-player-edit-image" />
                  </div>
                )}
                {showCrop && (
                  <div className="event-banner-cancel" style={{position: 'absolute', top: '0px', right: '30px'}}>
                    <Media style={{ width: '24px', height: '24px' }} onClick={() => { this.handleEditPhoto(showCrop); this.setState({ preview: null }); }} src={cancelImg} className="menu-player-edit-image" />
                  </div>
                )}
                <Input type="select" name="bannerImageType" className="open-page-select mt-4" value={bannerImageType} disabled={loading} onChange={this.changeBannerImageType}>
                  <option value="" className="background-222">Select Event Banner Images</option>
                  {
                    map(constants.EVENT_BANNER_TYPES, (item, key) => (
                      <option value={key} className="background-222">{item}</option>
                    ))
                  }
                </Input>
                {
                  showCrop && (
                    <div>
                      <div className="event-banner-button" onClick={this.handleImageClick}>
                        {/* {t('uploadImageButton')} */}
                        Upload a Image
                      </div>
                      <input
                        type="file"
                        ref={this.hiddenFileInput}
                        onChange={this._handleImageChange}
                        style={{ display: 'none' }}
                      />
                    </div>
                  )
                }
                <Input
                  type="text"
                  name="imageUrl"
                  id="imageUrl"
                  placeholder=""
                  hidden
                  readOnly
                  value={imageUrl}
                />
              </FormGroup>
              <FormGroup>
                <Label for="role">
                  {/* {t('openPage')} */}
                  Open Page
                </Label>
                <Label className="event-type-input" style={{ marginLeft: 40 }}>
                  <Input type="checkbox" onClick={this.toggleExternalLink} disabled={loading} checked={externalLink} readOnly />
                  <span className="checkmark" />
                  <div className="event-type-text">
                    External Link
                  </div>
                </Label>
                {
                  !externalLink ? (
                    <Input type="select" name="role" className="open-page-select" value={pageToOpen} disabled={loading} onChange={this.changePageToOpen}>
                      <option className="background-222" value={constants.PAGE_OPEN_LINK.futureTournaments}>Future tournaments</option>
                      <option className="background-222" value={constants.PAGE_OPEN_LINK.tournaments}>Tournaments</option>
                      <option className="background-222" value={constants.PAGE_OPEN_LINK.top}>Top</option>
                      <option className="background-222" value={constants.PAGE_OPEN_LINK.rooms}>Rooms</option>
                      <option className="background-222" value={constants.PAGE_OPEN_LINK.shop}>Shop</option>
                    </Input>
                  ) : (
                    <Input
                      type="text"
                      name="pageToOpen"
                      id="pageToOpen"
                      value={pageToOpen}
                      disabled={loading}
                      onChange={this.handleChange}
                    />
                  )
                }
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col sm="12">
                    <Label className="event-type-input" style={{ marginLeft: 40 }}>
                      <Input type="checkbox" onClick={this.toggleShowInBanner} disabled={loading} checked={showInBanner} readOnly />
                      <span className="checkmark" />
                      <div className="event-type-text">
                        {/* {t('showInBanner')} */}
                        Would you like to show event in banner Image?
                      </div>
                    </Label>
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col sm="12">
                    <Label className="event-type-input" style={{ marginLeft: 40 }}>
                      <Input type="checkbox" onClick={this.toggleDevMode} disabled={loading} checked={devMode} readOnly />
                      <span className="checkmark" />
                      <div className="event-type-text">
                        {/* {t('devMode')} */}
                        Only Dev
                      </div>
                    </Label>
                  </Col>
                </Row>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button className="modal-footer-button" onClick={this.editSelectEvent}>Save</Button>
            <Button className="modal-footer-button" onClick={this.toggle}>Close</Button>
          </ModalFooter>
        </Modal>

        {/* Add Event modal */}
        <Modal container={'div > div'} returnFocusAfterClose={false} size="lg" isOpen={newEventModalOpen} toggle={this.closeNewEventModal}>
          <ModalHeader toggle={this.closeNewEventModal}>
            {/* {t('addEvent')} */}
            Add Event
          </ModalHeader>
          <ModalBody>
            <Form className="form-style">
              <FormGroup>
                <Label for="startDate">
                  {/* {t('startDate')} */}
                  Start Date
                </Label>
                <Row>
                  <Col md="12">
                    <DateTimePicker
                      format={constants.FORMAT_DATE_TYPE.pointDate}
                      culture={constants.sendLanguage.lv}
                      onChange={this.changeStartDate}
                      value={startDate}
                      disabled={loading}
                      defaultValue={new Date()}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Label for="endDate">
                  {/* {t('endDate')} */}
                  End Date
                </Label>
                <Row>
                  <Col md="12">
                    <DateTimePicker
                      format={constants.FORMAT_DATE_TYPE.pointDate}
                      culture={constants.sendLanguage.lv}
                      onChange={this.changeEndDate}
                      value={endDate}
                      disabled={loading}
                      defaultValue={new Date()}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Label for="name">
                  {/* {t('lvTitle')} */}
                  Title By Latvian
                </Label>
                <Input
                  type="text"
                  name="lvTitle"
                  id="lvTitle"
                  value={lvTitle}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="name">
                  {/* {t('enTitle')} */}
                  Title By English
                </Label>
                <Input
                  type="text"
                  name="enTitle"
                  id="enTitle"
                  value={enTitle}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="name">
                  {/* {t('ruTitle')} */}
                  Title By Russian
                </Label>
                <Input
                  type="text"
                  name="ruTitle"
                  id="ruTitle"
                  value={ruTitle}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="lvDescription">
                  {/* {t('lvDescription')} */}
                  Description By Latvian
                </Label>
                <Input
                  type="textarea"
                  name="lvDescription"
                  id="lvDescription"
                  value={lvDescription}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="enDescription">
                  {/* {t('enDescription')} */}
                  Description By English
                </Label>
                <Input
                  type="textarea"
                  name="enDescription"
                  id="enDescription"
                  value={enDescription}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="ruDescription">
                  {/* {t('ruDescription')} */}
                  Description By Russian
                </Label>
                <Input
                  type="textarea"
                  name="ruDescription"
                  id="ruDescription"
                  value={ruDescription}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup style={{ position: 'relative' }}>
                <Label>
                  {/* {t('bannerImage')} */}
                  Banner Image
                </Label>
                <div className="banner-image">
                  {
                    !preview && !imageUrl ? (
                      <Label className="banner-image-label">Please upload the banner image for event.</Label>
                      // <Label className="banner-image-label">{t('uploadImage')}</Label>
                    ) : (
                      <img className="event-banner-image" src={preview || imageUrl} alt="Preview" />
                    )
                  }
                </div>
                {!showCrop && (
                  <div className="event-banner-edit" style={{position: 'absolute', top: '0px', right: '0px'}}>
                    <Media style={{ width: '24px', height: '24px' }} onClick={() => { this.handleEditPhoto(showCrop); }} src={pencilImg} className="menu-player-edit-image" disabled={loading} />
                  </div>
                )}
                {!showCrop && (
                  <div className="event-banner-cancel" style={{position: 'absolute', top: '0px', right: '50px'}}>
                    <Media style={{ width: '24px', height: '24px' }} onClick={() => { this.setState({ imageUrl: '', preview: '' }); }} src={cancelImg} className="menu-player-edit-image" disabled={loading} />
                  </div>
                )}
                {showCrop && (
                  <div className="event-banner-edit" style={{position: 'absolute', top: '0px', right: '0px'}}>
                    <Media style={{ width: '24px', height: '24px' }} onClick={() => { this.handleEditPhoto(showCrop); this.uploadEventFile(); }} src={checkImg} className="menu-player-edit-image" />
                  </div>
                )}
                {showCrop && (
                  <div className="event-banner-cancel" style={{position: 'absolute', top: '0px', right: '50px'}}>
                    <Media style={{ width: '24px', height: '24px' }} onClick={() => { this.handleEditPhoto(showCrop); this.setState({ preview: null }); }} src={cancelImg} className="menu-player-edit-image" />
                  </div>
                )}
                <Input type="select" name="bannerImageType" className="open-page-select" value={bannerImageType} disabled={loading} onChange={this.changeBannerImageType}>
                  <option value="" className="background-222">Select event banner images</option>
                  {
                    map(constants.EVENT_BANNER_TYPES, (item, key) => (
                      <option value={key} className="background-222">{item}</option>
                    ))
                  }
                </Input>
                {
                  showCrop && (
                    <div>
                      <div className="event-banner-button" onClick={this.handleImageClick}>
                        {/* {t('uploadImageButton')} */}
                        Upload a Image
                      </div>
                      <input
                        type="file"
                        ref={this.hiddenFileInput}
                        onChange={this._handleImageChange}
                        style={{ display: 'none' }}
                      />
                    </div>
                  )
                }
                <Input
                  type="text"
                  name="imageUrl"
                  id="imageUrl"
                  placeholder=""
                  hidden
                  readOnly
                  value={imageUrl}
                />
              </FormGroup>
              <FormGroup>
                <Label for="role">
                  {/* {t('openPage')} */}
                  Open Page
                </Label>
                <Label className="event-type-input" style={{ marginLeft: 40 }}>
                  <Input type="checkbox" onClick={this.toggleExternalLink} disabled={loading} checked={externalLink} readOnly />
                  <span className="checkmark" />
                  <div className="event-type-text">
                    External Link
                  </div>
                </Label>
                {
                  !externalLink ? (
                    <Input type="select" name="role" className="open-page-select" value={pageToOpen} disabled={loading} onChange={this.changePageToOpen}>
                      <option className="background-222" value={constants.PAGE_OPEN_LINK.futureTournaments}>Future tournaments</option>
                      <option className="background-222" value={constants.PAGE_OPEN_LINK.tournaments}>Tournaments</option>
                      <option className="background-222" value={constants.PAGE_OPEN_LINK.top}>Top</option>
                      <option className="background-222" value={constants.PAGE_OPEN_LINK.rooms}>Rooms</option>
                      <option className="background-222" value={constants.PAGE_OPEN_LINK.shop}>Shop</option>
                    </Input>
                  ) : (
                    <Input
                      type="text"
                      name="pageToOpen"
                      id="pageToOpen"
                      value={pageToOpen}
                      disabled={loading}
                      onChange={this.handleChange}
                    />
                  )
                }
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col sm="12">
                    <Label className="event-type-input" style={{ marginLeft: 40 }}>
                      <Input type="checkbox" onClick={this.toggleShowInBanner} disabled={loading} checked={showInBanner} readOnly />
                      <span className="checkmark" />
                      <div className="event-type-text">
                        {/* {t('showInBanner')} */}
                        Would you like to show event in banner Image?
                      </div>
                    </Label>
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col sm="12">
                    <Label className="event-type-input" style={{ marginLeft: 40 }}>
                      <Input type="checkbox" onClick={this.toggleDevMode} disabled={loading} checked={devMode} readOnly />
                      <span className="checkmark" />
                      <div className="event-type-text">
                        {/* {t('devMode')} */}
                        Only Dev
                      </div>
                    </Label>
                  </Col>
                </Row>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button className="modal-footer-button" onClick={this.addNewEvent}>Save</Button>
            <Button className="modal-footer-button" onClick={this.closeNewEventModal}>Close</Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  allEvents: state.admin.allEvents || [],
});

const mapDispatchToProps = {
  fetchAllEvents: getAllEvents,
  addANewEvent: addNewEvent,
  supportAMessageFileUpload: supportMessageFileUpload,
  deleteAEvent: deleteEvent,
  editASelectEvent: editSelectEvent,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('admin')(EventsBanner));

import React, { Fragment, Suspense, lazy } from 'react';
import { Switch, Route, Router } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { createBrowserHistory } from 'history';
import { connect } from 'react-redux';

import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';

import {
  isAndroid,
  isIOS,
} from 'react-device-detect';

import LogRocket from 'logrocket';

import { ErrorBoundary } from "react-error-boundary";

// import Fullscreen from 'react-fullscreen-crossbrowser';

import { isEqual, isString, size } from 'lodash';
// Templates
import Media from 'reactstrap/lib/Media';
import TemplateNothing from '../components/Templates/Nothing';
import TemplateAdmin from '../components/Templates/Admin';
import TemplateHome from '../components/Templates/Home';
import TemplateGame from '../components/Templates/Game';
import TemplateMenu from '../components/Templates/Menu';

import TemplateHome2 from '../components/Redesign/Components/Templates/Home';


// Routes
import Home from '../components/Home';
import Home2 from '../components/Redesign/Home';

import RulesPage from '../components/RulesPage';

import UpdateProfileContainer from '../../containers/UpdateProfile';
import UpdateProfileComponent from '../components/User/UpdateProfile';

/*
import ZoleContainer from '../../containers/Game/Zole';
import ZoleComponent from '../components/Game/Zole';

import MenuContainer from '../../containers/Menu/Menu';
import MenuComponent from '../components/Menu/Menu';


import TemplateNothing2 from '../components/Templates/Design2';
import TemplateGame2 from '../components/Templates/GameDesign2';


import Menu2Component from '../components/Redesign/Menu/Menu';
import Menu2Container from '../../containers/Menu2.0/Menu';

import Zole2Component from '../components/Redesign/Game/Zole';
import Zole2Container from '../../containers/Game/Zole'; */

import TransactionSuccess from '../components/UI/TransactionSuccess';
import NewTransactionSuccess from '../components/Redesign/NewTransactionSuccess';

import InboxTransactionResult from '../components/UI/InboxTransactionResult';
import NewInboxTransactionResult from '../components/Redesign/NewInboxTransactionResult';



import Error from '../components/UI/Error';

import Auth from './Auth';

import ForgotPasswordContainer from '../../containers/ForgotPassword';
import ForgotPasswordComponent from '../components/User/ForgotPassword';

import LoginContainer from '../../containers/Login';
import LoginComponent from '../components/User/Login';
import Login2Component from '../components/Redesign/User/Login';

import SignUpContainer from '../../containers/SignUp';
import SignUpComponent from '../components/User/SignUp';

import AdminContainer from '../../containers/Admin/Admin';
import AdminPanelComponent from '../components/Admin/AdminPanel';

import DraRedirect from '../components/User/DraRedirect';
import EmailVerify from '../components/User/EmailVerify';
import ResetPassword from '../components/User/ResetPassword';

import {
  TOP_HEADER_NAVIGATION,
  SCREEN_MODE,
  FONT_MODE,
  FONT_MODE_ARRAY,
  FOOTER_NAVIGATION_LINK,
  SCREEN_MODE_ARRAY,
  LANGUAGE_LIST,
  DWEB_PLATFORM,
  MWEB_PLATFORM,
  DRAUGIEM_PLATFORM,
  FACEBOOK_PLATFORM,
  INBOX_PLATFORM,
  IOS_PLATFORM,
  ANDROID_PLATFORM,
} from '../../constants/constants';

import {
  setFontMode,
  getFontMode,
  setDesign,
  getDesign,
  setUserLoginStatistics,
  everyPayCallback,
  saveErrorCode,
  getDesignPlatforms,
} from '../../actions/member';

import {
  changeNotificationSettings,
} from '../../actions/userSettings';
import config from '../../constants/config';

let hasResetError = false;

function fallbackRender({ error, resetErrorBoundary }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  console.log('fallbackRender', error);

  if (LogRocket) {
    LogRocket.captureException(error, {
      tags: {
        subscription: 'Pro',
      },
      extra: {
        error: error,
      },
    });
  }

  saveErrorCode(error);

  if (!hasResetError) {
    hasResetError = true;
  //  resetErrorBoundary();
  }

  return (
    <div role="alert">
      <p>Kļūda:</p>
      <pre>{error.message}</pre>
      <pre>{error.code}</pre>
    </div>
  );
}


export const history = createBrowserHistory();

class Index extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    fontMode: PropTypes.string,
    screenMode: PropTypes.string,
    everyPayCallbackFunc: PropTypes.func.isRequired,
    setUserLoginStats: PropTypes.func.isRequired,
    fetchDesignPlatforms: PropTypes.func.isRequired,
    designPlatforms: PropTypes.shape({}),
  }

  static defaultProps = {
    fontMode: FONT_MODE.normal,
    screenMode: SCREEN_MODE.dark,
    designPlatforms: {},
  }

  constructor(props) {
    super(props);
    try {
      window.addEventListener('resize', this.handleResize);
    } catch (err) {
      console.log(err);
    }
    this.showNotifications = this.showNotifications.bind(this);

    this.notificationDOMRef = React.createRef();
    this.fullScreenRef = React.createRef();

    const { hostname } = window.location;

    let isWebVersion = true;

    if (!hostname) {
      isWebVersion = false;
    }

    if (hostname.includes('dra') || hostname.includes('fb') || hostname.includes('inbox')) {
      isWebVersion = false;
    }

    //  isWebVersion = false;

    let newDesign = false;
    let platform = DWEB_PLATFORM;
    const isInDraugiemAppFrame = config.isInDraugiemAppFrame();
    const isInFacebooxAppFrame = config.isInFacebookAppFrame();
    const isInInboxAppFrame = config.isInInboxAppFrame();

    const { member } = props;

    console.log('index member', member);

    console.log('isInInboxAppFrame', { isInInboxAppFrame, isInFacebooxAppFrame, isInDraugiemAppFrame });

    if (isIOS) {
      platform = IOS_PLATFORM;
    } else if (isAndroid) {
      platform = ANDROID_PLATFORM;
    } else if (isInDraugiemAppFrame) {
      platform = DRAUGIEM_PLATFORM;
    } else if (isInFacebooxAppFrame) {
      platform = FACEBOOK_PLATFORM;
    } else if (isInInboxAppFrame) {
      platform = INBOX_PLATFORM;
    } else if (window && window.innerWidth <= 1280) {
      platform = MWEB_PLATFORM;
    } else {
      platform= DWEB_PLATFORM;
    }

    console.log('platform', platform);

    let designPlatform = {};
    try {
      let designByPlatform = JSON.parse(localStorage.getItem('designPlatforms')); //x

      if (size(designByPlatform) > 0) {
        designPlatform = {
          oldWeb: designByPlatform[platform]?.old || false,
          newWeb: designByPlatform[platform]?.new || false,
          showReminder: designByPlatform[platform]?.new && designByPlatform[platform]?.showReminder || true,
          frequency: designByPlatform[platform]?.new && designByPlatform[platform]?.showReminder ? designByPlatform[platform]?.frequency : 0,
          key: platform,
          devMode: designByPlatform[platform]?.devMode || false,
        }
      } else {
        designPlatform = {
          old: true,
          new: true,
          showReminder: false,
          frequency: 0,
          key: platform,
          devMode: false,
        }
      }

      if (props.newDesign || localStorage.getItem('newDesign')) { //x
        newDesign = true;
      } else {
        newDesign = false;
      }
    } catch (err) {
    }

    console.log('set newDesign 1', newDesign, designPlatform, platform);
    this.state = {
      isFullscreenEnabled: false,
      isWebVersion,
      newDesign,
      everyPayPaymentSuccessful: null,
      everyPayPaymentStatus: null,
      authChecked: null,
      isEventOption: false,
      designPlatform,
      platform,
    };

    this.exitHandler = this.exitHandler.bind(this);
  }

  handleResize = () => {
    const { designPlatforms } = this.props;
    const { platform, newDesign } = this.state;

    const isInDraugiemAppFrame = config.isInDraugiemAppFrame();
    const isInFacebooxAppFrame = config.isInFacebookAppFrame();
    const isInInboxAppFrame = config.isInInboxAppFrame();

    console.log('handleResize', isInDraugiemAppFrame, isInFacebooxAppFrame, isInInboxAppFrame, window.innerWidth);

    let currentPlatform = platform;
    if (!isInDraugiemAppFrame && !isInFacebooxAppFrame && !isInInboxAppFrame) {
      if (window.innerWidth <= 1280) {
        currentPlatform = currentPlatform !== MWEB_PLATFORM ? MWEB_PLATFORM : platform;
        console.log('set platform', currentPlatform, MWEB_PLATFORM);

        this.setState({
          platform: currentPlatform,
        });
      } else {
        currentPlatform = currentPlatform !== DWEB_PLATFORM ? DWEB_PLATFORM : platform;
        console.log('set platform', currentPlatform, MWEB_PLATFORM);

        this.setState({
          platform: currentPlatform,
        });
      };
    }

    console.log("call handleSize", platform, currentPlatform, designPlatforms);

    if (platform !== currentPlatform && size(designPlatforms) > 0) {
      console.log('set designPlatform', designPlatforms, platform);

      this.setState({
        designPlatform: {
          ...designPlatforms[currentPlatform],
          key: currentPlatform,
        },
      })
      // this.setDesignByPlatform(currentPlatform, designPlatforms[currentPlatform], newDesign)
    }
  };

  componentDidMount() {
    console.log('componentDidMount');
    const { everyPayCallbackFunc, getFontModeFunc, fetchDesignPlatforms, designPlatforms, newDesign } = this.props;
    const { platform, designPlatform } = this.state;

    this.handleResize();
    this.checkLangQueryParam();
    fetchDesignPlatforms();
    
    if (size(designPlatforms) > 0) {
      console.log('set designPlatform', designPlatforms, platform);

      this.setState({
        designPlatform: {
          ...designPlatforms[platform],
          key: platform,
        }
      });
      // this.setDesignByPlatform(platform, designPlatforms[platform], newDesign);
    }


    // getFontModeFunc();
    try {
    document.addEventListener('fullscreenchange', this.exitHandler);
    document.addEventListener('webkitfullscreenchange', this.exitHandler);
    document.addEventListener('mozfullscreenchange', this.exitHandler);
    document.addEventListener('MSFullscreenChange', this.exitHandler);

      window.addEventListener('resize', this.handleResize);
    } catch (err) {
      console.log(err);
    }

    try {
      if (newDesign || localStorage.getItem('newDesign')) { //x
        this.setState({
          newDesign: true,
        });
      } else {
        this.setState({
          newDesign: false,
        });
      }
    } catch (err) {
    }

    const searchParams = new URLSearchParams(window.location.search);
    const paymentReference = searchParams.get('payment_reference');

    console.log('paymentReference', paymentReference);

    if (paymentReference) {
      everyPayCallbackFunc(paymentReference).then((res) => {
        console.log('everyPayCallbackFunc res', res);

        if (res && res.data && res.data.data) {
          if (res.data.data === 'settled') {
            this.setState({ everyPayPaymentSuccessful: true });
          } else {
            this.setState({ everyPayPaymentSuccessful: false });
          }
        } else {
          this.setState({ everyPayPaymentSuccessful: false });
        }
        this.setState({ everyPayPaymentStatus: res.data.status});
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { designPlatforms, currentUser, fetchDesignPlatforms } = this.props;
    const { platform, newDesign } = this.state;
    console.log(designPlatforms, nextProps.designPlatforms, platform, "checkUpdateDesignPlatform")
    if (currentUser && (!isEqual(designPlatforms, nextProps.designPlatforms) || (size(designPlatforms) === 0 && size(nextProps.designPlatforms) === 0))) {
      fetchDesignPlatforms();

      console.log('set designPlatform', nextProps.designPlatforms, platform);

      this.setState({
        designPlatform: {
          ...nextProps.designPlatforms[platform],
          key: platform,
        },
      })
      // this.setDesignByPlatform(platform, nextProps.designPlatforms[platform], newDesign);
    }
  }
  
  componentWillUnmount() {
    try {
      window.removeEventListener('resize', this.handleResize);
    } catch(err) {
      console.log(err);
    }
  }

  // setDesignByPlatform = (curPlatform, curDesignPlatform, newDesign ) => {
  //   if (curDesignPlatform) {
  //     if (!curDesignPlatform.old && !newDesign) {
  //       this.toggleNewDesign(true);
  //     }

  //     if (!curDesignPlatform.new && newDesign) {
  //       this.toggleNewDesign(false);
  //     }
  //   }
  // }

  checkLangQueryParam() {
    const { i18n } = this.props;
    const searchParams = new URLSearchParams(window.location.search);
    const languageParam = searchParams.get('lang');
    if (languageParam && LANGUAGE_LIST.includes(languageParam)) {
      i18n.changeLanguage(languageParam)
    }
  }

  authChecked = () => {
    this.setState({ authChecked: true });
  }

  showNotifications = (title, message, type) => {
    this.notificationDOMRef.current.addNotification({
      title,
      message,
      type,
      insert: 'top',
      container: 'top-right',
      animationIn: ['animated', 'fadeIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: { duration: 4000 },
      dismissable: { click: true },
    });
  }

  toggleFullscreen = () => {
    const { setUserLoginStats } = this.props;
    const { newDesign, isFullscreenEnabled } = this.state;

    if ((document.fullScreenElement && document.fullScreenElement !== null)
        || (!document.mozFullScreen && !document.webkitIsFullScreen)) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else if (document.cancelFullScreen) {
      document.cancelFullScreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen();
    }

    this.setState(prevState => ({ isFullscreenEnabled: !prevState.isFullscreenEnabled }));

    setUserLoginStats(false, newDesign, null, null, null, !isFullscreenEnabled);
  }

  exitHandler = () => {
    if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
      this.setState({ isFullscreenEnabled: false });
    } else {
      this.setState({ isFullscreenEnabled: true });
    }
  }

  toggleNewDesign = (toggle, dontSaveStats) => {
    const {
      setDesignFunc, setUserLoginStats, member, changeNotification, screenMode,
    } = this.props;

    try {
      if (toggle === true) {
        localStorage.setItem('newDesign', true); //x
      } else {
        localStorage.removeItem('newDesign'); //x
      }
    } catch (err) {
    }

    console.log('set newDesign 3', toggle, screenMode, dontSaveStats);
    this.setState({
      newDesign: toggle,
    });

    setDesignFunc(toggle);

    if (!dontSaveStats) {
      if (toggle === true) {
        setUserLoginStats(true, toggle, null, null, screenMode, null);
      } else {
        setUserLoginStats(true, toggle, null, null, null, null);
      }
    }
  }

  toggleScreenMode = (screenMode) => {
    try {
      if (localStorage.getItem('screenMode')) { //x
        localStorage.removeItem('screenMode'); //x
        localStorage.setItem('screenMode', JSON.stringify(screenMode)); //x
      } else {
        localStorage.setItem('screenMode', JSON.stringify(screenMode)); //x
      }
    } catch (err) {
    }

    this.setState({
      screenMode,
    });
  }

  toggleFont = (item) => {
    const { setFontModeFunc, getFontModeFunc, setUserLoginStats } = this.props;

    try {
      if (localStorage.getItem('fontMode')) { //x
        localStorage.removeItem('fontMode'); //x
        localStorage.setItem('fontMode', JSON.stringify(item)); //x
      } else {
        localStorage.setItem('fontMode', JSON.stringify(item)); //x
      }
    } catch (err) {
    }

    setFontModeFunc(item);
    setUserLoginStats(false, null, null, item, null, null);
  }

  toggleEvent = () => {
    this.setState(prevState => ({
      isEventOption: !prevState.isEventOption,
    }));
  }

  render() {
    const {
      t, fontMode, screenMode, currentUser, verifiedEmail, socProvider, member, designPlatforms, i18n,
    } = this.props; // notificationSettings
    const {
      isFullscreenEnabled, isWebVersion, newDesign, everyPayPaymentSuccessful, everyPayPaymentStatus, authChecked, isEventOption, designPlatform, platform,
    } = this.state;

    let hostname = '';
    let pathname = '';
    if (window && window.location) {
      hostname = window.location.hostname;
      pathname = window.location.pathname;
    }

    console.log('render root', pathname, fontMode);

    console.log('authChecked', authChecked);

    const isLoggedIn = (socProvider !== 'password' && currentUser) || (socProvider === 'password' && currentUser && verifiedEmail);
    console.log(designPlatform, designPlatforms, platform, "renderDesignPlatform");

    let title = "Zole - spēlē latviešu kāršu spēli online";
    let description = "Zole jeb zolīte ir Latvijā populāra kāršu spēle, ko citviet pazīst ar nosaukumu 'revelīts'.Parasti to spēlē 3 spēlētāji.Pārbaudi spēkus!";

    try {
      if (i18n && i18n.language) {
        if (i18n.language === 'en') {
          title = "Zole - spēlē latviešu kāršu spēli online";
          description = "Zole jeb zolīte ir Latvijā populāra kāršu spēle, ko citviet pazīst ar nosaukumu 'revelīts'.Parasti to spēlē 3 spēlētāji.Pārbaudi spēkus!";
        } else if (i18n.language === 'ru') {
          title = "Zole - spēlē latviešu kāršu spēli online";
          description = "Zole jeb zolīte ir Latvijā populāra kāršu spēle, ko citviet pazīst ar nosaukumu 'revelīts'.Parasti to spēlē 3 spēlētāji.Pārbaudi spēkus!";
        }
      }
    } catch (err) {
      console.log('err', err);
    }

    return (
      <>
        <ErrorBoundary
          fallbackRender={fallbackRender}
        >
        <Helmet>
            <title>
              {title}
            </title>
            <meta name="description" content={description} />
        </Helmet>
        <Router history={history}>
          <Fragment>
            <Suspense fallback={<div />}>
              {/*  {pathname && pathname !== '/admin' ? (
                <div style={{ position: 'absolute', left: '35%', zIndex: 950 }} onClick={this.toggleFullscreen}>
                  {isFullscreenEnabled ? (
                    <Media src={closeFullscreen} className="notification-header-close" onClick={this.toggleFullscreen} />
                  ):(
                    <Media src={openFullscreen} className="notification-header-close" onClick={this.toggleFullscreen} />
                  )}
                  Fullscreen
                </div>
              ):(null)}  */}
              <Switch>
                <Route
                  path="/zole/:id"
                  render={props => (
                    <TemplateGame
                      {...props}
                      pageTitle="Zole"
                    //  isFullscreen={isWebVersion || isFullscreenEnabled}
                      showNotification={this.showNotifications}
                      toggleFullscreen={this.toggleFullscreen}
                      isFullscreen={isFullscreenEnabled}
                      isFullscreenEnabled={isFullscreenEnabled}
                      isWebVersion={isWebVersion}
                      toggleNewDesign={this.toggleNewDesign}
                      screenMode={screenMode}
                      fontMode={fontMode}
                      toggleScreenMode={this.toggleScreenMode}
                      toggleFont={this.toggleFont}
                      newDesign={newDesign}
                      i18n={i18n}
                      designPlatform={designPlatform}
                    >
                      {/*  <ZoleContainer

                        Layout={ZoleComponent}
                        showNotification={this.showNotifications}
                        toggleFullscreen={this.toggleFullscreen}
                        isFullscreen={isFullscreenEnabled}
                        isFullscreenEnabled={isFullscreenEnabled}
                        isWebVersion={isWebVersion}
                      />  */}
                    </TemplateGame>
                  )}
                />
                {/*  <Route
                  path="/zole/:id"
                  render={props => (
                    <>
                      {notificationSettings && notificationSettings.newDesign ? (
                        <TemplateGame2 isFullscreen={isWebVersion || isFullscreenEnabled} pageTitle="Zole">
                          <Zole2Container
                            {...props}
                            Layout={Zole2Component}
                            showNotification={this.showNotifications}
                            toggleFullscreen={this.toggleFullscreen}
                            isFullscreen={isFullscreenEnabled}
                            isFullscreenEnabled={isFullscreenEnabled}
                            isWebVersion={isWebVersion}
                          />
                        </TemplateGame2>
                      ):(
                        <TemplateGame isFullscreen={isWebVersion || isFullscreenEnabled} pageTitle="Zole">
                          <ZoleContainer
                            {...props}
                            Layout={ZoleComponent}
                            showNotification={this.showNotifications}
                            toggleFullscreen={this.toggleFullscreen}
                            isFullscreen={isFullscreenEnabled}
                            isFullscreenEnabled={isFullscreenEnabled}
                            isWebVersion={isWebVersion}
                          />
                        </TemplateGame>
                      )}
                    </>

                  )}
                /> */}

                <Route
                  path="/dra-redirect"
                  render={props => (
                    <DraRedirect {...props} />
                  )}
                />
                <Route
                  path="/landing"
                  render={props => (
                    !newDesign ? (
                      <TemplateHome fontMode={fontMode} toggleFont={this.toggleFont} isFullscreen={isWebVersion || isFullscreenEnabled} pageTitle={`Zole - ${t('route.landing')}`}>
                        <Home
                          {...props}
                          toggleFullscreen={this.toggleFullscreen}
                          isFullscreen={isWebVersion || isFullscreenEnabled}
                          toggleNewDesign={this.toggleNewDesign}
                          fontMode={fontMode}
                          toggleFont={this.toggleFont}
                        />
                      </TemplateHome>
                    ) : (
                      <TemplateHome2 fontMode={fontMode} toggleFont={this.toggleFont} isFullscreen={isWebVersion || isFullscreenEnabled} screenMode={screenMode} toggleScreenMode={this.toggleScreenMode} pageTitle={`Zole - ${t('route.landing')}`} containerClassName="new-design-landing">
                        <Home2
                          {...props}
                          toggleFullscreen={this.toggleFullscreen}
                          isFullscreen={isWebVersion || isFullscreenEnabled}
                          isFullscreenEnabled={isFullscreenEnabled}
                          screenMode={SCREEN_MODE.dark}
                          toggleScreenMode={this.toggleScreenMode}
                          toggleNewDesign={this.toggleNewDesign}
                          fontMode={fontMode}
                          toggleFont={this.toggleFont}
                          isLoggedIn={isLoggedIn}
                        />
                      </TemplateHome2>
                    )
                  )}
                />

                <Route
                  path="/home/:lang"
                  render={props => (
                    <TemplateHome2 fontMode={fontMode} toggleFont={this.toggleFont} isFullscreen={isWebVersion || isFullscreenEnabled} screenMode={screenMode} toggleScreenMode={this.toggleScreenMode} pageTitle={`Zole - ${t('route.landing')}`} containerClassName="new-design-landing">
                      <Home2
                        {...props}
                        toggleFullscreen={this.toggleFullscreen}
                        isFullscreen={isWebVersion || isFullscreenEnabled}
                        isFullscreenEnabled={isFullscreenEnabled}
                        screenMode={screenMode}
                        toggleScreenMode={this.toggleScreenMode}
                        toggleNewDesign={this.toggleNewDesign}
                        fontMode={fontMode}
                        toggleFont={this.toggleFont}
                        seoLang={props.match.params.lang || 'lv'}
                        isLoggedIn={isLoggedIn}
                      />
                    </TemplateHome2>
                  )}
                />

                <Route
                  path="/terms-of-use"
                  render={props => (
                    !newDesign ? (
                      <TemplateHome toggleFont={this.toggleFont} fontMode={fontMode} isFullscreen={isWebVersion || isFullscreenEnabled} pageTitle={`Zole - ${t('route.termsOfUse')}`}>
                        <RulesPage
                          {...props}
                          toggleFullscreen={this.toggleFullscreen}
                          isFullscreen={isWebVersion || isFullscreenEnabled}
                          toggleNewDesign={this.toggleNewDesign}
                          fontMode={fontMode}
                          toggleFont={this.toggleFont}
                        />
                      </TemplateHome>
                    ) : (
                      <TemplateHome2 fontMode={fontMode} toggleFont={this.toggleFont} isFullscreen={isWebVersion || isFullscreenEnabled} screenMode={screenMode} toggleScreenMode={this.toggleScreenMode} pageTitle={`Zole - ${t('route.termsOfUse')}`} containerClassName="new-design-landing">
                        <Home2
                          {...props}
                          toggleFullscreen={this.toggleFullscreen}
                          isFullscreen={isWebVersion || isFullscreenEnabled}
                          selectPage={FOOTER_NAVIGATION_LINK.termsOfUse}
                          isFullscreenEnabled={isFullscreenEnabled}
                          screenMode={screenMode}
                          toggleScreenMode={this.toggleScreenMode}
                          toggleNewDesign={this.toggleNewDesign}
                          fontMode={fontMode}
                          toggleFont={this.toggleFont}
                          isLoggedIn={isLoggedIn}
                        />
                      </TemplateHome2>
                    )
                  )}
                />

                <Route
                  path="/zoleBlog/:lang/:blogId"
                  exact
                  render={props => (
                    <TemplateHome2 fontMode={fontMode} toggleFont={this.toggleFont} isFullscreen={isWebVersion || isFullscreenEnabled} screenMode={screenMode} toggleScreenMode={this.toggleScreenMode} pageTitle={`Zole - ${t('route.blog')}`} containerClassName="new-design-landing">
                      <Home2
                        {...props}
                        toggleFullscreen={this.toggleFullscreen}
                        isFullscreen={isWebVersion || isFullscreenEnabled}
                        selectPage={TOP_HEADER_NAVIGATION.blog}
                        blogId={props.match.params.blogId || ''}
                        isFullscreenEnabled={isFullscreenEnabled}
                        screenMode={screenMode}
                        toggleScreenMode={this.toggleScreenMode}
                        toggleNewDesign={this.toggleNewDesign}
                        fontMode={fontMode}
                        toggleFont={this.toggleFont}
                        isLoggedIn={isLoggedIn}
                      />
                    </TemplateHome2>
                  )}
                />

                <Route
                  path="/blog/:lang/:blogId"
                  exact
                  render={props => (
                    <TemplateHome2 fontMode={fontMode} toggleFont={this.toggleFont} isFullscreen={isWebVersion || isFullscreenEnabled} screenMode={screenMode} toggleScreenMode={this.toggleScreenMode} pageTitle={`Zole - ${t('route.blog')}`} containerClassName="new-design-landing">
                      <Home2
                        {...props}
                        toggleFullscreen={this.toggleFullscreen}
                        isFullscreen={isWebVersion || isFullscreenEnabled}
                        selectPage={TOP_HEADER_NAVIGATION.blog}
                        blogId={props.match.params.blogId || ''}
                        isFullscreenEnabled={isFullscreenEnabled}
                        screenMode={screenMode}
                        toggleScreenMode={this.toggleScreenMode}
                        toggleNewDesign={this.toggleNewDesign}
                        fontMode={fontMode}
                        toggleFont={this.toggleFont}
                        isLoggedIn={isLoggedIn}
                      />
                    </TemplateHome2>
                  )}
                />

                <Route
                  path="/blog"
                  exact
                  render={props => (
                    <TemplateHome2 fontMode={fontMode} toggleFont={this.toggleFont} isFullscreen={isWebVersion || isFullscreenEnabled} screenMode={screenMode} toggleScreenMode={this.toggleScreenMode} pageTitle={`Zole - ${t('route.blog')}`} containerClassName="new-design-landing">
                      <Home2
                        {...props}
                        toggleFullscreen={this.toggleFullscreen}
                        isFullscreen={isWebVersion || isFullscreenEnabled}
                        selectPage={TOP_HEADER_NAVIGATION.blog}
                        isFullscreenEnabled={isFullscreenEnabled}
                        screenMode={screenMode}
                        toggleScreenMode={this.toggleScreenMode}
                        toggleNewDesign={this.toggleNewDesign}
                        fontMode={fontMode}
                        toggleFont={this.toggleFont}
                        isLoggedIn={isLoggedIn}
                      />
                    </TemplateHome2>
                  )}
                />

                <Route
                  path="/game-rule"
                  render={props => (
                    <TemplateHome2 fontMode={fontMode} toggleFont={this.toggleFont} isFullscreen={isWebVersion || isFullscreenEnabled} screenMode={screenMode} toggleScreenMode={this.toggleScreenMode} pageTitle={`Zole - ${t('route.helpPage')}`} containerClassName="new-design-landing">
                      <Home2
                        {...props}
                        toggleFullscreen={this.toggleFullscreen}
                        isFullscreen={isWebVersion || isFullscreenEnabled}
                        selectPage={TOP_HEADER_NAVIGATION.helpPage}
                        isFullscreenEnabled={isFullscreenEnabled}
                        screenMode={screenMode}
                        toggleScreenMode={this.toggleScreenMode}
                        toggleNewDesign={this.toggleNewDesign}
                        fontMode={fontMode}
                        toggleFont={this.toggleFont}
                        isLoggedIn={isLoggedIn}
                      />
                    </TemplateHome2>
                  )}
                />

                <Route
                  path="/version-history"
                  render={props => (
                    <TemplateHome2 fontMode={fontMode} toggleFont={this.toggleFont} isFullscreen={isWebVersion || isFullscreenEnabled} screenMode={screenMode} toggleScreenMode={this.toggleScreenMode} pageTitle={`Zole - ${t('home.versionHistory')}`} containerClassName="new-design-landing">
                      <Home2
                        {...props}
                        toggleFullscreen={this.toggleFullscreen}
                        isFullscreen={isWebVersion || isFullscreenEnabled}
                        selectPage={FOOTER_NAVIGATION_LINK.versionHistory}
                        isFullscreenEnabled={isFullscreenEnabled}
                        screenMode={screenMode}
                        toggleScreenMode={this.toggleScreenMode}
                        toggleNewDesign={this.toggleNewDesign}
                        fontMode={fontMode}
                        toggleFont={this.toggleFont}
                        isLoggedIn={isLoggedIn}
                      />
                    </TemplateHome2>
                  )}
                />

                <Route
                  path="/contacts"
                  render={props => (
                    <TemplateHome2 fontMode={fontMode} toggleFont={this.toggleFont} isFullscreen={isWebVersion || isFullscreenEnabled} screenMode={screenMode} toggleScreenMode={this.toggleScreenMode} pageTitle={`Zole - ${t('home.contacts')}`} containerClassName="new-design-landing">
                      <Home2
                        {...props}
                        toggleFullscreen={this.toggleFullscreen}
                        isFullscreen={isWebVersion || isFullscreenEnabled}
                        selectPage={TOP_HEADER_NAVIGATION.contacts}
                        isFullscreenEnabled={isFullscreenEnabled}
                        screenMode={screenMode}
                        toggleScreenMode={this.toggleScreenMode}
                        toggleNewDesign={this.toggleNewDesign}
                        fontMode={fontMode}
                        toggleFont={this.toggleFont}
                        isLoggedIn={isLoggedIn}
                      />
                    </TemplateHome2>
                  )}
                />

                <Route
                  path="/login"
                  render={props => (
                    !newDesign ? (
                      <TemplateHome toggleFont={this.toggleFont} fontMode={fontMode} isFullscreen={isWebVersion || isFullscreenEnabled} pageTitle={`Zole - ${t('route.login')}`} addClassName="login-container">
                        <LoginContainer
                          {...props}
                          Layout={LoginComponent}
                          toggleFullscreen={this.toggleFullscreen}
                          isFullscreen={isWebVersion || isFullscreenEnabled}
                          toggleNewDesign={this.toggleNewDesign}
                          fontMode={fontMode}
                          toggleFont={this.toggleFont}
                        />
                      </TemplateHome>
                    ) : (
                      <TemplateHome2 fontMode={fontMode} toggleFont={this.toggleFont} isFullscreen={isWebVersion || isFullscreenEnabled} screenMode={screenMode} toggleScreenMode={this.toggleScreenMode} pageTitle={`Zole - ${t('route.login')}`} containerClassName="new-design-login">
                        <LoginContainer
                          {...props}
                          Layout={Login2Component}
                          toggleFullscreen={this.toggleFullscreen}
                          isFullscreen={isWebVersion || isFullscreenEnabled}
                          isFullscreenEnabled={isFullscreenEnabled}
                          screenMode={screenMode}
                          toggleScreenMode={this.toggleScreenMode}
                          toggleNewDesign={this.toggleNewDesign}
                          fontMode={fontMode}
                          toggleFont={this.toggleFont}
                          newDesign={newDesign}
                        />
                      </TemplateHome2>
                    )
                  )}
                />

                <Route
                  path="/update-profile"
                  render={props => (
                    <TemplateHome toggleFont={this.toggleFont} fontMode={fontMode} isFullscreen={isWebVersion || isFullscreenEnabled} pageTitle={`Zole - ${t('route.updateProfile')}`} addClassName="login-container">
                      <UpdateProfileContainer
                        {...props}
                        Layout={UpdateProfileComponent}
                        toggleFullscreen={this.toggleFullscreen}
                        isFullscreen={isWebVersion || isFullscreenEnabled}
                        fontMode={fontMode}
                        toggleFont={this.toggleFont}
                      />
                    </TemplateHome>
                  )}
                />

                <Route
                  path="/forgot-password"
                  render={props => (
                    <TemplateHome toggleFont={this.toggleFont} fontMode={fontMode} isFullscreen={isWebVersion || isFullscreenEnabled} pageTitle={`Zole - ${t('route.forgotPassword')}`} addClassName="forgot-pass-container">
                      <ForgotPasswordContainer
                        {...props}
                        Layout={ForgotPasswordComponent}
                        toggleFullscreen={this.toggleFullscreen}
                        isFullscreen={isWebVersion || isFullscreenEnabled}
                        toggleFont={this.toggleFont}
                        fontMode={fontMode}
                      />
                    </TemplateHome>
                  )}
                />

                <Route
                  path="/sign-up"
                  render={props => (
                    !newDesign ? (
                      <TemplateHome toggleFont={this.toggleFont} fontMode={fontMode} isFullscreen={isWebVersion || isFullscreenEnabled} pageTitle={`Zole - ${t('route.register')}`} addClassName="sign-up-container">
                        <SignUpContainer
                          {...props}
                          Layout={SignUpComponent}
                          toggleFullscreen={this.toggleFullscreen}
                          isFullscreen={isWebVersion || isFullscreenEnabled}
                          fontMode={fontMode}
                          toggleFont={this.toggleFont}
                        />
                      </TemplateHome>
                    ) : (
                      <TemplateHome2 fontMode={fontMode} toggleFont={this.toggleFont} isFullscreen={isWebVersion || isFullscreenEnabled} screenMode={screenMode} toggleScreenMode={this.toggleScreenMode} pageTitle={`Zole - ${t('route.landing')}`} containerClassName="new-design-login" addClassName="login-container">
                        <LoginContainer
                          {...props}
                          Layout={Login2Component}
                          toggleFullscreen={this.toggleFullscreen}
                          isFullscreen={isWebVersion || isFullscreenEnabled}
                          resetPasswordFlag
                          isFullscreenEnabled={isFullscreenEnabled}
                          screenMode={screenMode}
                          toggleScreenMode={this.toggleScreenMode}
                          fontMode={fontMode}
                          toggleFont={this.toggleFont}
                        />
                      </TemplateHome2>
                    )
                  )}
                />
                <Route
                  path="/email-verify"
                  render={props => (
                    <TemplateHome2 toggleFont={this.toggleFont} fontMode={fontMode} isFullscreen={isFullscreenEnabled} pageTitle={`Zole - ${t('route.updateProfile')}`} addClassName="login-container">
                      <EmailVerify {...props} />
                    </TemplateHome2>
                  )}
                />

                <Route
                  path="/reset-password"
                  render={props => (
                    !newDesign ? (
                      <TemplateHome toggleFont={this.toggleFont} fontMode={fontMode} isFullscreen={isWebVersion || isFullscreenEnabled} pageTitle={`Zole - ${t('route.login')}`} addClassName="login-container">
                        <LoginContainer
                          {...props}
                          Layout={LoginComponent}
                          toggleFullscreen={this.toggleFullscreen}
                          isFullscreen={isWebVersion || isFullscreenEnabled}
                          resetPasswordFlag
                          fontMode={fontMode}
                          toggleFont={this.toggleFont}
                        />
                      </TemplateHome>
                    ) : (
                      <TemplateHome2 fontMode={fontMode} toggleFont={this.toggleFont} isFullscreen={isWebVersion || isFullscreenEnabled} screenMode={screenMode} toggleScreenMode={this.toggleScreenMode} pageTitle={`Zole - ${t('route.login')}`} containerClassName="new-design-login" addClassName="login-container">
                        <LoginContainer
                          {...props}
                          Layout={Login2Component}
                          toggleFullscreen={this.toggleFullscreen}
                          isFullscreen={isWebVersion || isFullscreenEnabled}
                          resetPasswordFlag
                          isFullscreenEnabled={isFullscreenEnabled}
                          screenMode={screenMode}
                          toggleScreenMode={this.toggleScreenMode}
                          fontMode={fontMode}
                          toggleFont={this.toggleFont}
                        />
                      </TemplateHome2>
                    )
                  )}
                />

                <Route
                  path="/email-verify-success"
                  render={props => (
                    <TemplateMenu
                      {...props}
                      pageTitle={`Zole - ${t('route.menu')}`}
                      isFullscreen={isWebVersion || isFullscreenEnabled}
                      isWebVersion={isWebVersion}
                      showNotification={this.showNotifications}
                      toggleFullscreen={this.toggleFullscreen}
                      isFullscreenEnabled={isFullscreenEnabled}
                      activePath={props.match.params.activePath}
                      // emailVerifySuccess
                      toggleNewDesign={this.toggleNewDesign}
                      newDesign={newDesign}
                      screenMode={screenMode}
                      toggleScreenMode={this.toggleScreenMode}
                      fontMode={fontMode}
                      toggleFont={this.toggleFont}
                    />
                  )}
                />

                <Route
                  path="/payment/:stripeStatus"
                  render={props => (
                    !newDesign
                      ? <TransactionSuccess isFullscreen={isFullscreenEnabled} pageTitle={`Zole - ${t('route.landing')}`} stripeStatus={props.match.params.stripeStatus} paymentState={everyPayPaymentStatus} {...props} screenMode={screenMode} toggleScreenMode={this.toggleScreenMode} />
                      : (
                        <TemplateHome2 addScreenModeRootClass fontMode={fontMode} toggleFont={this.toggleFont} isFullscreen={isWebVersion || isFullscreenEnabled} screenMode={screenMode} toggleScreenMode={this.toggleScreenMode} pageTitle={`Zole - ${t('route.landing')}`} containerClassName="new-design-landing">
                          <NewTransactionSuccess isFullscreen={isFullscreenEnabled} pageTitle={`Zole - ${t('route.landing')}`} stripeStatus={props.match.params.stripeStatus} paymentState={everyPayPaymentStatus} {...props} screenMode={screenMode} toggleScreenMode={this.toggleScreenMode} />
                        </TemplateHome2>
                      )
                  )}
                />
                <Route
                  path="/every-pay-payment"
                  render={props => (
                    !newDesign
                      ? <TransactionSuccess isFullscreen={isFullscreenEnabled} pageTitle={`Zole - ${t('route.landing')}`} stripeStatus={everyPayPaymentSuccessful} paymentState={everyPayPaymentStatus} {...props} screenMode={screenMode} toggleScreenMode={this.toggleScreenMode} />
                      : (
                        <TemplateHome2 addScreenModeRootClass fontMode={fontMode} toggleFont={this.toggleFont} isFullscreen={isWebVersion || isFullscreenEnabled} screenMode={screenMode} toggleScreenMode={this.toggleScreenMode} pageTitle={`Zole - ${t('route.landing')}`} containerClassName="new-design-landing">

                          <NewTransactionSuccess isFullscreen={isFullscreenEnabled} pageTitle={`Zole - ${t('route.landing')}`} stripeStatus={everyPayPaymentSuccessful} paymentState={everyPayPaymentStatus} {...props} screenMode={screenMode} toggleScreenMode={this.toggleScreenMode} />
                        </TemplateHome2>
                      )
                  )}
                />
                  <Route
                    path="/inbox-payment-success"
                    render={props => (
                      !newDesign
                        ? <InboxTransactionResult isFullscreen={false} pageTitle={`Zole - ${t('route.landing')}`} status={true} paymentState={'success'} {...props} />
                        : (
                          <TemplateHome2 addScreenModeRootClass fontMode={fontMode} toggleFont={this.toggleFont} isFullscreen={false} screenMode={screenMode} toggleScreenMode={this.toggleScreenMode} pageTitle={`Zole - ${t('route.landing')}`} containerClassName="new-design-landing">

                            <NewInboxTransactionResult isFullscreen={false} pageTitle={`Zole - ${t('route.landing')}`} status={true} paymentState={'success'} {...props} />
                          </TemplateHome2>
                        )
                    )}
                  />
                  <Route
                    path="/inbox-payment-cancelled"
                    render={props => (
                      !newDesign
                        ? <InboxTransactionResult isFullscreen={false} pageTitle={`Zole - ${t('route.landing')}`} status={false} paymentState={'canceled'} {...props} />
                        : (
                          <TemplateHome2 addScreenModeRootClass fontMode={fontMode} toggleFont={this.toggleFont} isFullscreen={false} screenMode={screenMode} toggleScreenMode={this.toggleScreenMode} pageTitle={`Zole - ${t('route.landing')}`} containerClassName="new-design-landing">

                            <NewInboxTransactionResult isFullscreen={false} pageTitle={`Zole - ${t('route.landing')}`} status={false} paymentState={'canceled'} {...props} />
                          </TemplateHome2>
                        )
                    )}
                  />

                {!hostname.includes('dra') && !hostname.includes('fb') && (
                  <Route
                    path="/admin/:searchUid"
                    render={props => (
                      <TemplateAdmin isFullscreen={isWebVersion || isFullscreenEnabled} pageTitle="Administration">
                        <AdminContainer
                          {...props}
                          t={t}
                          Layout={AdminPanelComponent}
                          showNotification={this.showNotifications}
                          toggleFullscreen={this.toggleFullscreen}
                          isFullscreen={isWebVersion || isFullscreenEnabled}
                          searchUid={props.match.params.searchUid}
                          fontMode={fontMode}
                          toggleNewDesign={this.toggleNewDesign}
                          newDesign={newDesign}
                          screenMode={screenMode}
                          toggleScreenMode={this.toggleScreenMode}
                        />
                      </TemplateAdmin>
                    )}
                  />
                )}

                {!hostname.includes('dra') && !hostname.includes('fb') && (
                  <Route
                    path="/admin"
                    render={props => (
                      <TemplateAdmin isFullscreen={isWebVersion || isFullscreenEnabled} pageTitle="Administration">
                        <AdminContainer
                          {...props}
                          t={t}
                          Layout={AdminPanelComponent}
                          showNotification={this.showNotifications}
                          toggleFullscreen={this.toggleFullscreen}
                          isFullscreen={isWebVersion || isFullscreenEnabled}
                          fontMode={fontMode}
                          toggleNewDesign={this.toggleNewDesign}
                          newDesign={newDesign}
                          screenMode={screenMode}
                          toggleScreenMode={this.toggleScreenMode}
                        />
                      </TemplateAdmin>
                    )}
                  />
                )}

                <Route
                  path="/activeLink/:activePath"
                  render={props => (
                    <TemplateMenu
                      {...props}
                      pageTitle={`Zole - ${t('route.menu')}`}
                      isFullscreen={isWebVersion || isFullscreenEnabled}
                      isWebVersion={isWebVersion}
                      showNotification={this.showNotifications}
                      toggleFullscreen={this.toggleFullscreen}
                      isFullscreenEnabled={isFullscreenEnabled}
                      activePath={props.match.params.activePath}
                      toggleNewDesign={this.toggleNewDesign}
                      newDesign={newDesign}
                      screenMode={screenMode}
                      toggleScreenMode={this.toggleScreenMode}
                      fontMode={fontMode}
                      toggleFont={this.toggleFont}
                    />
                  )}
                />

                <Route
                  path="/"
                  render={props => (
                    <TemplateMenu
                      {...props}
                      pageTitle={`Zole - ${t('route.menu')}`}
                      isFullscreen={isWebVersion || isFullscreenEnabled}
                      isWebVersion={isWebVersion}
                      showNotification={this.showNotifications}
                      toggleFullscreen={this.toggleFullscreen}
                      isFullscreenEnabled={isFullscreenEnabled}
                      toggleNewDesign={this.toggleNewDesign}
                      pNewDesign={newDesign}
                      pScreenMode={screenMode}
                      toggleScreenMode={this.toggleScreenMode}
                      fontMode={fontMode}
                      toggleFont={this.toggleFont}
                      authChecked={authChecked}
                      isEventOption={isEventOption}
                      toggleEvent={this.toggleEvent}
                      designPlatform={designPlatform}
                      platform={platform}
                    >
                      {/*  <MenuContainer

                        Layout={MenuComponent}
                        showNotification={this.showNotifications}
                        toggleFullscreen={this.toggleFullscreen}
                        isFullscreen={isWebVersion || isFullscreenEnabled}
                        isFullscreenEnabled={isFullscreenEnabled}
                      /> */}
                    </TemplateMenu>
                  )}
                />

                {/*  <Route
                  path="/"
                  render={props => (
                    <>
                      {console.log('render default path')}
                      {notificationSettings && notificationSettings.newDesign ? (
                        <TemplateNothing2 isFullscreen={isWebVersion || isFullscreenEnabled} pageTitle={`Zole 2 - ${t('route.menu')}`}>
                          <Menu2Container
                            {...props}
                            Layout={Menu2Component}
                            isNewDesign
                            showNotification={this.showNotifications}
                            toggleFullscreen={this.toggleFullscreen}
                            isFullscreen={isWebVersion || isFullscreenEnabled}
                            isFullscreenEnabled={isFullscreenEnabled}
                          />
                        </TemplateNothing2>
                      ):(
                        <TemplateNothing isFullscreen={isWebVersion || isFullscreenEnabled} pageTitle={`Zole - ${t('route.menu')}`}>
                          <MenuContainer
                            {...props}
                            Layout={MenuComponent}
                            showNotification={this.showNotifications}
                            toggleFullscreen={this.toggleFullscreen}
                            isFullscreen={isWebVersion || isFullscreenEnabled}
                            isFullscreenEnabled={isFullscreenEnabled}
                          />
                        </TemplateNothing>
                      )}
                    </>
                  )}
                />  */}

                <Route
                  render={props => (
                    <TemplateNothing isFullscreen={isFullscreenEnabled} pageTitle="404 - Page not found">
                      <Error {...props} title="404" content="Sorry, the route you requested does not exist" />
                    </TemplateNothing>
                  )}
                />
              </Switch>
            </Suspense>
              <Auth t={t} newDesign={newDesign} screenMode={screenMode} fontMode={fontMode} toggleNewDesign={this.toggleNewDesign} authChecked={this.authChecked} />
               <ReactNotification ref={this.notificationDOMRef} />
          </Fragment>
          </Router>
        </ErrorBoundary>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  let localScreenMode; let
    localFontMode;

  try {
    localScreenMode = localStorage.getItem('screenMode'); //x
    localFontMode = localStorage.getItem('fontMode'); //x
  } catch (err) {
  }

  return ({
    notificationSettings: state.userSettings ? (state.userSettings[state.member.uid] ? (state.userSettings[state.member.uid].notificationSettings) : (state.userSettings.default ? state.userSettings.default.notificationSettings : {})) : {},

    fontMode: state.member.fontMode && FONT_MODE_ARRAY.indexOf((state.member.fontMode).replace(/^"(.*)"$/, '$1')) !== -1 ? (state.member.fontMode).replace(/^"(.*)"$/, '$1') : (localFontMode ? (isString(localFontMode) ? localFontMode : JSON.parse(localFontMode)) : FONT_MODE.normal),
    screenMode: state.member.screenMode && SCREEN_MODE_ARRAY.indexOf((state.member.screenMode).replace(/^"(.*)"$/, '$1')) !== -1 ? (state.member.screenMode).replace(/^"(.*)"$/, '$1') : (localScreenMode ? (isString(localScreenMode) ? localScreenMode : JSON.parse(localScreenMode)) : SCREEN_MODE.dark),
    currentUser: state.member.uid ? state.member.uid : null,
    verifiedEmail: state.member.verifiedEmail ? state.member.verifiedEmail : null,
    socProvider: state.member.socProvider ? state.member.socProvider : null,
    member: state.member || {},
    newDesign: state.member.newDesign || false,
    designPlatforms: state.member.designPlatforms || {},
  });
};

const mapDispatchToProps = {
  setFontModeFunc: setFontMode,
  getFontModeFunc: getFontMode,
  setDesignFunc: setDesign,
  getDesignFunc: getDesign,
  setUserLoginStats: setUserLoginStatistics,
  everyPayCallbackFunc: everyPayCallback,
  changeNotification: changeNotificationSettings,
  fetchDesignPlatforms: getDesignPlatforms,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Index));

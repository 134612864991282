import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// import ReactTableContainer from 'react-table-container';

import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';

import { size } from 'lodash';
import Moment from 'react-moment';
import moment from 'moment';

import ReactDateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

import ScrollArea from 'react-scrollbar';
import Spinner from 'reactstrap/lib/Spinner';
import classNames from 'classnames';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Form from 'reactstrap/lib/Form';
import FormGroup from 'reactstrap/lib/FormGroup';
import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import Button from 'reactstrap/lib/Button';
import UncontrolledPopover from 'reactstrap/lib/UncontrolledPopover';
import PopoverBody from 'reactstrap/lib/PopoverBody';
import Media from 'reactstrap/lib/Media';
import Pagination from 'reactstrap/lib/Pagination';
import PaginationItem from 'reactstrap/lib/PaginationItem';
import PaginationLink from 'reactstrap/lib/PaginationLink';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import Avatar from 'react-avatar-edit';
import UserHistory from './UserHistory';
import UserGamesHistory from './UserGamesHistory';
import Message from './AdminSupportMessage';
import UserAchievementsModal from './UserAchievementsModal';
import NonVerifiedUsers from './NonVerifiedUsers';

import pencilImg from '../../../images/icons/pencil.svg';
import checkImg from '../../../images/icons/check.png';
import cancelImg from '../../../images/icons/close_old.png';
import defaultImage from '../../../images/Game/defaultImage.jpg';
import * as constants from '../../../constants/constants';

import DateFormat from '../UI/DateFormat';

import {
  editUserDob,
  getAutoPlayOtherUser,
  getUserAchievements,
  getUserLastIp,
  getUserSettings,
} from '../../../actions/admin';
import { Popover } from 'reactstrap';

class AllUsers extends React.Component {
  static propTypes = {
    member: PropTypes.shape({
      email: PropTypes.string,
    }),
    allUsers: PropTypes.shape({}),
    filteredUsers: PropTypes.shape({}),
    nonVerifiedUsers: PropTypes.arrayOf(PropTypes.shape({})),
    chatMessages: PropTypes.shape({}),
    userAchievements: PropTypes.shape({}),
    userGamesHistory: PropTypes.arrayOf(PropTypes.shape({})),
    userBalanceHistory: PropTypes.arrayOf(PropTypes.shape({})),
    userPointsHistory: PropTypes.arrayOf(PropTypes.shape({})),
    usersCount: PropTypes.number,
    deleteUser: PropTypes.func.isRequired,
    swapToUser: PropTypes.func.isRequired,
    manualyVerifyUser: PropTypes.func.isRequired,
    blockUser: PropTypes.func.isRequired,
    unblockUser: PropTypes.func.isRequired,
    editUser: PropTypes.func.isRequired,
    addBalance: PropTypes.func.isRequired,
    addPoints: PropTypes.func.isRequired,
    fetchUsersRange: PropTypes.func.isRequired,
    fetchFilteredUsers: PropTypes.func.isRequired,
    fetchNonVerifiedUsers: PropTypes.func.isRequired,
    fetchUserGamesHistory: PropTypes.func.isRequired,
    fetchUserBalanceHistory: PropTypes.func.isRequired,
    fetchUserPointsHistory: PropTypes.func.isRequired,
    supportMessageFileUpload: PropTypes.func.isRequired,
    updateAvatarFileUpload: PropTypes.func.isRequired,
    toggleLogRocket: PropTypes.func.isRequired,
    toggleSmartlook: PropTypes.func.isRequired,
    resetActiveRoom: PropTypes.func.isRequired,
    getUserMessages: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    success: PropTypes.string,
    submitUserDobFuc: PropTypes.func.isRequired,
    fetchUserLastIp: PropTypes.func.isRequired,
    lastIpData: PropTypes.shape({}),
    nonVerifiedStart: PropTypes.bool,
    searchUid: PropTypes.string,
    fontMode: PropTypes.string,
    setSupportMessageAsResponded: PropTypes.func.isRequired,
    fetchUserAchievements: PropTypes.func.isRequired,
    fetchUserSettings: PropTypes.func.isRequired,
    fetchAutoPlayOtherUser: PropTypes.func.isRequired,
  }

  static defaultProps = {
    member: {},
    allUsers: {},
    filteredUsers: {},
    nonVerifiedUsers: [],
    chatMessages: {},
    userAchievements: {},
    usersCount: 0,
    userGamesHistory: [],
    userBalanceHistory: [],
    userPointsHistory: [],
    success: null,
    nonVerifiedStart: false,
    searchUid: null,
    fontMode: constants.FONT_MODE.normal,
  }

  constructor(props) {
    super(props);
    this.state = {
      balance: 0,
      editUserId: null,
      openModal: false,
      currentPage: 0,
      pageSize: 50,
      blockUserId: null,
      openBlockModal: false,
      endDate: moment(),
      reason: '',
      //  userFilter: '',
      filteredUsers: null,
      nonVerifiedUsers: props.nonVerifiedUsers,
      nonVerifiedCount: 0,
      filteredCount: 0,
      filterType: props.searchUid ? 'uid' : 'lowerCaseName',
      filter: props.searchUid || '',
      gamesHistoryModalOpen: false,
      bilanceHistoryModalOpen: false,
      pointsHistoryModalOpen: false,
      openDeleteConfirmation: false,
      deleteUserId: null,
      swapToUserId: null,
      openManualVerifyConfirmation: false,
      verifyUserId: null,
      fileUploadUrl: '',
      fileName: '',
      ableToSend: true,
      showCrop: false,
      firstName: '',
      lastName: '',
      preview: null,
      showCrop: false,
      ableToSave: true,
      photo: '',
      updateFlag: 'all',
      spinnerLoading: false,
      minDate: null,
      maxDate: null,
      birthday: null,
      infoPopoverOpen: null,
    };

    //  this.openModal = this.openModal.bind(this);
    //  this.openModalBlock = this.openModalBlock.bind(this);
    //  this.toggle = this.toggle.bind(this);
    //  this.toggleBlock = this.toggleBlock.bind(this);
    //  this.editUser = this.editUser.bind(this);
    //  this.blockUser = this.blockUser.bind(this);
    //  this.handleChange = this.handleChange.bind(this);

    //  this.handleClick = this.handleClick.bind(this);
    //  this.changeEndDate = this.changeEndDate.bind(this);
    this.handleEditPhoto = this.handleEditPhoto.bind(this);
    this.onCrop = this.onCrop.bind(this);
    this.onBeforeFileLoad = this.onBeforeFileLoad.bind(this);
    this.hiddenFileInput = React.createRef(null);
  }

  componentWillMount() {
    //  const { fetchAllUsers } = this.props;

    //  fetchAllUsers();
  }

  componentDidMount() {
    const { fetchUsersRange, usersCount, fetchNonVerifiedUsers, nonVerifiedStart, searchUid } = this.props;
    const { filter, filterType } = this.state;
    const start = usersCount - 49;
    const end = usersCount;

    if (searchUid) {
      this.filter();
    } else {
      fetchUsersRange(start, end);
    }
    console.log('fetchNonVerifiedUsers 1', nonVerifiedStart);

    if (nonVerifiedStart) {
      fetchNonVerifiedUsers();

      this.setState({
        nonVerified: true
      });
    }

    const minDate = new Date(new Date().getFullYear() - 100, 1, 1, 0, 0, 0);
    const maxDate = new Date();
    this.setState({
      minDate,
      maxDate,
    });

    document.addEventListener('click', this.handleDocumentClick);

  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick);
  }

  componentWillReceiveProps = (nextProps) => {
    const nextFilteredUsers = nextProps.filteredUsers;
    const nextNonVerifiedUsers = nextProps.nonVerifiedUsers;
    const { filteredUsers, nonVerifiedUsers, fetchNonVerifiedUsers, searchUid } = this.props;
    const { filter, filterType } = this.state;
    // const { nonVerified } = this.state;
    if (searchUid && size(nextFilteredUsers) === 0) {
      this.filter(filter, filterType);
    }
    if (filteredUsers && !nextFilteredUsers) {
      this.setState({ currentPage: 0, filteredUsers: null });
    } else if (nextFilteredUsers && filteredUsers !== nextFilteredUsers) {
      console.log(nextFilteredUsers);
      this.setState({
        currentPage: 0,
        filteredUsers: nextFilteredUsers,
      });
    }
    console.log(size(nonVerifiedUsers), size(nextNonVerifiedUsers), "check before check");

    if (nonVerifiedUsers && !nextNonVerifiedUsers) {
      this.setState({ currentPage: 0 });
    } else if (nextNonVerifiedUsers && size(nonVerifiedUsers) !== size(nextNonVerifiedUsers)) {
      console.log(size(nonVerifiedUsers), size(nextNonVerifiedUsers), "check non verified users");
      fetchNonVerifiedUsers(filter, filterType);
    }
  }

  // componentWillUnmount = () => {
  //   const { fetchFilteredUsers, fetchNonVerifiedUsers } = this.props;

  //   fetchFilteredUsers('');
  // //  fetchNonVerifiedUsers('', '');
  // }

  handleDocumentClick = (event) => {
    const { infoPopoverOpen, userSettingsPopoverId } = this.state;
    if (infoPopoverOpen) {
      if ((!event.target.id || !event.target.id.includes('info-button-')) && !(event.target.className.includes('info-popover-body'))) {
        this.setState({infoPopoverOpen: null});
      }
    }

    if (userSettingsPopoverId) {
      if ((!event.target.id || !event.target.id.includes('user-settings-button-')) && !(event.target.className.includes('user-settings-popover-body'))) {
        this.setState({userSettingsPopoverId: null});
      }
    }
  };


  handleChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    this.setState({
      [event.target.name]: value,
    });
  }

  handleChangeChat = (event) => {
    if (event.key !== 'Enter' || (event.key === 'Enter' && event.shiftKey)) {
      const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

      this.setState({
        [event.target.name]: value,
      });
    }
  }

  handleKeyPress = (target) => {
    if (target.charCode === constants.ENTERKEY) {
      this.filter();
    }
  }

  selectType = (e) => {
    this.setState({ filterType: e.target.value });
  }

  changeEndDate = (date) => {
    this.setState({ endDate: date });
  }

  filter = () => {
    const { fetchFilteredUsers, fetchNonVerifiedUsers } = this.props;
    const { filter, filterType, nonVerified } = this.state;
    if (!nonVerified) {
      fetchFilteredUsers(filter, filterType, nonVerified);
    } else {
      this.setState({
        // spinnerLoading: true,
        currentPage: 0,
      });
      fetchNonVerifiedUsers(filter, filterType);

      // if (filter && filterType) {
      //   let filterData = {};
      //   if (filterType === 'lowerCaseName') {
      //     filterData = nonVerifiedUsers.filter(item => item.lowerCaseName && item.lowerCaseName.includes(filter.toLowerCase()));
      //   } else if (filterType === 'lowerCaseLastName') {
      //     filterData = nonVerifiedUsers.filter(item => item.lowerCaseLastName && item.lowerCaseLastName.includes(filter.toLowerCase()));
      //   } else if (filterType === 'uid') {
      //     filterData = nonVerifiedUsers.filter(item => item.key && item.key.includes(filter.toLowerCase()));
      //   } else if (filterType === 'email') {
      //     filterData = nonVerifiedUsers.filter(item => item.email && item.email.includes(filter.toLowerCase()));
      //   }
      //   console.log("filter non verified", filterData);
      //   this.setState({
      //     nonVerifiedUsers: filterData,
      //     spinnerLoading: false,
      //   });
      // } else {
      //   this.setState({
      //     nonVerifiedUsers,
      //     spinnerLoading: false,
      //   });
      // }
    }
  }
  /*
  filterName = () => {
    const { fetchFilteredUsers } = this.props;
    const { userFilter } = this.state;

    fetchFilteredUsers(userFilter);
  } */

  getUserGamesHistory = (userId) => {
    const { fetchUserGamesHistory } = this.props;
    if (userId) {

      fetchUserGamesHistory(userId);

      this.setState({
        gamesHistoryModalOpen: true,
        historyUid: userId,
      });
    }
  }

  getUserBalanceHistory = (userId) => {
    const { fetchUserBalanceHistory } = this.props;
    if (userId) {
      const startAt = Date.now() - (1000 * 60 * 60 * 24 * 7);
      const endAt = Date.now();

      fetchUserBalanceHistory(userId, startAt, endAt);

      this.setState({
        bilanceHistoryModalOpen: true,
        historyUid: userId,
      });
    }
  }

  getUserPointsHistory = (userId) => {
    const { fetchUserPointsHistory } = this.props;
    if (userId) {
      const startAt = Date.now() - (1000 * 60 * 60 * 24 * 14);
      const endAt = Date.now();

      fetchUserPointsHistory(userId, startAt, endAt);

      this.setState({
        pointsHistoryModalOpen: true,
        historyUid: userId,
      });
    }
  }

  closeGamesHistory = () => {
    this.setState({
      gamesHistoryModalOpen: false,
    });
  }

  closeBilanceHistory = () => {
    this.setState({
      bilanceHistoryModalOpen: false,
    });
  }

  closePointsHistory = () => {
    this.setState({
      pointsHistoryModalOpen: false,
    });
  }

  openModal = (uid) => {
    const { allUsers } = this.props;
    const { filteredUsers } = this.state;

    if (filteredUsers) {
      const user = filteredUsers[uid];
      this.setState({
        editUserId: uid,
        firstName: user.firstName || (user.name.split(' ')[0] || ''),
        lastName: user.lastName || (user.name.split(' ')[1] || ''),
        photo: user.photo ? user.photo : defaultImage,
        openModal: true,
        balance: 0,
        gamesPlayed: user.gPlayed,
        level: user.lvl,
        totalPnts: 0,
        updateFlag: 'filtered',
        birthday: user?.birthday ? new Date(user.birthday) : null,
      });
    } else {
      const user = allUsers[uid];
      console.log(user);

      this.setState({
        editUserId: uid,
        firstName: user.firstName || (user.name.split(' ')[0] || ''),
        lastName: user.lastName || (user.name.split(' ')[1] || ''),
        photo: user.photo ? user.photo : defaultImage,
        openModal: true,
        balance: 0,
        gamesPlayed: user.gPlayed,
        level: user.lvl,
        totalPnts: 0,
        updateFlag: 'all',
        birthday: user?.birthday ? new Date(user.birthday) : null,
      });
    }
  }

  openModalBlock = (uid) => {
    this.setState({
      blockUserId: uid,
      openBlockModal: true,
    });
  }

  openChatModal = (uid, name) => {
    const { getUserMessages } = this.props;

    getUserMessages(uid).then(() => {
      //  this.scrollToBottom();
    });

    this.setState({
      openChatModal: true,
      openChatModalName: name,
      openChatModalUid: uid,
      fileName: '',
      fileUploadUrl: '',
      //  uid,
    });
  }

  handleEnter = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      this.submitMessage();
    }
  }

  submitMessage = () => {
    const { answerSupportMessage } = this.props;
    const {
      inputMessage, openChatModalUid, fileUploadUrl, fileName,
    } = this.state;
    if (inputMessage != '' || fileUploadUrl != '') {
      answerSupportMessage({
        uid: openChatModalUid, message: inputMessage, fileUploadUrl, fileName,
      }).then(() => {
        //  this.scrollToBottom();
        this.setState({ inputMessage: '', fileUploadUrl: '', fileName: '' });
      });
    }
  }

  chooseFile = (e) => {
    e.preventDefault();

    const { showNotification, t } = this.props;
    const target = e.target.files[0];
    const typeList = '.jpg,.jpeg,.png,.pdf,.msword,.doc,.rtf,.txt,ain';
    if (!target || (target && target.length === 0)) return;
    if (!typeList.includes((target.type).substring((target.type).length - 3)) || target.type == '') { return showNotification('Error!', "Unsupported file!", 'danger'); }
    if (target.size / 1024 > 1024) {
      showNotification('Warning', "Can't upload more than 1.5 MB", 'warning');
      this.setState({
        fileUploadUrl: '',
        fileName: '',
      });
    } else {
      this.setState({
        ableToSend: false,
      });
      const { supportMessageFileUpload } = this.props;
      supportMessageFileUpload(target).then((data) => {
        this.setState({
          fileUploadUrl: data.url,
          fileName: target.name,
          ableToSend: true,
        });
        showNotification('Success', "File successfully attached!", 'success');
      });
    }
  }

  setSupportMessageAsResponded = () => {
    const { setSupportMessageAsResponded } = this.props;
    const { openChatModalUid } = this.state;

    setSupportMessageAsResponded({ uid: openChatModalUid }).then(() => {
      this.setState({
        openChatModal: false,
        //  uid: '',
      });
    });
  }

  toggleChat = () => {
    const { openChatModal, openChatModalUid } = this.state;

    if (openChatModal) {
      const { cancelUserMessages } = this.props;

      cancelUserMessages(openChatModalUid);
    }

    this.setState(prevState => ({
      openChatModal: !prevState.openChatModal,
      openChatModalUid: '',
    }));
  }

  editUser = () => {
    const { editUser } = this.props;
    const {
      editUserId, balance, level, gamesPlayed, showCrop, totalPnts, firstName, lastName, photo, updateFlag, filter, filterType,
    } = this.state;
    const { showNotification, t } = this.props;

    const name = firstName + " " + lastName;
    const lowerCaseName = name.toLowerCase();
    const lowerCaseLastName = lastName.toLowerCase();

    if ((firstName.trim()).length == 0) {
      showNotification('Error!', "Missing first name", 'warning');
      return;
    }
    if ((lastName.trim()).length == 0) {
      showNotification('Error!', "Missing last name", 'warning');
      return;
    }
    if (!showCrop) {
      editUser(editUserId, balance, level, gamesPlayed, totalPnts, firstName, lastName, photo, updateFlag, filter, filterType, name, lowerCaseName, lowerCaseLastName);
      this.setState({ openModal: false });
    } else {
      showNotification('Error!', "If you don't apply or close changed the avatar, you can't change your profile.", 'warning');
    }
  }

  addBalance = () => {
    const { addBalance } = this.props;
    const { editUserId, balance, filteredUsers } = this.state;

    addBalance(editUserId, balance);
    console.log(filteredUsers, editUserId, "checkEditB");
    this.setState(prevState => ({
      filteredUsers: {
        ...prevState.filteredUsers,
        [editUserId]: {
          ...prevState.filteredUsers[editUserId],
          bal: parseInt(prevState.filteredUsers[editUserId]?.bal, 10) + parseInt(balance, 10),
        }
      }
    }))
    // filter(filteredUsers, )
  }

  addPoints = () => {
    const { addPoints } = this.props;
    const { editUserId, totalPnts } = this.state;

    addPoints(editUserId, totalPnts);
    this.setState(prevState => ({
      filteredUsers: {
        ...prevState.filteredUsers,
        [editUserId]: {
          ...prevState.filteredUsers[editUserId],
          totalPnts: parseInt(prevState.filteredUsers[editUserId]?.totalPnts, 10) + parseInt(totalPnts, 10),
        }
      }
    }))
  }

  blockUser = () => {
    const { blockUser } = this.props;
    const {
      blockUserId, reason, endDate,
    } = this.state;

    blockUser(blockUserId, endDate, reason);
    this.setState({ openBlockModal: false });
  }

  unblockUser = (userId) => {
    const { unblockUser } = this.props;

    unblockUser(userId);
  }

  toggle = () => {
    console.log('close modal');
    this.setState(prevState => ({
      openModal: !prevState.openModal,
    }));
    this.setState({
      preview: null,
      showCrop: false,
    });
  }

  toggleBlock = () => {
    this.setState(prevState => ({
      openBlockModal: !prevState.openBlockModal,
    }));
  }

  toggleDelete = () => {
    this.setState(prevState => ({
      openDeleteConfirmation: !prevState.openDeleteConfirmation,
      deleteUserId: null,
    }));
  }

  openDeleteModal = (uid) => {
    this.setState({
      deleteUserId: uid,
      openDeleteConfirmation: true,
    });
  }

  deleteUser = () => {
    const { deleteUser } = this.props;
    const { deleteUserId } = this.state;

    console.log('deleteUserId');
    console.log(deleteUserId);
    if (deleteUserId) {
      deleteUser(deleteUserId);
      this.setState({
        deleteUserId: null,
        openDeleteConfirmation: false,
      });
    }
  }

  swapToUser = (userId, userNewDesign, userScreenMode, userLanguage) => {
    const { swapToUser, toggleNewDesign, newDesign, screenMode, toggleScreenMode, i18n } = this.props;
    const curLanguage = i18n.language;

    console.log('Allusers swapToUser userId', userId, userNewDesign, userScreenMode, newDesign, screenMode, curLanguage, userLanguage);


    if (userId) {
      // Set newDesign and screenMode to the users values before swaping to his account
      toggleNewDesign(userNewDesign, true);
      toggleScreenMode(userScreenMode);
      if (userLanguage === 'lv' || userLanguage === 'en' || userLanguage === 'ru') {
        i18n.changeLanguage(userLanguage);
      }

      // Swap user and pass admin's newDesign, screenMode to be saved for returning
      swapToUser(userId, newDesign, screenMode, curLanguage);
      this.setState({
        swapToUserId: null,
      });
    }
  }

  toggleVerify = () => {
    this.setState(prevState => ({
      openManualVerifyConfirmation: !prevState.openManualVerifyConfirmation,
      verifyUserId: null,
    }));
  }

  openVerifyModal = (uid) => {
    this.setState({
      verifyUserId: uid,
      openManualVerifyConfirmation: true,
    });
  }

  verifyUser = () => {
    const { manualyVerifyUser } = this.props;
    const { verifyUserId } = this.state;

    if (verifyUserId) {
      manualyVerifyUser(verifyUserId, 'lv');
      this.setState({
        verifyUserId: null,
        openManualVerifyConfirmation: false,
      });
    }
  }

  handleClick = (e, index) => {
    e.preventDefault();
    const { fetchUsersRange, usersCount } = this.props;
    const { nonVerified } = this.state;
    //  const { fetchAllUsers, allUsersLastKey } = this.props;
    //  fetchAllUsers(index, allUsersLastKey);
    //  const start = (50 * index) + 1;
    //  const end = (50 * index) + 50;

    const start = usersCount - (50 * index) - 49;
    const end = usersCount - (50 * index);

    fetchUsersRange(start, end, nonVerified);

    this.setState({
      currentPage: index,
    });
  }

  handleFilterClick = (e, index) => {
    e.preventDefault();

    this.setState({
      currentPage: index,
    });
  }


  openAchievementsModal = (uid, name) => {
    const { fetchUserAchievements } = this.props;

    fetchUserAchievements(uid);

    this.setState({
      openAchievementsModal: true,
      openAchievementsModalName: name,
      openAchievementsModalUid: uid,
    });
  }

  closeAchievementsModal = () => {
    this.setState(prevState => ({
      openAchievementsModal: false,
      openAchievementsModalName: null,
      openAchievementsModalUid: null,
    }));
  }

  onCrop = (preview) => {
    this.setState({ preview });
  }

  handleEditPhoto = (value) => {
    this.setState({ showCrop: !value });
  }

  uploadAvatarFile = () => {
    const { updateAvatarFileUpload } = this.props;
    const { preview, editUserId } = this.state;
    this.setState({ ableToSave: false });
    if (preview !== null) {
      updateAvatarFileUpload(preview, editUserId).then((data) => {
        console.log('value@@@@@@@@@@@@@@@@', data);
        this.setState({
          photo: data.url,
          ableToSave: true,
        });
      });
    }
  }

  onBeforeFileLoad = (elem) => {
    try {
      const { showNotification, t } = this.props;

      console.log(elem.target.files[0].type);
      const typeList = 'image/jpg,image/png,image/jpeg, image/pjp, image/pjpeg, image/jfif';
      if (!typeList.includes(elem.target.files[0].type) || elem.target.files[0].type == '') {
        showNotification('Error!', "Unsupported File!", 'danger');
        return elem.target.value = '';
      }

      if (elem.target.files[0].size / 1024 > 3072) {
        showNotification('Warning', "Can't upload more than 1.5 MB", 'warning');
        elem.target.value = '';
      }
    } catch (e) {
      console.log('catch error', e.message);
      return elem.target.value = '';
    }
  }

  _handleFileClick = () => {
    this.hiddenFileInput.current.click();
  };

  _handleNonVerified = (verified) => {
    const { fetchNonVerifiedUsers } = this.props;
    const { nonVerified, filter, filterType } = this.state;
    if (nonVerified !== verified) {
      this.setState({
        filter: '',
        currentPage: 0,
        nonVerified: verified,
      });
      if (verified) {
        fetchNonVerifiedUsers(filter, filterType);
      }
    }
  }

  selectLanguageFlag = (lang) => {
    let img;
    img = require(`../../../images/flags/${lang}.png`);
    return img;
  }

  changeBirthday = (event) => {
    console.log('changeBirthday event', event);
    this.setState({ birthday: event });
  }

  handleEditUserDob = () => {
    const { editUserId, birthday, filter, filterType, nonVerified } = this.state;
    const { submitUserDobFuc, fetchFilteredUsers, showNotification, t } = this.props;
    submitUserDobFuc(editUserId, birthday).then((res) => {
      console.log(res, "editUserDobStatus")
      if (res.status === constants.FROUNT_SIDE_STATUS.success) {
        return showNotification('Success!', "User's birthday has been successfully updated", 'success');
      }
      return showNotification('Error!', "User's birthday has been not successfully updated", 'danger');
    }).catch((err) => {
      console.log(err);
      return showNotification('Error!', "User's birthday has been not successfully updated", 'danger');
    });
    fetchFilteredUsers(filter, filterType, nonVerified);
  }

  openSettingsPopover = (userId) => {
    const { fetchUserSettings, fetchAutoPlayOtherUser } = this.props;
    const { userSettingsPopoverId } = this.state;

    this.setState({ userSettings: null, disabledAutoPlay: null });

    if (userSettingsPopoverId === userId) {
      this.setState({ userSettingsPopoverId: null, userSettings: null, disabledAutoPlay: null });
    } else {
      fetchUserSettings(userId).then((userSettings) => {
        fetchAutoPlayOtherUser(userId).then((disabledAutoPlay) => {
          this.setState({ userSettingsPopoverId: userId, userSettings: userSettings || null, disabledAutoPlay});
        });
      });
    }
  }

  toggleInfoPopover = (key) => {
    const { fetchUserLastIp } = this.props;
    const { infoPopoverOpen } = this.state;

    if (!infoPopoverOpen || infoPopoverOpen !== key) {
      fetchUserLastIp(key);
      this.setState({infoPopoverOpen: key})
    } else {
      this.setState({infoPopoverOpen: null})
    }
  }

  table = () => {
    const {
      t,
      allUsers,
      //  deleteUser,
      usersCount,
      toggleLogRocket,
      toggleSmartlook,
      resetActiveRoom,
      fetchNonVerifiedUsers,
      nonVerifiedUsers,
      lastIpData,
    } = this.props;
    const {
      currentPage,
      pageSize,
      filteredUsers,
      nonVerified,
      spinnerLoading,
      infoPopoverOpen,
      // nonVerifiedUsers,
      userSettingsPopoverId,
      userSettings,
      disabledAutoPlay,
      showHint,
    } = this.state;

    console.log('')

    if (!allUsers) {
      return null;
    }

    console.log('nonVerified', nonVerified, Object.keys(nonVerifiedUsers).length);

    if (nonVerified) {
      if (spinnerLoading) {
        return (
          <Spinner type="grow" color="warning" style={{ width: '2rem', height: '2rem' }} />
        );
      }
      if (nonVerifiedUsers && Object.keys(nonVerifiedUsers).length > 0) {
        const pagesCount = Math.ceil(Object.keys(nonVerifiedUsers).length / pageSize);
        return (
          <NonVerifiedUsers
            t={t}
            nonVerifiedUsers={nonVerifiedUsers}
            pagesCount={pagesCount}
            currentPage={currentPage}
            pageSize={pageSize}
            handelClick={this.handleFilterClick}
            selectLanguageFlag={this.selectLanguageFlag}
            openVerifyModal={this.openVerifyModal}
            openDeleteModal={this.openDeleteModal}
            fetchFilteredUsers={fetchNonVerifiedUsers}
          />
        );
      }
    } else {
      if (filteredUsers) {
        const pagesCount = Math.ceil(Object.keys(filteredUsers).length / pageSize);
        return (
          <>
            <table className="textAlign players-table">
              <thead>
                <tr>
                  <th>
                    <div>Name</div>
                  </th>
                  <th>
                    Last Login
                  </th>
                  <th className="allUsers-table-col">
                    Birthday
                  </th>
                  <th className="allUsers-table-col">
                    Registration Method
                  </th>
                  <th>
                    Date Created
                  </th>
                  <th className="allUsers-table-col">
                    Level
                  </th>
                  <th className="allUsers-table-col">
                    Balance
                  </th>
                  <th className="allUsers-table-col">
                    Points
                  </th>
                  <th className="allUsers-table-col">
                    Played games
                  </th>
                  <th className="allUsers-table-col">
                    Info
                  </th>
                  <th className="allUsers-table-col">
                    User Settings
                  </th>
                  <th className="allUsers-table-col">
                    Results
                  </th>
                  <th className="allUsers-table-col">
                    Balance history
                  </th>
                  <th className="allUsers-table-col">
                    Points History
                  </th>
                  <th className="allUsers-table-col">
                    Edit
                  </th>
                  <th className="allUsers-table-col">
                    Logrocket
                  </th>
                  {/* <th className="allUsers-table-col">
                    Smartlook
                  </th> */}
                  <th className="allUsers-table-col">
                    Send message
                  </th>
                  <th className="allUsers-table-col">
                    Data
                  </th>
                  <th className="allUsers-table-col">
                    Reset Room
                  </th>
                  <th className="allUsers-table-col">
                    Block
                  </th>
                  <th className="allUsers-table-col">
                    Delete
                  </th>
                  <th className="allUsers-table-col">
                    Swap To User
                  </th>
                  <th className="allUsers-table-col">
                    Verified
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers && Object.keys(filteredUsers)
                  .sort((a, b) => filteredUsers[b].lastLogin - filteredUsers[a].lastLogin)
                  .slice(
                    currentPage * pageSize,
                    (currentPage + 1) * pageSize,
                  )
                  .map((key, index) => (

                    <tr key={key} className={classNames({ 'allUsers-table-row odd': (index % 2 === 0), 'allUsers-table-row even': index % 2 !== 0 })}>
                      <td colSpan={1} className="allUsers-table-col" style={{ maxWidth: '150px' }}>
                        <div>{`${filteredUsers[key].name ? filteredUsers[key].name : (filteredUsers[key].firstName && filteredUsers[key].lastName ? (filteredUsers[key].firstName + ' ' + filteredUsers[key].lastName) : filteredUsers[key].firstName ? filteredUsers[key].firstName : filteredUsers[key].lastName ? filteredUsers[key].lastName : '')}`}</div>
                      </td>
                      <td className="allUsers-table-col">
                        <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateMinute} date={filteredUsers[key].lastLogin} />
                      </td>
                      <td className="allUsers-table-col">
                        {
                          filteredUsers[key]?.birthday && filteredUsers[key]?.birthday !== undefined ? (
                            <DateFormat formatType={constants.FORMAT_DATE_TYPE.slashFullDate} date={filteredUsers[key]?.birthday} />
                          ) : (
                            '-'
                          )
                        }
                      </td>
                      <td className="allUsers-table-col">
                        {filteredUsers[key].socProvider}
                      </td>
                      <td className="allUsers-table-col">
                        {
                          filteredUsers[key]?.dateCreated && filteredUsers[key]?.dateCreated !== undefined ? (
                            <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateMinute} date={filteredUsers[key].dateCreated} />
                          ) : ('Long time ago')
                        }
                      </td>
                      <td className="allUsers-table-col">
                        {filteredUsers[key].lvl}
                      </td>
                      <td className="allUsers-table-col">
                        {`${filteredUsers[key].bal}€`}
                      </td>
                      <td className="allUsers-table-col">
                        {filteredUsers[key].totalPnts}
                      </td>
                      <td className="allUsers-table-col">
                        {filteredUsers[key].gPlayed}
                      </td>
                      <td className="allUsers-table-col">
                        <Button id={`info-button-${key}`} type="button" color="primary" style={{ fontSize: 10 }} onClick={() => this.toggleInfoPopover(key)/*fetchUserLastIp(key)*/}>
                          Info
                        </Button>
                        <Popover isOpen={infoPopoverOpen === key} popperClassName={`popover`} placement="top" target={`info-button-${key}`}>
                          <PopoverBody className="popover-body info-popover-body">
                            <b className="info-popover-body-item">E-mail:</b><br />
                            {filteredUsers[key].email || '-'}<br />
                            <b className="info-popover-body-item">Contact E-mail:</b><br />
                            {filteredUsers[key].contactEmail || '-'}<br />
                            <b className="info-popover-body-item">Social ID:</b><br />
                            {filteredUsers[key].socId || '-'}<br />
                            <b className="info-popover-body-item">Player ID:</b><br />
                            {key}<br />
                            <b className="info-popover-body-item">Last IP address:</b><br />
                            {(lastIpData && lastIpData.userUid && lastIpData.userUid === key) ? lastIpData.IP : '-'}<br />
                          </PopoverBody>
                        </Popover>
                      </td>
                      {/* <td className="allUsers-table-col">
                        {filteredUsers[key].socProvider === 'password' ? (filteredUsers[key].verifiedEmail === true ? 'Yes' : 'No') : '-'}
                      </td> */}
                      {/*  <td className="allUsers-table-col col-img">
                        {
                          (filteredUsers[key].lastLanguage && filteredUsers[key].lastLanguage != null) ? (
                            <Media
                              className="language-flag"
                              src={this.selectLanguageFlag(filteredUsers[key].lastLanguage)}
                            />
                          ) : ('')
                        }
                      </td> */}
                      <td className="allUsers-table-col col-img">
                        <Button id={`user-settings-button-${key}`} type="button" color="primary" style={{ fontSize: 10 }} onClick={() => this.openSettingsPopover(key)}>
                          View settings
                        </Button>
                        <Popover
                          isOpen={userSettingsPopoverId === key}
                          popperClassName={`popover`}
                          placement="bottom"
                          trigger="focus"
                          target={`user-settings-button-${key}`}
                          // toggle={() => { this.openSettingsPopover(key) }}
                        >
                          <PopoverBody className="popover-body user-settings-popover-body">
                            <div className="user-settings-popover-body-item">
                              {`Font mode - ${filteredUsers[key].fontMode || 'normal'}`}
                            </div>
                            <div className="user-settings-popover-body-item">
                              <span className="user-settings-popover-body-item">{`Language - `}</span>
                              {(filteredUsers[key].lastLanguage && filteredUsers[key].lastLanguage != null) ? (
                                <Media
                                  className="language-flag user-settings-popover-body-item"
                                  style={{ display: 'inline-block' }}
                                  src={this.selectLanguageFlag(filteredUsers[key].lastLanguage)}
                                />
                              ) : (
                                <Media
                                  className="language-flag user-settings-popover-body-item"
                                  style={{ display: 'inline-block' }}
                                  src={this.selectLanguageFlag('lv')}
                                />
                              )}
                            </div>
                            <div className="user-settings-popover-body-item">
                              {`Design - ${filteredUsers[key].newDesign ? 'New design' : 'Old design'}`}
                            </div>
                            <div className="user-settings-popover-body-item">
                              {`Theme - ${filteredUsers[key].screenMode || 'normal'}`}
                            </div>
                            {(userSettings && userSettings.userSettings) ? (
                              <div className="user-settings-popover-body-item">
                                {`Sound - ${userSettings.userSettings.soundOn ? 'On' : 'Off'}`}
                              </div>
                            ) : (
                              <div className="user-settings-popover-body-item">
                                {`Sound - On`}
                              </div>
                            )}
                            {(userSettings && userSettings.notificationSettings) ? (
                              <>
                                <div className="user-settings-popover-body-item" style={{ borderTop: '1px solid white' }}>
                                  {`Game setings`}
                                </div>
                                <div className="user-settings-popover-body-item">
                                  {`leaving room before end - ${userSettings.notificationSettings.leaveRoom ? 'true' : 'false'}`}
                                </div>
                                <div className="user-settings-popover-body-item">
                                  {`choosing 'Last round' - ${userSettings.notificationSettings.lastRound ? 'true' : 'false'}`}
                                </div>
                                <div className="user-settings-popover-body-item">
                                  {`choosing 'Fold' - ${userSettings.notificationSettings.quitRound ? 'true' : 'false'}`}
                                </div>
                                <div className="user-settings-popover-body-item">
                                  {`ignoring player - ${userSettings.notificationSettings.ignorePlayer ? 'true' : 'false'}`}
                                </div>
                                <div className="user-settings-popover-body-item">
                                  {`leaving game account - ${userSettings.notificationSettings.quitAccount ? 'true' : 'false'}`}
                                </div>
                                <div className="user-settings-popover-body-item">
                                  {`purchasing gift - ${userSettings.notificationSettings.purchaseGift ? 'true' : 'false'}`}
                                </div>
                                <div className="user-settings-popover-body-item">
                                  {`removing gift from profile - ${userSettings.notificationSettings.removeGift ? 'true' : 'false'}`}
                                </div>
                                <div className="user-settings-popover-body-item">
                                  {`Show results after each round - ${userSettings.notificationSettings.showAfterRound ? 'true' : 'false'}`}
                                </div>
                                <div className="user-settings-popover-body-item">
                                  {`Show results at the end of room - ${userSettings.notificationSettings.showAtRoomEnd ? 'true' : 'false'}`}
                                </div>
                                <div className="user-settings-popover-body-item">
                                  {`Do not show any confirmation window - ${(!userSettings.notificationSettings.leaveRoom && !userSettings.notificationSettings.lastRound && !userSettings.notificationSettings.quitRound && !userSettings.notificationSettings.ignorePlayer && !userSettings.notificationSettings.quitAccount && !userSettings.notificationSettings.purchaseGift && !userSettings.notificationSettings.removeGift) ? 'true' : 'false'}`}
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="user-settings-popover-body-item" style={{ borderTop: '1px solid white' }}>
                                  {`Game setings`}
                                </div>
                                <div className="user-settings-popover-body-item">
                                  {`leaving room before end - true`}
                                </div>
                                <div className="user-settings-popover-body-item">
                                  {`choosing 'Last round' - true`}
                                </div>
                                <div className="user-settings-popover-body-item">
                                  {`choosing 'Fold' - true`}
                                </div>
                                <div className="user-settings-popover-body-item">
                                  {`ignoring player - true`}
                                </div>
                                <div className="user-settings-popover-body-item">
                                  {`leaving game account - true`}
                                </div>
                                <div className="user-settings-popover-body-item">
                                  {`purchasing gift - true`}
                                </div>
                                <div className="user-settings-popover-body-item">
                                  {`removing gift from profile - true`}
                                </div>
                                <div className="user-settings-popover-body-item">
                                  {`Show results after each round - true`}
                                </div>
                                <div className="user-settings-popover-body-item">
                                  {`Show results at the end of room - true`}
                                </div>
                                <div className="user-settings-popover-body-item">
                                  {`Do not show any confirmation window - false`}
                                </div>
                              </>
                            )}
                            <div className="user-settings-popover-body-item">
                              {`Auto play - ${!disabledAutoPlay ? 'true' : 'false'}`}
                            </div>
                            <div className="user-settings-popover-body-item">
                              {`Show hint - ${!filteredUsers[key].showHint ? 'true' : 'false'}`}
                            </div>
                            {(userSettings && userSettings.gameSettings) ? (
                              <div className="user-settings-popover-body-item">
                                {`Card play with one click - ${!userSettings.gameSettings.doubleClickPlay ? 'true' : 'false'}`}
                              </div>
                            ) : (
                              <div className="user-settings-popover-body-item">
                                {`Card play with one click - true`}
                              </div>
                            )}

                          </PopoverBody>
                        </Popover>
                      </td>
                      <td className="allUsers-table-col">
                        <Button color="primary" style={{ fontSize: 10 }} onClick={() => this.getUserGamesHistory(key)}>
                          Results
                        </Button>
                      </td>
                      <td className="allUsers-table-col">
                        <Button color="primary" style={{ fontSize: 10 }} onClick={() => this.getUserBalanceHistory(key)}>
                          Balance History
                        </Button>
                      </td>
                      <td className="allUsers-table-col">
                        <Button color="primary" style={{ fontSize: 10 }} onClick={() => this.getUserPointsHistory(key)}>
                          Points History
                        </Button>
                      </td>
                      <td className="allUsers-table-col">
                        <Button color="primary" onClick={() => this.openModal(key)}>
                          Edit
                        </Button>
                      </td>
                      <td className="allUsers-table-col">
                        <Button color={filteredUsers[key].enableLogRocket ? 'success' : 'warning'} onClick={() => toggleLogRocket(key)}>
                          Logrocket
                        </Button>
                      </td>
                      {/* <td className="allUsers-table-col">
                        <Button color={filteredUsers[key].enableSmartlook ? 'success' : 'warning'} onClick={() => toggleSmartlook(key)}>
                          Smartlook
                        </Button>
                      </td> */}

                      <td className="allUsers-table-col">
                        <Button color="primary" onClick={() => this.openChatModal(key, filteredUsers[key].name)}>
                          Message
                        </Button>
                      </td>

                      <td className="allUsers-table-col">
                        <Button color="primary" onClick={() => this.openAchievementsModal(key, filteredUsers[key].name)}>
                          Data
                        </Button>
                      </td>

                      <td className="allUsers-table-col">
                        <Button color="primary" onClick={() => resetActiveRoom(key)}>
                          Reset
                        </Button>
                      </td>
                      <td className="allUsers-table-col">
                        {filteredUsers[key].blocked ? (
                          <Button color="primary" onClick={() => this.unblockUser(key)}>
                            Unblock
                          </Button>
                        ) : (
                          <Button color="primary" onClick={() => this.openModalBlock(key)}>
                            Block
                          </Button>
                        )}
                      </td>
                      <td className="allUsers-table-col">
                        <Button color="danger" onClick={() => this.openDeleteModal(key)}>
                          Delete
                        </Button>
                      </td>
                      <td className="allUsers-table-col">
                        <Button color="danger" onClick={() => this.swapToUser(key, filteredUsers[key].newDesign, filteredUsers[key].screenMode, filteredUsers[key].lastLanguage)}>
                          Swap To User
                        </Button>
                      </td>
                      <td className="allUsers-table-col">
                        {filteredUsers[key].socProvider === 'password'
                          ? (filteredUsers[key].verifiedEmail === true ? 'Yes' : (<Button color="danger" onClick={() => this.openVerifyModal(key)}>Verify</Button>))
                          : ((!filteredUsers[key].contactEmail || filteredUsers[key].verifiedEmail === true) ? 'Yes' : <Button color="danger" onClick={() => this.openVerifyModal(key)}>Verify</Button>)
                        }
                      </td>
                    </tr>

                  ))}
              </tbody>
            </table>

            {pagesCount && Object.keys(filteredUsers).length > pageSize && (
              <div className="pagination-wrapper">
                <Pagination aria-label="Page navigation example">
                  <PaginationItem disabled={currentPage <= 0}>
                    <PaginationLink
                      style={{ color: '#000' }}
                      onClick={e => this.handleClick(e, currentPage - 1)}
                      previous
                      href="#"
                    />
                  </PaginationItem>

                  <PaginationItem disabled={currentPage === 0}>
                    <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, 0)} href="#">
                      1
                    </PaginationLink>
                  </PaginationItem>

                  {[...Array(pagesCount)].map((page, i) => {
                    if (i > currentPage - 3 && i < currentPage + 3) {
                      return (
                        <PaginationItem active={i === currentPage} key={page}>
                          <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, i)} href="#">
                            {i + 1}
                          </PaginationLink>
                        </PaginationItem>
                      );
                    }

                    return null;
                  })}

                  {currentPage < (pagesCount - 3) && (
                    <PaginationItem disabled={currentPage >= pagesCount - 1}>
                      <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, pagesCount - 1)} href="#">
                        {pagesCount}
                      </PaginationLink>
                    </PaginationItem>
                  )}

                  <PaginationItem disabled={currentPage >= pagesCount - 1}>
                    <PaginationLink
                      style={{ color: '#000' }}
                      onClick={e => this.handleClick(e, currentPage + 1)}
                      next
                      href="#"
                    />
                  </PaginationItem>
                </Pagination>
              </div>
            )}
          </>
        );
      }
      const pagesCount = Math.ceil(usersCount / pageSize);
      console.log("filteredUser", filteredUsers, allUsers);
      return (
        <>
          <table className="textAlign players-table">
            <thead>
              <tr>
                <th>
                  <div>Name</div>
                </th>
                <th>
                  Last Login
                </th>
                <th className="allUsers-table-col">
                  Birthday
                </th>
                <th className="allUsers-table-col">
                  Registration Method
                </th>
                <th>
                  Date Created
                </th>
                <th className="allUsers-table-col">
                  Level
                </th>
                <th className="allUsers-table-col">
                  Balance
                </th>
                <th className="allUsers-table-col">
                  Points
                </th>
                <th className="allUsers-table-col">
                  Played games
                </th>
                <th className="allUsers-table-col">
                  Info
                </th>
                {/* <th className="allUsers-table-col">
                  Last Language
                </th> */}
                <th className="allUsers-table-col">
                  User Settings
                </th>
                <th className="allUsers-table-col">
                  Results
                </th>
                <th className="allUsers-table-col">
                  Balance History
                </th>
                <th className="allUsers-table-col">
                  Points History
                </th>
                <th className="allUsers-table-col">
                  Edit
                </th>
                <th className="allUsers-table-col">
                  Logrocket
                </th>
                {/* <th className="allUsers-table-col">
                  Smartlook
                </th> */}
                <th className="allUsers-table-col">
                  Send Message
                </th>
                <th className="allUsers-table-col">
                  Achievements
                </th>
                <th className="allUsers-table-col">
                  Reset Room
                </th>
                <th className="allUsers-table-col">
                  Block
                </th>
                <th className="allUsers-table-col">
                  Delete
                </th>
                <th className="allUsers-table-col">
                  Swap to User
                </th>
                <th className="allUsers-table-col">
                  Verified
                </th>
              </tr>
            </thead>
            <tbody>
              {allUsers && Object.keys(allUsers)
                .sort((a, b) => allUsers[b]?.lastLogin - allUsers[a]?.lastLogin)
                .map((key, index) => (

                  <tr key={key} className={`allUsers-table-row ${index % 2 === 0 ? ('odd') : ('even')}`}>
                    <td colSpan={1} className="allUsers-table-col" style={{ maxWidth: '250px' }}>
                      <div>{`${allUsers[key].name ? allUsers[key].name : (allUsers[key].firstName && allUsers[key].lastName ? (allUsers[key].firstName + ' ' + allUsers[key].lastName) : allUsers[key].firstName ? allUsers[key].firstName : allUsers[key].lastName ? allUsers[key].lastName : '')}`}</div>
                    </td>
                    <td className="allUsers-table-col">
                      <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateMinute} date={allUsers[key].lastLogin} />
                    </td>
                    <td className="allUsers-table-col">
                      {
                        allUsers[key]?.birthday && allUsers[key]?.birthday !== undefined ? (
                          <DateFormat formatType={constants.FORMAT_DATE_TYPE.slashFullDate} date={allUsers[key]?.birthday} />
                        ) : (
                          '-'
                        )
                      }
                    </td>
                    <td className="allUsers-table-col">
                      {allUsers[key].socProvider}
                    </td>
                    <td className="allUsers-table-col">
                      {
                        allUsers[key]?.dateCreated && allUsers[key]?.dateCreated !== undefined ? (
                          <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateMinute} date={allUsers[key].dateCreated} />
                        ) : ('Long time ago')
                      }
                    </td>
                    <td className="allUsers-table-col">
                      {allUsers[key].lvl}
                    </td>
                    <td className="allUsers-table-col">
                      {`${allUsers[key].bal}€`}
                    </td>
                    <td className="allUsers-table-col">
                      {allUsers[key].totalPnts}
                    </td>
                    <td className="allUsers-table-col">
                      {allUsers[key].gPlayed}
                    </td>
                    <td className="allUsers-table-col">
                      <Button id={`info-button-${key}`} type="button" color="primary" style={{ fontSize: 10 }} onClick={() => this.toggleInfoPopover(key)/*fetchUserLastIp(key)*/}>
                        Info
                      </Button>
                      <Popover isOpen={infoPopoverOpen === key} popperClassName={`popover`} placement="top" target={`info-button-${key}`}>
                        <PopoverBody className="popover-body info-popover-body">
                          <b className="info-popover-body-item">E-mail:</b><br />
                          {allUsers[key].email || '-'}<br />
                          <b className="info-popover-body-item">Contact E-mail:</b><br />
                          {allUsers[key].contactEmail || '-'}<br />
                          <b className="info-popover-body-item">Social ID:</b><br />
                          {allUsers[key].socId || '-'}<br />
                          <b className="info-popover-body-item">Player ID:</b><br />
                          {key}<br />
                          <b className="info-popover-body-item">Last IP address:</b><br />
                          {(lastIpData && lastIpData.userUid && lastIpData.userUid === key) ? lastIpData.IP : '-'}<br />
                        </PopoverBody>
                      </Popover>
                    </td>
                    {/* <td className="allUsers-table-col col-img">
                      {(allUsers[key].lastLanguage && allUsers[key].lastLanguage != null) ? (
                          <Media
                            className="language-flag"
                            src={this.selectLanguageFlag(allUsers[key].lastLanguage)}
                          />
                        ) : ('')}
                    </td> */}
                    <td className="allUsers-table-col col-img">
                      <Button id={`user-settings-button-${key}`} type="button" color="primary" style={{ fontSize: 10 }} onClick={() => this.openSettingsPopover(key)}>
                        View settings
                      </Button>
                      <Popover
                        isOpen={userSettingsPopoverId === key}
                        popperClassName={`popover`}
                        placement="bottom"
                        trigger="focus"
                        target={`user-settings-button-${key}`}
                        // toggle={() => { this.openSettingsPopover(key) }}
                      >
                        <PopoverBody className="popover-body user-settings-popover-body">
                          <div className="user-settings-popover-body-item">
                            {`Font mode - ${allUsers[key].fontMode || 'normal'}`}
                          </div>
                          <div className="user-settings-popover-body-item">
                            <span className="user-settings-popover-body-item">{`Language - `}</span>
                            {(allUsers[key].lastLanguage && allUsers[key].lastLanguage != null) ? (
                              <Media
                                className="language-flag user-settings-popover-body-item"
                                style={{ display: 'inline-block' }}
                                src={this.selectLanguageFlag(allUsers[key].lastLanguage)}
                              />
                            ) : (
                              <Media
                                className="language-flag user-settings-popover-body-item"
                                style={{ display: 'inline-block' }}
                                src={this.selectLanguageFlag('lv')}
                              />
                            )}
                          </div>
                          <div className="user-settings-popover-body-item">
                            {`Design - ${allUsers[key].newDesign ? 'New design' : 'Old design'}`}
                          </div>
                          <div className="user-settings-popover-body-item">
                            {`Theme - ${allUsers[key].screenMode || 'normal'}`}
                          </div>
                          {(userSettings && userSettings.userSettings) ? (
                            <div className="user-settings-popover-body-item">
                              {`Sound - ${userSettings.userSettings.soundOn ? 'On' : 'Off'}`}
                            </div>
                          ) : (
                            <div className="user-settings-popover-body-item">
                              {`Sound - On`}
                            </div>
                          )}
                          {(userSettings && userSettings.notificationSettings) ? (
                            <>
                              <div className="user-settings-popover-body-item" style={{ borderTop: '1px solid white' }}>
                                {`Game setings`}
                              </div>
                              <div className="user-settings-popover-body-item">
                                {`leaving room before end - ${userSettings.notificationSettings.leaveRoom ? 'true' : 'false'}`}
                              </div>
                              <div className="user-settings-popover-body-item">
                                {`choosing 'Last round' - ${userSettings.notificationSettings.lastRound ? 'true' : 'false'}`}
                              </div>
                              <div className="user-settings-popover-body-item">
                                {`choosing 'Fold' - ${userSettings.notificationSettings.quitRound ? 'true' : 'false'}`}
                              </div>
                              <div className="user-settings-popover-body-item">
                                {`ignoring player - ${userSettings.notificationSettings.ignorePlayer ? 'true' : 'false'}`}
                              </div>
                              <div className="user-settings-popover-body-item">
                                {`leaving game account - ${userSettings.notificationSettings.quitAccount ? 'true' : 'false'}`}
                              </div>
                              <div className="user-settings-popover-body-item">
                                {`purchasing gift - ${userSettings.notificationSettings.purchaseGift ? 'true' : 'false'}`}
                              </div>
                              <div className="user-settings-popover-body-item">
                                {`removing gift from profile - ${userSettings.notificationSettings.removeGift ? 'true' : 'false'}`}
                              </div>
                              <div className="user-settings-popover-body-item">
                                {`Show results after each round - ${userSettings.notificationSettings.showAfterRound ? 'true' : 'false'}`}
                              </div>
                              <div className="user-settings-popover-body-item">
                                {`Show results at the end of room - ${userSettings.notificationSettings.showAtRoomEnd ? 'true' : 'false'}`}
                              </div>
                              <div className="user-settings-popover-body-item">
                                {`Do not show any confirmation window - ${(!userSettings.notificationSettings.leaveRoom && !userSettings.notificationSettings.lastRound && !userSettings.notificationSettings.quitRound && !userSettings.notificationSettings.ignorePlayer && !userSettings.notificationSettings.quitAccount && !userSettings.notificationSettings.purchaseGift && !userSettings.notificationSettings.removeGift) ? 'true' : 'false'}`}
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="user-settings-popover-body-item" style={{ borderTop: '1px solid white' }}>
                                {`Game setings`}
                              </div>
                              <div className="user-settings-popover-body-item">
                                {`leaving room before end - true`}
                              </div>
                              <div className="user-settings-popover-body-item">
                                {`choosing 'Last round' - true`}
                              </div>
                              <div className="user-settings-popover-body-item">
                                {`choosing 'Fold' - true`}
                              </div>
                              <div className="user-settings-popover-body-item">
                                {`ignoring player - true`}
                              </div>
                              <div className="user-settings-popover-body-item">
                                {`leaving game account - true`}
                              </div>
                              <div className="user-settings-popover-body-item">
                                {`purchasing gift - true`}
                              </div>
                              <div className="user-settings-popover-body-item">
                                {`removing gift from profile - true`}
                              </div>
                              <div className="user-settings-popover-body-item">
                                {`Show results after each round - true`}
                              </div>
                              <div className="user-settings-popover-body-item">
                                {`Show results at the end of room - true`}
                              </div>
                              <div className="user-settings-popover-body-item">
                                {`Do not show any confirmation window - false`}
                              </div>
                            </>
                          )}
                          <div className="user-settings-popover-body-item">
                            {`Auto play - ${!disabledAutoPlay ? 'true' : 'false'}`}
                          </div>
                          <div className="user-settings-popover-body-item">
                            {`Show hint - ${!allUsers[key].showHint ? 'true' : 'false'}`}
                          </div>
                          {(userSettings && userSettings.gameSettings) ? (
                            <div className="user-settings-popover-body-item">
                              {`Card play with one click - ${!userSettings.gameSettings.doubleClickPlay ? 'true' : 'false'}`}
                            </div>
                          ) : (
                            <div className="user-settings-popover-body-item">
                              {`Card play with one click - true`}
                            </div>
                          )}
                        </PopoverBody>
                      </Popover>
                    </td>
                    <td className="allUsers-table-col">
                      <Button color="primary" style={{ fontSize: 10 }} onClick={() => this.getUserGamesHistory(key)}>
                        Results
                      </Button>
                    </td>
                    <td className="allUsers-table-col">
                      <Button color="primary" style={{ fontSize: 10 }} onClick={() => this.getUserBalanceHistory(key)}>
                        Balance History
                      </Button>
                    </td>
                    <td className="allUsers-table-col">
                      <Button color="primary" style={{ fontSize: 10 }} onClick={() => this.getUserPointsHistory(key)}>
                        Points History
                      </Button>
                    </td>
                    <td className="allUsers-table-col">
                      <Button color="primary" onClick={() => this.openModal(key)}>
                        Edit
                      </Button>
                    </td>
                    <td className="allUsers-table-col">
                      <Button color={allUsers[key].enableLogRocket ? 'success' : 'warning'} onClick={() => toggleLogRocket(key)}>
                        Logrocket
                      </Button>
                    </td>
                    {/* <td className="allUsers-table-col">
                      <Button color={allUsers[key].enableSmartlook ? 'success' : 'warning'} onClick={() => toggleSmartlook(key)}>
                        Smartlook
                      </Button>
                    </td> */}

                    <td className="allUsers-table-col">
                      <Button color="primary" onClick={() => this.openChatModal(key, allUsers[key].name)}>
                        Message
                      </Button>
                    </td>

                    <td className="allUsers-table-col">
                      <Button color="primary" onClick={() => this.openAchievementsModal(key, allUsers[key].name)}>
                        Data
                      </Button>
                    </td>

                    <td className="allUsers-table-col">
                      <Button color="primary" onClick={() => resetActiveRoom(key)}>
                        Reset
                      </Button>
                    </td>
                    <td className="allUsers-table-col">
                      {allUsers[key].blocked ? (
                        <Button color="primary" onClick={() => this.unblockUser(key)}>
                          UnBlock
                        </Button>
                      ) : (
                        <Button color="primary" onClick={() => this.openModalBlock(key)}>
                          Block
                        </Button>
                      )}
                    </td>
                    <td className="allUsers-table-col">
                      <Button color="danger" onClick={() => this.openDeleteModal(key)}>
                        Delete
                      </Button>
                    </td>
                    <td className="allUsers-table-col">
                      <Button color="danger" onClick={() => this.swapToUser(key, allUsers[key].newDesign, allUsers[key].screenMode, allUsers[key].lastLanguage)}>
                        Swap To User
                      </Button>
                    </td>
                    <td className="allUsers-table-col">
                      {allUsers[key].socProvider === 'password'
                        ? (allUsers[key].verifiedEmail === true ? 'Yes' : (<Button color="danger" onClick={() => this.openVerifyModal(key)}>Verify</Button>))
                        : ((!allUsers[key].contactEmail || allUsers[key].verifiedEmail === true) ? 'Yes' : <Button color="danger" onClick={() => this.openVerifyModal(key)}>Verify</Button>)
                      }
                    </td>
                  </tr>

                ))}
            </tbody>
          </table>

          {
            pagesCount && usersCount > pageSize && (
              <div className="pagination-wrapper">
                <Pagination aria-label="Page navigation example">
                  <PaginationItem disabled={currentPage <= 0}>
                    <PaginationLink
                      style={{ color: '#000' }}
                      onClick={e => this.handleClick(e, currentPage - 1)}
                      previous
                      href="#"
                    />
                  </PaginationItem>

                  <PaginationItem disabled={currentPage === 0}>
                    <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, 0)} href="#">
                      1
                    </PaginationLink>
                  </PaginationItem>

                  {[...Array(pagesCount)].map((page, i) => {
                    if (i > currentPage - 3 && i < currentPage + 3) {
                      return (
                        <PaginationItem active={i === currentPage} key={page}>
                          <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, i)} href="#">
                            {i + 1}
                          </PaginationLink>
                        </PaginationItem>
                      );
                    }

                    return null;
                  })}

                  {currentPage < (pagesCount - 3) && (
                    <PaginationItem disabled={currentPage >= pagesCount - 1}>
                      <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, pagesCount - 1)} href="#">
                        {pagesCount}
                      </PaginationLink>
                    </PaginationItem>
                  )}

                  <PaginationItem disabled={currentPage >= pagesCount - 1}>
                    <PaginationLink
                      style={{ color: '#000' }}
                      onClick={e => this.handleClick(e, currentPage + 1)}
                      next
                      href="#"
                    />
                  </PaginationItem>
                </Pagination>
              </div>
            )
          }
        </>
      );
    }
  }

  render() {
    const {
      fontMode, t, loading, success, userGamesHistory, userBalanceHistory, userPointsHistory, fetchUserPointsHistory, fetchUserGamesHistory, fetchUserBalanceHistory, chatMessages, userAchievements, i18n,
    } = this.props;
    const {
      openModal,
      balance,
      level,
      gamesPlayed,
      endDate,
      reason,
      openBlockModal,
      //  userFilter,
      filterType,
      filter,
      gamesHistoryModalOpen,
      bilanceHistoryModalOpen,
      pointsHistoryModalOpen,
      totalPnts,
      openDeleteConfirmation,
      historyUid,
      openChatModal,
      openChatModalName,
      openChatModalUid,
      inputMessage,
      openAchievementsModal,
      openAchievementsModalName,
      openAchievementsModalUid,

      openManualVerifyConfirmation,
      ableToSend,
      fileName,
      firstName,
      lastName,
      photo,
      preview,
      showCrop,
      ableToSave,
      editUserId,
      nonVerified,
      minDate,
      maxDate,
      birthday,
    } = this.state;

    return (
      <Fragment>

        <UncontrolledPopover popperClassName={`popover`} placement="right" trigger="focus" target="admin-help-button-all-users">
          <PopoverBody className="popover-body">
            {nonVerified
              ? ("The players with a not confirmed email address. Admin can resend the verification email and delete any of the listed accounts if the email is invalid or the user is an obvious bot.")
              : ("The players can be searched by name, last name, email, or ID.")
            }
          </PopoverBody>
        </UncontrolledPopover>

        <div className="header-title">
          <h2>
            {!nonVerified ? "Players" : "Non Verified Accounts"}
            <Button className="admin-help-button" id="admin-help-button-all-users">
              ?
            </Button>
          </h2>
          <div className="all-users-header-option">
            <Button
              className={`all-users-header-option ${!nonVerified ? '' : 'active'}`}
              onClick={() => this._handleNonVerified(false)}
            >
              Players
            </Button>
            <Button
              className={`allusers-header-button ${!nonVerified ? '' : 'active'}`}
              onClick={() => this._handleNonVerified(true)}
            >
              Non Verified Accounts
            </Button>
          </div>
          <Row>
            <Col sm="5">
              <Label for="filterType">
                Filter By
              </Label>
              <Input type="select" className="game-bet-select" value={filterType} onChange={this.selectType}>
                <option value="lowerCaseName" style={{ backgroundColor: '#222' }}>Name</option>
                <option value="lowerCaseLastName" style={{ backgroundColor: '#222' }}>Last Name</option>
                <option value="uid" style={{ backgroundColor: '#222' }}>Player Id</option>
                <option value="email" style={{ backgroundColor: '#222' }}>Email</option>
              </Input>
            </Col>
            <Col sm="5">
              <Label for="filter">
                Value
              </Label>
              <Input
                type="text"
                name="filter"
                id="filter"
                value={filter}
                onChange={this.handleChange}
                onKeyPress={this.handleKeyPress}
              />
            </Col>
            <Col sm="2">
              <Button onClick={this.filter}>
                Filter
              </Button>
            </Col>
          </Row>

          {this.table()}

        </div>

        <Modal container={'div > div'} isOpen={openChatModal} toggle={this.toggleChat}>
          <ModalHeader toggle={this.toggleChat}>
            {`Chat - ${openChatModalName} - ${openChatModalUid}`}
          </ModalHeader>
          <ModalBody>
            <Row className="chat-body">
              <Col className="chat-body-wrapper" md="12">
                <ScrollArea
                  speed={0.65}
                  className="admin-chat-scroll-area"
                  contentClassName="admin-chat-scroll-content"
                  //  smoothScrolling
                  verticalContainerStyle={{
                    background: 'transparent',
                    opacity: 1,
                    width: 10,
                  }}
                  verticalScrollbarStyle={{
                    background: '#fff',
                    borderRadius: 2,
                    width: 6,
                    minHeight: 30,
                    minScrollSize: 35,
                  }}
                  horizontal={false}
                  ref={(el) => { this.messagesScrollbar = el; }}
                >
                  {chatMessages && Object.keys(chatMessages).map(key => (
                    <Row key={key}>
                      <Col md="12">
                        <Message message={chatMessages[key]} />
                      </Col>
                    </Row>
                  ))}
                </ScrollArea>
              </Col>
            </Row>
            <Row className="chat-footer" style={{ height: '20%' }}>
              <Form style={{ width: '100%' }}>
                <Col md="12">
                  <Input
                    className={`chat-footer-input chat-footer-input-admin admin-input-message-${fontMode}`}
                    type="textarea"
                    name="inputMessage"
                    id="inputMessage"
                    autoComplete="off"
                    placeholder='Write Message...'
                    style={{

                    }}
                    value={inputMessage}
                    onChange={this.handleChangeChat}
                    onKeyPress={this.handleEnter}
                  />

                </Col>
              </Form>
            </Row>
            <Row>
              <Button
                className="btn-warning admin-file-upload-button"
                onClick={this._handleFileClick}
              >
                {fileName === '' ? '+ Choose File' : fileName}
              </Button>
              <input
                id="files"
                hidden
                ref={this.hiddenFileInput}
                className="chat-footer-input chat-footer-input-admin admin-file-upload-input"
                accept=".jpg,.jpeg,.png,.pdf,.msword,.doc,.rtf,.txt,ain"
                type="file"
                onChange={this.chooseFile}
              />
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="secondary" onClick={this.setSupportMessageAsResponded}>Mark As Read</Button>
            <Button className="contact-support-footer-button" disabled={!ableToSend} color="primary" onClick={this.submitMessage}>
              Send
            </Button>
            <Button type="button" color="secondary" onClick={this.toggleChat}>Close</Button>
          </ModalFooter>
        </Modal>


        <Modal container={'div > div'} isOpen={openModal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
            Edit
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup style={{ marginTop: 40 }} className="notification">
                <FormGroup>
                  <img className="menu-player-avatar" src={preview || photo} alt="Preview" />
                  {!showCrop && (
                    <div className="menu-player-edit" style={success ? { position: 'absolute', top: 100, right: 180 } : { position: 'absolute', top: 40, right: 180 }}>
                      <Media onClick={() => { this.handleEditPhoto(showCrop); }} src={pencilImg} className="menu-player-edit-image" />
                    </div>
                  )}
                  {!showCrop && (
                    <div className="menu-player-edit" style={success ? { position: 'absolute', top: 140, right: 180 } : { position: 'absolute', top: 80, right: 180 }}>
                      <Media onClick={() => { this.setState({ photo: defaultImage }); }} src={cancelImg} className="menu-player-edit-image" />
                    </div>
                  )}
                  {showCrop && (
                    <div className="menu-player-edit" style={success ? { position: 'absolute', top: 100, right: 180 } : { position: 'absolute', top: 40, right: 180 }}>
                      <Media onClick={() => { this.handleEditPhoto(showCrop); this.uploadAvatarFile(); }} src={checkImg} className="menu-player-edit-image" />
                    </div>
                  )}
                  {showCrop && (
                    <div className="menu-player-edit" style={success ? { position: 'absolute', top: 140, right: 180 } : { position: 'absolute', top: 80, right: 180 }}>
                      <Media onClick={() => { this.handleEditPhoto(showCrop); this.setState({ preview: null }); }} src={cancelImg} className="menu-player-edit-image" />
                    </div>
                  )}
                  {showCrop && (
                    <div style={{
                      width: 150, height: 150, margin: 'auto', marginTop: 20,
                    }}
                    >
                      <Avatar
                        width={150}
                        height={150}
                        imageWidth={150}
                        imageHeight={150}
                        onCrop={this.onCrop}
                        onBeforeFileLoad={this.onBeforeFileLoad}
                        label={'Upload a Photo'}
                        labelStyle={{ fontSize: 15, fontVariant: 'all-petite-caps' }}
                      />
                    </div>
                  )
                  }
                  <Input
                    type="text"
                    name="photo"
                    id="photo"
                    placeholder=""
                    hidden
                    value={photo}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="firstName">Name</Label>
                  <Input
                    type="text"
                    name="firstName"
                    id="firstName"
                    placeholder=""
                    disabled={loading}
                    value={firstName}
                    maxlength="20"
                    onChange={this.handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="lastName">Surname</Label>
                  <Input
                    type="text"
                    name="lastName"
                    id="lastName"
                    placeholder=""
                    disabled={loading}
                    value={lastName}
                    maxlength="20"
                    onChange={this.handleChange}
                  />
                  <Input
                    type="hidden"
                    name="editUserId"
                    id="editUserId"
                    placeholder=""
                    value={editUserId}
                    maxlength="20"
                  />
                </FormGroup>
                <Button
                  color="link"
                  className="update-profile-button"
                  style={{ marginTop: 20, marginBottom: 20, width: 200 }}
                  onClick={this.editUser}
                >
                  Save
                </Button>
              </FormGroup>
              {/* <FormGroup>
                <Label for="level">
                  {t('level')}
                </Label>
                <Input
                  type="text"
                  name="level"
                  id="level"
                  value={level}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="gamesPlayed">
                  {t('gPlayed')}
                </Label>
                <Input
                  type="text"
                  name="gamesPlayed"
                  id="gamesPlayed"
                  value={gamesPlayed}
                  onChange={this.handleChange}
                />
              </FormGroup> */}
            </Form>
            {/* <Button color="primary" onClick={this.editUser}>{t('edit')}</Button> */}

            <FormGroup>
              <Label for="balance">
                Add Balance
              </Label>
              <Input
                type="text"
                name="balance"
                id="balance"
                value={balance}
                onChange={this.handleChange}
              />
              <Button color="primary" onClick={this.addBalance}>Add Balance</Button>
            </FormGroup>

            <FormGroup>
              <Label for="totalPnts">
                Add Points
              </Label>
              <Input
                type="text"
                name="totalPnts"
                id="totalPnts"
                value={totalPnts}
                onChange={this.handleChange}
              />
              <Button color="primary" onClick={this.addPoints}>Add Points</Button>
            </FormGroup>
            <FormGroup>
              <Label for="birthday">Birthday</Label>
              <DateTimePicker
                format={constants.FORMAT_DATE_TYPE.slashFullDate}
                culture={i18n.language}
                time={false}
                onChange={this.changeBirthday}
                value={birthday}
                min={minDate}
                max={maxDate}
                placeholder='dd/mm/yyyy'
              />
              <Button color="primary" onClick={this.handleEditUserDob}>Edit Birthday</Button>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>Close</Button>
          </ModalFooter>
        </Modal>


        <Modal container={'div > div'} isOpen={openBlockModal} toggle={this.toggleBlock}>
          <ModalHeader toggle={this.toggleBlock}>
            Block
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="endDate">
                  Until
                </Label>
                <ReactDateTime
                  closeOnSelect
                  onChange={this.changeEndDate}
                  value={endDate}
                  id="endDate"
                />
              </FormGroup>
              <FormGroup>
                <Label for="reason">
                  Reason
                </Label>
                <Input
                  type="text"
                  name="reason"
                  id="reason"
                  value={reason}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.blockUser}>Block</Button>
            <Button color="secondary" onClick={this.toggleBlock}>Close</Button>
          </ModalFooter>
        </Modal>

        <Modal container={'div > div'} isOpen={openDeleteConfirmation} toggle={this.toggleDelete}>
          <ModalHeader toggle={this.toggleDelete}>
            Do you really want to delete the player?
          </ModalHeader>
          <ModalBody />
          <ModalFooter>
            <Button color="primary" onClick={this.deleteUser}>Delete</Button>
            <Button color="secondary" onClick={this.toggleDelete}>Close</Button>
          </ModalFooter>
        </Modal>

        <Modal container={`div > div`} isOpen={openManualVerifyConfirmation} toggle={this.toggleVerify}>
          <ModalHeader toggle={this.toggleVerify}>
            Do you really want to send email verification to the user?
          </ModalHeader>
          <ModalBody />
          <ModalFooter>
            <Button color="primary" onClick={this.verifyUser}>Send</Button>
            <Button color="secondary" onClick={this.toggleVerify}>Close</Button>
          </ModalFooter>
        </Modal>


        <UserAchievementsModal
          t={t}
          openAchievementsModal={openAchievementsModal}
          openAchievementsModalName={openAchievementsModalName}
          openAchievementsModalUid={openAchievementsModalUid}
          userAchievementsData={userAchievements}
          closeModal={this.closeAchievementsModal}
        />

        {gamesHistoryModalOpen ? (
          <UserGamesHistory fetchFunction={fetchUserGamesHistory} userUid={historyUid} closeFunc={this.closeGamesHistory} modalOpen={gamesHistoryModalOpen} type="games" history={userGamesHistory} />
        ) : (null)}

        {bilanceHistoryModalOpen ? (
          <UserHistory fetchFunction={fetchUserBalanceHistory} userUid={historyUid} closeFunc={this.closeBilanceHistory} modalOpen={bilanceHistoryModalOpen} type="balance" history={userBalanceHistory} />
        ) : (null)}

        {pointsHistoryModalOpen ? (
          <UserHistory fetchFunction={fetchUserPointsHistory} userUid={historyUid} closeFunc={this.closePointsHistory} modalOpen={pointsHistoryModalOpen} type="points" history={userPointsHistory} />
        ) : (null)}

        {/* {
          nonVerified ? (
            <NonVerifiedAccounts />
          ) : (null)} */}
        {/*  <Modal size="lg" isOpen={bilanceHistoryModalOpen} toggle={this.closeBilanceHistory}>
          <ModalHeader toggle={this.closeBilanceHistory}>
            {t('balanceHistory')}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col sm="12">
                <table className="top-table">
                  <thead>
                    <tr className="top-table-header-row">
                      <th className="top-table-header-col">
                        {t('time')}
                      </th>
                      <th className="top-table-header-col">
                        {t('type')}
                      </th>
                      <th className="top-table-header-col">
                        {t('oldBal')}
                      </th>
                      <th className="top-table-header-col">
                        {t('newBal')}
                      </th>
                      <th className="top-table-header-col">
                        {t('change')}
                      </th>
                    </tr>
                  </thead>
                  <tbody />
                </table>
                <ReactTableContainer
                  width="100%"
                  height="420px"
                  scrollbarStyle={{
                    background: {
                      background: 'transparent',
                      width: 1,
                      marginRight: 3,
                    },

                    backgroundFocus: {
                      background: 'transparent',
                      width: 1,
                      marginRight: 3,
                    },
                    foreground: {
                      background: 'fff',
                      width: 4,
                      left: -1,
                    },

                    foregroundFocus: {
                      background: 'fff',
                      width: 4,
                      left: -1,
                    },
                  }}
                >
                  <table className="top-table">
                    <colgroup>
                      <col span="1" className="" />
                    </colgroup>
                    <thead />
                    <tbody>
                      {userBalanceHistory && Object.keys(userBalanceHistory)
                        .reverse().map((key, index) => (
                          <Fragment key={key}>
                            <tr key={key} className={`top-table-row ${index % 2 === 0 ? ('odd') : ('even')}`}>
                              <td className="top-table-col">
                                <Moment format="DD-MM-YYYY, HH:mm" locale="lv">
                                  {userBalanceHistory[key].time}
                                </Moment>
                              </td>
                              <td className="top-table-col col-player">
                                {userBalanceHistory[key].type === 'game' && (
                                  t('common:moneyHistory.gameResult')
                                )}
                                {userBalanceHistory[key].type === 'joinPrice' && (
                                  t('common:moneyHistory.joinPrice')
                                )}
                                {userBalanceHistory[key].type === 'dailyBonus' && (
                                  t('common:moneyHistory.dailyBonus')
                                )}
                                {userBalanceHistory[key].type === 'leaveTournament' && (
                                  t('common:moneyHistory.leaveTournament')
                                )}
                                {userBalanceHistory[key].type === 'joinTournament' && (
                                  t('common:moneyHistory.joinTournament')
                                )}
                                {userBalanceHistory[key].type === 'winTournament' && (
                                  t('common:moneyHistory.winTournament')
                                )}
                                {userBalanceHistory[key].type === 'canceledTournament' && (
                                  t('common:moneyHistory.canceledTournament')
                                )}
                                {userBalanceHistory[key].type === 'buyTournamentMoney' && (
                                  t('common:moneyHistory.buyTournamentMoney')
                                )}
                                {userBalanceHistory[key].type === 'friendReceived' && (
                                  t('common:moneyHistory.friendReceived')
                                )}
                                {userBalanceHistory[key].type === 'friendSent' && (
                                  t('common:moneyHistory.friendSent')
                                )}
                                {userBalanceHistory[key].type === 'purchaseCallback' && (
                                  t('common:moneyHistory.purchaseCallback')
                                )}
                                {userBalanceHistory[key].type === 'purchase' && (
                                  t('common:moneyHistory.purchase')
                                )}
                                {userBalanceHistory[key].type === 'missTurnMe' && (
                                  t('common:moneyHistory.missTurnMe')
                                )}
                                {userBalanceHistory[key].type === 'missTurnOther' && (
                                  t('common:moneyHistory.missTurnOther')
                                )}
                                {userBalanceHistory[key].type === 'leftRoom' && (
                                  t('common:moneyHistory.leftRoom')
                                )}
                                {userBalanceHistory[key].type === 'leftRoomOther' && (
                                  t('common:moneyHistory.leftRoomOther')
                                )}
                                {userBalanceHistory[key].type === 'minGamesPenalty' && (
                                  t('common:moneyHistory.minGamesPenalty')
                                )}
                                {userBalanceHistory[key].type === 'minGamesReward' && (
                                  t('common:moneyHistory.minGamesReward')
                                )}
                                {userBalanceHistory[key].type === 'adminChange' && (
                                  t('common:moneyHistory.adminChange')
                                )}
                                {userBalanceHistory[key].type === 'endRoomPules' && (
                                  t('common:moneyHistory.endRoomPules')
                                )}
                                {userBalanceHistory[key].type === 'giftsSent' && (
                                  t('common:moneyHistory.giftsSent')
                                )}
                                {userBalanceHistory[key].type === 'achievement' && (
                                  t('common:moneyHistory.achievement')
                                )}
                                {userBalanceHistory[key].type === 'balanceReset' && (
                                  t('common:moneyHistory.balanceReset')
                                )}
                                {userBalanceHistory[key].type === 'profileReset' && (
                                  t('common:moneyHistory.profileReset')
                                )}
                                {userBalanceHistory[key].type === 'holiday' && (
                                  t('common:moneyHistory.holiday')
                                )}
                                {userBalanceHistory[key].type === 'nameDay' && (
                                  t('common:moneyHistory.nameDay')
                                )}
                                {userBalanceHistory[key].type === 'birthday' && (
                                  t('common:moneyHistory.birthday')
                                )}
                                {userBalanceHistory[key].type === 'loginBonus' && (
                                  t('common:moneyHistory.loginBonus')
                                )}
                              </td>
                              <td className="top-table-col">
                                {userBalanceHistory[key].old}
                              </td>
                              <td className="top-table-col">
                                {userBalanceHistory[key].new}
                              </td>
                              <td className="top-table-col">
                                {userBalanceHistory[key].change}
                              </td>
                            </tr>
                          </Fragment>
                        ))}
                    </tbody>
                  </table>
                </ReactTableContainer>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.closeBilanceHistory}>{t('close')}</Button>
          </ModalFooter>
        </Modal>

        <Modal size="lg" isOpen={pointsHistoryModalOpen} toggle={this.closePointsHistory}>
          <ModalHeader toggle={this.closePointsHistory}>
            {t('pointsHistory')}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col sm="12">
                <table className="top-table">
                  <thead>
                    <tr className="top-table-header-row">
                      <th className="top-table-header-col">
                        {t('time')}
                      </th>
                      <th className="top-table-header-col">
                        {t('type')}
                      </th>
                      <th className="top-table-header-col">
                        {`${t('old')} ${t('points')}`}
                      </th>
                      <th className="top-table-header-col">
                        {`${t('new')} ${t('points')}`}
                      </th>
                      <th className="top-table-header-col">
                        {t('change')}
                      </th>
                    </tr>
                  </thead>
                  <tbody />
                </table>
                <ReactTableContainer
                  width="100%"
                  height="420px"
                  scrollbarStyle={{
                    background: {
                      background: 'transparent',
                      width: 1,
                      marginRight: 3,
                    },

                    backgroundFocus: {
                      background: 'transparent',
                      width: 1,
                      marginRight: 3,
                    },
                    foreground: {
                      background: 'fff',
                      width: 4,
                      left: -1,
                    },

                    foregroundFocus: {
                      background: 'fff',
                      width: 4,
                      left: -1,
                    },
                  }}
                >
                  <table className="top-table">
                    <colgroup>
                      <col span="1" className="" />
                    </colgroup>
                    <thead />
                    <tbody>
                      {userPointsHistory && Object.keys(userPointsHistory)
                        .reverse().map((key, index) => (
                          <Fragment key={key}>
                            <tr key={key} className={`top-table-row ${index % 2 === 0 ? ('odd') : ('even')}`}>
                              <td className="top-table-col">
                                <Moment format="DD-MM-YYYY, HH:mm" locale="lv">
                                  {userPointsHistory[key].time}
                                </Moment>
                              </td>
                              <td className="top-table-col col-player">
                                {userPointsHistory[key].type === 'game' && (
                                  t('common:moneyHistory.gameResult')
                                )}
                                {userPointsHistory[key].type === 'missTurnMe' && (
                                  t('common:moneyHistory.missTurnMe')
                                )}
                                {userPointsHistory[key].type === 'missTurnOther' && (
                                  t('common:moneyHistory.missTurnOther')
                                )}
                                {userPointsHistory[key].type === 'leftRoom' && (
                                  t('common:moneyHistory.leftRoom')
                                )}
                                {userPointsHistory[key].type === 'leftRoomOther' && (
                                  t('common:moneyHistory.leftRoomOther')
                                )}
                                {userPointsHistory[key].type === 'adminChange' && (
                                  t('common:moneyHistory.adminChange')
                                )}
                                {userPointsHistory[key].type === 'endRoomPules' && (
                                  t('common:moneyHistory.endRoomPules')
                                )}
                                {userPointsHistory[key].type === 'pointsReset' && (
                                  t('common:moneyHistory.pointsReset')
                                )}
                                {userPointsHistory[key].type === 'profileReset' && (
                                  t('common:moneyHistory.profileReset')
                                )}
                              </td>
                              <td className="top-table-col">
                                {userPointsHistory[key].old}
                              </td>
                              <td className="top-table-col">
                                {userPointsHistory[key].new}
                              </td>
                              <td className="top-table-col">
                                {userPointsHistory[key].change}
                              </td>
                            </tr>
                          </Fragment>
                        ))}
                    </tbody>
                  </table>
                </ReactTableContainer>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.closePointsHistory}>{t('close')}</Button>
          </ModalFooter>
        </Modal>  */}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  userAchievements: state.admin.userAchievements || {},
  lastIpData: state.admin.lastIpData,

});
const mapDispatchToProps = {
  fetchUserAchievements: getUserAchievements,
  fetchUserSettings: getUserSettings,
  submitUserDobFuc: editUserDob,
  fetchUserLastIp: getUserLastIp,
  fetchAutoPlayOtherUser: getAutoPlayOtherUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['admin', 'common'])(AllUsers));

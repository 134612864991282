import React, { Fragment, createRef } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { Helmet } from 'react-helmet';

import { connect } from 'react-redux';

// import isEqual from 'react-fast-compare';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';

// import ScrollArea from 'react-scrollbar';
// import ScrollAreaButtons from '../UI/ScrollAreaButtons';

import ScrollAreaWrapper from '../UI/ScrollAreaWrapper';

import CustomDate from '../UI/CustomDate';

import myInfoImg from '../../../images/icons/my_profile.png';

import {
  unBlockUser,
  getIgnoredPlayers,
  getIgnoredByPlayers,
} from '../../../actions/member';
import classNames from 'classnames';
import * as constants from '../../../constants/constants';
import { Label, Spinner } from 'reactstrap';
import buttonClickedSound from '../../../sounds/click_feedback.flac';

class IgnoredUsers extends React.Component {
  static propTypes = {
    ignoredUsers: PropTypes.shape({}),
    ignoredByUsers: PropTypes.shape({}),
    achievementsNotification: PropTypes.bool,
  //  userSettings: PropTypes.shape({
  //    soundOn: PropTypes.bool,
  //  }),
    uid: PropTypes.string,
    i18n: PropTypes.shape(),
    t: PropTypes.func.isRequired,
    unBlockUserFunc: PropTypes.func.isRequired,
    fetchIgnoredPlayers: PropTypes.func.isRequired,
    fetchIgnoredByPlayers: PropTypes.func.isRequired,
    changeTab: PropTypes.func.isRequired,
    ignoredSwitchChecked: PropTypes.bool.isRequired,
    toggleIgnoredSwitchChecked: PropTypes.func.isRequired,
  }

  static defaultProps = {
  //  userSettings: {},
    ignoredUsers: null,
    ignoredByUsers: null,
    achievementsNotification: false,
    i18n: null,
    uid: null,
    ignoredSwitchChecked: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      scrollPos: 0,
      ignoredUsers: null,
      ignoredByUsers: null,
    };
    this.inputRef = createRef();
    this.buttonClickedAudio = new Audio(buttonClickedSound);
  }

  componentWillMount() {
    const { fetchIgnoredPlayers, fetchIgnoredByPlayers } = this.props;

    fetchIgnoredPlayers();
    fetchIgnoredByPlayers();
  }

  componentWillReceiveProps(nextProps, nextState) {
    this.setState({
      ignoredUsers: nextProps.ignoredUsers ? nextProps.ignoredUsers : null,
      ignoredByUsers: nextProps.ignoredByUsers ? nextProps.ignoredByUsers : null
    })
  }

  unblock = (id) => {
    const { unBlockUserFunc, fetchIgnoredPlayers } = this.props;

    console.log('unblock', id);

    this.playButtonSound();
    unBlockUserFunc(id).then((res) => {
      console.log('unBlockUserFunc', res);

      if (res && res.status === 'success') {
        fetchIgnoredPlayers();
      }
    });
  }

  updateScrollPos = (val) => {
    this.setState({ scrollPos: val.topPosition || 0 });
  }

  scroll = (newVal) => {
    this.messagesScrollbar.scrollYTo(newVal);
  }

  playButtonSound = () => {
    const { soundOn, uid } = this.props;

      if (soundOn) {
        this.buttonClickedAudio.play();
      }
  }

  handleInputRef = () => {
    this.inputRef.current.click();
  };

  render() {
    const {
      changeTab, t, achievementsNotification, i18n, ignoredSwitchChecked, toggleIgnoredSwitchChecked,
    } = this.props;

    const { scrollPos, ignoredUsers, ignoredByUsers } = this.state;

    const sortedKeysIgnoredUsers = ignoredUsers ? Object.keys(ignoredUsers).sort((a, b) => {
      return ignoredUsers[b].date - ignoredUsers[a].date;
    }) : [];

    const sortedKeysIgnoredByUsers = ignoredByUsers ? Object.keys(ignoredByUsers).sort((a, b) => {
      return ignoredByUsers[b].date - ignoredByUsers[a].date;
    }) : [];

    const cutoffDate = 1717597859999; // dates 05-06-2024, 17:00 and before need to be displayed as "long time ago" for ignoredByUsers

    return (
      <div className="my-info">
        <Helmet>
          <title>
            Zole - {t('route.ignoredPlayers')}
          </title>
        </Helmet>
        <Row>

        </Row>
        <Row className="my-info-header mb-4">
          {/*  <Col xs="4" sm="4" className={`${i18n.language === 'ru' ? 'my-info-header-col-ru' : null}`}>
            <Media src={myInfoImg} className={`my-info-header-image ${i18n.language === 'ru' ? 'my-info-header-image-ru' : null}`} />
            <div className={`my-info-header-text ${i18n.language === 'ru' ? 'my-info-header-text-ru' : null}`}>
              {t('myInfo.ignoredPlayers')}
            </div>
          </Col>
          <Col className="menu-topTab">
            <Button color="link" className="my-info-header-button" onClick={() => changeTab('9')}>
              {t('myInfo.friends')}
            </Button>
          </Col>
          <Col className="menu-topTab">
            <Button color="link" className={`my-info-header-button active ${i18n.language === 'ru' ? 'my-info-header-button-ru' : null}`} onClick={() => changeTab('10')}>
              {t('myInfo.ignoredPlayers')}
            </Button>
          </Col>
          <Col className="menu-topTab" style={{ marginRight: 15 }}>
            <Button color="link" className={`my-info-header-button ${achievementsNotification ? 'my-info-header-button-notification' : ''}`} onClick={() => changeTab('11')}>
              {t('myInfo.achievements')}
            </Button>
          </Col>
          <Col className="menu-topTab" style={{ marginRight: 15 }}>
            <Button color="link" className="my-info-header-button" onClick={() => changeTab('12')}>
              {t('myInfo.results')}
            </Button>
          </Col>  */}

          <Col xs="3">
            <Media src={myInfoImg} className="my-info-header-image" />
            <div className="my-info-header-text">
              {t('myInfo.ignoredPlayers')}
            </div>

              <div className="ignored-users-switch-section-iframe">
                <div className="ignored-users-switch-section-iframe-left-section">
                  <Label className="mb-1">{t(`ignoredUsers.${constants.IGNORED_USERS_SWITCH_OPTION.iIgnore}`)}</Label>
                  <Label>{t(`ignoredUsers.${constants.IGNORED_USERS_SWITCH_OPTION.ignoresMe}`)}</Label>
                </div>
                <div className="ignored-users-switch-section-iframe-right-section">
                  <Label className="switch-wrapper mb-1" onClick={() => this.handleInputRef} style={{transform: 'rotate(90deg)', position: 'absolute', top: '48px'}}>
                    <input
                      type="checkbox"
                      name="switchOption"
                      checked={ignoredSwitchChecked}
                      ref={this.inputRef}
                      onClick={() => toggleIgnoredSwitchChecked()}
                    />
                    <span
                      className={classNames('switch', {
                        'switch-best': ignoredSwitchChecked,
                        'switch-worst': !ignoredSwitchChecked,
                      })}
                    >
                      <span className="switch-handle" />
                    </span>
                  </Label>
                </div>

              </div>
            {/* <Col xs="3" className="power-rankings-header-switch-section" id="power-rankings-switch-option">
              <Label className="mb-1">{t(`ignoredUsers.${constants.IGNORED_USERS_SWITCH_OPTION.iIgnore}`)}</Label>
              <Label className="switch-wrapper mb-1" onClick={() => this.handleInputRef}>
                <input
                  type="checkbox"
                  name="switchOption"
                  checked={ignoredSwitchChecked}
                  ref={this.inputRef}
                  onClick={() => toggleIgnoredSwitchChecked()}
                />
                <span
                  className={classNames('switch', {
                    'switch-best': ignoredSwitchChecked,
                    'switch-worst': !ignoredSwitchChecked,
                  })}
                >
                  <span className="switch-handle" />
                </span>
              </Label>
              <Label>{t(`ignoredUsers.${constants.IGNORED_USERS_SWITCH_OPTION.ignoresMe}`)}</Label>
            </Col> */}
          </Col>
          <Col xs="9" style={{ paddingRight: 30 }}>
            <Row>
              {/* Stop for production mode(change xs=3 to xs2 for first 3) */}
              <Col xs="2" className="menu-topTab">
                <Button color="link" className="my-info-header-button" onClick={() => changeTab(constants.MENU_NAVIGATION.friends)}>
                  {t('myInfo.friends')}
                </Button>
              </Col>
              <Col xs="2" className="menu-topTab ignored-players-menu">
                <Button color="link" className={`my-info-header-button active`} onClick={() => changeTab(constants.MENU_NAVIGATION.ignoredUsers)}>
                  {t('myInfo.ignoredPlayers')}
                </Button>
              </Col>
              <Col xs="2" className="menu-topTab">
                {/* <Button color="link" className={`my-info-header-button ${achievementsNotification ? 'my-info-header-button-notification' : ''}`} onClick={() => changeTab(constants.MENU_NAVIGATION.archievements)}> */}
                <Button color="link" className={`my-info-header-button`} onClick={() => changeTab(constants.MENU_NAVIGATION.archievements)}>
                  {t('myInfo.achievements')}
                </Button>
              </Col>
              <Col xs="2" className="menu-topTab">
                <Button color="link" className="my-info-header-button" onClick={() => changeTab(constants.MENU_NAVIGATION.gameHistory)}>
                  {t('myInfo.results')}
                </Button>
              </Col>
              {/* Stop for production mode */}
              <Col xs="2" className="menu-topTab">
                <Button color="link" className="my-info-header-button" onClick={() => changeTab(constants.MENU_NAVIGATION.weeklyStatus)}>
                  {t('myInfo.weeklyStatus')}
                </Button>
              </Col>
              <Col xs="2" className="menu-topTab">
                <Button color="link" className="my-info-header-button" onClick={() => changeTab(constants.MENU_NAVIGATION.gameLogs)}>
                  {t('myInfo.gameLogs')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="my-info-content-joyride">
          <Col sm="12">
            <Row className="ignored-users">
              <Col sm="12" className="ignored-users-table">
                {ignoredSwitchChecked ? (
                  <>
                    <Row className="ignored-users-table-header">
                      <Col sm="4" className="ignored-users-table-header-col text-left">
                        {t('common.name')}
                      </Col>
                      <Col sm="4" className="ignored-users-table-header-col">
                        {t('common.level')}
                      </Col>
                      <Col sm="4" className="ignored-users-table-header-col">
                        {t('common.date')}
                      </Col>
                    </Row>
                    { !ignoredByUsers ? (
                        <div className="top-table-spinner-loading-section d-flex align-items-center">
                          <Spinner color="light" style={{ width: '5rem', height: '5rem' }} />
                        </div>
                    ) : (
                      <ScrollAreaWrapper
                        className="chat-body-scroll-area h-100"
                        contentClassName={`online-users-ReactTableContainer`}
                        disableAutoHeight={ignoredByUsers && Object.keys(ignoredByUsers).length === 0}
                        show={ignoredByUsers && Object.keys(ignoredByUsers).length > 7 ? true : null}
                        rightOffset={15}
                        topOffset={33}
                        bottomOffset={-37}
                      >
                        {(ignoredByUsers && sortedKeysIgnoredByUsers.length > 0) ? sortedKeysIgnoredByUsers.map(key => ignoredByUsers[key].name && ignoredByUsers[key].date && (
                          <Fragment>
                            {ignoredByUsers[key] && (
                            <Row key={key} className="ignored-users-table-row">
                              <Col sm="4" className="ignored-users-table-row-col ignored-users-table-row-col-text text-left">
                                {ignoredByUsers[key].name}
                              </Col>
                              <Col sm="4" className="ignored-users-table-row-col ignored-users-table-row-col-text text">
                                {ignoredByUsers[key].lvl}
                              </Col>
                              <Col sm="4" className="ignored-users-table-row-col ignored-users-table-row-col-text">
                                {ignoredByUsers[key].date > cutoffDate ? (
                                  <CustomDate format="DD-MM-YYYY, hh:mm" date={ignoredByUsers[key].date} />
                                ) : (
                                  t('common.longTimeAgo')
                                )}
                              </Col>
                            </Row>
                            )}
                          </Fragment>
                        )) : <div className="d-flex h-100 align-items-center mt-2">{t('ignoredUsers.ignoredByUsersEmptyEntries')}</div>}
                      </ScrollAreaWrapper>
                    )}
                  </>
                ) : (
                  <>
                    <Row className="ignored-users-table-header">
                      <Col sm="3" className="ignored-users-table-header-col text-left">
                        {t('common.name')}
                      </Col>
                      <Col sm="3" className="ignored-users-table-header-col">
                        {t('common.level')}
                      </Col>
                      <Col sm="3" className="ignored-users-table-header-col">
                        {t('common.date')}
                      </Col>
                      <Col sm="3" className="ignored-users-table-header-col">
                        {t('ignoredUsers.unBlock')}
                      </Col>
                    </Row>
                    { !ignoredUsers ? (
                        <div className="top-table-spinner-loading-section d-flex align-items-center">
                          <Spinner color="light" style={{ width: '5rem', height: '5rem' }} />
                        </div>
                    ) : (
                      <ScrollAreaWrapper
                        className="chat-body-scroll-area h-100"
                        contentClassName={`online-users-ReactTableContainer`}
                        disableAutoHeight={ignoredUsers && Object.keys(ignoredUsers).length === 0}
                        show={ignoredUsers && Object.keys(ignoredUsers).length > 7 ? true : null}
                        rightOffset={15}
                        topOffset={33}
                        bottomOffset={-37}
                      >
                        {(ignoredUsers && sortedKeysIgnoredUsers.length > 0) ? sortedKeysIgnoredUsers.map(key => ignoredUsers[key].name && ignoredUsers[key].date && (
                          <Fragment>
                            {ignoredUsers[key] && (
                            <Row key={key} className="ignored-users-table-row">
                              <Col sm="3" className="ignored-users-table-row-col ignored-users-table-row-col-text text-left">
                                {ignoredUsers[key].name}
                              </Col>
                              <Col sm="3" className="ignored-users-table-row-col ignored-users-table-row-col-text text">
                                {ignoredUsers[key].lvl}
                              </Col>
                              <Col sm="3" className="ignored-users-table-row-col ignored-users-table-row-col-text">
                                {/*  <Moment format="DD-MM-YYYY, HH:mm" locale="lv">
                                    {ignoredUsers[key].date}
                                  </Moment> */}
                                <CustomDate format="DD-MM-YYYY, hh:mm" date={ignoredUsers[key].date} />
                              </Col>
                              <Col sm="3" className="ignored-users-table-row-col">
                                <Button color="link" className="ignored-users-table-button" onClick={() => this.unblock(key)}>
                                  {t('ignoredUsers.unBlock')}
                                </Button>
                              </Col>
                            </Row>
                            )}
                          </Fragment>
                        )) : <div className="d-flex h-100 align-items-center mt-2">{t('ignoredUsers.ignoredUsersEmptyEntries')}</div>}
                      </ScrollAreaWrapper>
                    )}
                  </>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  soundOn: state.userSettings.soundOn,
  ignoredUsers: state.member.ignoredUsers,
  ignoredByUsers: state.member.ignoredByUsers,
});

const mapDispatchToProps = {
  unBlockUserFunc: unBlockUser,
  fetchIgnoredPlayers: getIgnoredPlayers,
  fetchIgnoredByPlayers: getIgnoredByPlayers,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(IgnoredUsers));
